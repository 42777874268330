/**
 * @file edit-modes/EditMode.ts
 * @author Austin Day 2023
 * @description The base for toggleable edit modes
 * allows you to temporarily change properties of your canvas, then restore them after exiting the mode
 */

import { EditorClass } from "../.."
import { ModeEvent } from "../../events"
import { Selectable } from "../../controllers"

export interface ModeOptions {
	/**
	 * @private
	 * This is passed by the mode controller to the mode itself to give it an "exit" function internally. Does nothing when passed outside.
	 **/
	functionClearMode?: EditorModeClearFunction
}

type EditorModeClearFunction = (cancelled: boolean) => ModeEvent
export abstract class Mode<T extends ModeOptions> {
	protected clearEditorMode: EditorModeClearFunction

	abstract cancellable: boolean

	private active: boolean = false
	protected options: T
	public isTransformMode = false
	declare editor: EditorClass

	selection?: Selectable

	constructor(editor: EditorClass, options: T) {
		this.editor = editor
		this.options = options
		if (!options.functionClearMode)
			throw `functionClearMode not being passed to ${this.constructor.name}. This is required for proper mode functionality.`
		this.clearEditorMode = options.functionClearMode
	}

	abstract get modeTitle(): string

	//#region    ===========================				  Interface 		 		==============================

	/**
	 * Exit the current mode gracefully
	 * @param args
	 */
	complete(...args: any) {
		this.completeMode(...args)
	}

	/**
	 * Cancels the current mode. If the mode is set to not be cancellable, throws an error.
	 * Called by editor if another mode is being set while this one is still active
	 */
	cancel() {
		if (!this.cancellable) throw Error(`Mode ${this.constructor.name} cannot be cancelled`)
		this.cancelMode()
	}

	/**
	 * Handler that can be set to fire before the editor leaves this mode
	 */
	onbeforeexit?: (mode: this, cancelled: boolean) => any

	/**
	 * Handler that can be set to fire after the editor leaves this mode
	 * @param event A ModeEvent of type "mode:complete" or "mode:cancelled"
	 */
	onexit?: (mode: this, cancelled: boolean) => any

	protected priorSelection: Selectable[]
	/**
	 * Set the selection override - this is the intended way to change selection from within a mode!
	 * @param selection an item to set as selection. If left blank, clears the selection instead.
	 */
	protected setSelection(selection?: Selectable) {
		this.editor.selection.unlock()
		if (!selection) {
			this.editor.selection.clear({ origin: "mode" })
		} else {
			this.editor.selection.set_(selection, "mode")
		}
		this.editor.selection.lock(this)
	}

	/**
	 * Clear the canvas selection and lock it so no more selections can be made until the mode is done
	 */
	protected clearSelection() {
		this.setSelection()
	}

	/**
	 * Restore whatever you had selected before entering this mode
	 */
	protected restoreSelection() {
		this.setSelection(this.priorSelection.length > 0 ? this.priorSelection[0] : undefined)
	}

	//#endregion =====================================================================================================

	//#region    ===========================				 Entry Points 		 		==============================

	/**
	 * Apply this edit mode to the editor. This will automatically be called by editor.setMode(this)
	 * Automatically backs up canvas options and calls this.onActivate
	 */
	public activate(options: T) {
		if (this.active) throw Error("mode.activate() is being called redundantly")
		this.priorSelection = this.editor.selection.get()

		this.onActivate(options)
		this.active = true
	}

	/**
	 * Unset the editor mode and automatically restore prior properties.
	 * Automatically restores canvas option to what they were when activateMode() was called
	 */
	private deactivateMode(cancelled: boolean): void {
		if (this.onbeforeexit) this.onbeforeexit(this, cancelled)

		if (this.onDeactivate) this.onDeactivate()
		this.editor.selection.unlock()

		this.clearEditorMode(cancelled)

		if (this.onexit) this.onexit(this, cancelled)
	}

	/**
	 * Exit the editor mode with a "complete" event
	 */
	private completeMode(...args: any) {
		if (this.onComplete) this.onComplete(...args)
		this.deactivateMode(false)
	}

	/**
	 * Exit the editor mode with a "cancel" event
	 */
	private cancelMode() {
		if (this.onCancel) this.onCancel()
		this.deactivateMode(true)
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Implementation		 		==============================

	/** Mode specific behavior that is applied when activate() is called */
	protected onActivate?(options: T): void

	/**
	 * Unset any mode-specific behavior from onActivate
	 * Always fires when mode is being deactivated
	 * Fires before settings are restored
	 */
	protected onDeactivate?(): void

	/**
	 * Behavior for when mode is exited with complete()
	 * Fires before onDeactivate
	 */
	protected onComplete?(...args: any): void

	/**
	 * Behavior for when mode is exited with cancel()
	 * Fires before onDeactivate
	 */
	protected onCancel?(): void

	//#endregion =====================================================================================================
}

export type ModeConstructor<T> = T extends Mode<any> ? new (...args: any) => T : never
export type ModeOptionsArg<T> = T extends new (
	arg1: EditorClass,
	arg2: infer U,
	...args: any[]
) => any
	? U
	: any
