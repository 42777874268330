import { fabric } from "fabric"
import { Path } from "."
import { getEditor } from "../../.."

export class PathControl extends fabric.Control {
	index: number
	path: Path

	constructor(path: Path, index: number, fabricOptions?: Partial<fabric.Control>) {
		super(fabricOptions)
		this.path = path
		this.index = index

		this.actionHandler = this.actionHandler.bind(this)
	}

	get point(): fabric.Point {
		return this.path.points[this.index]
	}

	positionHandler(dim, finalmatrix, fabricObject, currentControl) {
		if (!this.point) return

		const position = fabric.util.transformPoint(
			this.path.toAbsolutePoint(this.point),
			this.path.canvas.viewportTransform
		)
		return position
	}

	actionHandler(eventData: MouseEvent, transform: fabric.Transform, x: number, y: number) {
		const newPoint = this.path.getRelativePointer(eventData)
		newPoint.addEquals(this.path.pathOffset)

		this.path.editPoint(this.index, newPoint)

		return true
	}

	get canvas() {
		return getEditor().canvas
	}
	set canvas(v) {
		return
	}

	actionName = "pointEdit"
}
