/**
 * This mode is to be extended from in order to standardize all 'transform mode' modes (e.g., EditPolygon, PerspectiveEdit)
 */

import { EditorClass } from "../.."
import { CanvasAsset } from "../../asset"
import { Selectable } from "../../controllers"
import Mode, { ModeOptions } from "../../modules/mode"
import { TransformMode } from "./TransformMode"

export interface TransformBaseOptions extends ModeOptions {
	target: CanvasAsset | fabric.Polyline
}

export abstract class TransformBase<T extends TransformBaseOptions> extends Mode<T> {
	target: CanvasAsset | fabric.Polyline

	protected cancelOnSelection: boolean = true // Calls _onSelect when selection changes if true
	protected unlockSelection: boolean = false // Allows for selection to change while in this mode

	public isTransformMode = true

	constructor(editor: EditorClass, options) {
		super(editor, options)

		this.selection = options.transformTarget ?? options.target
	}

	protected _onTargetChange = () => {
		this.editor.mode.set(TransformMode, {
			functionClearMode: this.clearEditorMode,
		})
	}

	protected abstract save(): void

	protected onDeactivate(): void {
		this.save()

		this.restoreSelection()
	}

	protected declare priorSelection: Selectable[]

	/**
	 * Set the selection override - this is the intended way to change selection from within a mode!
	 * @param selection an item to set as selection. If left blank, clears the selection instead.
	 */
	protected setSelection(selection?: Selectable) {
		if (!this.unlockSelection) this.editor.selection.unlock()
		if (!selection) {
			this.editor.selection.clear({ origin: "mode" })
		} else {
			this.editor.selection.set_(selection, "mode")
		}
		if (!this.unlockSelection) this.editor.selection.lock(this)
	}
}
