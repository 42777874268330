import { get, writable, type Writable } from "svelte/store"
import type { OverlapInstance } from ".."
import { LuxedoRPC } from "luxedo-rpc"
import { DataHandlerDevice, type DeviceGroup, type DeviceGroupSlot } from "luxedo-data"

export namespace BlendZoneEditorController {
	export type Point = { x: number; y: number }
	export type ContextType = {
		group: DeviceGroup
		overlap: OverlapInstance
		mainSlotID: number
		pathPoints: Array<Point>
		onSave: (imageData: string | null) => any
	}

	const CONTEXT_DEFAULT = {
		group: undefined,
		pathPoints: undefined,
		overlap: undefined,
		mainSlotID: undefined,
		onSave: undefined,
	}

	let store: Writable<ContextType> = writable(CONTEXT_DEFAULT)
	let hasInitialized = false

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function initialize(
		overlap: OverlapInstance,
		slotID: number,
		group: DeviceGroup,
		onSave: (imageData: string | null) => any
	) {
		store.set({ overlap, mainSlotID: slotID, group, pathPoints: undefined, onSave })
	}

	export function reset() {
		hasInitialized = false
		Canvas.resetCanvas()
	}

	export function setPathPoints(points: Array<Point>) {
		store.update((ctx) => ({ ...ctx, pathPoints: points }))
	}

	export function save(imageData: string) {
		const { group, overlap, mainSlotID, onSave } = get(store)
		const slot = overlap.slots.find((s) => s.id === mainSlotID)
		const other = overlap.slots.find((s) => s.id !== mainSlotID)

		let b64Data = null

		if (imageData) {
			b64Data = imageData.split("base64,")[1]
		}

		onSave && onSave(b64Data)
		//await LuxedoRPC.api.device.device_group_set_custom_overlap(group.id, slot.device_id, other.device_id, b64Data)
	}

	export function createImage() {
		const { overlap, pathPoints } = get(store)

		const canvas = document.createElement("canvas")

		let width = overlap.rect.right - overlap.rect.left
		let height = overlap.rect.bottom - overlap.rect.top

		canvas.width = width
		canvas.height = height

		const context = canvas.getContext("2d")

		context.fillStyle = "black"
		context.beginPath()
		context.moveTo(pathPoints[0].x, pathPoints[0].y)

		for (const point of pathPoints) {
			const multiplier = Canvas.isSizeHalved ? 2 : 1
			context.lineTo(point.x * multiplier, point.y * multiplier)
		}

		context.closePath()
		context.fill()

		const data = canvas.toDataURL("image/png")

		return data
	}

	export namespace Canvas {
		let canvas: HTMLCanvasElement

		export let isSizeHalved: boolean
		let width: number
		let height: number

		async function drawSnapshot() {
			const { mainSlotID, overlap } = get(store)
			const slot = overlap.slots.find((s) => s.id === mainSlotID)

			const device = DataHandlerDevice.get(slot.device_id)
			const snapshot = await device.getSnapshot()

			const context = canvas.getContext("2d")

			const img = document.createElement("img")

			img.src = snapshot.src
			img.style.backgroundColor = "transparent"

			const pos = getSlotPosition(slot)

			context.drawImage(img, pos.x, pos.y, pos.width, pos.height)
		}

		/**
		 * Gets the slot position of the provided slot. Used for positioning the snapshot.
		 */
		export function getSlotPosition(slot: DeviceGroupSlot): {
			x: number
			y: number
			width: number
			height: number
		} {
			const { overlap } = get(store)
			const device = DataHandlerDevice.get(slot.device_id)

			const pos = {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
			}

			pos.x = slot.pos_x - overlap.rect.left
			pos.y = slot.pos_y - overlap.rect.top
			pos.width = device.resX * slot.scale_x
			pos.height = device.resY * slot.scale_y

			if (isSizeHalved) {
				pos.width = pos.width / 2
				pos.height = pos.height / 2

				pos.x = pos.x / 2
				pos.y = pos.y / 2
			}

			return pos
		}

		/**
		 * Sets the canvas size.
		 * If the canvas is too large for the window, the size of the canvas is halved.
		 */
		export function initializeCanvas(elem: HTMLCanvasElement) {
			canvas = elem

			const { overlap } = get(store)
			if (hasInitialized) return

			hasInitialized = true
			width = overlap.rect.right - overlap.rect.left
			height = overlap.rect.bottom - overlap.rect.top

			isSizeHalved = false

			const vw = window.innerWidth / 100
			const vh = window.innerHeight / 100

			if (width > window.innerWidth - (6 * vw + 64) || height > window.innerHeight - (11 * vh + 64)) {
				isSizeHalved = true
				width = width / 2
				height = height / 2
			}

			resetCanvas()

			return { width, height, isSizeHalved }
		}

		/**
		 * Clears the canvas and calls drawSnapshot to reset.
		 */
		export function resetCanvas() {
			setPathPoints(undefined)
			canvas?.getContext("2d")?.reset()
			drawSnapshot()
		}
	}
}
