<script lang="ts">
	import { closeCtxMenu } from "./ctxMenuControl"
	import { ctxMenuStore } from "./ContextMenuStore"

	import ArrowForward from "../icons/ArrowForward.svelte"
	import ContextSubMenu from "./ContextSubMenu.svelte"

	import type {
		ContextMenuComponent,
		ContextMenuConfig,
		ContextMenuOption,
		ContextMenuOptionWithSubmenu,
		ContextMenuOptionWithoutSubmenu,
		ContextMenuOptions,
	} from "./ContextMenuTypes"

	let hasTabbed = false
	let config: ContextMenuConfig
	let ctxMenuRef: HTMLDivElement
	let ctxMenuRect: DOMRect

	let ctxMenuBgStyle: string = `pointer-events: none;`

	let subMenuConfig: ContextMenuConfig
	let subMenuButtonIndex: number
	let subMenuButtonRect: DOMRect

	let pos = {
		x: 0,
		y: 0,
	}

	let opacityAmt = 0

	let buttonRefs: Array<HTMLButtonElement> = []

	function initTab() {
		hasTabbed = true
		setTimeout(() => {
			buttonRefs[0].focus()
		})
	}

	/**
	 * Opens the submenu at the correct button index
	 */
	function openSubmenu(
		e: MouseEvent & {
			currentTarget: EventTarget & HTMLButtonElement
		},
		options: ContextMenuOptions,
		buttonIndex: number
	) {
		subMenuConfig = {
			isOpen: true,
			options,
			position: {
				x: 0,
				y: 0,
			},
		}
		subMenuButtonIndex = buttonIndex
		subMenuButtonRect = e.currentTarget.getBoundingClientRect()
	}

	/**
	 * Sets the position to the mouse position (set by the store)
	 */
	function updatePosition() {
		hasTabbed = false
		if (config.isOpen) {
			pos = config.position
			setTimeout(() => {
				restrictCtxMenuToWindow()
				opacityAmt = 100
			})
		}

		setTimeout(() => {
			ctxMenuBgStyle = "pointer-events: all"
		})
	}

	/**
	 * Restricts the position of the context menu to within the window
	 */
	function restrictCtxMenuToWindow() {
		const { right, bottom } = ctxMenuRef.getBoundingClientRect()
		if (right > window.innerWidth) {
			pos.x = pos.x - (right - window.innerWidth + 8)
		}
		if (bottom > window.innerHeight) {
			pos.y = pos.y - (bottom - window.innerHeight + 8)
		}
		setTimeout(() => {
			ctxMenuRect = ctxMenuRef.getBoundingClientRect()
		})
	}

	/**
	 * Closes submenu
	 */
	function closeSubmenu() {
		ctxMenuBgStyle = "pointer-events: none"

		opacityAmt = 0
		subMenuConfig = undefined
		subMenuButtonIndex = undefined
		subMenuButtonRect = undefined
	}

	ctxMenuStore.subscribe((val) => (config = val))
	$: config.isOpen && updatePosition()
	$: !config.isOpen && closeSubmenu()
</script>

<!-- style={`${config.isOpen ? "pointer-events: unset" : ""}`} -->

{#if config.isOpen}
	<div class="ctxMenuBg" style={ctxMenuBgStyle} on:mousedown|self={closeCtxMenu}>
		<div
			bind:this={ctxMenuRef}
			class="ctxMenu"
			style={`
			opacity: ${opacityAmt}%;
			left: ${pos.x}px;
			top: ${pos.y}px;
			`}
		>
			{#each config.options as option, i}
				{#if option.title}
					<button
						bind:this={buttonRefs[i]}
						tabindex={i + 1}
						on:mouseover={(e) => {
							if (option.onMouseOver) option.onMouseOver(e)
						}}
						on:mouseleave={(e) => {
							if (option.onMouseLeave) option.onMouseLeave(e)
						}}
						on:click={(e) => {
							if ("hasSubmenu" in option) {
								openSubmenu(e, option.submenu, i)
							} else {
								closeCtxMenu()
								option.onClick(e)
							}
						}}
						style={option.style}
						class="icon ctxMenuOpt"
					>
						{#if "icon" in option}
							<svelte:component this={option.icon} />
						{/if}
						{option.title}
						{#if "hasSubmenu" in option}
							<ArrowForward strokeColor="var(--color-text-light)" />
							<!-- {:else if "icon" in option}
						<svelte:component this={option.icon} /> -->
						{/if}
						{#if "shortcut" in option}
							<span class="ctx-menu-keyboard-shortcut">
								{option.shortcut}
							</span>
						{/if}
					</button>
				{:else}
					<svelte:component this={option.comp} {...option.props} />
				{/if}
				{#if subMenuButtonIndex === i}
					<ContextSubMenu
						config={subMenuConfig}
						parentBtnAmount={buttonRefs.length}
						parentMenuRect={ctxMenuRect}
						parentButtonRect={subMenuButtonRect}
						{closeCtxMenu}
					/>
				{/if}
			{/each}
		</div>
	</div>
{/if}
<svelte:window
	on:keydown={(e) => {
		if (e.key === "Escape") closeCtxMenu()
		if (e.key === "Tab" && config.isOpen && !hasTabbed) initTab()
	}}
/>

<!-- on:click={(e) => {
		if (!e.target.classList.contains("ctxMenu")) {
			closeCtxMenu()
		}
	}} -->

<style>
	.ctxMenuBg {
		pointer-events: none;
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.ctxMenu {
		pointer-events: all;
		transition: opacity var(--transition-duration);
	}

	.ctx-menu-keyboard-shortcut {
		position: absolute;
		right: 0;
		color: var(--color-text);
	}

	button :global(svg) {
		padding: 0.25rem;
	}
</style>
