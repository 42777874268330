import type { Device } from "luxedo-data"
import { openOverlay } from "svelte-comps/overlay"
import DeviceUpdateInformer from "./DeviceUpdateInformer.svelte"

export function openUpdateInformerOverlay(device: Device, updateVersion: string, isBeta?: boolean) {
	openOverlay(DeviceUpdateInformer, {
		classHeading: "no-underline",
		props: {
			device,
			version: updateVersion,
			isBeta,
		},
	})
}
