import { Controller } from "svelte-comps/stores"

class LoginCtrl extends Controller<"login" | "reset_request" | "reset_password"> {

	resetToken: string
	resetEmail: string
	initResetPassword = (token:string, email: string) => {
		this.resetToken = token
		this.resetEmail = email
		this.store.set("reset_password")
	}

	goToReset = () => {
		this.store.set("reset_request")
	}

	goToLogin = () => {
		this.store.set("login")
	}
}

export const LoginControler = new LoginCtrl("login")
