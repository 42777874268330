import { DataHandlerCalibration, DataHandlerSnapshot, type Device, type Snapshot } from "luxedo-data"
import {
	DeviceCalibrationManager,
	type CalibrationProgress,
} from "../../../../modules/device-operation-managers/DeviceCalibrationManager"
import { Controller } from "svelte-comps/stores"
import type { Unsubscriber } from "svelte/store"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import CalibrationOverlay from "./calibration-overlay/CalibrationOverlay.svelte"
import { Toast } from "svelte-comps/toaster"
import { hideMinimizedCalibration, openMinimizedCalibration } from "./calibration-minimized"

export enum CalibrationStep {
	"Instructions" = 0,
	"Loading" = 1,
	"Finished" = 2,
	"Failed" = -1,
	"Troubleshooter" = -2,
}

type ContextType_Running = {
	step: CalibrationStep
	progress: CalibrationProgress
}

type ContextType_Success = {
	step: CalibrationStep
	snapshot: Snapshot
	progress: CalibrationProgress
}

type ContextType_Failed = {
	step: CalibrationStep
	failImages?: Array<string>
	progress: CalibrationProgress
}

type ContextType = ContextType_Running | ContextType_Failed | ContextType_Success

class CalOverlayController extends Controller<ContextType> {
	device: Device
	overlayID: string
	unsubscriber: Unsubscriber

	constructor() {
		super({
			step: 0,
			progress: {
				progress: 0,
				message: "Initializing...",
				description:
					"Calibration is the process of projecting patterns from your Luxedo, taking pictures of the projection using the onboard camera, then processing the images to create a snapshot that truly reflects your projection space.",
			},
		})
	}

	reset = () => {
		super.reset()
		if (this.unsubscriber) this.unsubscriber()
	}

	open = (device: Device, skipReset?: boolean) => {
		if (!skipReset) this.reset()
		this.device = device

		this.overlayID = openOverlay(CalibrationOverlay, {
			heading: `Calibrating ${device.name}`,
			classHeading: "no-underline",
			beforeClose: async () => {
				const step = this.get("step")
				if (step === CalibrationStep.Loading) openMinimizedCalibration(device)
				else {
					if (step !== CalibrationStep.Failed) DeviceCalibrationManager.cleanUp(this.device)
					this.reset()
				}
			},
		})
	}

	close = () => {
		closeOverlay(this.overlayID)
	}

	cancel = () => {
		this.close()
		hideMinimizedCalibration()
		DeviceCalibrationManager.cancelCalibration(this.device)
		this.device = undefined
	}

	retry = () => {
		this.reset()
		this.calibrate()
	}

	calibrate() {
		this.reset()
		const store = DeviceCalibrationManager.startCalibration(this.device, this.onSuccess, this.onFailure)

		this.unsubscriber = store.subscribe((progress) => {
			console.warn("PROGRESS UPDATE", { progress })
			this.store.update((ctx) => ({ ...ctx, step: CalibrationStep.Loading, progress: { ...progress } }))
		})
	}

	onSuccess = (snapshot: Snapshot, deviceID: number) => {
		DataHandlerCalibration.pull()
		DataHandlerSnapshot.pull()

		if (this.device.id !== deviceID) return

		if (this.unsubscriber) this.unsubscriber()
		this.store.update((ctx) => ({ ...ctx, step: CalibrationStep.Finished, snapshot }))
	}

	onFailure = (images: Array<string> | undefined, deviceID: number) => {
		if (this.device.id !== deviceID) return

		if (this.unsubscriber) this.unsubscriber()
		this.store.update((ctx) => ({ ...ctx, step: CalibrationStep.Failed, failImages: images }))
	}

	async userUnsatisfied() {
		const images = await DeviceCalibrationManager.getCalibrationImages(this.device)
		if (!images) Toast.error("Unable to retrieve calibration images. [Error code: PNC-004]")

		this.store.update((ctx) => ({
			...ctx,
			step: -2,
			failImages: images,
		}))
	}
}

export const CalibrationOverlayController = new CalOverlayController()
