<script lang="ts">
	import type { Scene } from "luxedo-data"
	import { DateTime } from "luxon"
	import DownloadIcon from "../../../icons/DownloadIcon.svelte"

	export let scene: Scene
</script>

<div>
	<h3>Scene Details</h3>
	<div class="details">
		<ul>
			<li>
				<span class="label">Duration:</span>
				{scene.duration ?? 0} sec
			</li>
			<li>
				<span class="label">Resolution:</span>
				{scene.res_x} x {scene.res_y}
			</li>
			<li>
				{#if scene.isDirectUpload}
					<span class="label">Uploaded on:</span>
				{:else}
					<span class="label">Last render:</span>
				{/if}
				{#if scene.render_ver}
					{DateTime.fromJSDate(scene.render_ver).toFormat("ff")}
					<button class="icon small" id="download-button" on:click={scene.triggerDownload}>
						<DownloadIcon />
					</button>
				{:else}
					Never
				{/if}
			</li>
		</ul>
	</div>
</div>

<style>
	li {
		display: flex;
		align-items: center;
	}

	li > span {
		margin-right: 0.25rem;
	}

	#download-button {
		height: 1rem;
		margin-left: 0.5rem;
	}

	#download-button :global(svg) {
		height: 1.25rem;
	}
</style>
