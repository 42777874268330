<script lang="ts">
	import type { Media } from "luxedo-data"

	export let media: Media
</script>

<img src={media.src.compressed} alt={media.name} />

<style>
	img {
		object-fit: contain;
		height: 100%;
		width: 100%;
	}
</style>
