/**
 * @file asset/canvas/Audio.ts
 * @author Austin Day 2023
 */
import { DataHandlerMedia } from "luxedo-data"
import { Asset, type AssetOptions } from ".."
import { EditorClass } from "../.."
import { convertInstanceToObject, registerSerializableConstructor } from "../../modules/serialize"
import { Track } from "../../tracks"
import { AudioSource } from "./AudioSource"

export interface AudioOptions extends AssetOptions {
	/** Unique ID for this audio's media, used for importing and exporting */
	mediaId: number

	/** Source URL of the audio */
	src: string
}

export interface AudioAsset extends Asset {
	AnimatableProperty: never
	mediaId: number
	content: AudioSource
	getTrack(): Track<AudioAsset>
}

/**
 * Create a fabric Audio with extra properties for the editor
 */
@Asset.Mixin
export class AudioAsset extends Asset {
	//#region    ===========================		   		Construction 		 		==============================

	editor: EditorClass

	/**
	 * Create a fabric Audio object suited for the luxedo editor
	 * @param options
	 * @param importOptions extra options used when loading from a json file
	 */
	constructor(editor: EditorClass, options: AudioOptions) {
		super()
		this.editor = editor
		this.initAsset(options)

		this.mediaId = options.mediaId
		this.load(options.src)
	}

	onDelete(): void {
		this.clearEvents()
		this.content.pause()
		delete this.content
	}

	//#region    ===========================				   Controls					==============================

	/**
	 * The underlying audio DOM element
	 */
	// content: HTMLAudioElement

	isLoading: boolean = false
	public loadingPromise: Promise<void>

	get duration() {
		if (this.content.duration) return this.content.duration
		else return DataHandlerMedia.get(this.mediaId).duration
	}

	get currentTime() {
		return this.content.currentTime
	}

	set currentTime(positionSeconds: number) {
		if (positionSeconds > this.content.duration) throw RangeError("Position cannot be greater than duration")
		this.content.currentTime = positionSeconds
	}

	//#region    ===========================				Animation Logic				==============================

	bindTrack(track: Track<any>): void {
		super.bindTrack(track)

		const onTick = this.editor.timeline.on("tick", (e) => {
			if (!this.editor.timeline.playing) this.content.pause()
			if (!this.track || !this.track.isVisible()) return this.content.pause()

			if (this.editor.timeline.playing && this.content.paused) {
				this.currentTime = (e.timestamp - this.track.start) % this.duration
				this.content.play()
			}
		})

		const onSeek = this.editor.timeline.on("preview:seek", (e) => {
			if (!this.track || !this.track.isVisible()) return

			this.currentTime = (e.timestamp - this.track.start) % this.duration
		})

		const onStop = this.editor.timeline.on("preview:stop", (e) => {
			if (!this.track?.isVisible()) return

			this.currentTime = (e.timestamp - this.track.start) % this.duration
		})

		this.behaviorHandlers.push(onTick, onSeek, onStop)
	}

	//#endregion =====================================================================================================

	//#endregion =====================================================================================================

	/**
	 * Fetches the audio data, decodes it and returns an audio buffer.
	 */
	async getAudioSource(url: string) {
		this.content = new AudioSource(url)
		await this.content.load()
	}

	/**
	 * Load an audio from the specified URL and return it as an HTML audio element
	 * On load, re-renders the canvas (if applicable)
	 * On error, sets the source to the missing source placeholder
	 * @param url URL of the audio to load
	 */
	private async load(url: string): Promise<void> {
		this.isLoading = true

		this.loadingPromise = this.getAudioSource(url)
		await this.loadingPromise

		this.content.currentTime = 0
		this.isLoading = false
	}

	get volume() {
		return this.content.volume
	}

	set volume(newVolume: number) {
		this.content.volume = newVolume
	}

	//#region    ===========================		  Serialization			==============================

	toObject(propertiesToInclude?: string[]) {
		const json = {}

		if (!propertiesToInclude) propertiesToInclude = this.getDefaultPropertyExports().include
		for (const prop of propertiesToInclude) {
			if (prop in this) json[prop] = this[prop]
			else throw new Error(`Unable to find ${prop} in ${this.constructor.name}.`)
		}

		return json
	}

	static async loadJSON(
		editor: EditorClass,
		data: Partial<AudioAsset> & {
			media?: any
		},
		id?: string
	) {
		let clone: AudioAsset
		if (data.media) {
			clone = new AudioAsset(editor, {
				mediaId: data.media.id,
				src: data.media.src.editorPreview,
				id,
			})
		} else {
			clone = new AudioAsset(editor, {
				mediaId: data.mediaId,
				src: data["src"],
				id,
			})
		}

		await clone.loadingPromise

		clone.volume = data["volume"]

		return clone
	}

	serialize(forExport?: boolean) {
		let include = []

		if ("mediaId" in this) {
			include.push("mediaId")
		}

		const json = convertInstanceToObject(this, {
			propertiesToInclude: include,
			propertiesToExclude: ["content"],
			forExport,
		})

		json["src"] = this.content.src
		json["volume"] = this.content.volume

		return json
	}

	//#endregion =================================================================================
}

registerSerializableConstructor(AudioAsset)
