<script lang="ts">
	import { DataHandlerDevice, DataHandlerSnapshot, DeviceGroup } from "luxedo-data"
	import CustomSelectInstance from "./CustomSelectInstance.svelte"

	export let selectedSnapshotId: number
	export let selectedDeviceId: number

	let resX: number
	let resY: number

	function updateResolution() {
		const device = DataHandlerDevice.get(selectedDeviceId)
		const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)

		if (device instanceof DeviceGroup || !snapshot) {
			resX = device?.resX
			resY = device?.resY
		} else {
			resX = snapshot?.resolution.w
			resY = snapshot?.resolution.h
		}
	}

	$: (selectedSnapshotId || selectedDeviceId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Select your show</h3>

	<p>Explore the list of trusted experts below to select and purchase a custom show:</p>

	<!-- <div class="link-list">
		<a target="_blank" href="https://www.jollyholidayshows.com/">Jolly Holiday Shows</a>
		<a target="_blank" href="https://fxprojections.com/videos-1">FX Projections</a>
		<a target="_blank" href="https://www.jesterlaughs.com/">Jester Laughs</a>
		<a target="_blank" href="https://holidayprojectionmapping.com/">Holiday Projection Mapping</a>
	</div> -->

	<div class="card-list">
		<CustomSelectInstance
			img="/content-creators/jolly-holiday.webp"
			title="Jolly Holiday Shows"
			url="https://www.jollyholidayshows.com"
		/>
		<CustomSelectInstance
			img="/content-creators/fx-proj.png"
			title="FX Projections"
			url="https://fxprojections.com/"
		/>
		<CustomSelectInstance
			img="/content-creators/jester.webp"
			title="Jerster Laughs"
			url="https://www.jesterlaughs.com/"
		/>
		<CustomSelectInstance
			img="/content-creators/hpm.png"
			title="Holiday Projection Mapping"
			url="https://holidayprojectionmapping.com/"
		/>
	</div>

	<p class="guide">
		Be sure to provide them with the correct resolution <span class="highlight">
			{#if resX && resY}
				({resX ?? "0"}x{resY ?? "0"})
			{/if}
		</span> as well as the calibrated snapshot which you can download in the next step.
	</p>
</div>

<style>
	h3 {
		margin-bottom: 1rem;
	}

	p {
		margin: 0 0 0 0;
		color: var(--color-text);
	}

	.highlight {
		color: var(--color-main);
	}

	.card-list {
		width: fit-content;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 0.5rem;
		margin: 1rem 0;
	}

	.link-list {
		justify-content: center;
		margin-bottom: 2rem;
	}

	.link-list a {
		margin-right: 1rem;
		line-height: 2rem;
	}

	.guide {
		/* align-self: center; */
		/* margin: 1rem 0 0 1rem; */
	}

	a {
		color: var(--color-text-light);
		border-color: var(--color-text-light);
	}

	a:hover,
	a:focus-visible {
		color: var(--color-main);
		border-color: var(--color-main);
	}
</style>
