import { LuxedoRPC } from "luxedo-rpc"
import { Scene, type SceneRawData } from "../.."

class InvalidEffectError extends Error {}

export class SceneFilters {
	sceneId: number
	filters: SceneRawData["filters"]

	constructor(scene: Scene, filters: SceneRawData["filters"]) {
		this.filters = filters
		this.sceneId = scene.id!
	}

	/**
	 * Converts the argument string to an object
	 * @param effect
	 */
	getEffectArguments(effect: string) {
		if (effect in this.filters) {
			const argString = this.filters[effect]
			const args = argString.split(",").map((str) => str.split("="))
			const argObj = {}
			for (const [arg, val] of args) {
				argObj[arg] = val
			}
			return argObj
		} else return {}
	}

	/**
	 * Adds the specified effect to the scene by sending a request to the server
	 * @param effect the filter effect
	 * @param args the filter arguments
	 */
	async addFilter(effect: string, args: string) {
		try {
			await LuxedoRPC.api.scene.project_apply_filter(this.sceneId, effect, args)
			this.filters[effect] = args
		} catch (e) {
			console.error(`An error occurred while trying to apply a filter (${effect}) to scene (${this.sceneId}): `)
			console.error(e)
			if (e.code === 422) throw new InvalidEffectError(e.message)
		}
	}

	/**
	 * Removes the specified effect to the scene by sending a request to the server
	 * @param effect the filter effect
	 */
	async removeFilter(effect: string) {
		try {
			await LuxedoRPC.api.scene.project_remove_filter(this.sceneId, effect)
			if (effect in this.filters) delete this.filters[effect]
		} catch (e) {
			console.error(`An error occurred while trying to remove a filter (${effect}) from scene (${this.sceneId}): `)
			console.error(e)
			if (e.code === 422) throw new InvalidEffectError(e.message)
		}
	}
}
