import { fabric } from "fabric"
import { EditorClass } from "../.."
import { CanvasAsset, Ellipse, Polygon, Path, ClippingMask, MaskBase } from "../../asset"
import Mode, { ModeOptions } from "../../modules/mode"
import { TrackGroup } from "../../tracks"
import { AddShape } from "./AddShape"

// If the user drags their mouse 3px or less, this will count as a single click
const MIN_SHAPE_RADIUS = 3

interface AddMaskOptions_Base extends ModeOptions {
	left: number
	top: number
	width: number
	height: number
	angle: number
	shape: "Ellipse" | "Polygon"
	trackID: string
	name: string
	addOnComplete?: boolean
	invert?: boolean
	data?: { [index: string]: any }
}

interface AddMaskOptions_Ellipse extends AddMaskOptions_Base {
	shape: "Ellipse"
}

interface AddMaskOptions_Polygon extends AddMaskOptions_Base {
	shape: "Polygon"
	numSides: number
}

export type AddMaskOptions = AddMaskOptions_Polygon | AddMaskOptions_Ellipse

export class AddMask extends Mode<AddMaskOptions> {
	cancellable: boolean = true
	cancelReason: string = undefined

	left: number
	top: number
	width: number
	height: number
	angle: number

	shapeOption: "Ellipse" | "Polygon"
	sideAmount: number = undefined
	name: string

	previewPoint: fabric.Point
	initialPoint: fabric.Point
	endPoint: fabric.Point

	shape: Ellipse | Polygon
	mask: MaskBase

	maskData: { [index: string]: any }
	trackID: string
	addOnComplete: boolean

	isTemporaryMaskAttatched: boolean

	defaultHoverCursor: string
	defaultCursor: string

	constructor(editor: EditorClass, options: AddMaskOptions) {
		const modeOptions = { ...MASK_MODE_DEFAULTS, ...options }
		super(editor, modeOptions)

		this.sideAmount = "numSides" in modeOptions ? modeOptions.numSides : undefined
		this.addOnComplete = modeOptions.addOnComplete ?? true
		this.shapeOption = modeOptions.shape
		this.maskData = modeOptions.data
		this.trackID = modeOptions.trackID
		this.height = modeOptions.height
		this.width = modeOptions.width
		this.angle = modeOptions.angle
		this.left = modeOptions.left
		this.name = modeOptions.name
		this.top = modeOptions.top

		this.isTemporaryMaskAttatched = false
	}

	get modeTitle() {
		return `Creating Mask (${this.shapeOption})`
	}

	// #region =================== EVENT LISTENERS ================

	private initializeListeners() {
		this.clearSelection()
		this.cancelReason = undefined

		this.editor.canvas.selection = false
		this.editor.canvas.on("mouse:up", this._canvasClickUp)
		this.editor.canvas.on("mouse:down", this._canvasClickDown)

		document.addEventListener("keypress", this._keypress)
	}

	private clearListeners() {
		this.editor.canvas.selection = true
		this.editor.canvas.off("mouse:up", this._canvasClickUp)
		this.editor.canvas.off("mouse:down", this._canvasClickDown)
		this.editor.canvas.off("mouse:move", this._canvasMouseMove)
		document.removeEventListener("keypress", this._keypress)
	}

	protected _keypress = (e: KeyboardEvent) => {
		e.stopImmediatePropagation()
		if (e.key === "Enter") {
			this.complete()
		} else if (e.key === "Escape" || e.key === "Backspace" || e.key === "Delete") {
			this.cancel()
		}
	}

	protected _canvasClickDown = (e: fabric.IEvent<MouseEvent>) => {
		if (e.button === 3) return // If they are right clicking to move the canvas

		if (!this.initialPoint) this.initialPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)

		this.editor.canvas.on("mouse:move", this._canvasMouseMove)
	}

	protected _canvasClickUp = (e: fabric.IEvent<MouseEvent>) => {
		if (e.button === 3) return // If they are right clicking to move the canvas

		this.endPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)

		// If a different canvas asset is clicked, selected it and cancel this mode (as long as the click is not part of a drag action)
		if (e.target && e.target !== this.mask && !this.isTemporaryMaskAttatched) {
			const target = e.target
			this.cancelReason = "selection"
			this.cancel()

			return setTimeout(() => {
				this.editor.selection.set(target)
			})
		}

		if (!this.isTemporaryMaskAttatched) {
			this.generateMask()
		}

		this.editor.canvas.clearTemporaryObjects()
		this.complete()
	}

	protected _canvasMouseMove = (e: fabric.IEvent<MouseEvent>) => {
		const newPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)
		this.updateMask(newPoint)

		if (!this.isTemporaryMaskAttatched) {
			this.clearSelection()
			this.editor.canvas.addTemporaryObject(this.mask)
			this.isTemporaryMaskAttatched = true
			this.mask.stroke = "#faae1b"
			this.mask.strokeWidth = 2
		}
	}

	// #endregion ================ EVENT LISTENERS ================
	// #region ================= MODE IMPLEMENTATION ==============

	protected onComplete(...args: any): void {
		if (!this.initialPoint && !this.endPoint) {
			this.initialPoint = new fabric.Point(this.left, this.top)
			this.endPoint = new fabric.Point(this.left, this.top)
		}

		if (this.endPoint.distanceFrom(this.initialPoint) < MIN_SHAPE_RADIUS) {
			if (!this.mask) this.generateMask(this.endPoint)
		} else this.updateMask(this.endPoint)

		if (this.mask) {
			this.mask.overflow = "hidden"
			this.mask.stroke = MaskBase.OPTIONS_FORCED.stroke
			this.mask.strokeWidth = MaskBase.OPTIONS_FORCED.strokeWidth
		}

		this.mask.name = this.name
		this.mask.data = this.maskData
		this.mask.register()

		if (this.addOnComplete) {
			if (this.trackID) {
				const track = this.editor.tracks.getNode(this.trackID)
				track.addMask(this.mask)
			} else {
				const maskedGroup = new TrackGroup<CanvasAsset>(this.editor, {
					name: `${this.name}`,
				})
				maskedGroup.addMask(this.mask)
				this.editor.tracks.attach(maskedGroup)
				this.editor.tracks.emitEvent("tracklist:add", { track: maskedGroup })
			}
		}
	}

	protected onActivate(options: AddMaskOptions): void {
		this.initializeListeners()

		this.defaultCursor = this.editor.canvas.defaultCursor
		this.defaultHoverCursor = this.editor.canvas.hoverCursor

		this.editor.canvas.hoverCursor = "crosshair"
		this.editor.canvas.defaultCursor = "crosshair"
	}

	protected onDeactivate(): void {
		this.clearListeners()
		this.editor.canvas.clearTemporaryObjects()
		this.editor.canvas.hoverCursor = this.defaultHoverCursor ?? "move"
		this.editor.canvas.defaultCursor = this.defaultCursor ?? "default"
	}

	// #endregion ============== MODE IMPLEMENTATION ==============
	// #region ==================== MASK CREATION =================

	public showPreview(position: { left: number; top: number }) {
		const { left, top } = position
		this.previewPoint = new fabric.Point(left, top)
		this.generatePreveiwMask()

		this.editor.canvas.clearTemporaryObjects()
		this.editor.canvas.addTemporaryObject(this.mask)
	}

	protected generateMask(updatedEndPoint?: fabric.Point) {
		let endPoint
		if (updatedEndPoint) {
			endPoint = updatedEndPoint
		} else if (this.endPoint) {
			endPoint = this.endPoint
		} else {
			endPoint = this.initialPoint
		}

		let shape
		if (this.shapeOption === "Ellipse") {
			shape = AddShape.CreateEllipse(this.initialPoint, endPoint, {
				angle: this.angle,
				width: this.width,
				height: this.height,
				temporaryStroke: "#faae1b",
				temporaryStrokeWidth: 2,
			})
		} else if (this.shapeOption === "Polygon") {
			shape = AddShape.CreatePolygon(this.initialPoint, endPoint, this.sideAmount, {
				angle: this.angle,
				width: this.width,
				height: this.height,
				temporaryStroke: "#faae1b",
				temporaryStrokeWidth: 2,
			})
		}

		const path = Path.FromObject(shape)
		this.mask = new MaskBase(path, {
			overflow: "faded",
		})

		this.mask.left = shape.left
		this.mask.top = shape.top

		if (this.initialPoint === endPoint) {
			this.mask.scaleTo(this.width, this.height)
		}
	}

	protected generatePreveiwMask() {
		let shape
		if (this.shapeOption === "Ellipse") {
			shape = AddShape.CreateEllipse(this.previewPoint, this.previewPoint, {
				angle: this.angle,
				width: this.width,
				height: this.height,
				temporaryStroke: "#faae1b",
				temporaryStrokeWidth: 2,
			})
		} else if (this.shapeOption === "Polygon") {
			shape = AddShape.CreatePolygon(this.previewPoint, this.previewPoint, this.sideAmount ?? 4, {
				angle: this.angle,
				width: this.width,
				height: this.height,
				temporaryStroke: "#faae1b",
				temporaryStrokeWidth: 2,
			})
		}

		const path = Path.FromObject(shape)
		this.mask = new MaskBase(path, {
			overflow: "faded",
		})

		this.mask.left = this.previewPoint.x
		this.mask.top = this.previewPoint.y
		this.mask.scaleTo(this.width, this.height)
	}

	protected updateMask(endPoint: fabric.Point) {
		if (!this.mask) return this.generateMask()

		const width = AddShape.getWidth(this.initialPoint, endPoint)
		const height = AddShape.getHeight(this.initialPoint, endPoint)
		const left = AddShape.getLeft(this.initialPoint, endPoint, width)
		const top = AddShape.getTop(this.initialPoint, endPoint, height)

		this.mask.left = left
		this.mask.top = top

		this.mask.scaleTo(width, height)
		this.editor.canvas.requestRenderAll()
	}

	// #endregion ================= MASK CREATION =================

	static CreateAsync(editor: EditorClass, options: AddMaskOptions): Promise<MaskBase> {
		const mode = editor.mode.set(this, options)

		return new Promise((res, rej) => {
			mode.onexit = (m, cancelled) => {
				if (mode.cancelReason) return rej(mode.cancelReason)
				if (cancelled) return rej(mode.mask)
				if (!mode.mask) return rej(undefined)
				res(mode.mask)
			}
		})
	}
}

export const MASK_MODE_DEFAULTS: AddMaskOptions = {
	top: 0,
	name: "New Mask",
	left: 0,
	width: 100,
	height: 100,
	shape: "Polygon",
	numSides: 4,
	angle: 0,
	addOnComplete: true,
	trackID: undefined,
}
