<script lang="ts">
	import BlendZoneControls from "./BlendZoneControls.svelte"
	import BlendZoneCanvas from "./BlendZoneCanvas.svelte"
	import { DataHandlerDevice, DeviceGroup, type DeviceRPi } from "luxedo-data"
	import OverlapName from "./OverlapName.svelte"
	import { BlendZoneEditorController } from "./BlendZoneEditorController"
	import ProjectorIcon from "../../../icons/ProjectorIcon.svelte"
	import type { OverlapInstance } from ".."

	export let group: DeviceGroup
	export let slotID: number
	export let overlap: OverlapInstance
	export let onSave: (imageData: string | null) => any

	let device: DeviceRPi

	function initialize(overlap: OverlapInstance) {
		if (!overlap) return

		device = DataHandlerDevice.get(
			overlap.slots.find((slot) => slot.id === slotID).device_id
		) as DeviceRPi

		BlendZoneEditorController.initialize(overlap, slotID, group, onSave)
	}

	$: initialize(overlap)
</script>

<div id="blend-zone-editor">
	<h3>
		Custom Blend Zone for <span class="device-name">
			<ProjectorIcon {device} />
			{device.name}
		</span>
	</h3>
	<BlendZoneCanvas />
	<BlendZoneControls />
</div>

<style>
	#blend-zone-editor {
		padding: 2rem;
		padding-bottom: 4rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#blend-zone-editor :global(.overlap-instance) {
		margin-top: 0.2rem;
		font-size: var(--h1);
	}

	h3 {
		font-size: var(--h1);
		margin: 0 0 1rem 0;
		width: 100%;
	}

	.device-name :global(svg) {
		height: 1rem;
		width: 2rem;
	}
</style>
