import { LocalTimestamp } from "../../../animation"
import { PositionPath } from "../../../lib"

export type SpeedData = {
	[index: number]: {
		start: number
		end: number
	}
} // Index = point index; values indicate duration of path line segment from point[index-1] to point[index]

export class PathSpeed {
	points: Array<fabric.Point>
	speedData: SpeedData
	animation: PositionPath

	constructor(points: Array<fabric.Point>, animation: PositionPath, speedData?: SpeedData) {
		this.points = points
		this.animation = animation
		this.speedData = speedData ?? {}

		if (!speedData) this.initialize()
	}

	/**
	 * Initializes the speed data (creating an index for all points outside of the 0 point)
	 */
	initialize() {
		const initialKeyframes = this.animation.getBaseKeyframes()
		const timestamps = initialKeyframes.left.keyframes.map((frame) => frame.timestamp)

		const initPoint = (index: number) => {
			this.speedData[index] = {
				start: timestamps[index - 1], // This assumes the loop below starts at 1
				end: timestamps[index],
			}
		}

		for (let i = 1; i < this.points.length; i++) {
			initPoint(i)
		}
	}

	modify(index: number, adjustedTime: number) {
		if (index == 0) return

		let speedStart = this.speedData[index - 1]?.end ?? this.animation.startTime
		let speedEnd = adjustedTime

		if (speedEnd <= speedStart) {
			throw new Error("Cannot adjust speed to start before other point.")
		}

		if (index + 1 in this.speedData) {
			let nextStart = adjustedTime
			let nextEnd = this.speedData[index + 1].end

			if (speedEnd >= nextEnd) {
				throw new Error("Cannot adjust speed to start after other point.")
			}

			this.speedData[index + 1] = {
				start: nextStart,
				end: nextEnd as number,
			}
		}

		this.speedData[index] = {
			start: speedStart as number,
			end: speedEnd,
		}
	}

	remove(index: number) {
		if (index == 0) return
		const { start, end } = this.speedData[index]
	}

	getSpeed(index: number) {
		return this.speedData[index]
	}
}
