import { MaskBase } from "./MaskBase"
import { fabric } from "fabric"
import { MaskLinkedShape } from "./MaskLinkedShape"
import { getEditor } from "../../../.."

export interface MaskData {
	ids: Array<string>
	inverted: boolean
}

/**
 * Use to generate a complex mask for each track.
 */
export class TrackMask extends fabric.Group {
	constructor(masks: Array<MaskBase>) {
		super([], {
			absolutePositioned: true,
			lockMovementX: true,
			lockMovementY: true,
			lockScalingX: true,
			lockScalingY: true,
			originX: "center",
			originY: "center",
			width: getEditor().canvas.canvasWidth + 300,
			height: getEditor().canvas.canvasHeight + 300,
			left: getEditor().canvas.canvasWidth / 2,
			top: getEditor().canvas.canvasHeight / 2,
		})

		console.log(this)

		this._objects = masks.map((base) => new MaskLinkedShape(base, this))
	}

	get baseMasks(): Array<MaskBase> {
		return this.masks.map((mask) => getEditor().masks.get(mask.baseID))
	}

	get masks(): Array<MaskLinkedShape> {
		return this._objects as Array<MaskLinkedShape>
	}

	get name() {
		const maskNames = this.masks.map((m) => m.name)
		let nm = `(${maskNames.join(" u ")})`
		if (this.inverted) {
			return `~${nm}`
		} else {
			return nm
		}
	}

	has(base: MaskBase)
	has(baseID: string)
	has(base: MaskBase | string) {
		let baseID
		if (typeof base === "string") baseID = base
		else baseID = base.id
		return this.masks.find((maskShape) => maskShape.baseID === baseID)
	}

	getMaskCenter(): fabric.Point {
		let xTotal = 0
		let yTotal = 0

		let pointTotal = 0
		for (const shape of this.masks) {
			pointTotal += shape.points.length
			for (const { x, y } of shape.points) {
				xTotal += (x - shape.pathOffset.x) * shape.scaleX + (shape.left + this.left)
				yTotal += (y - shape.pathOffset.y) * shape.scaleY + (shape.top + this.top)
			}
		}

		const averageX = xTotal / pointTotal
		const averageY = yTotal / pointTotal

		return new fabric.Point(averageX, averageY)
	}

	isUnion() {
		return this.masks.length > 1
	}

	isIntersecting() {
		return !!this.clipPath
	}

	/**
	 * Overloading ensures the group is always larger than the canvas - this fixes an array of inconsistent behaviors
	 */
	_calcBounds(onlyWidthHeight?: boolean): void {
		return
	}
}
