/**
 * This module is to be used as a replacement to the HTMLAudioElement.
 * Because an AudioContext is needed to allow for waveform rendering, this new audio model need to be created.
 * This should work as a replacement to the HTMLAudioElement, as all of the functionality has been wrapped in the same interface (where needed).
 */

import { MediaSourceBase } from "../MediaSource"

export class AudioSource extends MediaSourceBase<["volumechange"]> {
	// Web Audio API
	declare context: AudioContext
	declare buffer: AudioBuffer
	declare source: AudioBufferSourceNode
	protected declare gainNode: GainNode

	// The audio source path
	declare src: string

	// State
	protected declare isPlaying: boolean
	protected declare timestamp: number

	protected declare _muted: boolean
	protected declare _volume: number

	constructor(src: string) {
		super()

		this.src = src
		this._volume = 1
		this._muted = false
		this.context = new AudioContext()
		this.source = this.context.createBufferSource()
	}

	/**
	 * Loads the audio data into the audio context and initializes needed Web Audio API classes
	 */
	public async load() {
		this.timestamp = 0
		this.isPlaying = false

		const res = await fetch(this.src)
		const arrayBuffer = await res.arrayBuffer()

		this.buffer = await this.context.decodeAudioData(arrayBuffer)
		this.gainNode = this.context.createGain()
		this.gainNode.connect(this.context.destination)

		this.resetSource()
	}

	/**
	 * Plays the audio at the current timestamp
	 */
	public play() {
		if (!this.isPlaying) {
			this.source.start(0, this.timestamp)
			this.isPlaying = true
		}
	}

	/**
	 * Pauses the audio
	 */
	public pause() {
		if (this.isPlaying) {
			this.source.stop(0)
			this.isPlaying = false
			this.resetSource()
		}
	}

	/**
	 * Resets the source - this is needed, as an audio source is deleted once a stop() call has been made on it.
	 */
	protected resetSource() {
		this.source = this.context.createBufferSource()
		this.source.buffer = this.buffer
		this.source.loop = true
		this.source.connect(this.gainNode)
	}

	/**
	 * Gets the current pause status of the audio
	 */
	get paused() {
		return !this.isPlaying
	}

	public mute(doMute: boolean): void {
		if (doMute) this.gainNode.gain.value = 0
		else this.gainNode.gain.value = this._volume

		this._muted = doMute
	}

	/**
	 * Sets the volume for the audio
	 */
	set volume(newVolume: number) {
		this._volume = newVolume

		if (!this._muted) {
			this.gainNode.gain.value = newVolume
			if (this.eventListeners.volumechange) {
				this.eventListeners.volumechange.forEach((fn) => {
					fn(this._volume)
				})
			}
		}
	}

	/**
	 * Gets the current volume for the audio
	 */
	get volume() {
		return this._volume
	}

	/**
	 * Gets the audio duration, if the buffer has not yet been created, returns 1
	 */
	get duration() {
		return this.buffer?.duration ?? NaN
	}

	/**
	 * Sets the timestamp and jumps to said new timestamp if playing
	 */
	set currentTime(newTime: number) {
		this.timestamp = newTime
		if (this.isPlaying) {
			this.pause()
			this.play()
		}
	}

	/**
	 * Gets the current timestamp
	 */
	get currentTime() {
		return this.timestamp
	}
}
