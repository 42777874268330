/**
 * @file edit-modes/PolygonEditMode.ts
 * @author Austin Day 2023
 * @description An edit mode which allows you to edit the points of a polygon
 */

import { IEvent } from "fabric/fabric-impl"
import { EditorClass } from "../.."
import { ModeOptions } from "../../modules/mode"
import { EditPath, TransformMode } from "."
import { ClippingMask } from "../../asset"

export interface MaskEditOptions extends ModeOptions {
	mask: ClippingMask
	editStyle?: fabric.IPolylineOptions
	maskOverflowMode?: ClippingMask["overflow"]
}

export interface MaskEditMode extends EditPath, MaskEditOptions {
	/** Stop editing and apply transformation to the shape */
	complete(): void
}

/** Edit mode for editing polygon points */
export class MaskEditMode extends EditPath {
	strokeColor: string // the initial stroke color of the mask
	cancellable = true

	constructor(editor: EditorClass, options: MaskEditOptions) {
		super(editor, {
			...options,
			target: options.mask.path,
			editStyle: {
				...EDIT_STYLE_DEFAULTS,
				...(options.editStyle ?? {}),
			},
			allowAddingPoints: true,
			allowDeletingPoints: true,
			minPoints: 3,
		})

		this.mask = options.mask
		this.mask.selectionPath.opacity = 0
		this.maskOverflowMode = options.maskOverflowMode ?? "faded"

		this.targetEvents["mouse:up"] = this._onClick
	}

	get modeTitle() {
		return "Editing Mask"
	}

	//#region    ===========================		   	   Implementation		 		==============================

	/**
	 * Update selection if applicable - replacement for _onSelect given odd behavior when selection can be changed.
	 */
	protected _onClick = (e: IEvent<MouseEvent>) => {
		if (e.target && e.target !== this.mask && e.target !== this.mask.path) {
			this.editor.selection.unlock()
			console.log(e.target)
			this.editor.selection.set(e.target)
			this.editor.mode.set(TransformMode, {
				functionClearMode: this.clearEditorMode,
			})
		}
	}

	protected overflowVisibilityPrior: ClippingMask["overflow"]
	protected onActivate(options): void {
		super.onActivate(options)

		this.overflowVisibilityPrior = this.mask.overflow
		this.mask.overflow = this.maskOverflowMode

		this.strokeColor = this.mask.path.stroke
		this.mask.path.stroke = "#faae1b"
	}

	protected onDeactivate(): void {
		super.onDeactivate()

		this.mask.path.stroke = this.strokeColor
		this.mask.overflow = this.overflowVisibilityPrior

		this.mask.resetSelectionPath()

		this.editor.canvas.requestRenderAll()
	}

	protected onComplete(...args: any): void {
		super.onComplete(...args)
		this.save()

		this.mask.boundTrack.maskUpdated()
	}

	onPointsEdit = () => {
		this.mask.points = this.mask.path.points
	}

	protected onCancel(): void {
		super.onCancel()

		this.mask.points = this.mask.path.points
		this.mask.like(this.mask.path)
	}

	save(): void {
		super.save()
		this.mask.path.notifySubscribers("point:edit")
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood		 		==============================

	//#endregion =====================================================================================================
}

const EDIT_STYLE_DEFAULTS: fabric.IPolylineOptions = {
	stroke: "#ffffff30",
	strokeWidth: 2,
}
