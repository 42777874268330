import { EditorClass, getEditor } from ".."
import { CanvasAsset, TrackMask } from "../asset"
import { MaskBase } from "../asset/canvas/masks/new-masks/MaskBase"
import { MaskEvent } from "../events/Mask"
import { EventedInstance } from "../events/decorators"
import { Track, TrackLike } from "../tracks"

class MaskOperationError extends Error {}

export interface MaskController extends EventedInstance<MaskEvent> {}

/**
 * @file controller/Masks.ts
 * @author Josiah Eakle 2024
 * @description Controller class to manage each mask within the luxedo editor
 */
@EventedInstance(MaskEvent)
export class MaskController {
	private editor: EditorClass
	private masks: {
		[id: string]: MaskBase
	}

	constructor(editor: EditorClass) {
		this.editor = editor
		this.masks = {}
	}

	all(): MaskBase[] {
		return Object.values(this.masks) ?? []
	}

	isRegistered(mask: MaskBase): boolean
	isRegistered(maskID: string): boolean
	isRegistered(mask: MaskBase | string) {
		if (typeof mask === "string") return mask in this.masks
		return mask.id in this.masks
	}

	register(mask: MaskBase) {
		if (this.isRegistered(mask)) return

		this.masks[mask.id] = mask
	}

	deregister(mask: MaskBase) {
		if (!this.isRegistered(mask)) return

		delete this.masks[mask.id]
	}

	get(maskID: string) {
		if (this.isRegistered(maskID)) return this.masks[maskID]

		return undefined
	}

	getInstances(maskID: string): Array<Track> {
		if (!this.isRegistered(maskID)) return []

		let instances = []
		this.editor.tracks.traverse((track) => {
			if (track.maskData.ids.includes(maskID)) instances.push(track)
		})

		return instances
	}

	// #region ============================= Mask Generation =============================

	getTrackMask(track: TrackLike) {
		if (!track.maskData?.ids?.length) return undefined
		const { ids, inverted } = track.maskData

		let mask: TrackMask
		// If there's only one mask, we return a humble MaskShape
		if (ids.length == 1) {
			mask = new TrackMask([this.get(ids[0])])
		} else {
			// If there's more than one mask, return a ClippingMask which is a union of them
			const masks = ids.map((id) => this.get(id))
			const initialMask = new TrackMask(masks)
			mask = MaskController.Union([initialMask])
		}

		if (inverted) mask = MaskController.Not(mask)
		return mask
	}

	static Not(mask: TrackMask): TrackMask {
		if (mask.masks.length === 1) mask = mask
		else if (mask.isIntersecting()) {
			throw new MaskOperationError(
				"You cannot apply a `NOT` operation to an intersection - it is needlessly complicated and can be avoided through simplification"
			)
		}

		mask.inverted = !mask.inverted
		return mask
	}

	static Union(masks: TrackMask[]): TrackMask {
		if (masks.filter((m) => m.inverted).length > 0) {
			throw new MaskOperationError(
				"Unions between inverted masks are not supported, it's a lot easier to simplify first."
			)
		}
		if (masks.filter((m) => !!m.clipPath).length > 0) {
			throw new MaskOperationError(
				"Unions between multi-mask intersections are not supported since they're hard to resolve and this situation shouldn't arise in our use-case"
			)
		}

		const allMasks = masks
			.flatMap((mask) => {
				return mask.masks
			})
			.map((shape) => getEditor().masks.get(shape.baseID))

		return new TrackMask(allMasks)
	}

	static Intersect(masks: TrackMask[]): TrackMask {
		const invertedMasks = masks.filter((m) => m.inverted)
		const regularMasks = masks.filter((m) => !m.inverted)

		console.warn(masks.map((m) => m.name).join(" n "))

		/**
		 * Set operations have the neat property that the intersection of a bunch of inverted sets
		 * is equal to the inversion of the union of those sets.
		 *
		 * In conjunction with the fact that AND set-operations are commutative,
		 * 	this helps us deal with fabric.js' "inverted mask inside another mask" problem,
		 * 	as we can now move all of our masks to the outermost clipPath.
		 */
		let invertedTerm: TrackMask = undefined

		invertedMasks.forEach((m) => (m.inverted = false))
		if (invertedMasks.length >= 2) {
			invertedTerm = MaskController.Not(MaskController.Union(invertedMasks))
		} else if (invertedMasks.length == 1) {
			invertedTerm = MaskController.Not(invertedMasks[0])
		}

		// If all we have is an inverted term then we have simplified the intersection. Yay!
		if (!regularMasks.length) return invertedTerm

		// Reduce the intersections into a bunch of nested clipPaths, with the inverted masks being the outermost group.
		const intersectedMask = regularMasks.reduce((output, mask) => {
			mask.clipPath = output
			return mask
		}, invertedTerm)

		return intersectedMask
	}

	// #endregion ========================== Mask Generation =============================
}
