<script lang="ts">
	export let onClick: (e: Event) => void
</script>

<button class="actions" on:click={onClick}>
	<div class="action-dot" />
	<div class="action-dot" />
	<div class="action-dot" />
</button>

<style>
	.actions .action-dot {
		width: 0.2rem;
		height: 0.2rem;
		border-radius: 999px;
		background-color: var(--color-text-light);
		margin: 0.05rem 0;

		transition: background-color 250ms;
	}

	.actions {
		line-height: 0;
		background-color: unset;
		border-radius: unset;
		height: unset;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 0.1rem 0.3rem;
		justify-content: center;
		width: 1rem;
		cursor: pointer;
		box-shadow: none;
	}

	.actions:hover .action-dot,
	.actions:focus-visible .action-dot {
		background-color: var(--color-main);
	}
</style>
