<script cript lang="ts">
	import { Toaster } from "svelte-comps/toaster"
	import { Overlay } from "svelte-comps/overlay"

	import { NavigationStore } from "../../stores/NavigationContext"
	import Sidebar from "../nav/Sidebar.svelte"
	import { ROUTES } from "./Routes"
	import Projectors from "./projectors/Projectors.svelte"
	import { ContextMenu } from "svelte-comps/context-menu"
	import { onMount } from "svelte"
	import { LatestVersion } from "../../release-notes"
	import ShowLibrary from "./show-library/ShowLibrary.svelte"
	import MediaLibrary from "./media-library/MediaLibrary.svelte"
	import Schedule from "./schedule/Schedule.svelte"
	import { Tooltip } from "svelte-comps/tooltip"
	import { openWhatsNewOverlay } from "../reusable/overlays/whats-new"
	import CalibrationMinimized from "../reusable/overlays/calibration/calibration-minimized/CalibrationMinimized.svelte"
	import { ScheduleController } from "./schedule/ScheduleController"
	import { DataHandlerDevice, DataHandlerScene } from "luxedo-data"

	let activeRoute: keyof typeof ROUTES

	NavigationStore.subscribe((route) => (activeRoute = route))

	function showWhatsNew() {
		const KEY = "last-visit-version"
		const visitedVersion = localStorage.getItem(KEY)

		if (!visitedVersion || visitedVersion != LatestVersion) {
			openWhatsNewOverlay()
			localStorage.setItem(KEY, LatestVersion)
		}
	}

	onMount(() => {
		if (!(activeRoute in ROUTES)) NavigationStore.set("show-library")

		console.log({ ScheduleController, DataHandlerDevice, DataHandlerScene })

		console.warn("VERSION 1 LOADED")
		setTimeout(() => {
			showWhatsNew()
		})
	})
</script>

<!-- ! Embed when ready to integrate checkout script -->
<svelte:head>
	<script
		src="https://js.chargebee.com/v2/chargebee.js"
		data-cb-site={import.meta.env.VITE_CHARGEBEE_SITE}
	></script>
</svelte:head>

<Sidebar>
	<!-- {#if activeRoute === "dashboard"}
		<Workspace />
	{:else if activeRoute === "library"}
		<Library />
	{:else if activeRoute === "projector"}
		<Projectors />
	{:else}
		<h1>Page not found</h1>
	{/if} -->
	{#if activeRoute === "projector"}
		<Projectors />
	{:else if activeRoute === "show-library"}
		<ShowLibrary />
	{:else if activeRoute === "media-library"}
		<MediaLibrary />
	{:else if activeRoute === "schedule"}
		<Schedule />
	{:else}
		<h1>Page not found</h1>
	{/if}
</Sidebar>

<CalibrationMinimized />
<Tooltip />
<Toaster topMargin={"1rem"} errorBackground="var(--red)" errorColor="var(--color-text-light)" />

<Overlay />
<ContextMenu />

<style>
</style>
