<script lang="ts">
	import DirectUploadForm from "./DirectUploadForm.svelte"

	export let triggerUpload: () => void
	export let postSubmit: () => void

	export let sceneName: string
	export let projectFolder: number
	export let selectedSnapshotId: number
	export let selectedDeviceId: number
	export let newSceneId: number

	export let deepFry: "off" | "low" | "med" | "high" = "off"

	let uploadState: "upload" | "process" | "error"
</script>

<div class="flex-column">
	{#if uploadState === "process"}
		<h3>Processing Video...</h3>
	{:else if uploadState === "error"}
		<h3>Error Uploading Project</h3>
	{:else}
		<div class="flex-row">
			<h3>Upload your Project</h3>

			<div class="input-container select-container editor-input">
				<label for="brightness-modifier">Brightness Booster: </label>
				<!-- disabled={!allowEdit} -->
				<select id="brightness-modifier" name="brightness_modifier" bind:value={deepFry}>
					<option value="off">Off</option>
					<option value="low">Bright</option>
					<option value="med">Brighter</option>
					<option value="high">Brightest</option>
				</select>
			</div>
		</div>
	{/if}
	<DirectUploadForm
		{deepFry}
		{sceneName}
		{projectFolder}
		{selectedSnapshotId}
		{selectedDeviceId}
		bind:currentState={uploadState}
		bind:triggerUpload
		bind:newSceneId
		onComplete={postSubmit}
	/>
</div>

<style>
	.flex-column > h3 {
		margin-bottom: 1rem;
	}

	.flex-row {
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}
</style>
