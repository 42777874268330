import { LuxedoRPC } from "luxedo-rpc"
import { User, type UserChargbeeSubscription } from "../entries/User"
import type {
	ChargebeePlanAddon,
	ChargebeePlanFamily,
	ChargebeePlanPrice,
	ChargebeePlanTitle,
} from "luxedo-rpc/src/routes/chargebee"
import { DateTime } from "luxon"

export class ChargebeeMngr {
	declare allPlans: {
		[key in ChargebeePlanTitle]: { [index: string]: ChargebeePlanFamily }
	}
	declare allPrices: {
		[index: string]: ChargebeePlanPrice
	}
	declare planFamilies: {
		[key in ChargebeePlanTitle]: string[]
	}

	declare allAddons: {
		[index: string]: ChargebeePlanAddon
	}

	declare userPlanSubscriptions: typeof this.user.chargebee

	declare user: User

	constructor() {}

	/**
	 * Populates this class with all of the available chargebee plan data.
	 * This should be called on app load.
	 */
	async pullPlans(user: User) {
		const planData = await LuxedoRPC.api.chargebee.chargebee_plans_fetch()
		this.allPlans = planData.all_plans
		this.allPrices = planData.all_plan_prices
		this.allAddons = planData.all_addons
		this.planFamilies = planData.plan_families
		this.userPlanSubscriptions = user.chargebee
	}

	/**
	 * Gets the family ID assocated with the plan type (LuxLink or Storage).
	 * Because the plans all only have one available family, this will get the first family of the specified plan type.
	 */
	private getFamilyId(planType: ChargebeePlanTitle) {
		return this.planFamilies[planType][0]
	}

	/**
	 * Gets the user's subscription plan for the specified plan type.
	 */
	getUserPlanSubscription(planType: ChargebeePlanTitle) {
		return this.allPlans[planType][this.getFamilyId(planType)]
	}

	/**
	 * Gets the available pricing options for the specified plan type.
	 */
	getPlanPricing(planType: ChargebeePlanTitle): Array<ChargebeePlanPrice> {
		const priceIds = this.allPlans[planType][this.getFamilyId(planType)].prices
		const prices: Array<ChargebeePlanPrice> = []

		for (const priceId of priceIds) {
			const price = this.allPrices[priceId]
			if (price.period_unit === "month")
				prices.unshift(price) // ensure the first price will be monthly
			else prices.push(price)
		}

		return prices
	}

	getPlanPriceById(id: string) {
		return this.allPrices[id]
	}

	/**
	 * Finds the plan which utilizes the spcified plan price.
	 */
	getPlanByPriceId(priceId: string): ChargebeePlanFamily {
		console.log(this.allPrices)

		const price = this.allPrices[priceId]
		const planId = price.item_id
		const family = price.item_family_id
		return this.allPlans[family][planId]
	}

	/**
	 * Gets the relevant addon price for the provided plan price
	 */
	getAddonPricing(planPrice: ChargebeePlanPrice) {
		const plan = this.getPlanByPriceId(planPrice.id)
		const period = planPrice.period_unit

		const addons = plan["addons"]
		if (!addons) throw new Error(`No addons for the specified plan ${plan.id}`)

		const addonId = addons[0]
		const addon = this.allAddons[addonId]
		for (const priceId of addon.prices) {
			const addonPrice = this.allPrices[priceId]
			if (addonPrice.period_unit === period) return addonPrice
		}

		throw new Error(
			`No addon price for the period of ${period} under the specified plan ${plan.id}`
		)
	}

	/**
	 * Returns the price of a plan based on the provided quantity (defaults to one).
	 * This should only be relevant for LuxLink subscriptions.
	 */
	getPricePerUnit(price: ChargebeePlanPrice, quantity: number = 1) {
		if (price.pricing_model === "volume") {
			for (const range of price.tiers) {
				if (
					quantity >= range.starting_unit &&
					(!range.ending_unit || quantity <= range.ending_unit)
				)
					return range.price
			}
		} else return price.price
	}

	/**
	 * Parses the cancel status of the provided subscription.
	 */
	getCancelStatus(
		subscription: UserChargbeeSubscription
	): null | { cancelled: boolean; endDate: DateTime; endDateString: string } {
		if (!subscription || subscription.status === "active") return null

		if (subscription.status === "cancelled") {
			const endDate = DateTime.fromSeconds(subscription.end_date!)
			return {
				cancelled: true,
				endDate,
				endDateString: endDate.toLocal().toFormat("LLL dd, yyyy 'at' HH:mm"),
			}
		}

		if (subscription.status === "non_renewing") {
			const endDate = DateTime.fromSeconds(subscription.end_date!)
			return {
				cancelled: false,
				endDate,
				endDateString: endDate.toLocal().toFormat("LLL dd, yyyy 'at' HH:mm"),
			}
		}

		return null
	}

	/** Utility function to format the price of a chargebee price */
	formatPrice(price: number, currencyCode: string, quantity: number = 1): string {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: currencyCode,
		})

		const amount = (quantity * price) / 100
		return formatter.format(amount)
	}

	/**
	 * Converts the price period unit to a renderable format (month -> monthly)
	 */
	getPlanFrequency(price: ChargebeePlanPrice) {
		if (price.period_unit === "month") return "Monthly"
		else return "Yearly"
	}
}
