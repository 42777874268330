<script lang="ts">
	import { PauseIcon, PlayIcon, SkipForwardIcon } from "../../../icons"
	import type { Media } from "luxedo-data"
	import { BackgroundRemoveController, type MediaToolController } from "../MediaToolController"
	import MediaVideoTimeInput from "../base/MediaVideo_TimeInput.svelte"

	const mediaController = BackgroundRemoveController

	export let media: Media
	export let isPreviewingChanges: boolean = false

	let mediaElem: HTMLVideoElement | HTMLImageElement
	let canvasElem: HTMLCanvasElement
	let isPlaying: boolean = false
	let timestamp: number

	let updateTimestamp: (newDuration: number) => void // passed upward from child component MediaVideoTimeInput to force updates from the current timestamp
	mediaController.subscribe((ctx) => {
		isPlaying = ctx.isPlaying
		timestamp = ctx.timestamp
		if (updateTimestamp) updateTimestamp(ctx.timestamp)
	})

	async function initVideoElem() {
		if (!mediaElem || !canvasElem) return
		await mediaController.initialize(mediaElem, media, canvasElem)
	}

	function handleSpacePress(e: KeyboardEvent) {
		e.preventDefault()
		e.stopPropagation()
		if (e.code === "Space") mediaController.play()
	}

	$: mediaElem && initVideoElem()
</script>

<div class="video-container">
	<canvas style="display: {isPreviewingChanges ? 'unset' : 'none'};" bind:this={canvasElem} />
	{#if media.fileType === "video"}
		<video
			style="display: {isPreviewingChanges ? 'none' : 'unset'};"
			preload="true"
			crossorigin="anonymous"
			bind:this={mediaElem}
		/>
	{:else}
		<img
			style="display: {isPreviewingChanges ? 'none' : 'unset'};"
			crossorigin="anonymous"
			bind:this={mediaElem}
		/>
	{/if}

	{#if media.fileType === "video"}
		<div class="top-row">
			<div class="video-controls">
				<button
					id="skip-backward-button"
					class="icon skip-button"
					on:click={mediaController.skipToStart}
				>
					<SkipForwardIcon />
				</button>
				<button id="play-button" class="icon" on:click={mediaController.play}>
					{#if isPlaying}
						<PauseIcon />
					{:else}
						<PlayIcon />
					{/if}
				</button>
				<button
					id="skip-forward-button"
					class="icon skip-button"
					on:click={mediaController.skipToEnd}
				>
					<SkipForwardIcon />
				</button>
			</div>
			<MediaVideoTimeInput
				bind:duration={timestamp}
				{media}
				bind:updateDuration={updateTimestamp}
				readonly
			/>
		</div>
	{/if}
	<slot />
</div>
<svelte:document on:keydown={handleSpacePress} />

<style>
	.video-container {
		position: relative;
		width: 100%;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.top-row {
		position: absolute;
		bottom: 1.25rem;
		right: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* width: 100%; */
	}

	.top-row :global(.input-container) {
		margin: 0;
	}

	.video-controls {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.top-row :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.top-row :global(.svg-fill) {
		fill: var(--color-main);
	}

	#skip-backward-button :global(svg) {
		transform: rotate(180deg);
	}

	button {
		width: fit-content;
		padding: 0;
	}

	button :global(svg) {
		height: 1.5rem;
	}

	.skip-button :global(svg) {
		height: 1rem;
	}
</style>
