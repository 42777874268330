<script lang="ts">
	import type { Lightshow } from "luxedo-data"
	import { onMount } from "svelte"

	export let lightshow: Lightshow

	let thumbnails: Array<string> = []
	let thumbnailIndex = 0

	let isHovering = false
	let intervalId

	function getThumbnailList() {
		const scenes = lightshow.getUniqueScenes()
		let thumbnailList = []
		let index = 0
		for (const scene of scenes) {
			thumbnailList.push(scene.src.thumbnail)
			if (index === 3) return thumbnailList
		}
		return thumbnailList
	}

	function initSlideshow() {
		if (intervalId) clearInterval(intervalId)
		intervalId = setInterval(() => {
			thumbnailIndex++
			if (thumbnailIndex >= thumbnails.length) thumbnailIndex = 0
		}, 750)
	}

	function cancelSlideshow() {
		clearInterval(intervalId)
	}

	export const onHover = () => {
		isHovering = true
		initSlideshow()
	}

	export const onLeave = () => {
		isHovering = false
		thumbnailIndex = 0
		cancelSlideshow()
	}

	onMount(() => {
		thumbnails = getThumbnailList()
	})
</script>

<div
	class="thumbnail-wrapper"
	role="presentation"
	on:mouseover={onHover}
	on:mouseleave={onLeave}
	on:focus={undefined}
>
	{#if isHovering}
		<img src={thumbnails[thumbnailIndex]} alt="" />
	{:else if thumbnails.length > 3}
		{#each thumbnails as thumbnail}
			<div class="img-wrapper">
				<img src={thumbnail} alt="" />
			</div>
		{/each}
	{:else}
		<img src={thumbnails[0]} alt="" />
	{/if}
</div>

<style>
	.thumbnail-wrapper {
		z-index: 100;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.img-wrapper {
		width: 50%; /* Each image will take up 50% of the container width */
		height: 50%; /* Each image will take up 50% of the container height */
		box-sizing: border-box;
		position: relative;
	}

	img {
		width: 100%;
		height: 100%;
		min-height: unset;
		min-width: unset;
		object-fit: cover; /* This ensures the image covers the entire box without stretching */
	}
</style>
