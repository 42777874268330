import { DataHandlerDevice, DataHandlerLightshow, Lightshow } from "luxedo-data"
import { LuxedoRPC } from "luxedo-rpc"
import { v4 as uuid } from "uuid"

type ProgressReportCallback = (progress: number) => void

class LightshowRenderCtx {
	private callbacks: {
		[index: number]: {
			[index: number]: ProgressReportCallback
		}
	} // Index: lightshow id

	private onCompleteCallbacks: {
		[index: number]: (lightshow: Lightshow) => void
	}

	private status: {
		[index: number]: number
	}

	constructor() {
		this.onCompleteCallbacks = {}
		this.callbacks = {}
		this.status = {}

		LuxedoRPC.bindEndpoint("lightshow_render_report_progress", (progress, id) =>
			this.handleProgressReport(progress, id)
		)
	}

	private async handleProgressReport(progress: number, id: number) {
		this.status[id] = progress
		this.triggerCallbacks(id)

		if (progress === 1) {
			const lightshow = DataHandlerLightshow.get(id)
			await DataHandlerDevice.get(lightshow.target_device_id).timetableManager.refresh()
			await DataHandlerLightshow.pull([id])

			Object.values(this.onCompleteCallbacks).forEach((cb) => cb(lightshow))

			// setTimeout(() => {
			// 	this.status[id] = undefined
			// }, 5000)
		}
	}

	public onLightshowRenderComplete(callback: (lightshow: Lightshow) => void) {
		const callbackId = uuid()
		this.onCompleteCallbacks[callbackId] = callback
		return callbackId
	}

	private triggerCallbacks(id) {
		if (id in this.callbacks) {
			const callbacks = Object.values(this.callbacks[id])
			if (callbacks) callbacks.forEach((cb) => cb(this.status[id]))
		}
	}

	subscribeTo(id: number, callback: ProgressReportCallback) {
		if (!this.callbacks[id]) {
			this.callbacks[id] = {}
		}

		const callbackId = uuid()

		this.callbacks[id][callbackId] = callback
		callback(this.status[id] ?? 0)

		return callbackId
	}

	unsubscribe(lightshowId: number, callbackId: number) {
		if (lightshowId in this.callbacks && callbackId in this.callbacks[lightshowId])
			if (this.callbacks[lightshowId][callbackId]) delete this.callbacks[lightshowId][callbackId]
	}
}

export const LightshowRenderContext = new LightshowRenderCtx()
