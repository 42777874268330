import { openOverlay } from "../../overlay"
import UserFeedbackMenu from "../user-feedback/UserFeedbackMenu.svelte"
import ErrorReport from "./ErrorReportMenu.svelte"

export function openFeedbackMenu(onSubmit: (feedback: string) => void) {
	openOverlay(UserFeedbackMenu, {
		heading: "Help",
		classHeading: "no-underline",
		props: {
			onSubmit,
		},
	})
}

export function openErrorReport(
	options: {
		forwardError?: Error
		additionalInfo?: string
	} = {}
) {
	const { forwardError, additionalInfo } = options

	openOverlay(ErrorReport, {
		heading: "Help",
		classHeading: "no-underline",
		props: {
			originalError: forwardError,
			additionalInfo,
		},
	})
}
