<script lang="ts">
	import { DataHandlerDevice, type DeviceGroupSlot, type DeviceRPi } from "luxedo-data"
	import HamburgerIcon from "../../../../../icons/HamburgerIcon.svelte"
	import ProjectorIcon from "../../../../../icons/ProjectorIcon.svelte"
	import { GroupEditorController } from "../../../GroupEditorController"

	export let slot: DeviceGroupSlot

	let device: DeviceRPi
	let isSelected = false
	let isDragOverTop = false
	let isDragOverBot = false

	GroupEditorController.Slots.subscribe((ctx) => (isSelected = ctx.selectedSlot === slot.id))

	function updateDeviceReference(slot: DeviceGroupSlot) {
		if (!slot) return
		device = DataHandlerDevice.get(slot.device_id) as DeviceRPi
	}

	function selectDevice() {
		GroupEditorController.Slots.selectSlot(slot.id)
	}

	function handleDragStart(
		e: DragEvent & {
			currentTarget: HTMLLIElement
		}
	) {
		e.dataTransfer.setData("slot-priority", String(slot.id))
	}

	function handleDragLeave(e) {
		isDragOverBot = false
		isDragOverTop = false
	}

	function handleDrop(e) {
		const slotID = e.dataTransfer.getData("slot-priority")
		GroupEditorController.Slots.updateSlotPriority(
			slotID,
			slot.id,
			isDragOverBot ? "below" : "above"
		)

		isDragOverBot = false
		isDragOverTop = false
	}

	function handleDragOver(e: DragEvent & { currentTarget: HTMLLIElement }) {
		const rect = e.currentTarget.getBoundingClientRect()
		const mid = rect.top + rect.height / 2
		if (e.clientY > mid) {
			isDragOverBot = true
			isDragOverTop = false
		} else if (e.clientY < mid) {
			isDragOverBot = false
			isDragOverTop = true
		}
	}

	$: updateDeviceReference(slot)
</script>

<li
	class="slot-priority-instance {isSelected ? 'selected' : ''}"
	draggable="true"
	on:dragstart={handleDragStart}
	on:dragover|preventDefault={handleDragOver}
	on:dragleave|preventDefault={handleDragLeave}
	on:dragenter|preventDefault
	on:drop={handleDrop}
>
	<HamburgerIcon />
	<ProjectorIcon {device} />
	<span class="device-name" on:click={selectDevice}>
		{device.name}
	</span>

	{#if isDragOverBot || isDragOverTop}
		<div class="drag-over-indicator {isDragOverBot ? 'bottom' : 'top'}" />
	{/if}
</li>

<style>
	.slot-priority-instance {
		z-index: 2;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		box-sizing: border-box;
	}

	.slot-priority-instance :global(svg.hamburger),
	.device-name {
		transition: color 250ms;
	}

	.device-name {
		cursor: pointer;
	}

	.slot-priority-instance.selected .device-name,
	.device-name:hover,
	.device-name:focus-visible {
		color: var(--color-main);
	}

	.slot-priority-instance :global(svg.projector-icon) {
		width: 2rem;
		height: 1rem;
		margin-right: 0.5rem;
	}

	.slot-priority-instance :global(svg.hamburger) {
		height: 0.65rem;
		width: fit-content;
		margin-right: 0.5rem;
	}

	.slot-priority-instance :global(svg.hamburger .svg-stroke) {
		stroke: var(--color-text-light);
	}

	.slot-priority-instance :global(svg.hamburger:hover .svg-stroke),
	.slot-priority-instance :global(svg.hamburger:focus-visible .svg-stroke) {
		stroke: var(--color-main);
	}

	.drag-over-indicator {
		position: absolute;
		width: 100%;
		border: 1px solid var(--color-main);
		pointer-events: none;
	}

	.drag-over-indicator.top {
		top: 0;
	}

	.drag-over-indicator.bottom {
		bottom: 0;
	}
</style>
