import { onDestroy } from "svelte"
import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
import { DeviceRPi, type Device } from "luxedo-data"
import { Controller } from "svelte-comps/stores"
import { getCurrentUser } from "../../../../../../stores/UserStore"

export type ProjectorActionType = "HDMI_FIX" | "REBOOT" | "STOP_PLAYBACK" | "FW_ROLLBACK"

class ProjActionsCntl extends Controller<Array<ProjectorActionType>> {
	declare device: Device

	constructor() {
		super([])

		SelectedDeviceStore.subscribe(this.onDeviceSelect)
	}

	eidosListener: string
	/**
	 * Called when the selected device changes
	 * @param device the device being selected
	 */
	onDeviceSelect = (device: Device) => {
		if (this.eidosListener) {
			this.device?.removeUpdateListener(this.eidosListener)
			this.eidosListener = undefined
		}

		this.device = device
		if (!device || !device.isOnline) return this.reset()

		this.eidosListener = device.addUpdateListener(this.onDeviceUpdate)
		this.onDeviceUpdate(device)
	}

	/**
	 * Called when the selected device has an eidos update
	 * @param device the current device
	 */
	onDeviceUpdate = (device: Device) => {
		const availActions: Array<ProjectorActionType> = []

		if (!(device instanceof DeviceRPi)) return this.reset() // the actions are only available to physical devices

		availActions.push("REBOOT")
		availActions.push("STOP_PLAYBACK")

		if (device.compareVersion("3.4.0") >= 0) availActions.push("HDMI_FIX")
		if (
			getCurrentUser().hasPriv("beta_opt_in") &&
			device.compareVersion(device.availableUpdate) > 0
		)
			availActions.push("FW_ROLLBACK")

		this.store.set(availActions)
	}
}

export const ProjectorActionsController = new ProjActionsCntl()
