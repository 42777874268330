<script lang="ts">
	import { DataHandlerDevice, type Device } from "luxedo-data"
	import { onMount } from "svelte"
	import ProjectorIcon from "../../../reusable/icons/ProjectorIcon.svelte"
	import { ScheduleController } from "../ScheduleController"
	import DeviceSelector from "../../../reusable/input/DeviceSelector.svelte"

	export let isValid: boolean = false

	let allDevices: Array<Device> = []
	let selected: Device = undefined

	ScheduleController.EventEditor.subscribe((ctx) => {
		selected = ctx?.selectedDevice ?? undefined
		isValid = !!ctx?.selectedDevice
	})

	onMount(() => {
		allDevices = DataHandlerDevice.getMany()
	})
</script>

{#each allDevices as device}
	<DeviceSelector
		{device}
		isSelected={selected && selected.id === device.id}
		onSelect={ScheduleController.EventEditor.selectDevice}
	/>
{/each}

<style>
</style>
