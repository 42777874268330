import { type BundleOf, DataHandler, type IDMap } from "data-handler"
import { Tag, type TagRawData } from "../entries/Tag"
import { LuxedoRPC } from "luxedo-rpc"
import { EntryCreationError } from "../types/ErrorTypes"

class DHTag extends DataHandler<Tag> {
	EntryClass = Tag

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Tag>> {
		return await LuxedoRPC.api.bundlers.tag_bundle_down(specificInstances ?? [])
	}

	protected async pushData(entryData: { [id: number]: Partial<TagRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.tag_bundle_up(entryData as IDMap<TagRawData>)
	}

	protected async requestDeleteEntry(entry: Tag): Promise<void> {
		return await LuxedoRPC.api.tag.tag_delete(entry.id!)
	}

	protected async requestCreateEntry(entryData: Partial<TagRawData>): Promise<number> {
		const { title, color } = entryData
		if (!title || !color)
			throw new EntryCreationError("Missing title or color value when creating tag", {
				entryType: Tag,
				entryData,
			})

		const id = await LuxedoRPC.api.tag.tag_create(title, color)

		return id
	}
}

export const DataHandlerTag = new DHTag()
