import { Media, type Folder } from "luxedo-data"
import { get, writable } from "svelte/store"
import { FileSystemMedia } from "../../../modules/file-system/FileSystemMedia"
import { openMediaUploadOverlay } from "svelte-comps/luxedo"

import { closeOverlay } from "svelte-comps/overlay"

type MediaLibraryCTX = {
	activeFolder: Folder
	path: Array<{ id: number; name: string }>
	filterSelection: {
		filter: string
		order: string
	}
}

export namespace MediaLibraryController {
	const libraryStore = writable<MediaLibraryCTX>({
		activeFolder: undefined,
		path: [],
		filterSelection: {
			filter: "Last Modified",
			order: "Descending",
		},
	})

	let onDirectoryUpdate: (media: Array<Media>) => void = () => {}

	export function setDirectoryUpdateListener(cb: (media: Array<Media>) => void) {
		onDirectoryUpdate = cb
		updateActiveDirectory(get(libraryStore).activeFolder.id)
	}

	export function openNewMediaOverlay() {
		openMediaUploadOverlay(get(libraryStore).activeFolder, () => closeOverlay())
	}

	export function subscribe(cb: (ctx: MediaLibraryCTX) => void) {
		return libraryStore.subscribe(cb)
	}

	/** Update the selected filter */
	export function updateFilterOptions(newFilterOptions: { filter: string; order: string }) {
		libraryStore.update((ctx) => {
			return {
				...ctx,
				filterSelection: newFilterOptions,
			}
		})
	}

	export function updateActiveDirectory(newFolderId: number) {
		const activeFolder = FileSystemMedia.useDirectory(newFolderId, onDirectoryUpdate)
		const path = FileSystemMedia.getFolderPath(activeFolder, true)
		libraryStore.update((ctx) => {
			return { ...ctx, activeFolder, path }
		})
	}
}
