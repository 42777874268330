<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_615_64)">
		<path
			d="M23.7882 2.47142C24.5041 1.92612 25.496 1.92612 26.2119 2.47142L46.7129 18.087C47.381 18.5959 47.66 19.4679 47.4114 20.2701L39.5432 45.6576C39.2838 46.4948 38.5094 47.0655 37.6329 47.0655H12.3672C11.4907 47.0655 10.7163 46.4948 10.4568 45.6576L2.58866 20.2701C2.34005 19.4679 2.61906 18.5959 3.28714 18.087L23.7882 2.47142Z"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
		/>
	</g>
	<defs>
		<clipPath id="clip0_615_64">
			<rect width="50" height="49" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
