<script lang="ts">
</script>

<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M3.62988 32.91C2.82988 32.91 2.12991 32.61 1.52991 32.01C0.929907 31.41 0.629883 30.71 0.629883 29.91V22.76H3.62988V29.91H29.6299V22.76H32.6299V29.91C32.6299 30.71 32.3299 31.41 31.7299 32.01C31.1299 32.61 30.4299 32.91 29.6299 32.91H3.62988ZM16.6299 25.26L6.97986 15.61L9.12988 13.46L15.1299 19.46V0.910034H18.1299V19.46L24.1299 13.46L26.2799 15.61L16.6299 25.26Z"
		class="svg-fill"
		fill="#817E84"
	/>
</svg>

<style>
</style>
