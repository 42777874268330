<script lang="ts">
	import { v4 as uuid } from "uuid"
	import { fabric } from "fabric"
	import type { MaskJson, Snapshot } from "luxedo-data"
	import { getEditor } from "luxedo-canvas"
	import { ClippingMask, MaskBase, MultiMask } from "luxedo-canvas/asset"
	import { CheckboxIcon } from "../../icons"

	export let mask: Partial<MaskJson> | MaskBase
	export let snapshot: Partial<Snapshot>

	let svgContainer: HTMLDivElement
	let isSelected: boolean

	export let onEdit: (mask: Partial<MaskJson> | MaskBase) => void = undefined
	export let onClick: (mask: Partial<MaskJson> | MaskBase) => void = undefined
	export let onHover: (mask: Partial<MaskJson> | MaskBase) => void = undefined
	export let onDelete: (mask: Partial<MaskJson> | MaskBase) => void = undefined
	export let onHoverEnd: (mask: Partial<MaskJson> | MaskBase) => void = undefined
	export let onSelectedUpdate: (selected: boolean) => void = undefined

	function convertToSVG(mask: Partial<MaskJson> | MaskBase | MaskJson) {
		if (!snapshot) {
			snapshot = {
				resolution: {
					w: getEditor().viewport.width,
					h: getEditor().viewport.height,
				},
				src: "",
			}
		}

		let maskPoints: Array<fabric.Point>
		if (mask instanceof MultiMask) return
		if (mask instanceof ClippingMask) {
			maskPoints = mask.points
		} else if (mask instanceof MaskBase) {
			maskPoints = mask.points
		} else {
			// serialized mask data - will need to be slightly manipulated to provide the correct data model
			maskPoints = mask.points
			// @ts-ignore
			mask.path = mask.pathData
			// @ts-ignore
			mask.pathOffset = mask.pathData.pathOffset
		}

		const points = maskPoints.map(
			(point) =>
				new fabric.Point(
					(point.x - mask.pathOffset.x) * mask.scaleX + mask.left,
					(point.y - mask.pathOffset.y) * mask.scaleY + mask.top
				)
		)

		const minX = Math.min(...points.map((point) => point.x))
		const minY = Math.min(...points.map((point) => point.y))
		const maxX = Math.max(...points.map((point) => point.x))
		const maxY = Math.max(...points.map((point) => point.y))

		let viewBox = `${minX} ${minY} ${maxX - minX} ${maxY - minY}`
		if (mask.inverted) viewBox = `0 0 ${snapshot.resolution.w} ${snapshot.resolution.h}`

		const svgNS = "http://www.w3.org/2000/svg"
		const svgElement = document.createElementNS(svgNS, "svg")
		svgElement.setAttribute("width", `${snapshot.resolution.w}`)
		svgElement.setAttribute("height", `${snapshot.resolution.h}`)
		svgElement.setAttribute("viewBox", viewBox)
		svgElement.setAttribute("xmlns", svgNS)

		// Create background image
		const background = document.createElementNS(svgNS, "image")
		background.setAttribute("href", snapshot.src)
		background.setAttribute("width", `${snapshot.resolution.w}`)
		background.setAttribute("height", `${snapshot.resolution.h}`)

		const clipPathId = `clip-path-${mask.path.id}-${uuid()}`
		const pathId = `path-${mask.path.id}-${uuid()}`

		const path = document.createElementNS(svgNS, "path")
		path.id = pathId
		path.setAttribute("strokeWidth", "1px")
		path.setAttribute("stroke", "red")
		path.setAttribute("transformOrigin", `50% 50%`)
		path.setAttribute("transform", `rotate(${mask.angle})`)
		let pathData = `M${points[0].x},${points[0].y}`
		for (let i = 1; i < points.length; i++) {
			pathData += ` L${points[i].x},${points[i].y}`
		}
		pathData += " Z" // Close the path
		path.setAttribute("d", pathData)
		path.setAttribute("fill", "none")

		background.setAttribute("clip-path", `url(#${clipPathId})`)
		const clipPath = document.createElementNS(svgNS, "clipPath")
		clipPath.id = clipPathId

		clipPath.appendChild(path)
		svgElement.appendChild(clipPath)

		svgElement.appendChild(background)

		// Create shape path

		return svgElement
	}

	function onToggle() {
		isSelected = !isSelected
	}

	function initializeListeners(mask: Partial<MaskJson> | MaskBase | MaskBase | ClippingMask) {
		if (mask instanceof ClippingMask || mask instanceof MaskBase) {
			mask.on("moving", () => embedSVG(mask))
			mask.on("rotating", () => embedSVG(mask))
			mask.on("scaling", () => embedSVG(mask))
		}
	}

	function embedSVG(mask) {
		if (!svgContainer) return

		const svg = convertToSVG(mask)
		if (!svg) return

		svgContainer.innerHTML = ``
		svgContainer.appendChild(svg)
	}

	function handleClick() {
		if (onClick) onClick(mask)
		else if (onSelectedUpdate) onToggle()
	}

	$: svgContainer && embedSVG(mask)
	$: initializeListeners(mask)

	$: onSelectedUpdate ? onSelectedUpdate(isSelected) : null
</script>

{#if mask}
	<div
		class="mask-instance {onSelectedUpdate ? 'selection' : ''} {isSelected ? 'selected' : ''}"
		on:click={handleClick}
		on:mouseenter={onHover ? () => onHover(mask) : null}
		on:mouseleave={onHoverEnd ? () => onHoverEnd(mask) : null}
		title={mask.name}
	>
		{#if onSelectedUpdate}
			<CheckboxIcon {isSelected} />
		{/if}
		<div class="mask-heading">
			<h3 class="mask-name">
				{mask.name}
			</h3>
			<div class="button-container">
				{#if onDelete}
					<button class="small warn" on:click={() => onDelete(mask)}>Delete</button>
				{/if}
				{#if onEdit}
					<button class="small" on:click={() => onEdit(mask)}>Edit</button>
				{/if}
			</div>
		</div>
		<div class="svg-container" bind:this={svgContainer}></div>
	</div>
{/if}

<style>
	.mask-instance {
		box-shadow: var(--shadow-medium);
		position: relative;
		cursor: pointer;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		padding: 1rem;
		margin-bottom: 0.5rem;
		width: 100%;
	}

	.mask-instance.selection {
		padding: 0.25rem 0.5rem 0.5rem 0.5rem;
		--tile-border-width: 4px;
		border: var(--tile-border-width) solid var(--light);
		position: relative;
		transition: border-color 250ms;
	}

	.mask-instance.selection .mask-name {
		transition: color 250ms;
	}

	.mask-instance.selection.selected .mask-name {
		color: var(--color-main);
	}

	.mask-instance.selection :global(.checkbox-container) {
		position: absolute;
		height: 2rem;
		width: 2rem;
		top: calc(var(--tile-border-width) * -1);
		right: calc(var(--tile-border-width) * -1);
	}

	.mask-instance.selection.selected {
		border-color: var(--color-main);
	}

	.mask-heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 1rem;
	}

	.mask-heading .button-container {
		width: fit-content;
		flex-wrap: nowrap;
		margin: 0;
	}

	.mask-heading .button-container button {
		margin-left: 0.5rem;
	}

	.svg-container {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: var(--mask-preview-height);
	}

	.svg-container :global(svg) {
		width: auto;
		height: 100%;
	}

	.svg-container :global(svg path) {
		transform-box: fill-box;
		transform-origin: center;
	}

	.mask-name {
		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: var(--h2);
		margin: 0;
	}
</style>
