<script lang="ts">
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		Scene,
		Lightshow,
		type Device,
		DataHandlerDevice,
		type FullCalendarEvent,
	} from "luxedo-data"
	import LightshowScheduleIcon from "../../icons/LightshowScheduleIcon.svelte"
	import { ArrowDown, SettingsIcon } from "svelte-comps/icons"
	import ProjectTags from "../../tags/ProjectTags.svelte"
	import ProjectorIcon from "../../icons/ProjectorIcon.svelte"
	import ShowPreviewVideoPlayer from "./ShowOverview_VideoPlayer.svelte"
	import { Toast } from "svelte-comps/toaster"
	import ShowPreviewEventInfo from "./ShowOverview_EventInfo.svelte"
	import ShowInfoLightshow from "./scene-info/ShowInfo_Lightshow.svelte"
	import ShowInfoScene from "./scene-info/ShowInfo_Scene.svelte"
	import { DateTime } from "luxon"
	import { closeOverlay } from "svelte-comps/overlay"
	import { UserStore } from "../../../../stores/UserStore"
	import { triggerContextMenuWithGenerator } from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../../modules/ctx-menu/ContextMenuOptions"
	import { ShowLibraryController } from "../../../routes/show-library/ShowLibraryController"
	import { ScheduleController } from "../../../routes/schedule/ScheduleController"
	import PreviewInBrowserIcon from "../../icons/PreviewInBrowserIcon.svelte"
	import LightshowThumbnail from "../../file-system/tiles/LightshowThumbnail.svelte"
	import ShowPreviewPlayOnProjector from "./ShowOverview_PlayOnProjector.svelte"
	import WarningIcon from "../../icons/WarningIcon.svelte"
	import CheckboxInput from "../../input/CheckboxInput.svelte"
	import DirectUploadForm from "../new-scene/direct-upload/DirectUploadForm.svelte"

	/**
	 * New version of the scene/lightshow preview. Includes a schedule section.
	 */

	export let scene: Scene | Lightshow
	export let event: FullCalendarEvent

	type EventDetails = {
		start: string
		end: string
	}

	let eventDetails: EventDetails
	let device: Device

	let isScheduling = false // If true, show the schedule form
	let isPreviewing = false // If true, show the preview comp

	let isReuploading = false // If true, replace the scene details with an upload form

	// #region Data update listeners

	DataHandlerScene.addListener((ids) => {
		if (!scene) return closeOverlay()
		if (ids.includes(scene.id)) scene = DataHandlerScene.get(scene.id)
		if (!scene) return closeOverlay()
	})

	DataHandlerLightshow.addListener((ids) => {
		if (!scene) return closeOverlay()
		if (ids.includes(scene.id)) scene = DataHandlerLightshow.get(scene.id)
		if (!scene) return closeOverlay()
	})

	let selectedDeviceId: number = -1
	let doUpdateAllScenes: boolean = false

	let availableDevices: Array<Device>
	UserStore.subscribe(() => {
		availableDevices = DataHandlerDevice.getMany()
	})

	// #endregion

	function refreshData(scene: Scene | Lightshow) {
		if (!scene) return closeOverlay()
		device = DataHandlerDevice.get(scene.target_device_id)
	}

	function initSchedule() {
		ScheduleController.EventEditor.editEvent(event, {
			show: scene,
		})
	}

	function handleEdit() {
		if (scene instanceof Scene) {
			ShowLibraryController.editShow(scene)
		} else {
			ShowLibraryController.LightshowEditor.editLightshow(scene)
		}
	}

	async function previewShow() {
		try {
			let src = scene?.src.video
			if (scene?.isDirectUpload) src = scene?.src.directUpload
			const res = await fetch(src)
			if (res.status >= 400) {
				isPreviewing = false
				isScheduling = false
				Toast.error("This video couldn't load. Be sure it has been rendered and try again. ")
				return
			}
		} catch (e) {
			console.error("[ERROR] ", e)
			isPreviewing = false
			isScheduling = false
			Toast.error("An error occured while loading this video, please try again. ")
			return
		}

		isScheduling = false
		isPreviewing = true
	}

	function closeSubmenus() {
		isPreviewing = false
		isScheduling = false
	}

	function editEvent() {
		ScheduleController.EventEditor.editEvent(event)
		closeOverlay()
	}

	async function deleteEvent() {
		try {
			await device.timetableManager.deleteEvent(device.timetableManager.getEvent(event))
			await ScheduleController.Calendar.refreshEvents()

			Toast.success("Event successfully removed from schedule!")
			closeOverlay()
		} catch (e) {
			console.error("Error removing event from schedule", e)
			Toast.error("Unable to remove from schedule, please refresh and try again...")
		}
	}

	function updateEventDetails() {
		if (!event) return

		const start = DateTime.fromJSDate(event.start).toFormat("ff")
		const end = DateTime.fromJSDate(event.end).toFormat("ff")

		eventDetails = {
			start,
			end,
		}
	}

	function onSceneNameChange(
		e: KeyboardEvent & {
			currentTarget: EventTarget & HTMLHeadingElement
		}
	) {
		if (e.key === "Enter") e.currentTarget.blur()
	}

	async function updateSceneName(
		e: FocusEvent & {
			currentTarget: EventTarget & HTMLHeadingElement
		}
	) {
		const newName = e.currentTarget.textContent
		if (newName === scene?.name) return

		try {
			if (scene instanceof Scene) {
				await DataHandlerScene.rename(scene, newName)
			} else {
				await DataHandlerLightshow.rename(scene, newName)
			}
			Toast.success("Show title updated successfully! ")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error(
				"An error occurred while updating the title of your show. Please refresh and try again. "
			)
		}
	}

	let triggerUpload: () => void
	let onUploadCancel: () => void
	function triggerReupload() {
		isReuploading = true
		isScheduling = false
		isPreviewing = false
	}

	async function onReuploadComplete() {
		Toast.success("Scene successfully updated!")
		isReuploading = false
		if (scene instanceof Scene) DataHandlerScene.pull([scene.id])
	}

	async function updateAllScenes() {
		const initDevId = scene.target_device_id

		const scenes = DataHandlerScene.getByDevice(initDevId)
		const lightshows = DataHandlerLightshow.getByDevice(initDevId)

		const updatedScenes: Array<Scene> = []
		const updatedLightshows: Array<Lightshow> = []

		for (const show of scenes) {
			const existingDevice = DataHandlerDevice.get(show.target_device_id)
			if (existingDevice) continue

			show.target_device_id = selectedDeviceId
			updatedScenes.push(show)
		}

		for (const show of lightshows) {
			const existingDevice = DataHandlerDevice.get(show.target_device_id)
			if (existingDevice) continue

			show.target_device_id = selectedDeviceId
			updatedLightshows.push(show)
		}

		try {
			if (updatedScenes.length) await DataHandlerScene.push(updatedScenes)
			if (updatedLightshows.length) await DataHandlerLightshow.push(updatedLightshows)
			Toast.success("All unassigned scenes and lightshows updated successfully!")
		} catch (e) {
			console.error("ERROR setting assigned device for scenes", e)
			Toast.error("An error occurred, please try again.")
		}
	}

	async function updateOneScene() {
		scene.target_device_id = selectedDeviceId
		try {
			if (scene instanceof Lightshow) {
				await DataHandlerLightshow.save(scene)
			} else {
				await DataHandlerScene.save(scene)
			}
			Toast.success("Show updated successfully!")
		} catch (e) {
			console.error("ERROR updating selected device for a scene", e)
			Toast.error("Error updating selected device, please try again.")
		}
	}

	async function saveScene() {
		if (doUpdateAllScenes) await updateAllScenes()
		else await updateOneScene()
	}

	$: refreshData(scene)
	$: event && updateEventDetails()
</script>

<div id="show-preview" class="{isPreviewing ? 'showing-preview' : 'not-preview'} show-{scene?.id}">
	<div class="heading-container">
		<div class="internal-heading-container">
			<div class="tile">
				{#if scene instanceof Lightshow}
					<LightshowThumbnail lightshow={scene} />
				{:else}
					<img src={scene?.src?.thumbnail} alt="{scene?.name} Thumbnail" />
				{/if}
			</div>
			<div class="heading">
				<div class="lightshow-info">
					<div class="left">
						<h3
							id="show-preview-title"
							spellcheck="false"
							on:keydown={onSceneNameChange}
							on:blur={updateSceneName}
							contenteditable={true}
						>
							{scene?.name === "__temp_ls__"
								? `Temporary playlist for ${DateTime.fromJSDate(scene?.updated_at).toFormat(
										"LLL dd, yyyy"
									)}`
								: scene?.name ?? ""}
						</h3>
						<p>{scene?.description ?? ""}</p>
					</div>
				</div>
				<div class="tag-container">
					<ProjectTags
						alignLeft
						selectedTags={scene?.tags}
						onToggle={(tag) => scene?.toggleTag(tag)}
						isLibraryContext={false}
					/>
				</div>
			</div>
			<div class="right">
				<div class="button-container lightshow-actions">
					<button
						id="more-options-button"
						class="icon"
						title="More Options"
						on:click={triggerContextMenuWithGenerator(
							scene instanceof Scene
								? CTXOptions.Scene.createOptions
								: CTXOptions.Lightshow.createOptions,
							[scene, { fromOverlay: true, triggerReupload }]
						)}><ArrowDown /></button
					>

					{#if !scene?.isDirectUpload}
						<button id="edit-lightshow-btn" class="icon" title="Edit Scene" on:click={handleEdit}
							><SettingsIcon /></button
						>
					{/if}
					<button
						id="preview-lightshow-btn"
						class="icon"
						title="View Scene in Browser"
						on:click={previewShow}><PreviewInBrowserIcon /></button
					>
					{#if device}
						<button
							id="schedule-lightshow-btn"
							class="icon"
							title="Schedule Scene"
							on:click={initSchedule}><LightshowScheduleIcon /></button
						>
						<ShowPreviewPlayOnProjector {scene} {device} />
					{/if}
				</div>
				{#if device && "color" in device}
					<div class="color-block" style="background-color: {device.color};" />
				{/if}
				<div class="flex-row">
					<div class="flex-column">
						{#if device}
							<!-- {#if scene.duration} -->
							<p class="duration-info">
								Projector:
								<!-- {TimeUtils.renderTime(scene.duration)} -->
							</p>
							<!-- {/if} -->
							<div>{device?.name}</div>
						{:else}
							<div id="assign-new-device">
								<div class="select-container">
									<label for="scene-projector-input">Select Projector:</label>
									<select id="scene-projector-input" bind:value={selectedDeviceId}>
										<option value={-1}>Unassigned</option>
										{#each availableDevices as device}
											<option value={device.id}>{device.name}</option>
										{/each}
									</select>
								</div>

								<CheckboxInput
									label="Transfer all scenes and lightshows?"
									bind:isChecked={doUpdateAllScenes}
								/>
							</div>
						{/if}
					</div>

					{#if device}
						<ProjectorIcon {device} />
					{/if}
				</div>
			</div>
		</div>
		<!-- Only exist when expanded -->

		<ShowPreviewVideoPlayer isOpen={isPreviewing} {scene} onBack={closeSubmenus} />
	</div>
	{#if isReuploading && scene instanceof Scene}
		<DirectUploadForm
			{scene}
			bind:triggerUpload
			bind:triggerCancel={onUploadCancel}
			onComplete={onReuploadComplete}
		/>
	{:else}
		<div class="show-information">
			{#if scene instanceof Lightshow}
				<ShowInfoLightshow lightshow={scene} />
			{:else}
				<ShowInfoScene {scene} />
			{/if}
			{#if event}
				<ShowPreviewEventInfo {eventDetails} />
			{/if}
			{#if !device}
				<div id="no-device-warning">
					<WarningIcon strokeColor="var(--color-error)" />
					<span>
						The device associated with this scene is no longer registered under your account, please
						assign to a new projector above.
					</span>
				</div>
			{/if}
		</div>
	{/if}

	{#if !isPreviewing && !isScheduling && device}
		<div class="overlay-button-container">
			{#if isReuploading}
				<button
					class="link-button"
					on:click={() => {
						onUploadCancel()
						isReuploading = false
					}}
				>
					Cancel
				</button>
			{:else}
				<button class="link-button" on:click={() => closeOverlay()}> Close </button>
			{/if}

			{#if event}
				<button id="remove-schedule-button" class="error" on:click={() => deleteEvent()}>
					Cancel Event
				</button>

				<button id="edit-schedule-button" class="alt" on:click={() => editEvent()}>
					Edit Schedule
				</button>
			{:else if !isReuploading}
				<button id="edit-schedule-button" class="alt" on:click={() => initSchedule()}>
					Schedule Show
				</button>
			{/if}
			{#if scene?.isDirectUpload && isReuploading}
				<button on:click={() => (isReuploading ? triggerUpload() : triggerReupload())}>
					Reupload
					{#if !isReuploading}
						Scene
					{/if}
				</button>
			{/if}
			{#if !scene?.isDirectUpload}
				<button on:click={() => handleEdit()}>
					Edit {scene instanceof Scene ? "Scene" : "Lightshow"}
				</button>
			{/if}
		</div>
	{:else if !device}
		<div class="overlay-button-container">
			<button class="link-button" on:click={() => closeOverlay()}> Close </button>

			<button on:click={saveScene} disabled={selectedDeviceId < 0}> Save </button>
		</div>
	{/if}
</div>

<style>
	#show-preview :global(.ctx-menu-button) {
		margin-right: 0.5rem;
	}

	#show-preview :global(#direct-upload-form-container > div) {
		padding: 2rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#show-preview :global(.scene-scheduler) {
		padding: 0;
	}

	#show-preview :global(form.dropzone) {
		margin-bottom: 0;
	}

	.heading-container {
		background-color: var(--color-bg-dark);
		backdrop-filter: blur(3rem);
		padding: 2rem;
		--tile-width: 12rem;
	}

	.internal-heading-container {
		display: flex;
		flex-direction: row;
		transition: transform;
		transition-duration: 500ms;
		transition-delay: 250ms;
	}

	.heading {
		display: flex;
		flex-direction: column;
		width: 50%;
		justify-content: flex-end;
	}

	.show-information {
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 70% 30%;
		/* grid-auto-columns: 70% 30%; */
	}

	.show-information > :global(*) {
		flex-basis: 50%;
	}

	.tile {
		min-width: var(--tile-width);
		width: var(--tile-width);
		height: var(--tile-width);
	}

	.lightshow-info {
		height: fit-content;
		margin-left: 1rem;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	#no-device-warning {
		color: var(--color-error);
		position: absolute;
		bottom: 1rem;
		left: 2rem;
		width: 60%;
		display: flex;
		align-items: center;
	}

	#no-device-warning span {
		margin-left: 1rem;
		line-height: 1rem;
	}

	/* 
	.right {
		position: absolute;
		right: 4rem;
		top: calc(var(--tile-width) - 1.5rem);
		width: fit-content;
	} */

	.left {
		width: 100%;
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		padding-top: 3rem;
		margin-bottom: 1rem;
		width: 100%;
	}

	.right .flex-row div {
		width: fit-content;
	}

	.right .flex-row :global(svg) {
		margin-left: 1rem;
		align-self: flex-end;
	}

	.color-block {
		width: 3rem;
		height: 3rem;
		right: 2.5rem;
		top: 11rem;
		position: absolute;
		border-radius: 100%;
		z-index: -1;
		filter: blur(3rem);
	}

	.duration-info {
		color: var(--color-text);
		margin-bottom: 0;
	}

	.tag-container {
		margin: 1rem 0 0 1rem;
		padding-right: 1rem;
	}

	.lightshow-actions {
		justify-content: flex-end;
	}

	#preview-lightshow-btn :global(.svg-fill) {
		fill: var(--color-main);
	}

	#preview-lightshow-btn :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	#preview-lightshow-btn,
	#schedule-lightshow-btn {
		margin-right: 1rem;
		transition: transform 250ms ease-out;
	}

	#more-options-button {
		margin-right: 0.25rem;
	}

	#more-options-button :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	#remove-schedule-button,
	#edit-schedule-button,
	#edit-lightshow-btn,
	#preview-lightshow-btn {
		margin-right: 0.5rem;
	}

	#more-options-button:hover,
	#more-options-button:focus-visible,
	#edit-lightshow-btn:hover,
	#edit-lightshow-btn:focus-visible,
	#preview-lightshow-btn:hover,
	#preview-lightshow-btn:focus-visible,
	#schedule-lightshow-btn:hover,
	#schedule-lightshow-btn:focus-visible {
		transform: scale(1.5);
	}

	#edit-lightshow-btn :global(svg) {
		width: 1rem;
	}

	#more-options-button :global(svg) {
		height: auto;
		width: 1.5rem;
	}

	#edit-lightshow-btn :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#edit-lightshow-btn :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.lightshow-info h3 {
		width: 100%;
		font-size: 3rem;
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	#show-preview {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}

	#show-preview.showing-preview .internal-heading-container {
		transform: translateY(calc(-100% - 10rem));
		transition-delay: 0ms;
	}

	.show-information {
		padding: 1rem 2rem;

		color: var(--color-text-light);
	}

	.show-information :global(h3) {
		font-size: var(--h1);
		margin: 0;
	}

	.show-information :global(.details) {
		margin-left: 1rem;
	}

	.show-information :global(.details .label) {
		color: var(--color-text);
	}

	.overlay-button-container {
		font-size: var(--h2);
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}

	.select-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.select-container select {
		text-align: start;
		padding: 0.1em 0;
	}

	.select-container label {
		padding-left: 0.25rem;
	}

	#assign-new-device :global(.input-container) {
		margin-top: 0.5rem;
	}

	#assign-new-device :global(.input-container label) {
		font-size: var(--h3);
	}
</style>
