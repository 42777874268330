import { fabric } from "fabric"
import { AnimationPreset, PresetKeyframes, PresetOptions } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { Path, PathMessageType, PathSubscriber } from "../../asset"
import { Keyframe, LocalTimestamp } from "../../animation"
import { Track } from "../../tracks"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../modules/serialize"
import { EditorClass } from "../.."
import { PathSpeed, SpeedData } from "../../asset/canvas/path/PathSpeedManager"

export class PositionPath extends AnimationPreset<CanvasAsset> implements PathSubscriber, Serializable {
	name = "PositionPath"
	properties = ["left" as const, "top" as const]
	type = "base" as const
	path: Path
	pathId: string

	declare track: Track<CanvasAsset>
	declare speed: PathSpeed

	constructor(editor: EditorClass, track: Track<CanvasAsset>, path: Path, options: PresetOptions) {
		super(editor, track, options)
		this.path = path
		this.pathId = path.id

		this.path.useRelativeOrigin(track.asset)
		this.generateKeyframes()

		this.path.register()
		this.path.subscribe(this)

		this.speed = undefined
	}

	// #region 		============================= 		 Interface  	 ================================

	/**
	 * Updates the total duration of the animation (% of animation duration)
	 * @param start [0-1] the % time of the animated object's duration before the animation starts
	 * @param end [0-1] the % time of the animated object's duration when the animation will end
	 */
	updateTime(start: LocalTimestamp, end: LocalTimestamp) {
		this.startTime = start
		this.endTime = end

		this.clearSpeedData()
	}

	/**
	 * Adjusts the speed of a path
	 * @param pointIndex the index of the point whose speed is being adjusted
	 * @param newTime the new timestamp [0-1] of the point
	 */
	adjustSpeed(pointIndex: number, newTime: LocalTimestamp) {
		if (!this.speed) this.speed = new PathSpeed(this.points, this)
		this.speed.modify(pointIndex, newTime)

		this.generateKeyframes()
	}

	clearSpeedData() {
		this.speed = undefined
		this.generateKeyframes()
	}

	// #endregion ============================= 		 Interface    	================================
	// #region 		============================= 	 Path Propeties  	================================

	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	) {
		switch (messageType) {
			case "point:add":
				this.onPointAdd(modifiedIndex)
				this.editor.canvas.requestRenderAll()
				return
			case "point:edit":
				this.generateKeyframes()
				this.editor.canvas.requestRenderAll()
				return
			case "point:delete":
				this.onPointDelete(modifiedIndex)
				this.editor.canvas.requestRenderAll()
				return
			case "unsubscribed":
			case "deleted":
				this.pathId = undefined
				this.track.removeAnimationPreset(this)
				return
		}
	}

	/**
	 * Called when a point is added to the path. Adjusts the speed data to align with the new point indecies.
	 * @param newPointIndex the new point index
	 */
	onPointAdd(newPointIndex: number) {
		this.clearSpeedData()
	}

	/**
	 * Called when a point is removed from the path. Adjusts the speed data to align with the new point indecies.
	 * @param removedPointIndex the removed point index
	 */
	onPointDelete(removedPointIndex: number) {
		this.clearSpeedData()
	}

	get points(): fabric.Point[] {
		return this.path.points
	}

	// #endregion ============================= 	 Path Propeties  	================================
	// #region 		============================= Keyframe Generation ================================

	/**
	 * If speed data has been provided, timestamps will use the speed data as the source of truth.
	 * Assumes each point has speed data.
	 */
	private calculateSpeedTimestamps(ignoreCurveData?: boolean) {
		let timestamps: Array<number> = [this.startTime]

		for (let i = 1; i < this.points.length; i++) {
			const { start, end } = this.speed.getSpeed(i)
			if (this.path.curvedPointData[i] && !ignoreCurveData) {
				let curvedDuration = end - start
				for (let t = 0; t < 1; t += 0.01) {
					timestamps.push(start + curvedDuration * t)
				}
			} else {
				timestamps.push(end)
			}
		}

		return timestamps
	}

	/**
	 * If there has been no speed data provided, timestamps will be calcualted using the length of each line segment
	 */
	private calculateLengthTimestamps(ignoreCurveData?: boolean) {
		const lengths = []
		/*
		 * Loop through each point creating a line segment,
		 * calculating the length of each line segment (providing curve data when applicable).
		 */
		for (let i = 1; i < this.points.length; i++) {
			const prev = this.points[i - 1]
			const next = this.points[i]
			if (this.path.curvedPointData[i] && !ignoreCurveData) {
				let curveLength = this.path.curvedPointCache[i].getCurveLength()
				// split curveLength into 100 samples - this should be dynamic depending on the length of the line
				for (let t = 0; t < 1; t += 0.01) {
					lengths.push(curveLength * 0.01)
				}
			} else {
				lengths.push(next.distanceFrom(prev))
			}
		}

		// calculate the total length of the path by combining all segment lengths
		const total = lengths.reduce((a, b) => a + b)

		// convert each length into a % mapped relative to the total length
		const relative = lengths.map((len) => len / total)

		let timestamp = 0
		let cumulative = [0]

		// loop through each relative % length, adding previous timespan to current and pushing to cumulative timspans.
		for (const len of relative) {
			timestamp += len
			cumulative.push(timestamp)
		}

		const timeScale = this.endTime - this.startTime

		// convert cumulative % timstamps to actual time
		cumulative = cumulative.map((ts) => {
			return ts * timeScale + this.startTime
		})

		return cumulative
	}

	/**
	 * Calculates the distance from point to point, creating timestamps for each point's time position
	 * @returns an array of times
	 */
	protected calcNormalizedTimestamps(ignoreCurveData?: boolean) {
		if (this.speed) return this.calculateSpeedTimestamps(ignoreCurveData)
		return this.calculateLengthTimestamps(ignoreCurveData)
	}

	private createKeyframes(ignoreCurveData?: boolean) {
		const leftKfs: Keyframe[] = []
		const topKfs: Keyframe[] = []
		const timestamps = this.calcNormalizedTimestamps(ignoreCurveData)

		let interpolatedPointIndex = 0
		for (let i = 0; i < this.points.length; i++) {
			const point = this.points[i]

			if (this.path.curvedPointData[i] && !ignoreCurveData) {
				for (let t = 0.01; t <= 1; t += 0.01) {
					const curvePoint = this.path.curvedPointCache[i].getPointByPosition(t)
					leftKfs.push({ timestamp: timestamps[i + interpolatedPointIndex] as LocalTimestamp, value: curvePoint.x })
					topKfs.push({ timestamp: timestamps[i + interpolatedPointIndex] as LocalTimestamp, value: curvePoint.y })
					interpolatedPointIndex++
				}
			} else {
				leftKfs.push({ timestamp: timestamps[i + interpolatedPointIndex] as LocalTimestamp, value: point.x })
				topKfs.push({ timestamp: timestamps[i + interpolatedPointIndex] as LocalTimestamp, value: point.y })
			}
		}

		const left = new PresetKeyframes(this, leftKfs)
		const top = new PresetKeyframes(this, topKfs)

		return {
			left,
			top,
		}
	}

	/**
	 * Generates the "under-the-hood" keyframes for this animation
	 */
	generateKeyframes() {
		this.keyframes = this.createKeyframes()
	}

	/**
	 * Returns the keyframes without the interpolated curve data
	 */
	getBaseKeyframes() {
		return this.createKeyframes(true)
	}

	// #endregion ============================= Keyframe Generation ================================
	// #region 		================================ Serialization ================================

	/**
	 * Loads the JSON data for the position path, assuming the PATH object is passed to data as data.path
	 * @param data The json data to import
	 * @param data.path The Path instance
	 * @returns
	 */
	static async loadJSON(
		editor: EditorClass,
		data: Partial<PositionPath> & { startTime: number; endTime: number; pathData: Partial<Path>; speedData?: SpeedData }
	): Promise<PositionPath> {
		const pathData = data.pathData
		pathData["track"] = data.track

		const path = await Path.loadJSON(editor, pathData, pathData.id)
		const instance = new this(editor, data.track, path, data)

		if (data.speedData) instance.speed = new PathSpeed(path.points, instance, data.speedData)
		instance.generateKeyframes()

		return instance
	}

	/**
	 * Converts keyframes into a JSON object
	 */
	serializeKeyframes() {
		let serialized = {}
		for (const [name, keyframes] of Object.entries(this.keyframes)) {
			serialized[name] = (keyframes as PresetKeyframes<any>).serialize()
		}
		return serialized
	}

	/**
	 * Converts this animation to a JSON object
	 */
	serialize(forExport?: boolean): Partial<PositionPath> {
		let json = {}

		if (forExport) {
			json = convertInstanceToObject(this, {
				forExport,
				propertiesToInclude: [],
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
				propertiesToExclude: ["path", "track", "speed"], // When exporting, the paths will be saved separately
			})
		} else {
			json = convertInstanceToObject(this, {
				forExport,
				propertiesToInclude: [],
				propertiesToDeepCopy: ["path"],
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
				propertiesToExclude: ["track", "speed"],
			})
		}

		if (this.speed) json["speedData"] = this.speed.speedData

		return json
	}

	// #endregion ================================ Serialization ================================
}

registerSerializableConstructor(PositionPath)
