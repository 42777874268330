<script lang="ts">
	export let isSelected
</script>

<div class="checkbox-container {isSelected ? 'is-selected' : ''}">
	<svg id="Layer_15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.79 26.79">
		<!-- <rect class="cls-3" x="0" y="0" width="26.79" height="26.79" rx="6" ry="6" /> -->
		<g class="cls-1">
			<!-- <rect class="cls-2" x="1.5" y="1.5" width="23.79" height="23.79" rx="4.5" ry="4.5" /> -->
			<rect class="cls-2" width="26.79" height="26.79" />
			<!-- <path
				class="cls-2"
				d="m20.79,3c1.65,0,3,1.35,3,3v14.79c0,1.65-1.35,3-3,3H6c-1.65,0-3-1.35-3-3V6c0-1.65,1.35-3,3-3h14.79m0-3H6C2.69,0,0,2.69,0,6v14.79c0,3.31,2.69,6,6,6h14.79c3.31,0,6-2.69,6-6V6c0-3.31-2.69-6-6-6h0Z"
				/> -->
		</g>
		<polyline
			stroke={isSelected ? "var(--color-main)" : "var(--light)"}
			class="cls-4"
			points="5.63 11.52 12.06 17.94 21.15 8.85"
		/></svg
	>
</div>

<style>
	div.checkbox-container {
		border: var(--tile-border-width) solid var(--light);
		background-color: #141116;
		overflow: hidden;
		border-radius: var(--br);

		transition: border-color 250ms;
	}

	.checkbox-container.is-selected {
		border-color: var(--color-main);
	}

	.cls-1 {
		opacity: 0.5;
	}
	.cls-2 {
		fill: #817e84;
		/* border-radius: var(--br); */
	}
	.cls-2 {
		stroke-width: 0px;
	}
	.cls-4 {
		fill: none;
		stroke-miterlimit: 10;
		stroke-width: 3px;
	}
</style>
