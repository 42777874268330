<script lang="ts">
	export let height: string = "2rem"
	export let color: string = "white"
</script>

<span
	class="loader"
	style="height: {height}; width: {height}; border-color: {color}; border-bottom-color: transparent"
/>

<style>
	.loader {
		aspect-ratio: 1/1;
		height: auto;
		width: auto;
		border: 2px solid var(--color-text-light);
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
