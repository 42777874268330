<script lang="ts">
	import type { Device } from "luxedo-data"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { DeviceSpotlightManager } from "luxedo-data"
	import { CalibrationOverlayController } from "../CalibrationOverlayController"

	let device: Device

	let togglingSpotlight = false
	let spotlightActive = false

	SelectedDeviceStore.subscribe((dev) => (device = dev))
	DeviceSpotlightManager.subscribe((ctx) => (spotlightActive = ctx[device.id]))

	async function beginCalibration() {
		if (spotlightActive) {
			await disableSpotlight()
		}
		await CalibrationOverlayController.calibrate()
	}

	function cancelCalibration() {
		CalibrationOverlayController.cancel()
	}

	async function activateSpotlight() {
		togglingSpotlight = true
		await DeviceSpotlightManager.activateSpotlight(device)
		togglingSpotlight = false
	}

	async function disableSpotlight() {
		togglingSpotlight = true
		await DeviceSpotlightManager.deactivateSpotlight(device)
		togglingSpotlight = false
	}

	async function toggleSpotlight() {
		if (spotlightActive) {
			return await disableSpotlight()
		} else {
			return await activateSpotlight()
		}
	}
</script>

<div id="calibration-instructions">
	<div class="calibration-step">
		<span class="step_number">1</span>
		Make sure your device is in position before starting calibration process.
	</div>

	<div class="calibration-step">
		<span class="step_number">2</span>
		Ensure your projection surface is protected from any interfering light sources such as the sun, christmas
		lights or lights inside your home.
	</div>

	<div class="calibration-step">
		<span class="step_number">3</span>
		Do not block or stand in front of the device during the calibration process.
	</div>

	<div class="advanced-option-spotlight">
		<span class="label">Enable Projector Spotlight </span>
		<ToggleSwitch
			isActive={spotlightActive}
			disabled={togglingSpotlight}
			onUpdate={() => toggleSpotlight()}
			info="Enabling the projector spotlight will trigger your device to project white light allowing you to better visualize and adjust your device's position."
		/>
	</div>
</div>

<div class="button-container">
	<button on:click={cancelCalibration} class="link-button">Cancel</button>
	<button on:click={beginCalibration}>Calibrate</button>
</div>

<style>
	.step_number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 3rem;
		width: 3rem;
		height: 3rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.calibration-step {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		align-items: center;
	}

	#calibration-instructions {
		max-width: 36rem;
	}

	.advanced-option-spotlight {
		position: absolute;
		top: -2.25rem;
		right: 1rem;
		color: var(--color-text);
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 15;
	}

	.advanced-option-spotlight .label {
		font-size: var(--h3);
		color: var(--color-text);
		line-height: 1em;
		margin-right: 0.5rem;
	}
</style>
