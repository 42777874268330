import type { Mode } from "../modules/mode"
import type { EventName, NoWildcard } from "."
import { EditorEvent } from "."

export type ModeEventName = EventName<"activated" | "deactivated" | "cancelled" | "completed">

interface ModeEventInit {
	mode: Mode<any>
}

export interface ModeEvent extends EditorEvent<ModeEventName>, ModeEventInit {}

export class ModeEvent extends EditorEvent<ModeEventName> {
	public OptionsType: ModeEventInit

	constructor(type: NoWildcard<ModeEventName>, modeEventData: ModeEventInit) {
		super(type)
		this.mode = modeEventData.mode
	}
}
