<script lang="ts">
	import { DataHandlerDevice, DeviceGroup, type DeviceGroupSlot, type DeviceRPi } from "luxedo-data"
	import { GroupOverlapController } from "../GroupOverlapController"
	import { GroupEditorController } from "../../../GroupEditorController"
	import NumberRange from "../../../../../input/NumberRange.svelte"
	import { tooltip } from "svelte-comps/tooltip"
	import { openCustomBlendOverlay } from "../../../custom-blend-zone"
	import { LuxedoRPC } from "luxedo-rpc"
	import { closeOverlay } from "svelte-comps/overlay"

	type DeviceWithSlot = DeviceRPi & { slot: DeviceGroupSlot }

	let devices: Array<DeviceWithSlot> = []
	let selectedOverlap: GroupOverlapController.OverlapInstance

	let leftBrightness
	let rightBrightness

	let initialBrightness
	let hasUpdatedBrightness = false

	let group: DeviceGroup
	GroupEditorController.subscribe((ctx) => (group = ctx.group))

	GroupOverlapController.subscribe(
		(ctx) =>
			(selectedOverlap =
				ctx.overlapInstances.find((instance) => instance.id === ctx.selectedOverlapInstance) ??
				undefined)
	)

	function updateDevices() {
		devices = []
		for (const slot of selectedOverlap.slots) {
			const device: DeviceWithSlot = DataHandlerDevice.get(slot.device_id) as DeviceWithSlot
			device.slot = slot
			devices.push(device)
		}

		updateInitialBrightness()
	}

	function updateInitialBrightness() {
		hasUpdatedBrightness = false
		const slotLeft = selectedOverlap.slots[0]
		const slotRight = selectedOverlap.slots[1]

		const overlapLeft = slotLeft?.overlap_override?.find(
			(overlap) => overlap.main_slot === slotLeft.id
		)
		const overlapRight = slotRight?.overlap_override?.find(
			(overlap) => overlap.main_slot === slotRight.id
		)

		if (overlapLeft?.rel_brightness < 1) {
			initialBrightness = overlapLeft.rel_brightness
		} else if (overlapRight?.rel_brightness < 1) {
			initialBrightness = 2 - overlapRight.rel_brightness
		} else initialBrightness = 1
		setTimeout(() => (hasUpdatedBrightness = true))
	}

	function updateGamma(slotID: number, newGamma: number) {
		const secondaryID = devices.find((dev) => dev.slot.id !== slotID).slot.id
		GroupEditorController.Slots.updateSlotOverlap(slotID, secondaryID, {
			gamma: newGamma,
		})
	}

	function getCurrentGamma(slotID: number) {
		const mainSlot = devices.find((dev) => dev.slot.id === slotID).slot
		const secondSlot = devices.find((dev) => dev.slot.id !== slotID).slot
		const overlap = mainSlot.overlap_override?.find(
			(overlap) => overlap.main_slot === mainSlot.id && overlap.other_slot === secondSlot.id
		)

		return overlap?.gamma ?? mainSlot.gamma ?? 2.2
	}

	/**
	 * WAITING ON DATA MODEL FINALIZATION
	 */
	function updateRelativeBrightness(left: number, right: number) {
		if (left === undefined || right === undefined || !hasUpdatedBrightness) return

		const leftDevice = devices[0]
		const rightDevice = devices[1]

		GroupEditorController.Slots.updateSlotOverlap(leftDevice.slot.id, rightDevice.slot.id, {
			relativeBrightness: left,
		})

		GroupEditorController.Slots.updateSlotOverlap(rightDevice.slot.id, leftDevice.slot.id, {
			relativeBrightness: right,
		})
	}

	async function initBlendZone(device: DeviceWithSlot) {
		await GroupEditorController.save(false, true)

		const mainDeviceId = device.id
		const otherSlot = selectedOverlap.slots.find((s) => s.device_id !== device.id)
		const otherDeviceId = otherSlot.device_id

		let overlayID

		const onSave = async (imageData: string) => {
			await LuxedoRPC.api.device.device_group_set_custom_overlap(
				group.id,
				mainDeviceId,
				otherDeviceId,
				imageData
			)
			if (overlayID) closeOverlay(overlayID)
			GroupEditorController.save(false, true)
		}

		overlayID = openCustomBlendOverlay(selectedOverlap, device.slot.id, group, onSave, () => {})
	}

	// $: updateRelativeBrightness(leftBrightness, rightBrightness)
	$: selectedOverlap && updateDevices()
</script>

<div class="overlap-properties">
	{#if selectedOverlap}
		<div class="overlap-slots">
			{#each devices as device (device.id)}
				<div class="overlap-slot-instance" style="--color-device: {device.color};">
					<h3>{device.name}</h3>
					<NumberRange
						label="Gamma: "
						min={1.6}
						max={2.8}
						step={0.1}
						value={getCurrentGamma(device.slot.id)}
						on:change={(e) => updateGamma(device.slot.id, e.detail)}
					/>

					<button
						id="blend-zone-button"
						class="outline-button small alt"
						on:click={() => initBlendZone(device)}
						use:tooltip={{
							pointing: "top",
							content: `Custom blend zones allow you to draw the bounds between each projector. This will determine how the overlapping space between projectors is handled.`,
						}}>Create Custom Blend Zone</button
					>
				</div>
			{/each}
		</div>
		<!-- <BrightnessInput
			colorLeft={devices[0].color}
			colorRight={devices[1].color}
			initialValue={initialBrightness}
			bind:leftBrightness
			bind:rightBrightness
		/> -->
	{/if}
</div>

<style>
	.overlap-properties {
		width: fit-content;
		display: flex;
		flex-direction: column;
		margin: 0 2rem;
		flex-grow: 1;
	}

	.overlap-slots {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 2rem;
	}

	.overlap-slot-instance {
		flex-grow: 1;
	}

	h3 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-device);
		margin-bottom: 1rem;
	}

	h4 {
		font-size: var(--h2);
		margin: 0;
	}

	.flex-row {
		align-items: center;
	}

	.advanced-header {
		margin-top: 1rem;
	}

	button.icon :global(svg) {
		transition: transform 250ms;
		height: 1.25rem;
		margin-left: 0.5rem;
	}

	button.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	.overlap-properties :global(label) {
		color: var(--color-text);
		font-size: var(--h3);
	}

	#blend-zone-button {
		margin-top: 1rem;
	}
</style>
