import { ClippingMask, MaskBase } from "luxedo-canvas/asset"
import { get, writable } from "svelte/store"

export namespace MaskSidebarController {
	export type MenuOption = "empty" | "list" | "new" | "edit" | "import"
	export type ContextType = { menu: MenuOption; mask?: MaskBase }

	const store = writable<ContextType>({ menu: "empty" })
	let lastMenu = undefined

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function goTo(menu: MenuOption, mask?: MaskBase) {
		lastMenu = get(store)
		store.set({ menu, mask })
	}

	export function goBack() {
		store.set(lastMenu)
	}

	export function reset() {
		store.set({ menu: "empty" })
	}
}
