import { Toast } from "svelte-comps/toaster"

export function openChargebeeCheckout(url: string, checkoutData: {}) {
	// @ts-ignore
	const chargebee = new Chargebee.init({
		site: import.meta.env.VITE_CHARGEBEE_SITE,
		isItemsModal: true,
	})

	const formData = new FormData()
	for (const key in checkoutData) {
		formData.append(key, checkoutData[key])
	}

	chargebee.openCheckout({
		hostedPage: async () => {
			const res = await fetch(url, {
				method: "post",
				body: formData,
			})
			const body = await res.json()
			return body
		},
		error: () => Toast.error("An error occurred while processing your payment."),
		success: () => Toast.success("Payment succesful!"),
	})
}
