import type { DeviceBrowser } from "luxedo-data"
import { LuxedoRPC } from "luxedo-rpc"
import { getCurrentUser } from "../../../../stores/UserStore"
import { Toast } from "svelte-comps/toaster"
import { closeOverlay, openConfirmOverlay } from "svelte-comps/overlay"

export async function launchLuxLink(device: DeviceBrowser) {
	const user = getCurrentUser()

	try {
		const { token } = await LuxedoRPC.api.device.phantasm_launch(device.id)
		const path = `phantasm?dev_id=${device.id}&token=${token}`
		window.open(`${import.meta.env.VITE_OLD_API_URL}${path}`)
	} catch (e) {
		console.error("[ERROR] ", e)
		if (e.statusCode === 403) {
			if (user.luxlink_max === 0) {
				openConfirmOverlay(
					{
						buttons: {
							confirm: {
								text: "Purchase LuxLink Subscription",
								onClick: () => {},
							},
							deny: {
								text: "Cancel",
								onClick: () => closeOverlay(),
							},
						},
						prompt: [
							"Either your demo period has ended or your subscription has expired.",
							"Either way, a valid subscription to LuxLink is required to use this LuxLink projector.",
							"If you would like to purchase one, please click 'Purchase' below.",
						],
					},
					{
						heading: "Access Has Expired",
					}
				)
			} else {
				openConfirmOverlay(
					{
						buttons: {
							confirm: {
								text: "Upgrade",
								onClick: () => {},
							},
							deny: {
								text: "Cancel",
								onClick: () => closeOverlay(),
							},
						},
						prompt: [
							"You have reached the maximum number of LuxLink projectors allowed under your current subscription.",
							"Please either upgrade your subscription or delete one of your existing projectors.",
							"Also ensure you don't have any previous LuxLink session which are still open in a forgotten tab.",
						],
					},
					{
						heading: "Projector Limit Reached",
					}
				)
			}
		} else {
			Toast.error("Unable to open LuxLink session at this time. Please refresh and try again...")
		}
	}
}
