<script lang="ts">
	import { closeOverlay } from "svelte-comps/overlay"
	import NewSceneHeading from "./NewScene_Heading.svelte"
	import NewSceneSnapshots from "./NewScene_Snapshots.svelte"
	import { LuxedoRPC } from "luxedo-rpc"
	import {
		DataHandlerDevice,
		DataHandlerScene,
		DataHandlerSnapshot,
		DataHandlerTag,
		DeviceGroup,
		Scene,
		User,
	} from "luxedo-data"
	import DirectUploadInfo from "../direct-upload/DirectUpload_Info.svelte"
	import DirectUploadDropzone from "../direct-upload/DirectUpload_Dropzone.svelte"
	import DirectUploadCreate from "../direct-upload/DirectUpload_Create.svelte"
	import DirectUploadExport from "../direct-upload/DirectUpload_Export.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { UserStore, getCurrentUser } from "../../../../../stores/UserStore"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import type { Component } from "../../../../../types/SvelteTypes"
	import { openShowOverview } from "../.."
	import CustomSelect from "../direct-upload/CustomSelect.svelte"
	import { RPCError } from "../../../../../../../../packages/rpc-client"

	// #region Global State

	let user: User // declared here so that dropzone will have an initialized projects folder id

	UserStore.subscribe((u) => (user = u))

	// #endregion Global State
	// #region Scene Inputs (e.g., scene name, description)

	let name: string
	let description: string

	let selectedTags: Array<number>
	let selectedDeviceId: number
	let selectedSnapshotId: number
	let newSceneId: number

	SelectedDeviceStore.subscribe((device) => (device ? (selectedDeviceId = device.id) : undefined))

	// #endregion Scene Inputs
	// #region Form State (e.g., canCreate, isDirectUpload)

	export let createOrSelect: "Create your own" | "Working with a professional" = "Create your own"

	let canCreate: boolean
	let isDirectUpload: boolean = createOrSelect === "Working with a professional" ? true : false

	let showDirectUploadInfo: boolean = false

	let directUploadStepIndex: number = 0
	let directUploadSteps: { [index: number]: Component } =
		createOrSelect === "Create your own"
			? {
					0: NewSceneSnapshots,
					1: DirectUploadCreate,
					2: DirectUploadExport,
					3: DirectUploadDropzone,
				}
			: {
					0: CustomSelect,
					1: NewSceneSnapshots,
					2: DirectUploadDropzone,
				}

	// #endregion Form State
	// #region Methods

	async function createScene() {
		if (name === "Enter scene title...") return enforceSceneName()

		const device = DataHandlerDevice.get(selectedDeviceId)
		const user = getCurrentUser()

		try {
			const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)

			if (selectedSnapshotId && snapshot.dev_id === selectedDeviceId)
				await LuxedoRPC.api.device.set_default_snap(selectedDeviceId, selectedSnapshotId)

			const id = await DataHandlerScene.createEntry({
				name,
				description,
				target_device_id: Number(selectedDeviceId),
				res_x: device.resX,
				res_y: device.resY,
				parent_id: user.directories.projects,
			})

			for (const tagId of selectedTags) {
				await LuxedoRPC.api.tag.tag_project_add(tagId, id)
			}
			window.location.replace(`${import.meta.env.VITE_STATIC_API_URL}editor?id=${id}`)
		} catch (e) {
			console.error("ERROR CREATING SCENE", e)
			if (e instanceof RPCError && e.statusCode === 507)
				Toast.error(
					"Not enough storage remaining on your account... Try deleting content or upgrading storage."
				)
			else Toast.error("Unable to create scene. Please try again.")
		}
	}

	function updateCanCreate() {
		const device = DataHandlerDevice.get(selectedDeviceId)
		if (createOrSelect === "Working with a professional") {
			canCreate = true
			return
		}
		if (device && device.typeId === "dev_amalgam") {
			selectedSnapshotId = undefined
			canCreate = true
			return
		}
		if (!selectedDeviceId) {
			canCreate = false
		} else canCreate = true
	}

	let triggerUpload: () => void

	function enforceSceneName() {
		Toast.error("Don't forget to title your new scene!")

		const labelElem = document.getElementById(`new-scene-title-input`)
		setTimeout(() => {
			const range = document.createRange()
			range.selectNodeContents(labelElem)
			const selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
		})
	}

	let hasUploadStarted = false

	/**
	 * Manages the state of the direct upload process
	 */
	async function directUploadButtonHandler() {
		if (directUploadStepIndex >= Object.keys(directUploadSteps).length - 1) {
			if (name === "Enter scene title...") return enforceSceneName()

			hasUploadStarted = true
			triggerUpload()
			return
		}
		directUploadStepIndex++
	}

	function directUploadBack() {
		if (hasUploadStarted) hasUploadStarted = false
		if (directUploadStepIndex === 0) isDirectUpload = false
		directUploadStepIndex--
	}

	function setStepIndex(index: number) {
		if (isNextButtonDisabled(selectedSnapshotId, directUploadStepIndex, hasUploadStarted)) return
		directUploadStepIndex = index
	}

	function isNextButtonDisabled(
		snapshotId: number,
		stepIndex: number,
		hasUploadStarted?: boolean
	): boolean {
		if (hasUploadStarted) return true
		const device = DataHandlerDevice.get(selectedDeviceId)
		if (device instanceof DeviceGroup) return false
		if (!snapshotId) return true
		else return false
	}

	function postDirectUpload() {
		Toast.success("New scene successfully created!")
		if (newSceneId) {
			setTimeout(async () => {
				const newScene = await getNewScene()
				hasUploadStarted = false
				closeOverlay()
				openShowOverview(newScene)
			})
		} else () => closeOverlay()
	}

	async function getNewScene() {
		const pullScene = async () => {
			await DataHandlerScene.pull([newSceneId])
			return DataHandlerScene.get(newSceneId)
		}

		return new Promise<Scene>(async (res, rej) => {
			let scene = await pullScene()
			if (scene.render_ver) res(scene)

			let intervalIndex = 0
			let interval = setInterval(async () => {
				const resolve = () => {
					clearInterval(interval)
					res(scene)
				}

				if (intervalIndex >= 5) resolve()

				scene = await pullScene()
				if (scene.render_ver) resolve()

				intervalIndex++
			}, 1000)
		})
	}

	// #endregion Methods

	function updateSteps() {
		directUploadSteps =
			createOrSelect === "Create your own"
				? {
						0: NewSceneSnapshots,
						1: DirectUploadCreate,
						2: DirectUploadExport,
						3: DirectUploadDropzone,
					}
				: {
						0: CustomSelect,
						1: NewSceneSnapshots,
						2: DirectUploadDropzone,
					}
	}

	$: createOrSelect && updateSteps()

	$: ((selectedDeviceId && selectedSnapshotId) || !(selectedDeviceId && selectedSnapshotId)) &&
		updateCanCreate()

	$: (!isDirectUpload || !selectedSnapshotId) &&
		(() => {
			directUploadStepIndex = 0
		})()
</script>

<div id="new-scene-form">
	<NewSceneHeading
		triggerCreate={isDirectUpload ? directUploadButtonHandler : createScene}
		bind:name
		bind:description
		bind:selectedDeviceId
		bind:selectedTags
	/>
	<div class="scene-variable-inputs">
		{#if !isDirectUpload}
			<div class="third-party-selection-container">
				<button class="link-button" on:click={() => (isDirectUpload = true)}>Upload scene</button>
			</div>
		{/if}
		{#if isDirectUpload}
			<div class="flex-row">
				<DirectUploadInfo
					{createOrSelect}
					stepIndex={directUploadStepIndex}
					updateStepIndex={setStepIndex}
					{selectedSnapshotId}
				/>
				{#if (directUploadStepIndex === 0 && createOrSelect === "Create your own") || (directUploadStepIndex === 1 && createOrSelect === "Working with a professional")}
					<NewSceneSnapshots
						bind:selectedSnapshotId
						isDirectUpload={true}
						onSnapshotClick={(id) => (selectedSnapshotId = id)}
						{selectedDeviceId}
					/>
				{:else}
					<svelte:component
						this={directUploadSteps[directUploadStepIndex]}
						bind:newSceneId
						bind:triggerUpload
						postSubmit={postDirectUpload}
						isDirectUpload={true}
						projectFolder={user?.directories.projects}
						sceneName={name}
						{createOrSelect}
						{selectedSnapshotId}
						{selectedDeviceId}
					/>
				{/if}
			</div>
		{:else}
			<NewSceneSnapshots {selectedDeviceId} bind:selectedSnapshotId />
		{/if}
	</div>

	<div class="flex-row">
		<!-- <CheckboxInput
			id="third-party-editor-input"
			label="Use 3rd party editor?"
			bind:isChecked={isDirectUpload}
		/> -->
		<div class="button-container">
			<button
				class="link-button"
				on:click={isDirectUpload ? directUploadBack : () => closeOverlay()}
			>
				{#if isDirectUpload && directUploadStepIndex !== 0}
					Back
				{:else}
					Cancel
				{/if}
			</button>
			<button
				on:click={isDirectUpload ? directUploadButtonHandler : createScene}
				disabled={isDirectUpload && createOrSelect === "Create your own"
					? isNextButtonDisabled(selectedSnapshotId, directUploadStepIndex, hasUploadStarted)
					: !canCreate}
			>
				{#if isDirectUpload}
					{#if directUploadStepIndex >= Object.keys(directUploadSteps).length - 1}
						Upload
					{:else}
						Next
					{/if}
				{:else}
					Create Scene
				{/if}
			</button>
		</div>
	</div>
</div>

<style>
	#new-scene-form {
		--tile-width: 6rem;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
	}

	.scene-variable-inputs :global(h3) {
		font-size: var(--h1);
		margin: 0;
	}

	.flex-row {
		padding: 0 2rem;
		margin: 0 0 2rem 0;
	}

	.flex-row:nth-child(2) {
		margin-right: 1rem;
	}

	.scene-variable-inputs .flex-row :global(.snapshot-selector) {
		padding: 1rem 2rem 0rem 2rem;
		margin-bottom: 0;
	}

	.scene-variable-inputs {
		flex-grow: 1;
		margin: 1rem 2rem 0 2rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.scene-variable-inputs .flex-row :global(.flex-column:nth-of-type(2)) {
		overflow-y: auto;
		margin-top: 3rem;
		padding-bottom: 1rem;
		width: 60%;
	}

	.scene-variable-inputs .flex-row :global(.dropzone) {
		margin-bottom: 0;
	}

	.scene-variable-inputs .flex-row {
		margin: 0;
		padding: 0;
		--tile-width: 7.5rem;
		flex-grow: 1;
		overflow: hidden;
	}

	.scene-variable-inputs .flex-row :global(.tile-label) {
		font-size: var(--h3);
	}

	#new-scene-form :global(label[for="third-party-editor-input"]) {
		color: var(--color-text);
		width: fit-content;
	}

	.button-container {
		justify-content: flex-end;
		margin: 0;
	}

	.scene-variable-inputs .third-party-selection-container {
		position: absolute;
		right: 2rem;
	}

	.third-party-selection-container .link-button {
		color: var(--color-main);
		padding: 0 1rem 0 0;
	}
</style>
