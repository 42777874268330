import { fabric } from "fabric"
import { TrackLike, Track, TrackGroup } from "."
import { PositionPath } from "../lib"
import { EditorClass } from ".."
import { CanvasAsset, ClippingMask, MultiMask } from "../asset"

export class TrackRenderManager {
	RendererType: TrackRendererType
	editor: EditorClass
	constructor(editor: EditorClass, rendererType: TrackRendererType) {
		this.editor = editor
		this.RendererType = rendererType
	}

	getRenderer(track: TrackLike<CanvasAsset>) {
		if (!(track.id in this.renderers)) this.renderers[track.id] = new TrackRenderObject(this.editor, track)
		return this.renderers[track.id]
	}

	shouldRender(track: TrackLike<CanvasAsset>): boolean {
		if (!(track.id in this.renderers)) this.renderers[track.id] = new TrackRenderObject(this.editor, track)
		return this.renderers[track.id].shouldRender()
	}

	protected renderers: {
		[trackId: string]: TrackRenderObject
	} = {}
}

export type TrackRendererType = new (editor: EditorClass, track: TrackLike<CanvasAsset>) => fabric.Object
export class TrackRenderObject extends fabric.Object {
	//#region    ===========================				Initialization				==============================
	track: TrackLike<CanvasAsset>
	editor: EditorClass

	constructor(editor: EditorClass, track: TrackLike<CanvasAsset>) {
		super({ objectCaching: false })

		this.editor = editor
		this.selectable = false
		this.track = track
	}
	//#endregion =====================================================================================================

	//#region    ===========================				   Interface				==============================
	shouldRender(): boolean {
		if (this.shouldRenderMaskOutline()) return true
		if (this.shouldRenderMaskOverflow()) return true
		if (this.shouldRenderAnimationPath()) return true

		return false
	}

	render(ctx: CanvasRenderingContext2D): void {
		if (this.shouldRenderAnimationPath()) this.renderAnimationPath(ctx)
		if (this.shouldRenderMaskOutline()) this.renderMaskOutline(ctx)
		if (this.shouldRenderMaskOverflow()) this.renderMaskOverflow(ctx)
	}

	//#endregion =====================================================================================================

	//#region    ===========================		Implementation (Animation Path)		==============================

	protected shouldRenderAnimationPath(): boolean {
		if (!(this.track instanceof Track)) return false
		if (this.track.hidden || !this.track.asset.visible) return false
		if (!("PositionPath" in this.track.animationPresets)) return false
		return true
	}

	protected renderAnimationPath(ctx: CanvasRenderingContext2D): void {
		if (!(this.track instanceof Track)) return

		const preset = this.track.animationPresets["PositionPath"] as PositionPath
		const path = preset.path
		let override = path._visibilityOverride
		path.forceVisibility(true)
		path.render(ctx)
		path.forceVisibility(override)
	}

	//#endregion =====================================================================================================

	//#region    ===========================		Implementation (Clipping Mask)		==============================

	protected shouldRenderMaskOutline(): boolean {
		if (!this.track.hasOwnMask()) return false
		if (this.track.hidden) return false
		if (this.track instanceof Track && !this.track.isVisible()) return false
		return true
	}

	protected renderMaskOutline(ctx: CanvasRenderingContext2D): void {
		const mask = this.track.clippingMask

		if (mask instanceof MultiMask) {
			for (const submask of mask.masks) {
				const override = submask.path._visibilityOverride
				submask.path.forceVisibility(true)
				submask.path.render(ctx)
				submask.path.forceVisibility(override)
			}
		} else if (mask instanceof ClippingMask) {
			const override = mask.path._visibilityOverride
			mask.path.forceVisibility(true)
			mask.path.render(ctx)
			mask.path.forceVisibility(override)
		}
	}

	protected shouldRenderMaskOverflow(): boolean {
		if (!this.track.hasOwnMask()) return false

		const mask = this.track.getMask()

		if (mask.overflow !== "faded") return false
		return true
	}

	protected renderMaskOverflow(ctx: CanvasRenderingContext2D) {
		if (this.track instanceof Track) {
			this.renderOutsider(this.track.asset, ctx)
		} else if (this.track instanceof TrackGroup) {
			this.track.forEach((target: TrackLike<CanvasAsset>) => {
				if (!(target instanceof Track)) return
				this.renderOutsider(target.asset, ctx)
			})
		}
	}

	protected renderOutsider(asset: fabric.Object, ctx: CanvasRenderingContext2D) {
		if (!asset.visible) return
		const mask = this.track.getMask()
		mask.inverted = !mask.inverted
		const prevOpacity = asset.opacity
		asset.opacity *= 0.15
		asset.render(ctx)
		asset.opacity = prevOpacity
		mask.inverted = !mask.inverted
		asset.render(ctx)
	}

	_set(...args: any): fabric.Object {
		return
	}

	//#endregion =====================================================================================================

	get canvas() {
		return this.editor.canvas
	}

	set canvas(v) {
		return
	}
}

export type CanvasRenderFunction = (ctx: CanvasRenderingContext2D) => any
