<script lang="ts">
	import type { Component } from "../../../../../types/SvelteTypes"
	import { LUXLINK_MENUS, LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"

	let currentMenu: Component
	LuxLinkSubscriptionManager.subscribe(({ route }) => (currentMenu = LUXLINK_MENUS[route]))
</script>

{#if currentMenu}
	<svelte:component this={currentMenu} />
{/if}

<style>
</style>
