<script lang="ts">
	import { tooltip } from "../tooltip"

	export let textContent: string
	export let rightAligned = false
	export let inline = false
</script>

<div
	class="info-dialogue-button {rightAligned ? 'right-align' : ''} {inline ? 'inline' : ''}"
	use:tooltip={{ content: textContent, pointing: "top" }}
>
	?
</div>

<style>
	.info-dialogue-button {
		cursor: help;
		display: flex;
		box-sizing: border-box;
		line-height: 1.5em;
		/* border: 1px solid var(--color-alt); */

		color: var(--color-main);
		border-radius: 100%;
		font-size: var(--h3);
		width: 1rem;
		height: 1rem;
		text-align: center;
		align-items: center;
		justify-content: center;
		/* padding: 0.25rem; */
		/* line-height: 0; */
		margin-left: 0.25rem;
		z-index: var(--z-index-front);
		/* color: var(--color-text); */
	}

	.info-dialogue-button.inline {
		padding: 0;
		display: inline;
		/* height: unset; */
		/* font-size: unset; */
		width: unset;
		margin-left: 0;
	}

	.info-dialogue-button.right-align :global(.dialogue) {
		left: unset;
		right: -1.75rem;
	}

	.info-dialogue-button.right-align :global(.dialogue svg) {
		left: unset;
		margin-left: auto;
		margin-right: 0;
		right: 1rem;
	}
</style>
