/**
 * This module initiates mock data to allow for simple local development without needing active devices
 */

import { DataHandlerDevice, MockDevice } from "luxedo-data"
import { mockDatahandler } from "../../../../../packages/data-handler/src"

export namespace DevelopmentMode {
	export function init() {
		initMockData()
		console.log("initiating mock data")
	}

	function initMockData() {
		const DeviceNeedsUpdate = new MockDevice.DeviceNeedsUpdate()

		mockDatahandler(DataHandlerDevice, [DeviceNeedsUpdate])
	}
}
