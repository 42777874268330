<script lang="ts">
	import {
		Device,
		DataHandlerDevice,
		DeviceRPi,
		DeviceBrowser,
		DeviceGroup,
		DataHandlerCalibration,
		DataHandlerSnapshot,
	} from "luxedo-data"
	import ProjectorIcon from "../../../../reusable/icons/ProjectorIcon.svelte"
	import DeviceColorPicker from "../device-color-picker/DeviceColorPicker.svelte"
	import { openDeviceColorPicker } from "../device-color-picker/DeviceColorPickerContext"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"
	import { onMount } from "svelte"
	import { ProjectorRegistrationContext } from "../../ProjectorRegistrationContext"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { openOverlay, openPopupOverlay } from "svelte-comps/overlay"
	import { launchLuxLink } from "../LuxLinkLauncher"
	import { openGroupEditorOverlay } from "../../../../reusable/overlays/group-editor"

	let prevSelectedDevice: Device
	let deviceName: string

	let deviceType: "Cast" | "Group" | "LuxLink"

	let deviceId: string
	let devicePassword: string
	let deviceIconElem: SVGElement

	ProjectorRegistrationContext.subscribe((ctx) => {
		if (ctx.isRegistering) {
			prevSelectedDevice = ctx.prevSelectedDevice
			deviceType = ctx.type
		} else {
			prevSelectedDevice = undefined
			deviceType = "Cast"
		}
	})

	let color = Device.getUIColorByIndex(DataHandlerDevice.getNextUIColor())

	function onColorChange(colorIndex: number) {
		ProjectorRegistrationContext.update((ctx) => {
			return {
				isRegistering: true,
				prevSelectedDevice,
				type: ctx.type ?? "Cast",
				colorIndex,
			}
		})
		color = Device.getUIColorByIndex(colorIndex)
	}

	function openColorInput() {
		openDeviceColorPicker(onColorChange, deviceIconElem)
	}

	async function registerNewDevice() {
		try {
			await LuxedoRPC.api.device.device_register(
				Number(deviceId),
				devicePassword,
				deviceName ?? "My Luxedo"
			)

			Toast.success(`${deviceName ?? "My Luxedo"} successfully registered.`)

			await DataHandlerDevice.pull([Number(deviceId)])
			const dev = DataHandlerDevice.get(Number(deviceId)) as DeviceRPi
			await DataHandlerCalibration.pull()
			await DataHandlerSnapshot.pull()

			SelectedDeviceStore.set(dev)
			if (dev._rawData.product_id && dev._rawData.product_id < 5) {
				openPopupOverlay(
					{
						buttonText: "I understand",
						prompt: [
							"Our systems detected that this may be a second-hand device!",
							"Please note that Luxedo does not offer support, warranties, or returns for second-hand devices, and does not take responsibility for any damage or malfunction of devices which have had a previous owner.",
							"If you experience issues with a second-hand device, please take it up with the person who sold you the device.\n",
							"(If you purchased this device directly from Luxedo, you may ignore this message)",
						],
					},
					{
						heading: "Resale Device Disclaimer",
						classHeading: "no-unerline",
					}
				)
			}
		} catch (e) {
			console.error("ERROR registering projector", e)
			Toast.error("Failed to register projector, please verify the id and password then try again.")
		}
	}

	async function registerNewLuxLink() {
		try {
			const { dev_id } = await LuxedoRPC.api.device.device_synth_create(deviceName)
			await DataHandlerDevice.pull([dev_id])
			const dev = DataHandlerDevice.get(dev_id) as DeviceBrowser
			SelectedDeviceStore.set(dev)
			Toast.success(`${deviceName ?? "LuxLink"} successfully created - launching setup!`)
			launchLuxLink(dev)
		} catch (e) {
			console.error("ERROR creating device group", e)
			Toast.error("Unable to create LuxLink.")
		}
	}

	async function registerNewGroup() {
		try {
			const { dev_id } = await LuxedoRPC.api.device.device_group_create(deviceName)
			await DataHandlerDevice.pull([dev_id])
			const dev = DataHandlerDevice.get(Number(dev_id)) as DeviceGroup
			SelectedDeviceStore.set(dev)
			Toast.success(`${deviceName ?? "Device group"} successfully registered!`)
			openGroupEditorOverlay(dev)
		} catch (e) {
			console.error("ERROR creating device group", e)
			Toast.error("Failed to create device group, please refresh and try again.")
		}
	}

	async function onRegister() {
		const device = DataHandlerDevice.get(Number(deviceId))
		if (device) {
			return Toast.error("Device already registered! ")
		}

		if (deviceType === "Group") return registerNewGroup()
		else if (deviceType === "LuxLink") return registerNewLuxLink()
		else return registerNewDevice()
	}

	function onCancel() {
		const devices = DataHandlerDevice.getMany()
		if (!prevSelectedDevice) prevSelectedDevice = devices[devices.length - 1]
		SelectedDeviceStore.set(prevSelectedDevice)
		ProjectorRegistrationContext.set({
			isRegistering: false,
		})
	}

	function checkEnter(e: KeyboardEvent) {
		if (e.key === "Enter") {
			if (deviceType === "Cast" && deviceName && devicePassword && deviceId) onRegister()
			else if (deviceName) onRegister()
		}
	}

	function switchToLuxLink() {
		ProjectorRegistrationContext.update((ctx) => ({
			...ctx,
			type: "LuxLink",
			isRegistering: true,
			prevSelectedDevice: undefined,
			colorIndex: DataHandlerDevice.getNextUIColor(),
		}))
	}

	function switchToLPS() {
		ProjectorRegistrationContext.update((ctx) => ({
			...ctx,
			type: "Cast",
			isRegistering: true,
			prevSelectedDevice: undefined,
			colorIndex: DataHandlerDevice.getNextUIColor(),
		}))
	}
</script>

<div class="flex-row">
	<DeviceColorPicker />
	<!-- {#if deviceType === "Group"}
		<div class="icon-container">
			<ProjectorIcon strokeColor={color} onClick={openColorInput} bind:deviceIconElem />
			<div class="icon-bg" />
			<div style="background-color: {color}" class="icon-bg-color" />
		</div>
	{/if} -->
	<div class="flex-column">
		<div class="device-registration-inputs">
			{#if deviceType === "Group"}
				<span class="device-group-info"
					>A projector group is a combination of multiple projectors which can be used to create
					scenes and lightshows to cover larger projection spaces.
				</span>
			{:else if deviceType === "LuxLink"}
				<span class="device-group-info"
					>LuxLink allows you to use your own projector as a Luxedo device as long as it remains
					plugged into your computer.
				</span>
			{/if}
			<input
				type="text"
				placeholder={deviceType === "Group" ? "Group Name" : "Device Name"}
				bind:value={deviceName}
			/>
			{#if deviceType !== "Group" && deviceType !== "LuxLink"}
				<div class="flex-row">
					<input type="text" placeholder="Device ID" bind:value={deviceId} />
					<input type="text" placeholder="Device Password" bind:value={devicePassword} />
				</div>
			{/if}
		</div>
	</div>
</div>
<div class="space-between">
	{#if deviceType === "Cast"}
		<button class="link-button flip-reg-btn" on:click={switchToLuxLink}>
			Registering LuxLink?
		</button>
	{:else if deviceType === "LuxLink"}
		<button class="link-button flip-reg-btn" on:click={switchToLPS}>
			Registering Luxedo Device?
		</button>
	{/if}
	<div class="button-container">
		<button class="link-button" on:click={onCancel}>Cancel</button>
		<button
			class=""
			on:click={onRegister}
			disabled={deviceType === "Group" || deviceType === "LuxLink"
				? !!!deviceName
				: !(!!deviceId && !!devicePassword && !!deviceName)}>Register</button
		>
	</div>
</div>
<svelte:document on:keydown={checkEnter} />

<style>
	.device-registration-inputs {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.flex-column {
		width: 100%;
	}

	.device-group-info {
		color: var(--color-text);
		margin-bottom: 0.5rem;
		max-width: 40vw;
	}

	input {
		width: 100%;
		margin-bottom: 1rem;
		background-color: transparent;
	}

	input::placeholder {
		color: var(--color-text);
	}

	.button-container {
		justify-content: flex-end;
		margin-bottom: 0;
	}

	.button-container button {
		margin-left: 0.5rem;
		padding: 0rem 0.5rem;
	}

	.flex-row input:first-of-type {
		margin-right: 1rem;
	}

	.space-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.flip-reg-btn {
		padding: 0;
		width: 14rem;
		color: var(--color-text);
	}
</style>
