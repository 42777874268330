<script lang="ts">
	import { DataHandlerDevice, type DeviceGroupSlot, type DeviceRPi } from "luxedo-data"
	import { GroupEditorController } from "../../../GroupEditorController"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import NumberRange from "../../../../../input/NumberRange.svelte"
	import { AsyncCollapsibleSection, CollapsibleSection } from "svelte-comps/comps"
	import { ArrowDown, ArrowUp } from "svelte-comps/icons"
	import LockIcon from "../../../../../icons/LockIcon.svelte"

	let selectedSlot: DeviceGroupSlot
	let device: DeviceRPi

	let posX: number
	let posY: number

	let verticalScale: number
	let horizontalScale: number

	let isAspectRatioUnlocked = false

	let isScaleLocked = false
	let isPositionLocked = false

	GroupEditorController.Slots.subscribe((ctx) => {
		selectedSlot = ctx.slots.find((slot) => ctx.selectedSlot === slot.id)
		if (selectedSlot) {
			posX = selectedSlot.pos_x
			posY = selectedSlot.pos_y
			horizontalScale = selectedSlot.scale_x
			verticalScale = selectedSlot.scale_y
			isAspectRatioUnlocked = horizontalScale != verticalScale
			isScaleLocked = ctx.scaleLockedSlots[selectedSlot.id]
			isPositionLocked = ctx.positionLockedSlots[selectedSlot.id]
			updateDeviceReference(selectedSlot)
		}
	})

	function updateDeviceReference(slot: DeviceGroupSlot) {
		device = DataHandlerDevice.get(slot.device_id) as DeviceRPi
	}

	function onUpdatePosition(axis: "x" | "y") {
		let updateKey = `pos_${axis}`
		return (
			e: Event & {
				currentTarget: HTMLInputElement
			}
		) => {
			const target = e.currentTarget
			setTimeout(() => {
				// Wrapped in setTimeout to ensure target value is updated
				const value = Math.floor(Number(target.value))
				GroupEditorController.Slots.updateSlot({
					[updateKey]: value,
				})
			})
		}
	}

	function updateHorizontalScale(e: CustomEvent) {
		const newScale = e.detail
		GroupEditorController.Slots.updateSlot({
			scale_x: newScale,
		})
	}

	function updateVerticalScale(e: CustomEvent) {
		const newScale = e.detail
		if (!isAspectRatioUnlocked) {
			GroupEditorController.Slots.updateSlot({
				scale_y: newScale,
				scale_x: newScale,
			})
		} else {
			GroupEditorController.Slots.updateSlot({
				scale_y: newScale,
			})
		}
	}

	function getSlotGamma(slotID: number): number {
		const slot = GroupEditorController.Slots.getSlot(slotID)
		return slot.gamma ?? 2.2
	}

	function updateSlotGamma(gamma: number) {
		GroupEditorController.Slots.updateSlot({
			gamma,
		})
	}

	function toggleScaleLock() {
		GroupEditorController.Slots.setSlotScaleLocked(selectedSlot.id, !isScaleLocked)
	}

	function togglePositionLock() {
		GroupEditorController.Slots.setSlotPositionLocked(selectedSlot.id, !isPositionLocked)
	}
</script>

<div
	class="slot-properties-container"
	style="--color-device: {device?.color ?? 'var(--color-main)'};"
>
	{#if selectedSlot && device}
		<div class="flex-row">
			<h3>{device.name}</h3>
			<div class="switch-container">
				<span class="label"> Unlock Aspect Ratio? </span>
				<ToggleSwitch
					info="Unlock the aspect ratio when the projection scales differently vertically and horizontally in real space - this is usually not needed."
					isActive={isAspectRatioUnlocked}
					onUpdate={() => (isAspectRatioUnlocked = !isAspectRatioUnlocked)}
				/>
			</div>
		</div>
		<div class="slot-properties">
			<div class="position-properties">
				<div class="advanced-header">
					<h4>Position</h4>
					<button
						class="icon small"
						title="{isPositionLocked ? 'Unlock' : 'Lock'} movement"
						on:click={togglePositionLock}><LockIcon isLocked={isPositionLocked} /></button
					>
				</div>
				<div class="input-container">
					<label for="x-pos-input">X: </label>
					<input
						type="number"
						step="1"
						id="x-pos-input"
						value={posX}
						disabled={isPositionLocked}
						on:keydown={onUpdatePosition("x")}
					/>
				</div>
				<div class="input-container">
					<label for="y-pos-input">Y: </label>
					<input
						type="number"
						step="1"
						id="y-pos-input"
						value={posY}
						disabled={isPositionLocked}
						on:keydown={onUpdatePosition("y")}
					/>
				</div>
			</div>
			<div class="scale-properties">
				<div class="advanced-header">
					<h4>Scale</h4>
					<button
						class="icon small"
						title="{isScaleLocked ? 'Unlock' : 'Lock'} scaling"
						on:click={toggleScaleLock}><LockIcon isLocked={isScaleLocked} /></button
					>
				</div>
				<NumberRange
					label="Vertical: "
					value={verticalScale}
					max={3}
					min={0.5}
					step={0.1}
					disabled={isScaleLocked}
					on:change={updateVerticalScale}
				/>
				<NumberRange
					label="Horizontal: "
					value={horizontalScale}
					disabled={!isAspectRatioUnlocked || isScaleLocked}
					max={3}
					min={0.5}
					step={0.1}
					on:change={updateHorizontalScale}
				/>
			</div>
			<div class="advanced-properties">
				<CollapsibleSection>
					<div class="advanced-header" slot="header">
						<h4>Advanced</h4>
						<button class="icon small">
							<ArrowDown />
						</button>
					</div>
					<NumberRange
						slot="content"
						label="Gamma: "
						min={1.6}
						max={2.8}
						step={0.1}
						value={getSlotGamma(selectedSlot.id)}
						on:change={(e) => updateSlotGamma(e.detail)}
					/>
				</CollapsibleSection>
			</div>
		</div>
	{/if}
</div>

<style>
	.slot-properties-container {
		flex-grow: 1;
		padding-left: 2rem;
	}

	.slot-properties {
		display: flex;
		flex-direction: row;

		margin-top: 1rem;
	}

	.scale-properties,
	.advanced-properties {
		flex-grow: 1;
	}

	h3 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-device);
	}

	h4 {
		font-size: var(--h2);
		color: var(--color-text);
		margin: 0;
		height: 2rem;
	}

	label {
		color: var(--color-text);
		font-size: var(--h3);
	}

	.switch-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 1rem;
	}

	.switch-container .label {
		font-size: var(--h3);
		color: var(--color-text);
		margin-right: 0.5rem;
	}

	input {
		padding-block-start: 0;
		padding-block-end: 0;
	}

	.position-properties,
	.scale-properties {
		margin-right: 2rem;
	}

	.slot-properties :global(label) {
		color: var(--color-text);
		font-size: var(--h3);
	}

	.slot-properties :global(input[type="number"]) {
		font-size: var(--h2);
		background-color: unset;
		height: 1.75rem;
	}

	.scale-properties :global(label) {
		width: 3rem;
	}

	.advanced-properties {
		max-width: 15rem;
	}

	.advanced-header {
		display: flex;
	}

	.advanced-header button.icon :global(svg) {
		height: 1.25rem;
		transform: rotate(0);
	}

	.advanced-header button.icon {
		padding: 0;
		margin-left: 0.5rem;
	}

	.advanced-header button.icon :global(svg) {
		transition: transform 250ms;
	}

	.advanced-properties :global(.section-expanded button.icon svg) {
		transform: rotateX(180deg);
	}

	input:disabled {
		opacity: 0.5;
	}
</style>
