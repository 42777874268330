/**
 * @file edit-modes/PolygonEditMode.ts
 * @author Austin Day 2023
 * @description An edit mode which allows you to edit the points of a polygon
 */

import { fabric } from "fabric"
import { EditPoints, PointEditOptions } from "."
import { deepCopyPoints } from "../../fabric-plugins"
import { EditorClass } from "../.."
import { MaskBase, Path } from "../../asset"

/** Edit mode for editing polygon points */
export class PolygonEditMode extends EditPoints {
	cancellable = true

	//#region    ===========================		   	   Implementation		 		==============================

	constructor(editor: EditorClass, options: PointEditOptions) {
		const target = options.target

		const optionBlock: PointEditOptions = {
			minPoints: 3,
			allowAddingPoints: true,
			allowDeletingPoints: true,
			...options,
			target,
			editStyle: {
				strokeWidth: 2,
				stroke: "#ffffff80",
				...(options.editStyle ?? {}),
			},
		}

		super(editor, optionBlock)
	}

	get modeTitle() {
		return "Editing Polygon"
	}

	protected onActivate(options: PointEditOptions): void {
		/* Prevent selection of other objects */
		// this.target.points.push(this.target.points[0])
		super.onActivate(options)
		this.setSelection(this.target)
		this.save()
	}

	protected onDeactivate(): void {
		super.onDeactivate()
		this.updateTargetPosition()
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood		 		==============================

	protected savedValues: Partial<fabric.Polyline> = {}

	private notifySubscribers() {
		if (this.target instanceof MaskBase) {
			this.target.path.notifySubscribers("point:edit")
		}
	}

	protected onPointEdit(index: number, positionAbsolute: fabric.Point, eventData?: MouseEvent): void {
		super.onPointEdit(index, positionAbsolute, eventData)

		// Because this mode handles masks, update the mask's path in case that is indeed what is being edited
		if ("path" in this.target && this.target.path) (this.target.path as Path).like(this.target)

		this.notifySubscribers()
	}

	protected updatePoints(): void {
		super.updatePoints()

		this.notifySubscribers()
	}

	save(): void {
		this.savedValues.left = this.target.left
		this.savedValues.top = this.target.top
		this.savedValues.width = this.target.width
		this.savedValues.height = this.target.height
		this.savedValues.pathOffset = new fabric.Point(this.target.pathOffset.x, this.target.pathOffset.y)

		super.save()
	}

	loadSave(): void {
		super.loadSave()

		// Restore the original properties
		for (const [key, val] of Object.entries(this.savedValues)) {
			this.target[key] = val
		}

		this.editor.canvas.requestRenderAll()
	}

	//#endregion =====================================================================================================
}
