<script lang="ts">
	import type { Component } from "../../../../../../types/SvelteTypes"
	import { GroupOverlapController } from "./GroupOverlapController"
	import GroupBlendCanvas from "./GroupBlend_Canvas.svelte"
	import GroupBlendInputs from "./inputs/GroupBlend_Inputs.svelte"
	import GroupBlendTutorial from "./GroupBlend_Tutorial.svelte"
	import { onDestroy, onMount } from "svelte"
	import { GroupEditorController } from "../../GroupEditorController"

	let stepIndex: number = 0
	export let inputComponent: Component = undefined

	GroupOverlapController.subscribe((ctx) => {
		stepIndex = ctx.step

		if (stepIndex > 0) inputComponent = GroupBlendInputs
		else inputComponent = undefined
	})

	const STEPS = {
		0: GroupBlendTutorial,
		1: GroupBlendCanvas,
	}

	onMount(() => {
		GroupEditorController.Grid.activate("rainbow", true)
	})

	onDestroy(() => {
		GroupOverlapController.setStep(0)
	})
</script>

<svelte:component this={STEPS[stepIndex]} />

<style>
</style>
