import { EditorClass } from ".."
import { AudioAsset, CanvasAsset } from "../asset"
import { TimelineEvent } from "../events"
import { EventedInstance } from "../events/decorators"
import { Track, TrackLike } from "../tracks"

export interface TimelineController extends EventedInstance<TimelineEvent> {}

/**
 * @file controllers/Scene.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles the scene and its timeline
 */
@EventedInstance(TimelineEvent)
export class TimelineController {
	//#region    ===========================		  		Construction				==============================
	public framerate: number = 40
	declare editor: EditorClass

	constructor(editor: EditorClass, options: TimelineOptions) {
		this.editor = editor
		this._duration = options.duration
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Properties 				==============================

	/**
	 * Change the duration - content aware
	 * @param val
	 * @param contentMode
	 * 	"scale" = proportional scaling across the timeline,
	 * 	"trim" = cutting things down to the minimum duration and squishing them all at the end
	 */
	setDuration(val: number, contentMode: "scale" | "trim" | "init") {
		let durationUpdater: (track: TrackLike<CanvasAsset | AudioAsset>) => void

		if (contentMode == "init") return (this._duration = val)
		else if (contentMode === "trim") {
			/**
			 * Checks if provided track has a start or end time after the newly updated duration.
			 * If so, change the end time to the new duration and set the start time to 2 seconds before the end.
			 */
			durationUpdater = (track: TrackLike<CanvasAsset | AudioAsset>) => {
				if (track instanceof Track) {
					let start = track.start
					let end = track.end

					if (track.start > val) {
						start = val - 2
					}

					if (track.end > val) {
						end = val
					}

					track.setTimespan({
						start,
						end,
					})
				} else console.warn(`Updating duration, no start/end time associated with track named [${track.name}].`)
			}
		} else if (contentMode === "scale") {
			/**
			 * Multiplies each track duration by the multiplier found by dividing the old duration from the new duration
			 */

			const multiplier = val / this.editor.timeline.duration
			durationUpdater = (track: TrackLike<CanvasAsset | AudioAsset>) => {
				if (track instanceof Track) {
					const newTrackStart = track.start * multiplier
					const newTrackDuration = track.duration * multiplier
					track.setTimespan({
						start: newTrackStart,
						end: newTrackStart + newTrackDuration,
					})
				} else console.warn(`Updating duration, no start/end time associated with track named [${track.name}].`)
			}
		} else throw new Error(`Setting duration with an unknown content mode [${contentMode}].`)

		this._duration = val

		if (this.editor.timeline.currentTime > val) {
			this.editor.timeline.seek(val)
		}

		this.editor.tracks.forEach(durationUpdater)

		this.emitEvent("edit:duration", { startTime: 0, endTime: this.duration, timestamp: this.currentTime })
	}

	protected _duration: number = 10
	/** The duration of the scene in seconds */
	get duration(): number {
		return this._duration
	}

	//#endregion =====================================================================================================

	//#region    ===========================				Preview API 				==============================

	_currentTime: number = 0
	get currentTime() {
		return this._currentTime
	}
	set currentTime(v) {
		if (v > this._duration) v = this._duration
		this.exactTime = v
		this._currentTime = this.roundTimestamp(v)
	}

	/** Convert a time to a time that is constrained to the valid intervals for the current framerate */
	roundTimestamp(time: number) {
		return Math.floor(time * this.framerate) / this.framerate
	}

	get minimumInterval() {
		return 1 / this.framerate
	}

	public get playing(): boolean {
		return !!this.renderLoop
	}

	/**
	 * Set the current playback time and fully renders the canvas
	 **/
	seek(time_s: number): void {
		this._seek(time_s)
		this.emitEvent("preview:seek", {
			timestamp: time_s,
		})
	}

	/* Begin preview playback, starting at the current time */
	play() {
		if (this.playing) return
		this._play()
		this.emitEvent("preview:play", {
			timestamp: this.currentTime,
			startTime: this.currentTime,
		})
	}

	/** Pause the video without returning to the starting timestamp */
	pause() {
		if (!this.playing) return
		this._pause()
		this.nextFrame() // Fixes some visual bugs with videos

		this.emitEvent("preview:pause", {
			timestamp: this.currentTime,
			startTime: this.currentTime,
		})
	}

	/** Pause the video and return to your start timestamp */
	stop() {
		if (!this.playing) return
		let endTime = this.currentTime
		this._stop()
		this.emitEvent("preview:stop", {
			timestamp: this.currentTime,
			startTime: this.currentTime,
			endTime: endTime,
		})
	}

	/** Logic for seek - but unevented */
	protected _seek(time_s: number): void {
		this.currentTime = time_s
		this.updateCanvas()
	}

	nextFrame(): void {
		this._seek(this.currentTime + this.minimumInterval + 0.001)
	}

	prevFrame(): void {
		this._seek(this.currentTime - this.minimumInterval + 0.001)
	}

	protected playbackStartTime: number
	protected playbackLastTS: number
	protected renderLoop: number = null

	/** Logic for .play(), but does not fire an event. */
	protected _play() {
		this.editor.selection.clear()

		this.playbackStartTime = this.currentTime

		/* Begin the rendering loop */
		this.renderLoop = window.requestAnimationFrame(this.renderAnimFrame.bind(this))
	}

	/** Pause (unevented) */
	protected _pause() {
		window.cancelAnimationFrame(this.renderLoop)
		this.playbackLastTS = undefined
		this.exactTime = undefined
		this.renderLoop = null
	}

	/** Stop (unevented) */
	protected _stop() {
		this._pause()
		this._seek(this.playbackStartTime)
	}

	//#endregion =====================================================================================================

	//#region    ===========================		 Preview - Underlying Logic			==============================

	protected exactTime: number
	renderAnimFrame(timems: number): FrameRequestCallback {
		// Start the animation by establishing a basis for finding how far we have advanced in an animation
		if (this.playbackLastTS === undefined) this.playbackLastTS = timems
		if (this.exactTime === undefined) this.exactTime = this.currentTime

		const elapsed = (timems - this.playbackLastTS) / 1000
		this.playbackLastTS = timems

		// Update the current time
		this.exactTime = this.exactTime + elapsed
		this.currentTime = this.exactTime

		// If we reach the end, stop
		if (this.currentTime >= this.duration) {
			this.stop()
			return
		}

		// Update the canvas' data
		this.updateCanvas()

		this.renderLoop = window.requestAnimationFrame(this.renderAnimFrame.bind(this))
	}

	/**
	 * Sets the data of all the canvas assets to the specified time and request (without forcing) a canvas rerender
	 * @param time_s
	 */
	protected updateCanvas() {
		if (!this.editor.canvas) return
		this.editor.canvas.requestRenderAll()

		this.emitEvent("tick", {
			timestamp: this.currentTime,
			startTime: this.playbackStartTime,
		})
	}

	/** Returns true if the scrubber is within this track's timespan */
	isTrackActive(track: Track) {
		return this.currentTime > track.start && this.currentTime < track.end
	}

	//#endregion =====================================================================================================
}

export interface TimelineOptions {
	/** The duration of the scene in seconds */
	duration: number
}
