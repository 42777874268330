import { fabric } from "fabric"
import { v4 as uuid } from "uuid"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../../../modules/serialize"
import { PathSubscriber, Path, PathMessageType } from "../../path"
import { EditorClass, getEditor } from "../../../.."
import { fixPointSet } from "../../../../fabric-plugins"
import { Polygon } from "../../Polygon"
import { ClippingMask } from "../ClippingMask"
import { MaskOptions } from "../Mask"

export class MaskOperationError extends Error {}

export interface MaskBaseOptions {
	id?: string
	overflow?: "hidden" | "faded"
	strokeWidth?: number
	stroke?: string
	fill?: string | fabric.Pattern | fabric.Gradient | undefined
	entryId?: number
	data?: { [index: string]: any }
	name?: string
}

export interface MaskBase extends MaskOptions {}
export class MaskBase extends Polygon implements PathSubscriber, Serializable {
	id: string
	path?: Path
	data: { [index: string]: any }
	entryId?: number // References CalibratedMask entry
	_name: string

	constructor(path: Path, options?: MaskBaseOptions) {
		super(
			getEditor(),
			{ points: path.points },
			{
				...MaskBase.OPTIONS_DEFAULT,
				...(options ?? {}),
				...MaskBase.OPTIONS_FORCED,
			}
		)

		this.path = path
		this.path.stroke = "#ffffff66"
		this.path.strokeWidth = 2
		this.path.visible = true
		this.path.selectable = false
		this.path.register()
		this.path.like(this)
		this.path.subscribe(this)

		this.id = options.id ?? uuid()
		this.entryId = options.entryId

		this.data = options.data ?? {}
		this.name = options.name

		this.selectable = false
		this.hoverCursor = "pointer"
		this.perPixelTargetFind = true
		this.pathOffset = new fabric.Point(0, 0)

		this.initializePathUpdaters()
	}

	// #region ======== Interface ===============

	lock() {
		this.lockMovementX = true
		this.lockMovementY = true
		this.lockRotation = true
		this.lockScalingFlip = true
		this.lockScalingX = true
		this.lockScalingY = true
		this.lockSkewingX = true
		this.lockSkewingY = true
		this.lockUniScaling = true

		this.path.lockMovementX = true
		this.path.lockMovementY = true
		this.path.lockRotation = true
		this.path.lockScalingFlip = true
		this.path.lockScalingX = true
		this.path.lockScalingY = true
		this.path.lockSkewingX = true
		this.path.lockSkewingY = true
		this.path.lockUniScaling = true
	}

	unlock() {
		this.lockMovementX = false
		this.lockMovementY = false
		this.lockRotation = false
		this.lockScalingFlip = false
		this.lockScalingX = false
		this.lockScalingY = false
		this.lockSkewingX = false
		this.lockSkewingY = false
		this.lockUniScaling = false

		this.path.lockMovementX = false
		this.path.lockMovementY = false
		this.path.lockRotation = false
		this.path.lockScalingFlip = false
		this.path.lockScalingX = false
		this.path.lockScalingY = false
		this.path.lockSkewingX = false
		this.path.lockSkewingY = false
		this.path.lockUniScaling = false
	}

	register() {
		getEditor().masks.register(this)
	}

	deregister() {
		getEditor().masks.deregister(this)
	}

	select() {
		getEditor().selection.set(this)
	}

	delete() {
		// remove the mask from each track mask
		getEditor()
			.masks.getInstances(this.id)
			.forEach((track) => track.removeMask(this))

		// remove from mask controller
		this.deregister()
		getEditor().masks.emitEvent("removed", { maskBase: this })

		// rerender canvas and clear selection
		setTimeout(() => {
			getEditor().selection.remove(this)
			getEditor().canvas.requestRenderAll()
		})
	}

	scaleTo(width: number, height: number) {
		const wScale = width / this.width
		const hScale = height / this.height

		this.scaleX = wScale
		this.scaleY = hScale

		this.path.like(this)
	}

	like(shape: fabric.Polyline) {
		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left
		this.top = shape.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)
	}

	setTop(top: number): this {
		this.top = top
		this.path.top = top

		this.updateChildren()

		return this
	}

	setLeft(left: number): this {
		this.left = left
		this.path.left = left

		this.updateChildren()

		return this
	}

	setScaleX(scaleX: number): this {
		this.scaleX = scaleX
		this.path.scaleX = scaleX

		this.updateChildren()

		return this
	}

	setScaleY(scaleY: number): this {
		this.scaleY = scaleY
		this.path.scaleY = scaleY

		this.updateChildren()

		return this
	}

	setAngle(angle: number): this {
		this.angle = angle
		this.path.angle = angle

		this.updateChildren()

		return this
	}

	set name(newName: string) {
		this._name = newName
		getEditor().masks.emitEvent("renamed", { maskBase: this })
	}

	get name() {
		return this._name
	}

	updateChildren() {
		this.children.forEach((child) => {
			child.like(this)
		})
	}

	get children() {
		return getEditor()
			.masks.getInstances(this.id)
			.map((track) => track.trackMask.masks.find((shape) => shape.baseID === this.id))
	}

	/**
	 * Returns a new point as the center of
	 */
	getCenter() {
		let xTotal = 0
		let yTotal = 0

		for (const { x, y } of this.points) {
			xTotal += (x - this.pathOffset.x) * this.scaleX + this.left
			yTotal += (y - this.pathOffset.y) * this.scaleY + this.top
		}

		const averageX = xTotal / this.points.length
		const averageY = yTotal / this.points.length

		return new fabric.Point(averageX, averageY)
	}

	// #endregion ===== Interface ===============
	// #region ======== Listeners ===============

	initializePathUpdaters() {
		const updatePath = () => this.path.like(this)
		this.on("moving", updatePath)
		this.on("scaling", updatePath)
		this.on("rotating", updatePath)
		this.on("mousedown", () => {
			this.select()
		})
	}

	onSelect(options: { e?: Event }): boolean {
		this.fill = "#41414110"
		return false
	}

	onDeselect(options: { e?: Event; object?: fabric.Object }): boolean
	onDeselect(options: { e?: Event; object?: fabric.Object }): boolean
	onDeselect(options: unknown): boolean {
		this.fill = "transparent"
		return false
	}

	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	) {
		switch (messageType) {
			case "unsubscribed":
			case "deleted":
				this.deregister()
				break
			case "point:add":
			case "point:delete":
			case "point:edit":
				this.recalculateBounds()
			default:
				return
		}
	}

	// #endregion ===== Listeners ===============
	// #region ======== Serialization ===========

	/**
	 * Gets the exported properties for this object type
	 */
	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude: string[]
	} {
		let include = ["entryId", "data", "name"]
		let exclude = ["path"]

		if (forExport) {
			include.push("id")
		}

		return {
			include,
			deepCopy: [],
			exclude,
		}
	}

	/**
	 * Converts this object into a JSON object
	 */
	serialize(forExport?: boolean) {
		const json = convertInstanceToObject(this, { forExport })
		if (forExport) json["id"] = this.id
		json["pathId"] = this.path.id // add path id, otherwise cannot create mask as path is not known

		return json
	}

	/**
	 * Loads JSON data to recreate a mask base instance
	 */
	static async loadJSON(editor: EditorClass, data: Partial<MaskBase>) {
		let path: Path
		data.points = fixPointSet(data.points)
		if ("pathData" in data && data["pathData"]) {
			// Saved in the new editor
			const pathData: Partial<Path> = data["pathData"]
			path = await Path.loadJSON(editor, pathData, pathData.id)
		} else {
			// Saved in the old editor
			path = new Path(editor, {
				points: data.points,
			})
		}

		const instance = new MaskBase(path, data)
		instance.entryId = data.entryId
		instance.data = data.data // currently only contains section
		instance.like(instance.path)

		return instance
	}

	/**
	 * Returns a new mask base instance from clipping mask data
	 */
	static async fromClippingMask(mask: Partial<ClippingMask>) {
		if (!mask.path) {
			const pathData = mask["pathData"]
			const path = await Path.loadJSON(getEditor(), pathData, pathData.id)
			mask.path = path
		}

		// @ts-ignore
		if (mask.__CONSTRUCTOR__) delete mask.__CONSTRUCTOR__
		if (mask.track) delete mask.track

		const instance = new MaskBase(mask.path, {
			...mask,
		})

		instance.data = mask.data ?? {}

		return instance
	}

	// #endregion ===== Serialization ===========
	// #region ======== Mask Options ============

	static OPTIONS_DEFAULT: fabric.IPolylineOptions = {
		angle: 0,
		width: 100,
		height: 100,
		scaleX: 1,
		scaleY: 1,
	}

	static OPTIONS_FORCED: fabric.IPolylineOptions = {
		absolutePositioned: true,
		originX: "center",
		originY: "center",
		objectCaching: false,
		noScaleCache: true,
		centeredRotation: true,
		strokeUniform: true,
		stroke: "#41414110",
		strokeWidth: 10,
		fill: "transparent",
	}

	// #endregion ===== Mask Options ============
}

registerSerializableConstructor(MaskBase)
