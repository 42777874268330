const HouseSections = [
	"Facade",
	"Trim",
	"Garage Door",
	"Door",
	"Window",
	"Shutters",
	"Stairs",
	"Porch",
	"Chimney",
	"Gutter",
]

export { HouseSections }
