import { fabric } from "fabric"
import { EditorClass, getEditor } from "../../.."
import { TrackGroup, TrackLike } from "../../../tracks"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../../modules/serialize"
import { CanvasAsset } from "../"
import { MaskOptions, Mask, ClippingMask } from "."
import { MaskPolygon } from "./LinkedMaskPolygon"

export interface MultiMaskOptions extends MaskOptions {
	masks: ClippingMask[]
	boundTrack?: TrackGroup<CanvasAsset>
}

export interface MultiMask extends Mask {
	masks: ClippingMask[]
	clipPath: Mask
}

@Mask.Mixin
export class MultiMask extends fabric.Group implements Serializable {
	constructor(editor: EditorClass, options: MultiMaskOptions) {
		const masks = options.masks
		const shapes = masks.map((mask) => MultiMask.createShape(mask))

		super(shapes, {
			absolutePositioned: true,
			inverted: options.inverted ?? masks[0].inverted,
			lockScalingX: true,
			lockScalingY: true,
		})

		this.editor = editor
		this.masks = masks
		this.masks.forEach((mask) => (mask.parent = this))
		this.inverted = options.inverted ?? masks[0].inverted
		this.boundTrack = options.boundTrack ?? masks[0].boundTrack
	}

	addMask(mask: Mask): void
	addMask(mask: ClippingMask | MultiMask): void {
		// Merge with another combinedmask
		if (mask instanceof MultiMask) {
			for (const m of mask.masks) {
				this.addMask(m)
			}
			return
		}

		// Add a regular clipping mask to the combined mask
		this.masks.push(mask)
		mask.parent = this

		const shape = MultiMask.createShape(mask)
		this.addWithUpdate(shape)
	}

	select() {
		this.editor.selection.set([
			this,
			...this.masks,
			...this.masks.map((m) => m.path),
			...this.masks.map((m) => m.selectionPath),
		])
	}

	bindTrack(track: TrackLike) {
		this.boundTrack = track
		for (const mask of this.masks) {
			mask.boundTrack = track
		}
	}

	get name() {
		return `Masks (${this.masks.length})`
	}

	static mergeMaskUp(mask: Mask) {
		getEditor().selection.clear()
		const group = mask.boundTrack as TrackGroup
		const parent = group.parent
		const parentMask = parent.clippingMask

		let maskGroup: MultiMask
		if (parentMask instanceof MultiMask) {
			maskGroup = parentMask
		} else if (parentMask instanceof ClippingMask) {
			maskGroup = new MultiMask(getEditor(), {
				masks: [parentMask],
				inverted: mask.inverted,
				boundTrack: parent,
			})
		}

		maskGroup.addMask(mask)

		// Now move the children up one level
		const order = group.positionIndex
		for (const child of group.children.toReversed()) {
			parent.moveChild(child, order)
		}

		group.clippingMask = null
		maskGroup.inverted = mask.inverted
		parent.setMask(maskGroup)
		parent.delete(group)
	}

	serialize(forExport?: boolean) {
		const json = convertInstanceToObject(this, {
			forExport,
		})

		json.masks = this.masks.map((mask) => mask.serialize(forExport)) as ClippingMask[]
		console.log(json)

		return json
	}

	getDefaultPropertyExports(forExport?: boolean): { include: string[]; deepCopy: string[]; exclude?: string[] } {
		return {
			include: ["id", "inverted"],
			deepCopy: [],
			exclude: ["shapes", "objects"],
		}
	}

	static createShape(mask: ClippingMask) {
		// const polygon: Polygon & {
		// 	maskID: string
		// 	onPathUpdate: (
		// 		path: Path,
		// 		messageType: PathMessageType,
		// 		points: fabric.Point[],
		// 		modifiedIndex?: number,
		// 		modifiedValue?: fabric.Point
		// 	) => void
		// } = new Polygon(
		// 	getEditor(),
		// 	{
		// 		points: mask.points,
		// 	},
		// 	{
		// 		angle: mask.angle,
		// 		left: mask.left,
		// 		top: mask.top,
		// 		scaleX: mask.scaleX,
		// 		scaleY: mask.scaleY,
		// 		originX: mask.originX,
		// 		originY: mask.originY,
		// 		absolutePositioned: true,
		// 	}
		// ) as Polygon & {
		// 	maskID: string
		// 	onPathUpdate: (
		// 		path: Path,
		// 		messageType: PathMessageType,
		// 		points: fabric.Point[],
		// 		modifiedIndex?: number,
		// 		modifiedValue?: fabric.Point
		// 	) => void
		// }
		// polygon.maskID = mask.id

		// polygon.onPathUpdate = (
		// 	path: Path,
		// 	messageType: PathMessageType,
		// 	points: fabric.Point[],
		// 	modifiedIndex?: number,
		// 	modifiedValue?: fabric.Point
		// ) => {
		// 	console.log(path, messageType, points, modifiedIndex, modifiedValue)
		// }

		// mask.path.subscribe(polygon)

		// return polygon

		return new MaskPolygon(mask)
	}

	static async loadJSON(editor: EditorClass, data: Partial<MultiMask>) {
		console.log("loading json for combined mask", { editor, data })
		return new MultiMask(editor, {
			masks: data.masks,
			inverted: data.inverted,
		})
	}
}

registerSerializableConstructor(MultiMask)
