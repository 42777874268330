<script lang="ts">
	import type { Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import {
		AdvancedSettingsController,
		type AdvancedSettingProperty_Name,
	} from "../ProjectorAdvancedController"

	let device: Device
	let availableProperties: Array<AdvancedSettingProperty_Name>
	let audioOutput: "HDMI" | "HEADPHONES"

	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		availableProperties = AdvancedSettingsController.getAvailableProperties(device)
	})

	AdvancedSettingsController.subscribe((ctx) => {
		if (!ctx.isOnline) return
		audioOutput = ctx.audioOutput
	})

	function onAudioOutputChange(output: string) {
		AdvancedSettingsController.modifyProperty("audioOutput", output as "HDMI" | "HEADPHONES")
		audioOutput = output as "HDMI" | "HEADPHONES"
	}
</script>

<div class="projector-heading flex-row">
	<h2 class="setting-heading camera">Audio Settings</h2>
</div>
{#if availableProperties.includes("audioOutput")}
	<div class="select-container">
		<label for="audio-config-input">Audio Output: </label>
		<select
			name="audio-config"
			id="audio-config-input"
			bind:value={audioOutput}
			on:change={(e) => onAudioOutputChange(e.currentTarget.value)}
		>
			<option value="HDMI">Projector</option>
			<option value="HEADPHONES">3.5mm Jack</option>
		</select>
	</div>
{/if}

<style>
</style>
