import type { SvelteComponent } from "svelte"
import { get, writable } from "svelte/store"
import { v4 as uuid } from "uuid"

export type OverlayOptions = {
	heading?: string
	classOverlay?: string
	classHeading?: string
	sideMenu?: SideMenuOptions
	zIndex?: number
	props?: { [index: string]: any }
	beforeClose?: () => Promise<void>
}

export type SideMenuOptions = {
	[index: string]: {
		title: string
		isActive: boolean
		onClick: () => void
	}
}

export namespace OverlayController {
	let currentIndex = 0

	type ActiveOverlay = {
		component: typeof SvelteComponent
		options?: OverlayOptions
	}

	export type ContextType = {
		[index: string]: ActiveOverlay
	}

	const store = writable<ContextType>({})

	/** Subscribe to the overlay store */
	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	/**
	 * Mounts a new overlay
	 * @param component the component to mount as an overlay
	 * @param options the component options (props, ect)
	 */
	export function openOverlay(
		component: new (...args: any[]) => SvelteComponent,
		options?: OverlayOptions
	) {
		const opts: OverlayOptions = { ...options }
		if (!opts.zIndex) opts.zIndex = ++currentIndex

		const id = uuid()

		store.update((ctx) => ({
			...ctx,
			[id]: {
				component,
				options: opts,
			},
		}))

		return id
	}

	/**
	 * Closes the provided overlay (or all overlays if uuid is not provided)
	 */
	export async function closeOverlay(uuid?: string, force?: boolean) {
		if (uuid) {
			const overlayContext = get(store)[uuid]
			if (overlayContext?.options?.beforeClose && !force)
				await overlayContext?.options?.beforeClose()

			store.update((ctx) => {
				const currentState = ctx
				delete currentState[uuid]
				return currentState
			})
		} else {
			const activeOverlays = Object.values(get(store))
			for (const overlay of activeOverlays) {
				if (overlay.options?.beforeClose && !force) await overlay.options.beforeClose()
			}

			store.set({})
		}

		updateZIndex()
	}

	function updateZIndex() {
		const active = get(store)
		let topZIndex = -9999
		for (const [id, overlay] of Object.entries(active)) {
			if (overlay.options.zIndex > topZIndex) topZIndex = overlay.options.zIndex
		}

		currentIndex = topZIndex !== -9999 ? topZIndex : 0
	}
}
