import { Track } from "../../../tracks"
import { CanvasAsset, Mask, TrackMask } from "../"

/**
 * @file asset/canvas/mixins/properties.ts
 * @author Austin Day 2023
 * @description WARNING: This file commits some *deep dark black magic fuckery* which is designed to be IGNORED AND TAKEN FOR GRANTED
 * 	If you really gotta know, just ask me in person.
 *
 **/

/* Add all the base Asset functionality to fabric.Object */

interface CanvasAssetRender extends CanvasAsset {
	track: Track<CanvasAsset>
	hidden: boolean
}

class CanvasAssetRender {
	//#region    ===========================				Rendering Logic				==============================

	get visible(): boolean {
		if (!this.track) return true
		if (this.hidden) return false
		if (!this.track.isVisible()) return false
		return true
	}

	set visible(v) {}

	get clipPath(): TrackMask {
		if (!this.track) {
			return undefined
		}

		return this.track.getMask()
	}

	private _hasBorders: boolean
	get hasBorders(): boolean {
		if (!Object.hasOwn(this, "_hasBorders")) this._hasBorders = true
		if (!this._hasBorders) return false
		if (!this.track) return true
		if (!this.track.isVisible()) return false

		return this._hasBorders
	}

	set hasBorders(v) {
		this._hasBorders = v
	}

	private _hasControls: boolean
	get hasControls(): boolean {
		if (!this.track) return true
		if (!this.track.isVisible()) return false

		if (!Object.hasOwn(this, "_hasControls")) this._hasControls = true
		if (this.track.isVisible()) return this._hasControls
	}

	set hasControls(v) {
		this._hasControls = v
	}

	private _selectable: boolean
	get selectable(): boolean {
		if (this.track && !this.track.isVisible()) return false
		if (!Object.hasOwn(this, "_selectable")) this._selectable = true
		return this._selectable
	}

	set selectable(v) {
		this._selectable = v
	}
}

export function bindCanvasAssetProperties() {
	const propertyDescriptors = Object.getOwnPropertyDescriptors(CanvasAssetRender.prototype)
	delete propertyDescriptors["constructor"]
	Object.defineProperties(CanvasAsset.prototype, propertyDescriptors)
}
