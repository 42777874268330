/**
 * Use as base for any media source which has a time and volume element (e.g., audio asset, video asset)
 */

export abstract class MediaSourceBase<const SourceEvent extends Array<string>> {
	protected eventListeners: Partial<{
		[key in SourceEvent[number]]: Array<Function>
	}>
	constructor() {
		this.eventListeners = {}
	}

	public addEventListener(event: SourceEvent[number], fn: Function) {
		if (!this.eventListeners[event]) this.eventListeners[event] = []
		this.eventListeners[event].push(fn)
	}

	public removeEventListener(event: SourceEvent[number], fn: Function) {
		const listenerIndex = this.eventListeners[event].findIndex((func) => func === fn)
		this.eventListeners[event].splice(listenerIndex, 1)
	}

	public abstract load(): Promise<void>
	public abstract play(): void
	public abstract pause(): void
	public abstract mute(doMute: boolean): void

	abstract get paused(): boolean

	abstract get volume(): number
	abstract set volume(vol: number)

	abstract get duration(): number
	abstract set currentTime(ts: number)
	abstract get currentTime()
}
