<script lang="ts">
	import AnimatedBackground from "../../reusable/AnimatedBackground.svelte"
	import { LoginControler } from "./LoginController"
	import LoginPage from "./LoginPage.svelte"
	import RequestPasswordReset from "./RequestPasswordReset.svelte"

	import "../../../assets/css/login.css"
	import ResetPassword from "./ResetPassword.svelte"
	import { onMount } from "svelte"
	import { Tooltip } from "svelte-comps/tooltip"
	import { Toaster } from "svelte-comps/toaster"
	import { getCurrentUser } from "../../../stores/UserStore"
	import { navigate } from "svelte-routing"

	let state: "login" | "reset_request" | "reset_password" = "login"
	LoginControler.subscribe((s) => (state = s))

	const componentMap = {
		login: LoginPage,
		reset_request: RequestPasswordReset,
		reset_password: ResetPassword,
	}

	onMount(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const token = urlParams.get("_reset_token")
		const email = urlParams.get("_reset_email")

		if (token && email) {
			LoginControler.initResetPassword(token, email)
		}

		if (getCurrentUser()) navigate("projector")
	})
</script>

<div id="login-page">
	<AnimatedBackground />
	<svelte:component this={componentMap[state]} />
</div>

<Tooltip />
<Toaster topMargin="1rem" />

<style>
	#login-page {
		width: 100dvw;
		height: 100dvh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>
