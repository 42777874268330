<script lang="ts">
	import { AccountNavigator } from "../../AccountStore"
	import StorageController from "./Storage_Controller.svelte"
	import StorageGeneral from "./Storage_General.svelte"

	export let isSubscriptionOpen: boolean = false
	AccountNavigator.subscribe((ctx) => (isSubscriptionOpen = ctx.props.openSubscription))
</script>

{#if isSubscriptionOpen}
	<StorageController />
{:else}
	<StorageGeneral />
{/if}

<style>
</style>
