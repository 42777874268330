<script lang="ts">
	import { DataHandlerDevice, type Device, DeviceRPi, DeviceGroup } from "luxedo-data"
	import DeviceSelector from "../../../input/DeviceSelector.svelte"
	import { GroupEditorController } from "../GroupEditorController"
	import { asyncConfirmOverlay } from "svelte-comps/overlay";
	import { Toast } from "svelte-comps/toaster";

	let group: DeviceGroup

	let availableDevices: Array<DeviceRPi> = []
	let selectedDevices: Array<DeviceRPi> = []
	let devicesInOtherGroups: Array<DeviceRPi> = []

	function updateAvailableDevices() {
		availableDevices = DataHandlerDevice.getMany().filter(
			(dev) => dev instanceof DeviceRPi
		) as Array<DeviceRPi>
		if (group)
			devicesInOtherGroups = availableDevices.filter((dev) => dev.getParent() && dev.getParent() !== group)
			availableDevices = availableDevices.filter(
				(dev) => !(dev.getParent() && dev.getParent() !== group)
			)
	}

	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
		selectedDevices = ctx.devices
		updateAvailableDevices()
	})

	DataHandlerDevice.addListener(updateAvailableDevices)

	function onDeviceSelect(device: DeviceRPi) {
		if (selectedDevices.includes(device)) {
			// if removing
			selectedDevices.splice(selectedDevices.indexOf(device), 1)
		} else {
			// if adding
			selectedDevices.push(device)
		}
		selectedDevices = [...selectedDevices]
	}

	async function onSelectDeviceFromOtherGroup(device: DeviceRPi) {
		const parent = device.getParent()!
		try {
			await asyncConfirmOverlay({
				title: "Confirm",
				prompt: [
					`Adding this device will deactivate the following group:`,
					`<b>${parent.name}</b>`,
					`Continue?`,
				],
				confirmText: "Yes",
				denyText: "No",
			})
		} catch (e) {
			selectedDevices = [...selectedDevices]
			return
		}

		await parent.deactivate()
		Toast.success(`Group ${parent.name} deactivated successfully!`)
		updateAvailableDevices()
		onDeviceSelect(device)
	}

	function getDeviceSelected(devices: Array<Device>, device: Device) {
		return devices.includes(device)
	}

	function next() {
		GroupEditorController.setSelectedDevices(selectedDevices)
		GroupEditorController.next()
	}
</script>

<h3>Select which devices will be included in this group</h3>
<div class="scrollable">
	{#each availableDevices as device}
		<DeviceSelector
			type="checkbox"
			{device}
			isSelected={getDeviceSelected(selectedDevices, device)}
			onSelect={onDeviceSelect}
		/>
	{/each}
	{#if devicesInOtherGroups.length > 0}
		<h5 class="divider">Devices in other groups</h5>
		{#each devicesInOtherGroups as device}
			<DeviceSelector
				type="checkbox"
				{device}
				isSelected={getDeviceSelected(selectedDevices, device)}
				onSelect={onSelectDeviceFromOtherGroup}
			/>
		{/each}
	{/if}
</div>

<div id="step-buttons">
	<button
		id="next-step-button"
		class="outline-button"
		on:click={next}
		disabled={selectedDevices.length === 0}
	>
		Next
	</button>
</div>

<style>
	.scrollable {
		max-height: 70vh;
		overflow-y: scroll;
	}

	h5.divider {
		margin: 1.5rem 0 0;
		font-size: var(--font-size);
	}
</style>
