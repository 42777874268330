<script lang="ts">
</script>

<div id="canvas-wrapper">
	<div id="canvas-container" />
</div>

<style>
	#canvas-wrapper {
		position: relative;
		background-color: #f0f8ff00;
		height: 100%;
		flex-grow: 1;
	}

	#canvas-container {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: var(--z-index-back);
	}
</style>
