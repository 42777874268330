import { Entry, type EntryRawData } from "data-handler"
import { DateTime } from "luxon"
import type { Snapshot } from "./Snapshot"
import { DataHandlerSnapshot } from "../datahandlers/DataHandlerSnapshot"
import type { Device } from "./Device"
import { DataHandlerDevice } from "../datahandlers/DataHandlerDevice"

export interface CalibrationRawData extends EntryRawData {
	id: number
	dev_id: number
	run_time: string
	failed: number
	failure_code: number
	n_images: number
	cal_vsn: string
	pnc_run_id: number
	active_snapshot_id: number
}

export interface Calibration {
	dev_id: number
	runTime: DateTime
	didFail: boolean
	active_snapshot: number
}

export class Calibration extends Entry<CalibrationRawData> {
	protected async importData(data: CalibrationRawData): Promise<void> {
		this.dev_id = data.dev_id
		this.runTime = DateTime.fromISO(data.run_time.replace(" ", "T"), {
			zone: "utc",
		}).toLocal()
		this.active_snapshot = data.active_snapshot_id
		this.didFail = data.failed > 0
	}

	protected exportData(): Partial<CalibrationRawData> {
		return {}
	}

	get snapshots(): Array<Snapshot> {
		return DataHandlerSnapshot.filterByCalibration(this.id!)
	}

	get device(): Device {
		return DataHandlerDevice.get(this.dev_id)
	}
}
