import { Entry, type EntryRawData } from "data-handler"

export class Snapshot extends Entry<SnapshotRawData> {
	declare src: string
	declare dev_id: number
	declare cal_id: number
	declare datesaved: Date
	declare is_default: boolean
	declare resolution: {
		w: number
		h: number
	}

	constructor(data: SnapshotRawData) {
		super(data)
	}

	protected importData(data: SnapshotRawData): void {
		this.datesaved = new Date(data.datesaved)
		this.src = data.src
		this.dev_id = data.dev_id
		this.cal_id = data.cal_id
		this.is_default = data.is_default
		this.resolution = data.resolution
	}

	protected exportData(): Partial<SnapshotRawData> {
		return {
			src: this.src,
			dev_id: this.dev_id,
		}
	}

	public triggerDownload = async () => {
		console.warn(
			"Cannot change name of download, may want to look into altering headers of s3 bucket (https://stackoverflow.com/questions/23872902/chrome-download-attribute-not-working-to-replace-the-original-name), or create a proxy using fgn"
		)

		const link = document.createElement("a")
		link.href = this.src
		link.target = "_blank"
		link.style.display = "none"
		link.download = `Snapshot ${this.datesaved}`

		document.body.appendChild(link)
		link.click()

		setTimeout(() => {
			link.parentNode?.removeChild(link)
		})
	}

	public async asyncImageLoad(): Promise<CanvasImageSource> {
		return new Promise((res, rej) => {
			const img = new Image()

			img.addEventListener("load", () => res(img))
			img.addEventListener("error", (e) => {
				rej(e)
				console.error(`UNABLE TO LOAD SNAPSHOT id=${this.id}`, e)
			})
			img.crossOrigin = "anonymous"

			img.src = `${this.src}&_noCache=${new Date().getTime()}`
		})
	}
}

export interface SnapshotRawData extends EntryRawData {
	src: string
	dev_id: number
	cal_id: number
	datesaved: string
	is_default: boolean
	resolution: {
		w: number
		h: number
	}
}
