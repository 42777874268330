/**
 * Use to update components if they have a mobile layout
 */

import { writable } from "svelte/store"

export function updateIsMobile() {
	if (window.innerWidth <= 900) {
		ResponsiveStore.set(true)
	} else ResponsiveStore.set(false)
}

export const ResponsiveStore = writable(false)
