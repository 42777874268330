<script lang="ts">
	import { InfoDialogue } from "svelte-comps/info-dialogue"
	import Logo from "../../../assets/icons/LuxedoLogo.svg"
	import { LoginControler } from "./LoginController"
	import PasswordInput from "../../reusable/input/PasswordInput.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { LuxedoRPC } from "luxedo-rpc"
	import { pageInit } from "../../../modules/auth/onUserLogin"

	let isLoading = false

	let password: string
	let passwordConfirm: string

	function failed(text: string) {
		isLoading = false
		Toast.error(text)
	}

	async function setNewPassword() {
		isLoading = true

		await pageInit.initRPC()

		if (password !== passwordConfirm) return failed("Passwords must match.")

		const email = LoginControler.resetEmail
		const token = LoginControler.resetToken

		try {
			await LuxedoRPC.api.login.pw_set_new(email, password, token)
			Toast.success("Password successfully updated!")
			LoginControler.goToLogin()
		} catch (e) {
			console.error("Unable to update password", e)
			if (e.statusCode == 700) return failed("Password reset request has expired.")
			if (e.statusCode == 704)
				return failed(
					"Passwords must be at least 8 characters long with at least one uppercase and one lowercase letter."
				)
			return failed("Failed to update password, please refresh and try again.")
		}
	}
</script>

<form
	class="card-container"
	style="--card-width: 24rem; --card-height: 34rem"
	on:submit|preventDefault={setNewPassword}
>
	<img id="luxedo-logo" src={Logo} alt="Luxedo Logo" />
	<span class="info-text">Account Recovery</span>

	<div class="form-container">
		<div class="input-row">
			<div class="input-container">
				<label for="pass-input"
					>Password
					<InfoDialogue
						textContent={"Passwords should be at least 8 characters long with at least one uppercase and one lowercase letter."}
						inline
					/>
				</label>
				<PasswordInput id="pass-input" bind:value={password} />
			</div>
			<div class="input-container">
				<label for="pass-conf-input">Confirm Password</label>
				<PasswordInput id="pass-conf-input" bind:value={passwordConfirm} />
			</div>
		</div>
		<span class="process-description">
			Updating the password to your account. Enter your new password above.
		</span>
	</div>

	<button
		id="login-button"
		class="outline-button"
		type="submit"
		disabled={!(password && passwordConfirm) || isLoading}>Update Password</button
	>
	<div class="link-footer">
		<button class="go-back link-button" on:click={LoginControler.goToLogin}>← Go Back</button>
	</div>
</form>

<style>
	.info-text {
		font-size: var(--h1);
	}

	.process-description {
		color: var(--color-text);
		margin-top: 1rem;
		line-height: 1.5em;
		text-align: center;
	}

	.form-container {
		margin-top: 0.85rem;
	}
</style>
