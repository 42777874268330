<script lang="ts">
	import { LuxedoRPC } from "luxedo-rpc"
	import Logo from "../../../assets/icons/LuxedoLogo.svg"
	import { LoginControler } from "./LoginController"
	import { Toast } from "svelte-comps/toaster"
	import { pageInit } from "../../../modules/auth/onUserLogin"

	let isLoading = false
	let email: string

	function failed(text: string) {
		Toast.error(text)
		isLoading = false
	}

	async function sendPasswordResetRequest() {
		isLoading = true

		await pageInit.initRPC()

		try {
			await LuxedoRPC.api.login.forgot_forgot(email)
			Toast.success("Password reset link sent!")
		} catch (e) {
			if (e.statusCode == 404) return failed("Email not associated with any account.")
			return failed("Failed to request password reset link.")
		}
	}
</script>

<form
	class="card-container"
	style="--card-width: 24rem; --card-height: 34rem"
	on:submit|preventDefault={sendPasswordResetRequest}
>
	<img id="luxedo-logo" src={Logo} alt="Luxedo Logo" />
	<span class="info-text">Account Recovery</span>

	<div class="form-container">
		<div class="input-container">
			<label for="Input">Email </label>
			<input id="Input-input" type="email" bind:value={email} />
		</div>
		<span class="process-description">
			Lost your account password? No need to worry! Simply enter your email above and we will send
			you a link to reset your password.
		</span>
	</div>

	<button id="login-button" class="outline-button" type="submit" disabled={!email || isLoading}
		>Send Password Reset Link</button
	>
	<div class="link-footer">
		<button class="go-back link-button" on:click={LoginControler.goToLogin}>← Go Back</button>
	</div>
</form>

<style>
	.info-text {
		font-size: var(--h1);
	}

	.process-description {
		color: var(--color-text);
		margin-top: 1rem;
		line-height: 1.5em;
		text-align: center;
	}

	.form-container {
		margin-top: 0.85rem;
	}
</style>
