import type { NoWildcard } from "./types"

export interface EditorEvent<T extends string> {
	eventName: T
}

export abstract class EditorEvent<T extends string> {
	constructor(eventName: NoWildcard<T>) {
		this.eventName = eventName
	}

	getKey() {
		return this.constructor.name
	}

	abstract OptionsType
}
