import { fabric } from "fabric"
import { Selectable } from "../controllers"
import { EditorEvent } from "./Event"
import type { EventName, NoWildcard } from "./types"

export type SelectionEventName =
	| EventName<"selection", "created" | "cleared" | "changed">
	| EventName<"locked" | "unlocked">

export interface SelectionEventInit {
	selected: Selectable[]
	previousSelection: Selectable[]
	origin: "canvas" | "timeline" | "mode" | "automatic"
	canvasEvent?: Event
	mouseEvent?: Event
}

export interface SelectionEvent extends EditorEvent<SelectionEventName>, SelectionEventInit {}

export class SelectionEvent extends EditorEvent<SelectionEventName> {
	OptionsType: SelectionEventInit

	constructor(type: NoWildcard<SelectionEventName>, options: SelectionEventInit) {
		super(type)
		this.selected = options.selected
		this.previousSelection = options.previousSelection
		this.origin = options.origin
		this.canvasEvent = options.canvasEvent
	}
}
