import { ctxMenuStore } from "./ContextMenuStore"
import type { ContextMenuOptions } from "./ContextMenuTypes"

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never

/**
 * To be used when creating a dynamic context menu (the options can change depending on state).
 * This ensures the ctx menu options are generated every time the user triggers it.
 * @param optionGenerator A method which returns ctx menu options
 * @param args The needed arguments for the `optionGenerator` method
 * @returns A mouse and keyboard event handler - this will activate the context menu when the user clicks or presses enter
 */
export function triggerContextMenuWithGenerator(
	optionGenerator: (...args: any) => ContextMenuOptions,
	args?: ArgumentTypes<typeof optionGenerator>
) {
	return (
		e:
			| MouseEvent
			| (KeyboardEvent & {
					currentTarget: EventTarget & HTMLElement
			  })
	) => {
		e.preventDefault()
		e.stopPropagation()

		const options = args ? optionGenerator(...args) : optionGenerator()

		function openMenu() {
			ctxMenuStore.set({
				isOpen: true,
				position: {
					x,
					y,
				},
				options,
			})
		}
		let x, y

		if ("key" in e) {
			const { right, top } = (e.currentTarget as HTMLDivElement).getBoundingClientRect()
			x = right
			y = top
		} else {
			x = (e as MouseEvent).clientX
			y = (e as MouseEvent).clientY
		}
		if ("key" in e) {
			if (e.key === "Enter") openMenu()
		} else openMenu()
	}
}

/**
 * Returns event handler (for mouse AND keyboard events) which will render the context menu at the mouse's position with the specified options
 */
export function initContextMenu(options: ContextMenuOptions) {
	return (
		e:
			| MouseEvent
			| (KeyboardEvent & {
					currentTarget: EventTarget & HTMLElement
			  })
	) => {
		e.preventDefault()
		e.stopPropagation()

		if (!options || options.length < 1) return false

		function openMenu() {
			ctxMenuStore.set({
				isOpen: true,
				position: {
					x,
					y,
				},
				options,
			})
		}
		let x, y

		if ("key" in e) {
			const { right, top } = (e.currentTarget as HTMLDivElement).getBoundingClientRect()
			x = right
			y = top
		} else {
			x = (e as MouseEvent).clientX
			y = (e as MouseEvent).clientY
		}
		if ("key" in e) {
			if (e.key === "Enter") openMenu()
		} else openMenu()
	}
}

export function openCtxMenu(e: MouseEvent, options: ContextMenuOptions) {
	e.preventDefault()
	e.stopPropagation()

	const x = e.clientX
	const y = e.clientY

	ctxMenuStore.set({
		isOpen: true,
		position: {
			x,
			y,
		},
		options,
	})
}

/**
 * Closes the opened context menu
 */
export function closeCtxMenu() {
	ctxMenuStore.set({
		isOpen: false,
	})
}
