<script lang="ts">
	import { ProgressBar } from "../../../progress-bar"
	import ErrorReportIcon from "../../error-reporting/ErrorReportIcon.svelte"

	export let progress
	export let error
</script>

<div class="save-overlay">
	{#if error}
		<div class="save-error-internal">
			<ErrorReportIcon />
			<p class="error">
				{error}
			</p>
		</div>
	{:else}
		<p>
			Saving this file will replace your old version, including where it appears in any existing
			scenes when you next render them. The old version will no longer be accessible. Save anyways?
		</p>
	{/if}
	<ProgressBar percentage={progress} width={"100%"} />
</div>

<style>
	div.save-overlay {
		max-width: 40vw;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10% 20%;
	}

	.save-error-internal {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	p {
		color: var(--color-text);
	}

	p.error {
		margin-top: 1rem;
		color: var(--color-error);
	}
</style>
