<script lang="ts">
	import QuickSelectorRadio from "../../../reusable/input/quick-select/QuickSelectorRadio.svelte"
	import { ShowLibraryController } from "../ShowLibraryController"

	let isHidden = true

	let filterOptions = {
		filter: ["Date Created", "Last Modified", "Projector", "Name"],
		order: ["Ascending", "Descending"],
	}
	let filterSelection: { [key in keyof typeof filterOptions]: string } = {
		filter: "Last Modified",
		order: "Descending",
	}

	function toggleHidden() {
		isHidden = !isHidden
	}

	$: ShowLibraryController.updateFilterOptions(filterSelection)
</script>

<a href="#" on:click|preventDefault={toggleHidden}>
	Filter By
	<QuickSelectorRadio
		bind:selections={filterSelection}
		options={filterOptions}
		defaultSelection={{
			filter: "Last Modified",
			order: "Descending",
		}}
		isHidden={isHidden}
		allowClear
	/>
</a>

<style>
	a {
		margin-right: 1rem;
	}
</style>
