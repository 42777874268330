import Tooltip from "./Tooltip.svelte"
import { TooltipController } from "./TooltipController"

/**
 * To be used with the use directive to show a tooltip on hover
 */
function tooltip(
	elem: HTMLElement,
	props: { content?: string; pointing?: "left" | "top"; style?: string }
) {
	if (props.content) TooltipController.useTooltip(elem, { content: undefined, ...props })
}

export { Tooltip, tooltip }
