<script lang="ts">
	
	import Paragraph from "../../comps/Paragraph.svelte"

	export let prompt: string | Array<string> = undefined
	export let buttonText: string = undefined
	export let onVerify: () => void = undefined
	export let closeOverlay: () => void

	function handleEnterPress(e: Event) {
		e.preventDefault()
		if (onVerify) onVerify()
		closeOverlay()
	}
</script>

<div>
	{#if prompt}
		<Paragraph textContent={prompt} />
	{/if}
	<button
		on:click={() => {
			if (onVerify) onVerify()
			closeOverlay()
		}}>{buttonText || "OK"}</button
	>
</div>
<svelte:window on:keypress={handleEnterPress} />

<style>
	div {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}
</style>
