<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_617_72)">
		<line
			x1="1.5"
			y1="11.5"
			x2="21.5"
			y2="11.5"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
		<line
			x1="11.5"
			y1="21.5"
			x2="11.5"
			y2="1.5"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
	</g>
	<defs>
		<clipPath id="clip0_617_72">
			<rect width="23" height="23" fill={"white"} class="svg-fill" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
