<script lang="ts">
</script>

<svg
	width="108"
	height="79"
	viewBox="0 0 108 79"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class="error-report-icon"
>
	<g clip-path="url(#clip0_2_26)">
		<path
			d="M94.6 1.26001H13.43C6.80258 1.26001 1.42999 6.63259 1.42999 13.26V65.1C1.42999 71.7274 6.80258 77.1 13.43 77.1H94.6C101.227 77.1 106.6 71.7274 106.6 65.1V13.26C106.6 6.63259 101.227 1.26001 94.6 1.26001Z"
			stroke="#817E84"
			stroke-width="2"
			stroke-miterlimit="10"
		/>
		<path
			d="M63.05 48.78C62.63 48.78 62.22 48.62 61.9 48.3L54.02 40.48L46.14 48.3C45.5 48.94 44.46 48.93 43.83 48.3C43.19 47.66 43.2 46.62 43.83 45.99L52.86 37.02C53.5 36.39 54.53 36.39 55.16 37.02L64.19 45.99C64.83 46.63 64.84 47.66 64.19 48.3C63.87 48.62 63.45 48.78 63.03 48.78H63.05Z"
			fill="#FAAE1B"
		/>
		<path
			d="M46.93 35.32C48.018 35.32 48.9 34.438 48.9 33.35C48.9 32.262 48.018 31.38 46.93 31.38C45.842 31.38 44.96 32.262 44.96 33.35C44.96 34.438 45.842 35.32 46.93 35.32Z"
			fill="#FAAE1B"
		/>
		<path
			d="M61.11 35.32C62.198 35.32 63.08 34.438 63.08 33.35C63.08 32.262 62.198 31.38 61.11 31.38C60.022 31.38 59.14 32.262 59.14 33.35C59.14 34.438 60.022 35.32 61.11 35.32Z"
			fill="#FAAE1B"
		/>
	</g>
	<defs>
		<clipPath id="clip0_2_26">
			<rect width="108" height="79" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
