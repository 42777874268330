<script lang="ts">
	import type { DeviceGroup } from "luxedo-data"
	import { GroupEditorController } from "./GroupEditorController"
	import SelectDevices from "./steps/GroupDeviceSelector.svelte"
	import type { Component } from "../../../../types/SvelteTypes"
	import PlacementTutorial from "./steps/PlacementTutorial.svelte"
	import GroupCalibration from "./steps/calibration/GroupCalibration.svelte"
	import GroupAlign from "./steps/align/GroupAlign.svelte"
	import GroupBlend from "./steps/blend/GroupBlend.svelte"
	import { GroupCanvasController } from "./canvas/GroupCanvasController"
	import { InstructionSteps } from "svelte-comps/instruction-steps"
	import CheckboxInput from "../../input/CheckboxInput.svelte"

	let group: DeviceGroup

	let stepIndex: number = 0
	let inputComp: Component
	let isCanvasExpanded: boolean = false
	let isAutoSaveActive: boolean = false

	GroupCanvasController.subscribe((ctx) => (isCanvasExpanded = ctx.isExpanded))

	GroupEditorController.subscribe((ctx) => {
		isAutoSaveActive = ctx.isAutoSaveActive
		stepIndex = ctx.step
		group = ctx.group
	})

	const STEPS: { [index: number]: Component } = {
		0: SelectDevices,
		1: PlacementTutorial,
		2: GroupCalibration,
		3: GroupAlign,
		4: GroupBlend,
	}

	function updateAutoSave(
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		GroupEditorController.toggleAutoSave(e.currentTarget.checked)
	}
</script>

<div id="group-editor" class={isCanvasExpanded ? "canvas-expanded" : ""}>
	<div id="group-editor-top">
		<div id="editor-heading">
			<h1>
				Align Projectors
				<span class="device-name">{group?.name ?? "Group"}</span>
			</h1>
		</div>

		<div id="current-step">
			<InstructionSteps
				index={stepIndex}
				steps={GroupEditorController.STEP_LIST}
				onStepClick={GroupEditorController.setStep}
			/>
			<div id="current-step-menu">
				<svelte:component this={STEPS[stepIndex]} bind:inputComponent={inputComp} />
			</div>
		</div>
	</div>
	{#if inputComp}
		<div id="current-step-inputs">
			<svelte:component this={inputComp} />
		</div>
	{/if}
</div>
<div class="always-save-input" hidden={isCanvasExpanded}>
	<CheckboxInput
		label="Auto-save"
		id="auto-save"
		isChecked={isAutoSaveActive}
		onChange={updateAutoSave}
	/>
</div>

<style>
	#group-editor {
		height: 100%;
		min-height: 43rem;
		display: flex;
		flex-direction: column;
	}

	#group-editor.canvas-expanded :global(.instruction-steps),
	#group-editor.canvas-expanded #current-step-inputs {
		display: none;
	}

	#group-editor.canvas-expanded #current-step-menu {
		padding: 0;
		border: none;
		flex-basis: 100%;
	}

	.always-save-input {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
	}

	.always-save-input :global(.checkbox-input label) {
		font-size: var(--h3);
		color: var(--color-text);
		padding-left: 1.75rem;
	}

	#group-editor-top {
		padding: 2rem;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	#editor-heading h1 {
		font-size: var(--h1);
	}

	.device-name {
		color: var(--color-text);
	}

	#current-step {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		flex-grow: 1;
	}

	#current-step :global(.instruction-steps) {
		flex-basis: 35%;
	}

	#current-step-menu {
		flex-basis: 65%;
		border-left: 1px solid var(--color-border);
		padding-left: 2rem;
	}

	#current-step-menu :global(h3) {
		font-size: var(--h1);
		margin: 0 0 0.5rem 0;
	}

	#current-step-menu:global(:has(.warning-container)) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	#group-editor :global(#step-buttons) {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}

	#group-editor :global(#back-step-button) {
		padding-right: 1rem;
	}

	#group-editor :global(p.info) {
		color: var(--color-text);
	}

	#current-step-inputs {
		overflow: hidden;
		background-color: var(--color-bg-dark);
	}

	#group-editor :global(#group-align-inputs),
	#group-editor :global(#group-blend-inputs) {
		height: 100%;
		overflow: hidden;
	}

	#group-editor :global(.instance-selector) {
		overflow-y: auto;
		height: calc(100% - 4rem);
	}
</style>
