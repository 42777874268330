import type { DeviceGroup } from "luxedo-data"
import { writable } from "svelte/store"
import { Toast } from "svelte-comps/toaster"

export namespace GroupGridManager {
	type ContextType = {
		[index: number]: {
			gridSize: number
			complex: boolean
		}
	}

	const store = writable<ContextType>({})

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export async function deactivateGrid(group: DeviceGroup) {
		group.clearGrid()
		store.update((ctx) => {
			const newCtx = ctx
			if (group.id in newCtx) delete newCtx[group.id]
			return newCtx
		})
	}

	export async function activateGrid(
		group: DeviceGroup,
		settings?: {
			complex: boolean
			gridSize: number
			blending: boolean
		}
	) {
		if (group.isDeactivated) {
			Toast.error("This device group is not active. To use the grid, please activate the group.")
			return
		}

		let { complex, gridSize, blending } = settings ?? {
			complex: false,
			gridSize: 200,
			blending: false,
		}

		store.update((ctx) => ({ ...ctx, [group.id]: { complex, gridSize } }))
		await group.showGrid(complex, gridSize, blending)
	}
}
