/**
 * This mode exists as a 'passthrough' for the transform button on the control panel.
 * This mode awaits a selection and then activates the proper mode chosen by the selected asset.
 */

import { EditorClass } from "../.."
import { AudioAsset, CanvasAsset, ClippingMask, Ellipse, ImageAsset, MaskBase, Polygon, VideoAsset } from "../../asset"
import Mode, { ModeOptions } from "../../modules/mode"
import { EventListenerControls } from "../../events/decorators"
import { Path } from "../../asset"
import { PerspectiveEditMode, PolygonEditMode, MaskEditMode, EditPath, EditEllipse } from "../"

interface TransformModeOptions extends ModeOptions {}

export class TransformMode extends Mode<TransformModeOptions> {
	cancellable: boolean = true
	cancelReason: string
	public isTransformMode: boolean = true

	selectionListener: EventListenerControls<any>

	constructor(editor: EditorClass, options: TransformModeOptions) {
		super(editor, options)
	}

	get modeTitle() {
		return "Transform Mode"
	}

	protected async onActivate(options: TransformModeOptions): Promise<void> {
		if (this.editor.selection.get().length === 0) {
			this.selectionListener = this.editor.selection.on("selection:*", async (event) => {
				if (
					(event.selected.length === 1 && event.selected[0] instanceof CanvasAsset) ||
					event.selected[0] instanceof ClippingMask
				) {
					const selected = event.selected[0]
					if ("loadingPromise" in selected) await selected.loadingPromise
					this.activateTransform(selected as CanvasAsset)
				} else if (event.selected.length > 1) {
					this.cancelReason = "Cannot transform more than one object simultaneously."
					return this.cancel()
				} else if (event.selected[0] instanceof AudioAsset) {
					this.cancelReason = "Cannot transform audio."
					this.cancel()
				} else {
					return window.console.warn(`Cannot transform selection - type ${event?.selected[0]?.constructor?.name}.`)
				}
				this.selectionListener.remove()
			})
		} else {
			const selected = this.editor.selection.get()[0] as CanvasAsset
			if ("loadingPromise" in selected) await selected.loadingPromise
			this.activateTransform(selected)
		}
	}

	protected onDeactivate(): void {
		this.selectionListener?.remove()
	}

	/**
	 * Activates the relevant edit mode for the passed selection
	 * @param selection
	 */
	activateTransform(selection: CanvasAsset) {
		if (selection instanceof AudioAsset) {
			this.cancelReason = "Cannot transform audio."
			return this.cancel()
		}

		return TransformMode.ActivateTransform(this.editor, selection)
	}

	static ActivateTransform(editor: EditorClass, selection: CanvasAsset | ClippingMask) {
		console.log({ selection })

		if (selection instanceof AudioAsset) return
		// Set within a setTimout to ensure any timestamp updates have been applied before selecting the proper mode
		setTimeout(() => {
			if (selection instanceof Polygon) editor.mode.set(PolygonEditMode, { target: selection })
			else if (selection instanceof Ellipse) editor.mode.set(EditEllipse, { target: selection })
			else if (selection instanceof ImageAsset || selection instanceof VideoAsset)
				editor.mode.set(PerspectiveEditMode, { transformTarget: selection })
			else if (selection instanceof ClippingMask) {
				editor.mode.set(MaskEditMode, {
					mask: selection,
				})
			} else if (selection instanceof Path) {
				editor.mode.set(EditPath, {
					target: selection,
				})
			}
		})
	}

	static async AwaitTransform(editor: EditorClass, options?: TransformModeOptions): Promise<void> {
		const mode = editor.mode.set(this, options ?? { functionClearMode: () => undefined })
		return new Promise((res, rej) => {
			mode.onexit = (m, cancelled) => {
				if (cancelled) return rej(mode.cancelReason)
				res()
			}
		})
	}
}
