<script lang="ts">
	import { HandShakeIcon, PaintIcon } from "svelte-comps/icons"
	import { NewSceneController } from "./NewSceneController"
	import { tooltip } from "svelte-comps/tooltip"
</script>

<div id="scene-type-selection">
	<button class="type-button standard-select" on:click={NewSceneController.selectStandard}>
		<PaintIcon />
		<span class="type-select-text">Create your Own</span>

		<span class="show-on-hover"
			>Use the Luxedo scene editor or create a scene or use a 3rd party video editing software.</span
		>
	</button>
	<div class="seperate" />
	<button class="type-button custom-select" on:click={NewSceneController.selectCustom}>
		<HandShakeIcon />
		<span class="type-select-text">Work with a Professional</span>
		<span class="show-on-hover"
			>Work with one of our trusted partners to create a custom show designed specifically for you.</span
		>
	</button>
</div>

<style>
	:global(.overlay.new-scene-overlay):has(#scene-type-selection) {
		min-width: unset;
		min-height: unset;
		height: 100%;
	}

	.seperate {
		height: 100%;
		width: 1px;
		background-color: var(--color-border);
	}

	#scene-type-selection {
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	.type-button {
		display: flex;
		flex-direction: column;
		border-radius: unset;
		background-color: unset;
		flex-basis: 50%;
		height: 100%;
		align-items: center;
		justify-content: center;
		box-shadow: none;
	}

	.type-select-text {
		font-size: var(--h1);
		font-family: var(--font-heading);
		color: var(--color-text);
		transition: color 250ms;
	}

	.type-button {
		transform: unset;
		transition: background-color 500ms;
	}

	.show-on-hover {
		text-align: center;
		padding: 0 2rem;
		opacity: 0;
		transition: opacity 500ms;
	}

	.type-button:hover .show-on-hover,
	.type-button:focus-visible .show-on-hover {
		opacity: 1;
	}

	.type-button:hover,
	.type-button:focus-visible {
		background-color: var(--color-bg-dark);
	}

	.type-button :global(svg) {
		width: 50%;
		height: auto;
	}

	.type-button :global(.svg-fill) {
		fill: var(--color-text);
		transition: fill 250ms;
	}

	.type-button:hover .type-select-text,
	.type-button:focus-visible .type-select-text {
		color: var(--color-main);
	}

	.type-button:hover :global(.svg-fill),
	.type-button:focus-visible :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
