<script lang="ts">
	import { DeviceGroup, type Device, DeviceBrowser } from "luxedo-data"
	import ProjectorGroupIcon from "./ProjectorGroupIcon.svelte"
	import LuxLinkIcon from "./LuxLinkIcon.svelte"

	export let device: Device = undefined

	export let strokeColor: string = undefined
	export let onClick: () => void = undefined
	export let deviceIconElem: SVGElement = undefined
</script>

{#if device instanceof DeviceGroup}
	<ProjectorGroupIcon group={device} {onClick} bind:deviceIconElem />
{:else if device instanceof DeviceBrowser}
	<LuxLinkIcon {device} {onClick} bind:deviceIconElem />
{:else}
	<svg
		class="projector-icon"
		bind:this={deviceIconElem}
		on:click={onClick}
		width="49"
		height="28"
		viewBox="0 0 49 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_7_2)">
			<g opacity="0.5">
				<path
					d="M8.64999 25.38C5.52999 25.38 2.98999 22.85 2.98999 19.72V8.64999C2.98999 5.52999 5.51999 2.98999 8.64999 2.98999H40.39C43.51 2.98999 46.05 5.51999 46.05 8.64999V19.72C46.05 22.84 43.52 25.38 40.39 25.38"
					stroke="#817E84"
					stroke-width="4"
					stroke-miterlimit="10"
				/>
				<path
					d="M34.8 19.78C37.8872 19.78 40.39 17.2772 40.39 14.19C40.39 11.1027 37.8872 8.59998 34.8 8.59998C31.7127 8.59998 29.21 11.1027 29.21 14.19C29.21 17.2772 31.7127 19.78 34.8 19.78Z"
					stroke="#817E84"
					stroke-width="4"
					stroke-miterlimit="10"
				/>
			</g>
			<g opacity="0.5">
				<path
					d="M8.64999 25.38C5.52999 25.38 2.98999 22.85 2.98999 19.72V8.64999C2.98999 5.52999 5.51999 2.98999 8.64999 2.98999H40.39C43.51 2.98999 46.05 5.51999 46.05 8.64999V19.72C46.05 22.84 43.52 25.38 40.39 25.38"
					stroke={strokeColor ?? device.color}
					stroke-width="4"
					stroke-miterlimit="10"
				/>
				<path
					d="M34.8 19.78C37.8872 19.78 40.39 17.2772 40.39 14.19C40.39 11.1027 37.8872 8.59998 34.8 8.59998C31.7127 8.59998 29.21 11.1027 29.21 14.19C29.21 17.2772 31.7127 19.78 34.8 19.78Z"
					stroke={strokeColor ?? device.color}
					stroke-width="4"
					stroke-miterlimit="10"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_7_2">
				<rect width="49" height="28" fill="white" />
			</clipPath>
		</defs>
	</svg>
{/if}

<style>
</style>
