<script lang="ts">
	import LuxedoLogo from "../../../assets/icons/LuxedoLogo.svg"
</script>

<svelte:head>
	<meta
		http-equiv="Cache-Control"
		content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
	/>
	<meta http-equiv="Pragma" content="no-cache" />
	<meta http-equiv="Expires" content="0" />
</svelte:head>

<a href="https://luxedo.com" target="_blank">
	<img src={LuxedoLogo} alt="Luxedo Logo" />
	<h1>Wi-Fi Assistant</h1>
</a>

<style>
	a {
		width: 100%;
		height: 4rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 1rem 0;
		margin: 1rem;
		border: none;
	}

	h1 {
		font-size: var(--h1);
		margin: 0 1rem;
		width: 4rem;
		line-height: 1em;
	}

	@media only screen and (max-width: 800px) {
		a {
			flex-direction: column;
			padding: 1rem 10%;
			height: 6rem;
			margin: 0;
		}

		img {
			height: 2rem;
		}

		h1 {
			width: unset;
			margin-top: 0.5rem;
		}
	}
</style>
