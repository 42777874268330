<script lang="ts">
	import { AccountNavigator } from "../../AccountStore"
	import LuxLinkGeneral from "./LuxLink_General.svelte"
	import LuxLinkSubscriptionController from "./LuxLink_Controller.svelte"
	import { LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"
	import { onMount } from "svelte"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"

	let isSubscriptionOpen: boolean = false
	AccountNavigator.subscribe((ctx) => (isSubscriptionOpen = ctx.props.openSubscription))

	onMount(() => {
		const userPrice =
			ChargebeeManager.getPlanPriceById(
				ChargebeeManager.userPlanSubscriptions.luxlinkPlan?.price_id
			) ?? undefined
		const userAddonAmount =
			ChargebeeManager.userPlanSubscriptions?.luxlinkPlan?.addon?.quantity ?? 0
		LuxLinkSubscriptionManager.store.update((ctx) => {
			return {
				...ctx,
				plan: userPrice,
				addonQuantity: userAddonAmount,
			}
		})
	})
</script>

{#if isSubscriptionOpen}
	<LuxLinkSubscriptionController />
{:else}
	<LuxLinkGeneral />
{/if}

<style>
</style>
