<script lang="ts">
	import {
		DataHandlerDevice,
		DeviceGroup,
		DeviceRPi,
		TEMPORARY_PLAY_NOW_EVENT_NAME,
		TimetableEvent,
		type Device,
		type Lightshow,
		type Scene,
	} from "luxedo-data"
	import { PlayIcon, StopIcon } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import LoadingSpinner from "../../loaders/LoadingSpinner.svelte"
	import { ProjectorPowerManager } from "luxedo-data"
	import { DevicePlaybackManager } from "luxedo-data"
	import { closeOverlay, openConfirmOverlay, openPopupOverlay } from "svelte-comps/overlay"
	import { ScheduleController } from "../../../routes/schedule/ScheduleController"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { get } from "svelte/store"
	import { DeviceSpotlightManager } from "luxedo-data"

	export let scene: Scene | Lightshow
	export let device: Device

	let isPlaying = false
	let isWaiting = false
	let isOffline = false

	let activeEvent: TimetableEvent

	DevicePlaybackManager.subscribe((ctx) => {
		const devStatus = ctx[device.id]

		if (!devStatus) {
			isOffline = false
			isWaiting = false
			isPlaying = false
			activeEvent = undefined
			return
		}

		const isDevicePlaying = devStatus.isPlaying
		const showScene = devStatus.scene
		const showLightshow = devStatus.lightshow

		if (isDevicePlaying && (scene.id === showScene?.id || scene.id === showLightshow?.id)) {
			isPlaying = true
			activeEvent = devStatus.event
		} else {
			isPlaying = false
			activeEvent = undefined
		}
	})

	let listeners: Array<string> = []

	listeners.push(
		device.addUpdateListener((dev) => {
			isOffline = !dev.isOnline
		})
	)

	async function stopPlayback() {
		isWaiting = true

		if (!activeEvent) activeEvent = await DevicePlaybackManager.getActiveEvent(device)

		const clearEvent = async () => {
			return new Promise<void>(async (res, rej) => {
				let listener: string
				let eidosDevice = device

				const resolve = () => {
					res()
					Toast.success("Playback stopped.")
					isPlaying = false
					isWaiting = false
					eidosDevice.removeUpdateListener(listener)
				}

				if (device instanceof DeviceGroup)
					eidosDevice = device.getChildDevices().find((dev) => dev.isOnline)

				listener = eidosDevice.addUpdateListener(async () => {
					if (DevicePlaybackManager.checkIsPlaying(device) === false) return resolve()
					if ((await DevicePlaybackManager.getActiveEvent(device)) !== activeEvent) return resolve()
				})

				await device.timetableManager.deleteEvent(activeEvent)
				await ScheduleController.Calendar.refreshEvents({
					deviceFilter: get(SelectedDeviceStore),
				})
			})
		}

		try {
			if (activeEvent?.name === TEMPORARY_PLAY_NOW_EVENT_NAME) clearEvent()
			else if (
				(device instanceof DeviceRPi && device.eidos.display_mode === "PREVIEW") ||
				(device instanceof DeviceGroup &&
					DataHandlerDevice.get(device.children[0].device_id).eidos.display_mode == "PREVIEW")
			) {
				await DevicePlaybackManager.cancelPreview(device)
				Toast.success("Playback stopped.")
				isPlaying = false
				isWaiting = false
			} else {
				openPopupOverlay({
					prompt: [
						"This show has been scheduled to play.",
						"To cancel it, please delete the event from the scheduler.",
					],
					onVerify: () => {
						isWaiting = false
					},
				})

				// const overlayID = openConfirmOverlay({
				// 	prompt: [
				// 		"Stopping playback will stop the remove the currently scheduled event.",
				// 		"Are you sure you want to stop playback?",
				// 	],
				// 	buttons: {
				// 		confirm: {
				// 			text: "Cancel",
				// 			onClick: () => {
				// 				closeOverlay(overlayID)
				// 				isWaiting = false
				// 			},
				// 		},
				// 		deny: {
				// 			text: "Stop Playback",
				// 			onClick: async () => {
				// 				await clearEvent()
				// 				closeOverlay()
				// 			},
				// 		},
				// 	},
				// })
			}
		} catch (e) {
			console.error("Error clearing playback... ", e)
			Toast.error("Error canceling playback.")
		}
		// finally { isWaiting = false }
	}

	async function playOnDevice() {
		try {
			isWaiting = true

			const isGroup = device instanceof DeviceGroup
			await DevicePlaybackManager.playOnDevice(device, scene, {
				onFirstDownload: () => Toast.text("Waiting for show to download..."),
				onPlayingOutdated: () =>
					Toast.text(
						"Playing old version of this show. The playback will restart as soon as the new version is downloaded."
					),
				onPoweringProjector: () => Toast.text(`Powering projector${isGroup ? "s" : ""} on...`),
			})

			Toast.success("Playback started")
			isPlaying = true
		} catch (e) {
			console.error("Error playing show on device... ", e)
			if (e && e.statusCode === 701)
				Toast.error(
					"Device appears to be disconnected, ensure is connected to Wi-Fi and try again."
				)
			else Toast.error("Unable to play show at this time. Please refresh and try again.")
		} finally {
			isWaiting = false
		}
	}

	async function handleClick() {
		if (device.isDeactivated) {
			Toast.error("This group is deactivated. To playback, please activate the group.")
			return
		}

		if (isOffline) {
			Toast.error("Device appears to be disconnected, ensure is connected to Wi-Fi and try again.")
			return
		}

		if (isWaiting) return

		if (isPlaying) {
			stopPlayback()
		} else {
			playOnDevice()
		}
	}

	// function checkStatus(dev: Device) {
	// 	if (dev.id != device.id) return

	// 	if (dev instanceof DeviceGroup) {
	// 		isOffline = false
	// 		isPlaying =
	// 			dev.getChildDevices().filter((d) => d.getEidos().display_mode === "PREVIEW").length > 0

	// 		return
	// 	}

	// 	if (!dev.isOnline) {
	// 		isOffline = true
	// 	} else {
	// 		isOffline = false
	// 	}

	// 	if (
	// 		(dev.eidos.display_mode === "STATIC" || dev.eidos.display_mode === "PREVIEW") &&
	// 		dev.eidos.proj_id === scene.id
	// 	) {
	// 		isPlaying = true
	// 	} else {
	// 		isPlaying = false
	// 	}
	// }

	function registerListener(dev: Device) {
		dev.addUpdateListener((dev) => {
			isOffline = !dev.isOnline
		})
		// if (!(dev instanceof DeviceGroup)) {
		// 	dev.addUpdateListener(checkStatus)
		// 	return
		// } else {
		// 	for (const child of dev.getChildDevices()) {
		// 		child.addUpdateListener((d) => checkStatus(dev))
		// 	}
		// }
	}

	registerListener(device)
</script>

{#if isWaiting}
	<LoadingSpinner color="var(--color-main)" height="3.5rem" />
{:else}
	<button
		id="play-lightshow-btn"
		class="icon"
		title={isPlaying ? "Stop Playback" : "Play on Projector"}
		on:click={handleClick}
	>
		{#if isPlaying}
			<StopIcon />
		{:else}
			<PlayIcon />
		{/if}
	</button>
{/if}

<style>
	#play-lightshow-btn {
		outline: 1px solid var(--color-main);
		padding: 1rem;
		transition:
			outline-offset 250ms ease-out,
			outline-color 250ms;
	}

	#play-lightshow-btn:hover,
	#play-lightshow-btn:focus-visible {
		outline-offset: 0.5rem;
	}

	#play-lightshow-btn :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
