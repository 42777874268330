import type { Tag } from "luxedo-data"
import { writable } from "svelte/store"

interface ColorPickerStoreContext_Closed {
	isOpen: false
}

interface ColorPickerStoreContext_Open {
	isOpen: true
	tag: Tag
	left: number
	top: number
	isLibraryContext?: boolean
}

type ColorPickerStoreContext = ColorPickerStoreContext_Closed | ColorPickerStoreContext_Open

export const ColorPickerStore = writable<ColorPickerStoreContext>({
	isOpen: false,
})

export function openTagColorPicker(
	tag: Tag,
	colorBlock: HTMLDivElement,
	isLibraryContext?: boolean
) {
	const { left, top } = colorBlock.getBoundingClientRect()

	ColorPickerStore.set({
		isOpen: true,
		isLibraryContext,
		tag: tag,
		left,
		top,
	})
}

export function closeTagColorPicker() {
	ColorPickerStore.set({
		isOpen: false,
	})
}
