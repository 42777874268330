import type { Snapshot } from "luxedo-data"
import { DateTime } from "luxon"
import { openOverlay } from "svelte-comps/overlay"
import SnapshotPreview from "./SnapshotPreview.svelte"

export function openSnapshotPreview(
	snapshot: Snapshot,
	options: {
		heading?: string
	} = {}
) {
	const dt = DateTime.fromJSDate(snapshot.datesaved)
	const dateString = `${dt.monthLong} ${dt.day}, ${dt.year}`

	const { heading } = options

	openOverlay(SnapshotPreview, {
		classHeading: "no-underline",
		classOverlay: "low-pad",
		heading: heading ?? `${dateString} Snapshot`,
		props: { snapshot },
	})
}
