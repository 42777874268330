<script lang="ts">
	import { EyeClosedIcon, EyeOpenIcon } from "svelte-comps/icons"

	export let id: string
	export let value: string
	export let disabled: boolean = false
	export let autocomplete: boolean = true
	export let readonly: boolean = false

	let isVisible = false

	function toggleVisibility() {
		isVisible = !isVisible
	}
</script>

<div class="flex-row">
	{#if isVisible}
		<input
			{id}
			type="text"
			bind:value
			{disabled}
			{readonly}
			autocomplete={autocomplete ? "on" : "off"}
		/>
	{:else}
		<input
			{id}
			type="password"
			bind:value
			{disabled}
			{readonly}
			autocomplete={autocomplete ? "on" : "new-password"}
		/>
	{/if}
	<button type="button" on:click={toggleVisibility} class="icon" tabindex="-1">
		{#if isVisible}
			<EyeOpenIcon />
		{:else}
			<EyeClosedIcon />
		{/if}
	</button>
</div>

<style>
	.flex-row {
		width: 100%;
		align-items: center;
		position: relative;
	}

	.flex-row :global(svg) {
		width: 1rem;
		position: absolute;
		right: 0;
	}

	button {
		position: absolute;
		right: 0.5rem;
		height: 1rem;
		width: 1rem;
	}

	button.icon :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button.icon:hover :global(.svg-stroke),
	button.icon:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}
</style>
