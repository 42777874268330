import { fabric } from "fabric"

import { EditorClass } from "../.."
import { PointControl, deepCopyPoints, findNearestPointOnLine } from "../../fabric-plugins"
import { FabricEvent } from "../../modules/context-menu"
import deepcopy from "deepcopy"
import { TransformBase, TransformBaseOptions } from "./TransformBaseMode"

export interface PointEditOptions extends TransformBaseOptions {
	/** @property An object with a points property of fabric Points to edit */
	target: fabric.Polyline

	/** @property Allow adding a new point by clicking between existing points */
	allowAddingPoints?: boolean

	/** @property Allow deleting a point by alt-clicking it */
	allowDeletingPoints?: boolean

	/** @property Causes deletePoint to error if it would bring the number of points below this. Default is 1 */
	minPoints?: number

	/** @property Optional style overrides for the polyline while editing. Some overrides are set by default in modes/EditPoints.ts */
	editStyle?: fabric.IPolylineOptions

	/** @property Specific options to apply to the control points themselves */
	pointOptions?: Partial<fabric.Control>

	/** @property Function to call after the points are updated from a control event */
	onPointsEdit?: () => void

	/** @property Callback to call when a point is dragged but before updating the real points. If an error is thrown in this, the movement is prevented */
	beforePointsEdit?: (pointsOld: fabric.Point[], pointsNew: fabric.Point[]) => void
}

export interface EditPoints extends TransformBase<PointEditOptions>, PointEditOptions {
	/** Add the asset to the editor per the options */
	complete(): void
}

export class EditPoints extends TransformBase<PointEditOptions> {
	cancellable: boolean = true
	declare target: fabric.Polyline

	//#region    ===========================		   	   		Setup			 		==============================

	constructor(editor: EditorClass, options: PointEditOptions) {
		super(editor, options)

		this.applyDefaultOptions(options)

		this.allowAddingPoints = options.allowAddingPoints
		this.allowDeletingPoints = options.allowDeletingPoints

		this.target = options.target
		this.updatePoints()

		this.onPointsEdit = options.onPointsEdit
		this.beforePointsEdit = options.beforePointsEdit

		this.isClosed = this.points.length > 1 && this.points[0] === this.points[this.points.length - 1]
		this.minPoints = options.minPoints
		if (this.isClosed) this.minPoints += 1
	}

	get modeTitle() {
		return "Editing Points"
	}

	protected applyDefaultOptions(options: PointEditOptions) {
		options.editStyle = options.editStyle ?? {}
		options.editStyle = {
			...POINT_EDIT_DEFAULT,
			...options.editStyle,
		}

		options.pointOptions = options.pointOptions ?? {}
		options.pointOptions = {
			...CONTROLS_DEFAULT,
			...options.pointOptions,
		}

		options.minPoints = options.minPoints ?? 1
	}

	protected isClosed: boolean

	//#endregion =====================================================================================================

	//#region    ===========================				  Interface					==============================

	protected updatePoints() {
		if (!this.absolutePoints) {
			this.absolutePoints = deepCopyPoints(this.target.points)
		}

		for (let i = 0; i < this.points.length; i++) {
			this.absolutePoints[i].setFromPoint(this.target.toAbsolutePoint(this.points[i]))
		}

		if (this.isClosed) {
			this.absolutePoints[this.points.length - 1] = this.absolutePoints[0]
		}

		this.editor.canvas.requestRenderAll()
	}

	insertPoint(point: fabric.Point, index: number, absolutePoint?: fabric.Point) {
		this.target.points.splice(index, 0, point)
		this.absolutePoints.splice(index, 0, absolutePoint ?? point.clone())
		this.updatePoints()
		this.initControls()
	}

	deletePoint(index: number) {
		if (this.points.length == this.minPoints)
			throw Error(
				`Too few points to delete one! (Minimum: ${this.minPoints - (this.isClosed ? 1 : 0)})`
			)
		this.target.points.splice(index, 1)
		this.absolutePoints.splice(index, 1)
		this.updatePoints()
		this.initControls()

		if (this.isClosed && index == 0) {
			const lastIndex = this.points.length - 1
			this.points[lastIndex] = this.points[0]
			this.absolutePoints[lastIndex] = this.absolutePoints[0]
		}
	}

	protected absolutePoints: fabric.Point[]

	get points(): fabric.Point[] {
		return this.target.points
	}

	protected savedPoints: fabric.Point[]
	protected savedPointValues: fabric.Point[]
	save(): void {
		this.savedPoints = this.target.points.slice()
		this.savedPointValues = deepCopyPoints(this.target.points)
	}

	loadSave(): void {
		// Restore the original set of points
		this.target.points = this.savedPoints

		// Restore the original set of points to their original values
		for (let i = 0; i < this.savedPointValues.length; i++) {
			this.target.points[i].setFromPoint(this.savedPointValues[i])
		}
	}

	protected updateTargetPosition() {
		// Pick a point from the path and determine its exact position
		const anchorPosition = this.target.toAbsolutePoint(this.points[0])

		// Update the polygon's dimensions
		/* @ts-ignore */
		this.target._setPositionDimensions({
			left: false,
			top: false,
		})

		// Find the point's absolute position after the change
		const afterPosition = this.target.toAbsolutePoint(this.points[0])
		const diff = afterPosition.subtract(anchorPosition)

		// Adjust the shape in accordance with the change
		this.target.left -= diff.x
		this.target.top -= diff.y
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Implementation		 		==============================

	protected onActivate(options: PointEditOptions): void {
		this.save()

		this.setStyle(this.options.editStyle)
		this.initControls()

		if (this.allowDeletingPoints) {
			document.onkeydown = (e) => {}
		}

		this.initEvents()
	}

	protected onDeactivate(): void {
		super.onDeactivate()

		this.restoreStyle()
		this.restoreControls()
		this.clearEvents()

		this.editor.canvas.requestRenderAll()
	}

	protected onComplete(...args: any): void {
		this.save()
	}

	protected onCancel(): void {
		// Restore save
		this.loadSave()
	}

	eventMoving = (e) => {
		this.updatePoints()
	}

	eventMouseMove = (e) => {
		if (e.target.__corner && e.target.__corner !== "creator")
			this.hoveredControl = this.target.controls[e.target.__corner] as PointControl
		else this.hoveredControl = undefined
	}

	eventMouseOut = (e) => {
		this.hoveredControl = undefined
	}

	protected targetEvents: Partial<Record<fabric.EventName, (e: FabricEvent) => any>> = {
		moving: this.eventMoving,
		mousemove: this.eventMouseMove,
		mouseout: this.eventMouseOut,
	}

	protected pageEvents: Partial<Record<keyof HTMLElementEventMap, (e: Event) => any>> = {
		keydown: (e: KeyboardEvent) => {
			if (e.key !== "Alt" || !e.altKey) return
			e.preventDefault()
			if (!(this.hoveredControl && this.allowDeletingPoints)) return
			this.editor.canvas.setCursor("no-drop")
		},
		keyup: (e: KeyboardEvent) => {
			if (e.key !== "Alt" || e.altKey) return
			if (!(this.hoveredControl && this.allowDeletingPoints)) return
			this.editor.canvas.setCursor("pointer")
		},
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood		 		==============================

	protected hoveredControl: PointControl
	protected initEvents() {
		for (const [evName, handler] of Object.entries(this.targetEvents)) {
			this.target.on(evName, handler)
		}
		for (const [evName, handler] of Object.entries(this.pageEvents)) {
			document.body.addEventListener(evName, handler)
		}

		if (this.allowAddingPoints) {
			this.initAddPointsMode()
		}
	}

	protected clearEvents() {
		for (const [evName, handler] of Object.entries(this.targetEvents)) {
			this.target.off(evName, handler)
		}
		for (const [evName, handler] of Object.entries(this.pageEvents)) {
			document.body.removeEventListener(evName, handler)
		}
		if (this.clearAddPointsMode) this.clearAddPointsMode()
	}

	protected onPointEdit(index: number, positionAbsolute: fabric.Point, eventData?: MouseEvent) {
		const relativePt = this.target.toRelativePoint(positionAbsolute)

		const ptsNew = deepCopyPoints(this.target.points)
		ptsNew[index].setFromPoint(relativePt)

		this.beforePointsEdit && this.beforePointsEdit(this.target.points, ptsNew)

		this.movePoint(index, relativePt)

		this.onPointsEdit && this.onPointsEdit()
	}

	protected movePoint(index: number, newPosition: fabric.Point): void {
		this.target.points[index].setFromPoint(newPosition)
		this.updateTargetPosition()
	}

	private oldControls: ControlBlock
	protected initControls() {
		if (!this.oldControls) this.oldControls = this.target.controls

		this.target.controls = {}
		let numControls = this.absolutePoints.length
		if (this.isClosed) numControls -= 1

		for (let i = 0; i < numControls; i++) {
			const index = i
			const pt = this.absolutePoints[index]

			const options = deepcopy(this.options.pointOptions)

			if (this.allowDeletingPoints) {
				options.cursorStyleHandler = deletablePointCursorHandler
				options.mouseDownHandler = (e) => {
					if (!e.altKey) return false
					try {
						this.deletePoint(index)
					} catch (e) {
						console.warn(e)
						return false
					}
					return true
				}
			}

			const control = new PointControl(pt, this.onPointEdit.bind(this, index), options)

			this.target.controls[`pt${index}`] = control
		}

		this.editor.canvas.requestRenderAll()
	}

	protected restoreControls() {
		this.target.controls = this.oldControls
	}

	protected oldStyle: fabric.IPolylineOptions
	protected setStyle(style: fabric.IPolylineOptions) {
		if (!style) return
		this.oldStyle = {}
		for (const [prop, val] of Object.entries(style)) {
			this.oldStyle[prop] = this.target[prop]
			this.target[prop] = val
		}
	}
	protected restoreStyle() {
		if (!this.oldStyle) return
		for (const [prop, val] of Object.entries(this.oldStyle)) {
			this.target[prop] = val
		}
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Point-Add mode Helpers			==============================

	protected getNearestLineInfo(absolutePoint: fabric.Point): {
		nearestPoint: fabric.Point
		distance: number
		lineIndex: number
	} {
		if (this.points.length < 2) return

		const lines: [fabric.Point, fabric.Point][] = []
		for (let i = 1; i < this.absolutePoints.length; i++) {
			lines.push([this.absolutePoints[i - 1], this.absolutePoints[i]])
		}

		if (!this.absolutePoints[this.absolutePoints.length - 1].eq(this.absolutePoints[0])) {
			lines.push([this.absolutePoints[this.absolutePoints.length - 1], this.absolutePoints[0]])
		}

		let distance: number = 999999
		let lineIndex: number = 0
		let nearestPoint: fabric.Point

		for (let index = 0; index < lines.length; index++) {
			const line = lines[index]
			const nearPt = findNearestPointOnLine(absolutePoint, line)
			const dist = absolutePoint.distanceFrom(nearPt)

			if (dist < distance) {
				distance = dist
				lineIndex = index
				nearestPoint = nearPt
			}
		}

		return {
			distance,
			lineIndex,
			nearestPoint,
		}
	}

	addPointsMouseMove = (e: FabricEvent) => {
		if (e.e.buttons) return this.clearCreatorControl()
		if (this.hoveredControl) return this.clearCreatorControl()
		const info = this.getNearestLineInfo(e.absolutePointer)

		const threshold = 15 / this.editor.viewport.scale
		const ctrlThreshold = threshold * (e.e.ctrlKey ? 3 : 1)
		const nearestPtDistance = Math.min(
			...this.absolutePoints.map((e) => info.nearestPoint.distanceFrom(e))
		)

		if (info.distance > ctrlThreshold) {
			this.clearCreatorControl()
		} else if (!e.e.ctrlKey && nearestPtDistance < 30 / this.editor.viewport.scale) {
			this.clearCreatorControl()
		} else {
			this.setCreatorControl(info.nearestPoint, info.lineIndex + 1, e)
		}
	}

	protected clearAddPointsMode: (...args: any) => void
	protected initAddPointsMode() {
		this.editor.canvas.on("mouse:move", this.addPointsMouseMove)
		this.clearAddPointsMode = () => {
			this.editor.canvas.off("mouse:move", this.addPointsMouseMove)
			this.clearAddPointsMode = null
		}
	}

	protected setCreatorControl(absolutePoint: fabric.Point, index: number, eventData?: FabricEvent) {
		if (!this.target.controls["creator"])
			this.target.controls["creator"] = new PointControl(
				absolutePoint,
				this.onPointEdit.bind(this, index)
			)

		const creator = this.target.controls["creator"] as PointControl
		creator.point.setFromPoint(absolutePoint)

		creator.cursorStyle = "cell"
		creator.clickableSize = 100
		creator.styleOverride = {
			cornerStrokeColor: "#ffffff",
			cornerColor: "#ffffff50",
			transparentCorners: false,
		}

		creator.mouseDownHandler = (e, tsf, x, y) => {
			this.editor.canvas.setCursor("pointer")
			const newPoint = this.target.toRelativePoint(new fabric.Point(x, y))
			this.insertPoint(newPoint, index, absolutePoint)

			this.clearCreatorControl()

			const newControl = this.target.controls[`pt${index}`] as PointControl
			creator.onEdit = (pt, e) => {
				newControl.onEdit(pt, e)
				this.updatePoints()
			}

			return true
		}

		this.editor.canvas.requestRenderAll()
	}

	protected clearCreatorControl() {
		if (!("creator" in this.target.controls)) return

		delete this.target.controls["creator"]
		this.editor.canvas.requestRenderAll()
	}

	//#endregion =====================================================================================================
}

export const POINT_EDIT_DEFAULT: fabric.IPolylineOptions = {
	cornerStyle: "circle",
	cornerColor: "white",
	transparentCorners: false,
	hasBorders: false,
	objectCaching: false,
}

type ControlBlock = fabric.Object["controls"]

export const CONTROLS_DEFAULT: Partial<fabric.Control> = {
	cursorStyle: "pointer",
}

export const deletablePointCursorHandler: fabric.Control["cursorStyleHandler"] = (e) => {
	if (e.altKey) return "no-drag"
	return "pointer"
}
