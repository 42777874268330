<script lang="ts">
	import { createEventDispatcher } from "svelte"

	const dispatch = createEventDispatcher()

	export let value: number
	export let max: number
	export let min: number
	export let label: string
	export let step: number = 1
	export let disabled: boolean = false

	function onValueChange(
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) {
		const target = e.currentTarget
		if (Number(target.value) > max) {
			value = max
			setTimeout(() => {
				target.value = value.toString()
			})
		} else if (Number(target.value) < min) {
			value = min
			setTimeout(() => {
				target.value = value.toString()
			})
		} else value = Number(target.value)

		dispatch("change", value)
	}
</script>

<div class="input-container">
	<label for="{label.replaceAll(' ', '-')}-input-number" class={disabled ? "disabled" : ""}
		>{label}
	</label>
	<input
		id="{label.replaceAll(' ', '-')}-input-number"
		type="number"
		{step}
		{min}
		{max}
		{value}
		on:change={onValueChange}
		{disabled}
	/>
	<!-- on:blur={onValueChange} -->
	<input
		id="{label.replaceAll(' ', '-')}-input-range"
		type="range"
		{step}
		{min}
		{max}
		{value}
		{disabled}
		on:change={onValueChange}
	/>
	<!-- on:blur={onValueChange} -->
</div>

<style>
	label.disabled {
		color: var(--color-text);
	}

	input[type="number"] {
		width: 3em;
		text-align: right;
		background-color: var(--color-bg);
		padding: 0.2em 0.5em;
		margin: 0 0.5rem;
		line-height: 1em;
		font-size: var(--h3);
		text-align: center;
		cursor: text;
	}

	input[type="number"]:disabled {
		opacity: 0.5;
	}

	input[type="range"] {
		padding: 0;
		border: 1px solid var(--color-main);
		width: unset;
		flex-grow: 1;
	}

	input[type="range"]:disabled {
		opacity: 0.25;
	}
</style>
