<svg
	id="Layer_7"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 14.72 11.25"
	width="13.47"
	height="10"
	><defs /><rect class="cls-1 svg-stroke" x=".62" y=".62" width="13.47" height="10" /><path
		class="cls-2 svg-fill"
		d="m9.37,4.33l-2.51-1.45c-1-.58-2.25.14-2.25,1.3v2.9c0,1.15,1.25,1.88,2.25,1.3l2.51-1.45c1-.58,1-2.02,0-2.6Z"
	/></svg
>

<style>
	.cls-1 {
		fill: none;
		stroke: #faae1b;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 1.25px;
	}
	.cls-2 {
		fill: #faae1b;
	}
</style>
