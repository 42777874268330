<script lang="ts">
	import { createEventDispatcher } from "svelte"

	export let progress = 0

	let dispatch = createEventDispatcher()
</script>

<div
	class="progress-bar"
	style="	background: radial-gradient(closest-side, var(--color-bg-dark) 79%, transparent 80% 100%),
conic-gradient(var(--color-main) {Math.round(progress * 100)}%, var(--color-text) 0);"
	on:click={() => dispatch("click")}
>
	<span> {Math.round(progress * 100)}% </span>
</div>

<style>
	.progress-bar {
		cursor: pointer;
		margin-right: 1rem;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		transition: background 250ms;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	span {
		font-size: 0.5rem;
	}
</style>
