import type { DataHandlerFile } from "../datahandlers/DataHandlerFile"
import type { F_Entry } from "../types/FileTypes"

type FolderUpdateFunction<File extends F_Entry, Folder extends F_Entry> = (files: Array<File | Folder>) => void

/**
 * Publicly accessible methods to be implemented by the FileSystem class
 */
interface FileSystemAPI<File extends F_Entry, Folder extends F_Entry> {
	useDirectory(directory: Folder | number, updateFn: FolderUpdateFunction<File, Folder>) // Returns all files from said directory, updating if the file system has any changes
	move(moveObject: File | Folder, moveTo: Folder) // Relocates a File or Folder to the specified directory
}

/**
 * File System API used as a router for the DataHandler. This class creates the file structure allowing us to easily retrieve all files/folders from a specific directory.
 * This class also acts as a middleman to move and delete files/folders - this is done merely for simplicity to provide a central place to manage both the file structure and the content of the directories.
 */
export class FileSystem<File extends F_Entry, Folder extends F_Entry> implements FileSystemAPI<File, Folder> {
	//#region Class Initialization - set initial properties

	// Only ONE update function will ever be needed, as the user can only have one directory open at a time
	private updateFn: FolderUpdateFunction<File, Folder>
	private updateFnFolder: number

	private folders: {
		[index: number]: Array<File | Folder>
	}

	private allFiles: Array<File>
	private allFolders: Array<Folder>

	declare dhs: {
		folder: DataHandlerFile<Folder>
		file: DataHandlerFile<File>
	}

	constructor(dhs: { folder: DataHandlerFile<Folder>; file: DataHandlerFile<File> }) {
		this.dhs = dhs
		this.folders = {}
		this.initialize()
	}

	//#endregion
	//#region Public Data Management - set or update the data

	/**
	 * Parses the datahandler files and folders creating the initial folder structure.
	 * Hooks into the datahandler listeners to update when any changes are made.`
	 */
	public initialize() {
		this.refreshData()

		this.dhs.file.addListener((changedIDs) => {
			for (const id of changedIDs) {
				// Remove the reference to the file if it exists
				const fileIndex = this.allFiles.findIndex((file) => file.id === id)
				if (fileIndex > -1) {
					// Remove from current folders object
					const folderFileIndex = this.folders[this.allFiles[fileIndex].parent_id].findIndex((file) => file.id === id)
					this.folders[this.allFiles[fileIndex].parent_id].splice(folderFileIndex, 1)

					// Remove from all files
					this.allFiles.splice(fileIndex, 1)
				}

				// Add the updated file back to the file references
				const updatedFile = this.dhs.file.get(id)
				this.allFiles.push(updatedFile)

				this.updateFile(id)
			}
		})

		this.dhs.folder.addListener((changedIDs) => {
			this.refreshData()
			// for (const id of changedIDs) {
			// 	// Remove the reference to the file if it exists

			// 	const folderIndex = this.allFolders.findIndex((folder) => folder.id === id)
			// 	if (folderIndex > -1) this.allFolders.splice(folderIndex, 1)

			// 	// Add the updated file back to the file references
			// 	const updatedFolder = this.dhs.folder.get(id)
			// 	this.allFolders.push(updatedFolder)

			// 	this.updateFolder(id)
			// }
		})
	}

	/**
	 * Refreshes the folders and files by pulling from the datahandler
	 */
	public async refreshData() {
		this.allFolders = this.dhs.folder.getMany()
		this.allFiles = this.dhs.file.getMany()

		console.log("data refreshed", this.dhs)

		for (const folder of this.allFolders) {
			// Set the folder ID key to an array of all files with that parent ID
			this.updateFolder(folder.id!)
		}
	}

	//#endregion
	//#region Component Use Cases - methods used within a component context
	/**
	 * Gets an array populated with the name and IDs of each parent folder
	 * @param pathOf Object to which the path is being requested
	 * @param ignoreRoot If true, the root folder will not be included in the list of folder names.
	 * @returns Object with keys which are the IDs of each parent folder and values which are the names of each parent folder
	 */
	public getFolderPath(
		pathOf: Folder,
		ignoreRoot?: boolean
	): Array<{
		id: number
		name: string
	}> {
		const getParent = (parentOf: Folder): Folder | undefined => {
			if (!parentOf.parent_id) return undefined
			return this.allFolders.find((f) => f.id === parentOf.parent_id)
		}

		let index = 0
		let pathArray: Array<{
			id: number
			name: string
		}> = [
			{
				id: pathOf.id!,
				name: pathOf.name,
			},
		]
		let parent: Folder | undefined

		do {
			parent = getParent(index === 0 ? pathOf : parent!)
			if (parent) {
				const { id, name } = parent
				pathArray.unshift({
					id: id!,
					name,
				})
			}
			index++
		} while (parent !== undefined)

		if (ignoreRoot) {
			pathArray.splice(0, 1)
			return pathArray
		}

		return pathArray
	}

	/**
	 * Calls passed function to set all files under specified Folder - updating the files when any changes are made.
	 * @param folderID Directory ID
	 * @param updateFn Function which is called on initialization and when any file changes are made
	 */
	public useDirectory(folderID: number, updateFn: FolderUpdateFunction<File, Folder>): Folder {
		const folder = this.allFolders.find((f) => f.id === folderID)

		console.log(this.allFiles, this.allFolders)

		if (!folder) throw "No folder with that ID"
		this.addUpdateFn(folderID, updateFn)
		const folderFiles = this.folders[folderID]
		updateFn(folderFiles)
		return folder
	}

	//#endregion
	//#region Data Manipulation - move and delete functionality (here to maintain data)

	/**
	 * Relocates a file or folder to the specified directory
	 * @param moveObject File or folder being moved
	 * @param moveTo Folder the file or folder is being moved to
	 */
	public async move(moveObject: File | Folder, moveTo: Folder) {
		const originalParentID = moveObject.parent_id

		if (moveObject instanceof this.dhs.file.EntryClass) {
			// MOVING FILE
			await this.dhs.file.move(moveObject, moveTo.id!)
			this.updateFolder(originalParentID)
			this.updateFolder(moveTo.id!)
		} else if (moveObject instanceof this.dhs.folder.EntryClass) {
			// MOVING FOLDER
			await this.dhs.folder.move(moveObject, moveTo.id!)
			this.updateFolder(originalParentID)
			this.updateFolder(moveObject.id!)
			this.updateFolder(moveTo.id!)
		}
	}

	/**
	 * Deletes a file or folder, updating the parent directory accordingly
	 * @param deleteObject File or folder being deleted
	 */
	public async delete(deleteObject: File | Folder) {
		const parentID = deleteObject.parent_id
		if (deleteObject instanceof this.dhs.file.EntryClass) {
			await this.dhs.file.deleteEntry(deleteObject)
			this.allFiles = this.allFiles.filter((file) => file.id !== deleteObject.id!)
		} else if (deleteObject instanceof this.dhs.folder.EntryClass) {
			await this.dhs.folder.deleteEntry(deleteObject)
			this.allFolders = this.allFolders.filter((folder) => folder.id !== deleteObject.id!)
		}
		this.updateFolder(parentID)
	}

	/**
	 * Calls the respective datahandler to rename a file or folder.
	 * @param renameObject The file or folder to rename
	 * @param newName The new name
	 */
	public async rename(renameObject: File | Folder, newName) {
		const parentID = renameObject.parent_id
		const fileID = renameObject.id!

		if (renameObject instanceof this.dhs.file.EntryClass) {
			await this.dhs.file.rename(renameObject, newName)
			this.updateFile(fileID)
		} else if (renameObject instanceof this.dhs.folder.EntryClass) {
			await this.dhs.folder.rename(renameObject, newName)
			this.updateFolder(fileID)
		}
		this.updateFolder(parentID)
	}

	//#endregion
	//#region Internal Data Management - called by the class to keep data up to date

	/**
	 * Removes any files or folders which are now undefined
	 */
	private resetAllFilesAndFolders() {
		// Remove any files or folders which are now undefined, this can happen as a result of a recently deleted folder. Without this check an error will occur.
		this.allFolders = this.allFolders.filter((folder) => folder !== undefined)
		this.allFiles = this.allFiles.filter((file) => file !== undefined)
	}

	/**
	 * Updates the contents of a specified folder by parsing through each folder and file within this.allFiles/this.allFolders
	 * @param folderID Folder to update
	 */
	private updateFolder(folderID: number) {
		this.resetAllFilesAndFolders()

		delete this.folders[folderID]
		const allFolderFiles = this.allFiles.filter((file) => file.parent_id === folderID) || []
		const allFolderFolders =
			this.allFolders.filter((folder) => {
				if (!folder) return false
				return folder.parent_id === folderID
			}) || []
		this.folders[folderID] = [...allFolderFiles, ...allFolderFolders]

		if (this.updateFnFolder !== folderID) return
		this.updateFn(this.folders[folderID])
	}

	/**
	 * Updates the the folder with
	 * @param fileID File to update
	 */
	private updateFile(fileID: number) {
		this.resetAllFilesAndFolders()

		const parentID = this.allFiles.find((file) => file.id === fileID)?.parent_id
		if (parentID) this.updateFolder(parentID)
	}

	/**
	 * Adds update function for specified folder
	 * @param folderID ID of the folder which updated
	 * @param updateFn function to call when the folder is updated
	 */
	private addUpdateFn(folderID: number, updateFn: FolderUpdateFunction<File, Folder>) {
		this.updateFn = updateFn
		this.updateFnFolder = folderID
	}
	//#endregion
}
