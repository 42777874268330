<script lang="ts">
	import { onMount } from "svelte"
	import type { ContextMenuConfig } from "../../../types/ContextMenuTypes"

	let ctxMenuRef: HTMLDivElement
	let pos = {
		x: 0,
		y: 0,
	}

	export let closeCtxMenu: () => void
	export let parentBtnAmount: number
	export let parentMenuRect: DOMRect
	export let parentButtonRect: DOMRect
	export let config: ContextMenuConfig

	let buttonRefs: Array<HTMLButtonElement> = []
	let hasTabbed = false
	let opacityAmt = 0

	function initTab() {
		hasTabbed = true
		setTimeout(() => {
			buttonRefs[0].focus()
		})
	}

	function updatePosition() {
		hasTabbed = false
		pos.x = parentMenuRect.right
		pos.y = parentButtonRect.top - convertRemToPixels(0.5)
	}

	function convertRemToPixels(rem) {
		return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
	}

	/**
	 * Restricts the position of the context menu to within the window
	 */
	function restrictCtxMenuToWindow() {
		const { right, bottom } = ctxMenuRef.getBoundingClientRect()
		if (right > window.innerWidth) {
			pos.x = pos.x - (right - window.innerWidth + 8)
			console.log({ pos, parentMenuRect })
			if (pos.x >= parentMenuRect.left && pos.x <= parentMenuRect.right) {
				pos.x = parentMenuRect.left - convertRemToPixels(12)
			}
		}
		if (bottom > window.innerHeight) {
			pos.y = pos.y - (bottom - window.innerHeight + 8)
		}

		if (pos.x < 0) {
			pos.x = 0 + 8
		}
	}

	onMount(() => {
		if (config.isOpen) {
			updatePosition()
			setTimeout(() => {
				opacityAmt = 100
				restrictCtxMenuToWindow()
			})
		}
	})
</script>

{#if config.isOpen}
	<div
		bind:this={ctxMenuRef}
		class="ctxMenu"
		style={`
			opacity: ${opacityAmt}%;
			left: ${pos.x}px;
			top: ${pos.y}px;
			`}
	>
		{#each config.options as option, i}
			<button
				tabindex={parentBtnAmount + i}
				bind:this={buttonRefs[i]}
				on:click={(e) => {
					if ("hasSubmenu" in option) {
						throw new Error("Submenu cannot have another submenu.")
					} else {
						closeCtxMenu()
						option.onClick(e)
					}
				}}
				style={option.style}
				class="icon ctxMenuOpt"
				>{option.title}
			</button>
		{/each}
	</div>
{/if}
<svelte:window
	on:keydown={(e) => {
		if (e.key === "Tab" && config.isOpen && !hasTabbed) initTab()
	}}
/>

<style>
	.ctxMenu {
		transition: left 50ms, right 50ms, opacity var(--transition-duration);
	}
</style>
