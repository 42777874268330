<script lang="ts">
	import TooltipInstance from "./TooltipInstance.svelte"
	import { TooltipController } from "./TooltipController"

	let content = undefined
	let pointing: "left" | "top"
	let style: string
	let position: {
		top: number
		left: number
	} = undefined

	TooltipController.subscribe((ctx) => {
		content = ctx.content
		position = ctx.position
		pointing = ctx.pointing
		style = ctx.style
	})
</script>

<div class="tooltip-container">
	{#if content && position}
		<TooltipInstance {content} {position} {pointing} />
	{/if}
</div>

<style>
	.tooltip-container {
		width: 100vw;
		height: 100vh;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
	}
</style>
