import type { DataHandler, Entry } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import { RPCError } from "../../../rpc-client"

export class PageInit<User extends Entry<any>> {
	declare datahandlers: Array<DataHandler<any>>
	declare userDatahandler: DataHandler<User>
	declare isInitRPC: boolean
	declare isDatahandlersRefreshsed: boolean
	declare apiURL: string
	declare setUser: (user: User) => void

	declare onLoadCallbacks: Array<Function>

	constructor(
		datahandlers: Array<DataHandler<any>>,
		userDatahandler: DataHandler<User>,
		apiURL: string,
		setUserFn: (user: User) => void
	) {
		this.datahandlers = datahandlers
		this.userDatahandler = userDatahandler
		this.isInitRPC = false
		this.apiURL = apiURL
		this.setUser = setUserFn
		this.onLoadCallbacks = []
	}

	async postInit(fn: () => Promise<void> | void) {
		this.onLoadCallbacks.push(fn)
	}

	private async onLoadFinished() {
		for (const cb of this.onLoadCallbacks) {
			await cb()
		}
	}

	async initPageLoad(skipPull?: boolean) {
		if (!this.isInitRPC) await this.initRPC()
		try {
			await this.setCurrentUser()
			if (!skipPull && !this.isDatahandlersRefreshsed) await this.refreshData()
			await this.onLoadFinished()
			return true
		} catch (e) {
			if (e instanceof RPCError && e.statusCode == 403) {
				console.error("Invalid login")
				return false
			} else {
				throw e
			}
		}
	}

	async onUserLogin() {
		await this.setCurrentUser()
	}

	async onUserLogout() {
		this.isDatahandlersRefreshsed = false
		await LuxedoRPC.api.login.logout_logout()
	}

	public async initRPC() {
		await LuxedoRPC.connect({
			apiUrl: this.apiURL,
		})
		this.isInitRPC = true
	}

	private async setCurrentUser() {
		const id = await LuxedoRPC.api.user.account_get_current_user()
		await this.refreshUserDataHandler()
		const user = this.userDatahandler.get(id)
		this.setUser(user)
		this.isDatahandlersRefreshsed = false
	}

	// #region DATAHANDLERS

	private async refreshUserDataHandler() {
		await this.userDatahandler.pull()
	}

	private async refreshData(): Promise<void> {
		return new Promise((res, rej) => {
			const promises = this.datahandlers.map((dh) => {
				return dh.pull()
			})
			Promise.all(promises)
				.then(() => {
					this.isDatahandlersRefreshsed = true
					res()
				})
				.catch(rej)
		})
	}

	// #endregion
}
