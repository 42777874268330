import { fabric } from "fabric"
import { Mode, ModeOptions } from "../../modules/mode"
import { EditorClass } from "../.."
import { CanvasAsset, Path } from "../../asset"

export interface PathDrawOptions extends ModeOptions {
	/** Style options for the path */
	fabricOptions?: fabric.IPolylineOptions

	/** Style options for the path while in a draw path mode */
	tempFabricOptions?: fabric.IPolylineOptions

	/** If true, the first point will link to the last point */
	closed?: boolean

	/** What should happen on rightclick? Defaults to complete */
	rightClickBehavior?: "complete" | "cancel" | "nothing"

	/** Assign the first point instead of waiting for a click */
	firstPoint?: fabric.Point

	/** Bind your path to use the x/y coordinates of another object */
	relativeOrigin?: CanvasAsset

	/**  */
	initiator?: "Mask" | "Shape" | "Animation"
}

export interface DrawPath extends Mode<PathDrawOptions>, PathDrawOptions {
	/** Add the asset to the editor per the options */
	complete(): void
}

export class DrawPath extends Mode<PathDrawOptions> {
	closePathRange = 5
	cancellable: boolean = true

	defaultHoverCursor: string
	defaultCursor: string

	path: Path
	constructor(editor: EditorClass, options: PathDrawOptions) {
		super(editor, options)

		this.path = new Path(
			editor,
			{
				points: options.firstPoint ? [options.firstPoint] : [],
				relativeOrigin: options.relativeOrigin,
			},
			options.tempFabricOptions
		)

		this.tempFabricOptions = options.tempFabricOptions
		this.fabricOptions = options.fabricOptions ?? Path.DefaultOptions
		this.relativeOrigin = options.relativeOrigin

		this.rightClickBehavior = options.rightClickBehavior || "nothing"
		this.closed = options.closed
		this.initiator = options.initiator
	}

	get modeTitle() {
		if (this.initiator) return `Drawing Path for ${this.initiator}`
		else return `Drawing Path`
	}

	get points(): fabric.Point[] {
		return this.path.points
	}

	protected _canvasMouseover: (e: fabric.IEvent<MouseEvent>) => any
	protected canvasMouseover(e: fabric.IEvent<MouseEvent>): any {
		if (this.points.length < 1) return
		const nextPoint = e.absolutePointer.subtract(new fabric.Point(this.path.left, this.path.top))
		this.previewNext(nextPoint)
		this.previewClose(nextPoint)
		this.editor.canvas.requestRenderAll()
	}

	protected _canvasClick: (e: fabric.IEvent<MouseEvent>) => any
	protected canvasClick(e: fabric.IEvent<MouseEvent>): any {
		if (e.button == 3) {
			if (this.rightClickBehavior == "nothing") return
			if (this.rightClickBehavior == "cancel") return this.cancel()
			return this.complete()
		} else if (e.button != 1) {
			return
		}

		const nextPoint = e.absolutePointer.subtract(new fabric.Point(this.path.left, this.path.top))

		if (this.points.length > 1) {
			const range = e.absolutePointer.subtract(this.points[0])
			if (Math.abs(range.x) < 10 && Math.abs(range.y) < 10) {
				this.closed = true
				return this.complete()
			}
		}

		this.path.insert(nextPoint)

		this.editor.canvas.requestRenderAll()
	}

	protected onActivate(options: PathDrawOptions): void {
		this.path.forceVisibility(true)
		this.editor.canvas.addTemporaryObject(this.path)

		this.defaultCursor = this.editor.canvas.defaultCursor
		this.defaultHoverCursor = this.editor.canvas.hoverCursor

		this.editor.canvas.hoverCursor = "crosshair"
		this.editor.canvas.defaultCursor = "crosshair"

		this.setSelection()

		this._canvasMouseover = this.canvasMouseover.bind(this)
		this.editor.canvas.on("mouse:move", this._canvasMouseover)

		this._canvasClick = this.canvasClick.bind(this)
		this.editor.canvas.on("mouse:down", this._canvasClick)
	}

	protected onComplete(): void {
		if (this.fabricOptions) this.path.setOptions(this.fabricOptions)

		if (this.closed) {
			this.path.points.push(this.path.points[0])
		}
	}

	protected onDeactivate(): void {
		this.editor.selection.set(this.priorSelection)

		this.editor.canvas.hoverCursor = this.defaultHoverCursor ?? "move"
		this.editor.canvas.defaultCursor = this.defaultCursor ?? "default"

		this.path.forceVisibility(undefined)
		this.editor.canvas.clearTemporaryObjects()

		if (this.tempLine) {
			this.editor.canvas.remove(this.tempLine)
		}

		if (this.tempLineClose) {
			this.editor.canvas.remove(this.tempLineClose)
		}

		this.editor.canvas.off("mouse:move", this._canvasMouseover)
		this.editor.canvas.off("mouse:down", this._canvasClick)

		this.editor.canvas.requestRenderAll()
	}

	private tempLine: fabric.Polyline
	protected previewNext(point: fabric.Point) {
		const len = this.path.points.length

		const prevPt = this.path.toAbsolutePoint(this.path.points[len - 1])
		const nextPt = this.path.toAbsolutePoint(point)

		if (this.tempLine) {
			this.tempLine.points[0] = prevPt
			this.tempLine.points[1] = nextPt
			this.editor.canvas.requestRenderAll()
			return
		}

		this.tempLine = new fabric.Polyline([prevPt, nextPt], {
			strokeUniform: true,
			strokeWidth: this.path.strokeWidth,
			stroke: this.path.stroke,
			opacity: 0.4,
			objectCaching: false,
		})
		this.editor.canvas.addTemporaryObject(this.tempLine)

		this.editor.canvas.requestRenderAll()
	}

	private tempLineClose: fabric.Polyline
	protected previewClose(point: fabric.Point) {
		if (!this.closed) return

		const len = this.path.points.length
		if (len < 2) return

		const nextPt = this.path.toAbsolutePoint(point)

		if (this.tempLineClose) {
			this.tempLineClose.points[0] = nextPt
			return
		}

		const firstPt = this.path.toAbsolutePoint(this.path.points[0])
		this.tempLineClose = new fabric.Polyline([nextPt, firstPt], {
			strokeUniform: true,
			strokeWidth: this.path.strokeWidth,
			stroke: this.path.stroke,
			strokeDashArray: [10, 10],
			opacity: 0.4,
			objectCaching: false,
		})

		this.editor.canvas.addTemporaryObject(this.tempLineClose)
	}

	/**
	 * Await for the user to complete the drawing or cancel it
	 * resolves or rejects with the final pointset
	 * @param options
	 * @returns
	 */
	static async CreateAsync(editor: EditorClass, options: PathDrawOptions): Promise<Path> {
		const mode = editor.mode.set(this, options)

		return new Promise((res, rej) => {
			mode.onexit = (m, cancelled) => {
				if (cancelled) return rej(mode.path)
				if (mode.points.length < 2) return rej(mode.path)
				res(mode.path)
			}
		})
	}
}
