<script lang="ts">
	export let strokeColor = "black"
</script>

<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 149">
	<g id="Layer_50" data-name="Layer 50">
		<ellipse
			stroke={strokeColor}
			class="cls-1 svg-stroke"
			cx="124.5"
			cy="74.5"
			rx="112.5"
			ry="62.5"
		/>
		<circle stroke={strokeColor} class="cls-1 svg-stroke" cx="124.5" cy="74.5" r="50" />
	</g>
</svg>

<style>
	.cls-1 {
		fill: none;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 24px;
	}
</style>
