import { fabric } from "fabric"
import { Corners } from "./pointsUtil"

function round(num) {
	return Math.round(num * 10000000000) / 10000000000
}

type C4F = [
	tl_x: number,
	tl_y: number,
	tr_x: number,
	tr_y: number,
	br_x: number,
	br_y: number,
	bl_x: number,
	bl_y: number
]

function toTopLeftOriginSrc(srcPts: Corners, dstPts: Corners): { srcPts: Corners; dstPts: Corners } {
	if (srcPts[0].x == 0 && srcPts[0].y == 0) return { srcPts, dstPts }

	const offset = {
		x: srcPts[0].x,
		y: srcPts[0].y,
	}

	const outputSrc = []
	const outputDst = []
	for (const index of [0, 1, 2, 3]) {
		outputSrc.push(new fabric.Point(srcPts[index].x - offset.x, srcPts[index].y - offset.y))
		outputDst.push(new fabric.Point(dstPts[index].x - offset.x, dstPts[index].y - offset.y))
	}

	return { srcPts: outputSrc as Corners, dstPts: outputDst as Corners }
}

function toTopLeftOrigin(pts: Corners): Corners {
	const offset = {
		x: fabric.util.array.min(pts, "x"),
		y: fabric.util.array.min(pts, "y"),
	}

	const out = []
	for (const pt of pts) {
		out.push(pt.subtract(offset))
	}

	return out as Corners
}

function getNormalizationCoefficients(srcPts: Corners, dstPts: Corners, isInverse: boolean) {
	if (isInverse) {
		var tmp = dstPts
		dstPts = srcPts
		srcPts = tmp
	}
	const r1: C4F = [srcPts[0].x, srcPts[0].y, 1, 0, 0, 0, -1 * dstPts[0].x * srcPts[0].x, -1 * dstPts[0].x * srcPts[0].y]
	const r2: C4F = [0, 0, 0, srcPts[0].x, srcPts[0].y, 1, -1 * dstPts[0].y * srcPts[0].x, -1 * dstPts[0].y * srcPts[0].y]
	const r3: C4F = [srcPts[1].x, srcPts[1].y, 1, 0, 0, 0, -1 * dstPts[1].x * srcPts[1].x, -1 * dstPts[1].x * srcPts[1].y]
	const r4: C4F = [0, 0, 0, srcPts[1].x, srcPts[1].y, 1, -1 * dstPts[1].y * srcPts[1].x, -1 * dstPts[1].y * srcPts[1].y]
	const r5: C4F = [srcPts[2].x, srcPts[2].y, 1, 0, 0, 0, -1 * dstPts[2].x * srcPts[2].x, -1 * dstPts[2].x * srcPts[2].y]
	const r6: C4F = [0, 0, 0, srcPts[2].x, srcPts[2].y, 1, -1 * dstPts[2].y * srcPts[2].x, -1 * dstPts[2].y * srcPts[2].y]
	const r7: C4F = [srcPts[3].x, srcPts[3].y, 1, 0, 0, 0, -1 * dstPts[3].x * srcPts[3].x, -1 * dstPts[3].x * srcPts[3].y]
	const r8: C4F = [0, 0, 0, srcPts[3].x, srcPts[3].y, 1, -1 * dstPts[3].y * srcPts[3].x, -1 * dstPts[3].y * srcPts[3].y]

	var matA: C4F[] = [r1, r2, r3, r4, r5, r6, r7, r8]
	var matB: number[] = dstPts.flatMap((pt) => {
		return [pt.x, pt.y]
	})
	var matC: number[][]

	try {
		matC = numeric.inv(numeric.dotMMsmall(numeric.transpose(matA), matA))
	} catch (e) {
		console.log(e)
		return [1, 0, 0, 0, 1, 0, 0, 0]
	}

	var matD = numeric.dotMMsmall(matC, numeric.transpose(matA))
	var matX = numeric.dotMV(matD, matB)
	for (var i = 0; i < matX.length; i++) {
		matX[i] = round(matX[i])
	}
	matX[8] = 1

	return matX
}

export class PerspectiveTransformer {
	srcPts: Corners
	dstPts: Corners

	protected coeffs: number[]
	protected coeffsInv: number[]

	protected srcBounds: ImgBounds
	protected dstBounds: ImgBounds
	public baseWidth: number
	public baseHeight: number
	public outputWidth: number
	public outputHeight: number

	protected sourceCanvas: HTMLCanvasElement
	protected srcCtx: CanvasRenderingContext2D
	protected destCanvas: HTMLCanvasElement
	protected dstCtx: CanvasRenderingContext2D

	constructor(srcPts, dstPts) {
		this.srcPts = toTopLeftOrigin(srcPts)
		this.dstPts = toTopLeftOrigin(dstPts)
		this.coeffs = getNormalizationCoefficients(this.srcPts, this.dstPts, false)
		this.coeffsInv = getNormalizationCoefficients(this.srcPts, this.dstPts, true)

		this.srcBounds = _calcBounds(this.srcPts)
		this.baseWidth = this.srcBounds.width
		this.baseHeight = this.srcBounds.height

		this.dstBounds = _calcBounds(this.dstPts)
		this.outputWidth = this.dstBounds.width
		this.outputHeight = this.dstBounds.height

		this.sourceCanvas = document.createElement("canvas")
		this.sourceCanvas.width = this.srcBounds.width
		this.sourceCanvas.height = this.srcBounds.height
		this.srcCtx = this.sourceCanvas.getContext("2d", {
			willReadFrequently: true,
		})

		this.destCanvas = document.createElement("canvas")
		this.destCanvas.width = this.dstBounds.width
		this.destCanvas.height = this.dstBounds.height
		this.dstCtx = this.destCanvas.getContext("2d")
	}

	transform(x, y) {
		var coordinates = []
		coordinates[0] =
			(this.coeffs[0] * x + this.coeffs[1] * y + this.coeffs[2]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1)
		coordinates[1] =
			(this.coeffs[3] * x + this.coeffs[4] * y + this.coeffs[5]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1)
		return coordinates
	}

	transformInverse(x, y) {
		var coordinates = []
		coordinates[0] =
			(this.coeffsInv[0] * x + this.coeffsInv[1] * y + this.coeffsInv[2]) /
			(this.coeffsInv[6] * x + this.coeffsInv[7] * y + 1)
		coordinates[1] =
			(this.coeffsInv[3] * x + this.coeffsInv[4] * y + this.coeffsInv[5]) /
			(this.coeffsInv[6] * x + this.coeffsInv[7] * y + 1)
		return coordinates
	}

	getTransformedImage(image: HTMLImageElement | HTMLVideoElement) {
		this.transformImage(image)
		return this.destCanvas
	}

	transformImage(image: HTMLImageElement | HTMLVideoElement): void {
		this.dstCtx.clearRect(0, 0, this.dstBounds.width, this.dstBounds.height)
		this.srcCtx.clearRect(0, 0, this.srcBounds.width, this.srcBounds.height)
		this.srcCtx.drawImage(image, 0, 0, this.srcBounds.width, this.srcBounds.height)
		const img_raw = this.srcCtx.getImageData(0, 0, this.srcBounds.width, this.srcBounds.height)
		const imgData = new ImageData(this.dstBounds.width, this.dstBounds.height)

		var _w_mult_src = img_raw.width * 4,
			_w_mult_dst = imgData.width * 4

		for (var dst_x = 0; dst_x < this.dstBounds.width; dst_x++) {
			for (var dst_y = 0; dst_y < this.dstBounds.height; dst_y++) {
				var src_pt = this.transformInverse(dst_x, dst_y)
				var src_x = parseInt(src_pt[0]),
					src_y = parseInt(src_pt[1])
				// If the source pixel actually wound up being within the source image. For those out-of-quad
				// transparent dst pixels this will not fire.
				if (
					this.srcBounds.left <= src_x &&
					src_x <= this.srcBounds.right &&
					this.srcBounds.top <= src_y &&
					src_y <= this.srcBounds.bottom
				) {
					// If we are here, we need to plant the pixel from src to the right place in dst
					var index_src = src_y * _w_mult_src + src_x * 4,
						index_dst = dst_y * _w_mult_dst + dst_x * 4
					// Copy all four values
					imgData.data[index_dst] = img_raw.data[index_src]
					imgData.data[index_dst + 1] = img_raw.data[index_src + 1]
					imgData.data[index_dst + 2] = img_raw.data[index_src + 2]
					imgData.data[index_dst + 3] = img_raw.data[index_src + 3]
				}
			}
		}

		this.dstCtx.putImageData(imgData, 0, 0)
	}
}

function _calcBounds(points: fabric.Point[]): ImgBounds {
	var minX: number = fabric.util.array.min(points, "x") || 0,
		minY: number = fabric.util.array.min(points, "y") || 0,
		maxX: number = fabric.util.array.max(points, "x") || 0,
		maxY: number = fabric.util.array.max(points, "y") || 0
	return {
		left: minX,
		top: minY,
		right: maxX,
		bottom: maxY,
		width: Math.ceil(maxX - minX),
		height: Math.ceil(maxY - minY),
	}
}

type ImgBounds = {
	left: number
	top: number
	right: number
	bottom: number
	width: number
	height: number
}

//#region NUMERIC SHIM
/** Not sure why but importing numeric doesn't seem to work, so here's a shim that does its best. */
export namespace numeric {
	export function dim(x) {
		var y, z
		if (typeof x === "object") {
			y = x[0]
			if (typeof y === "object") {
				z = y[0]
				if (typeof z === "object") {
					return [x.length, y.length].concat(dim(z))
				}
				return [x.length, y.length]
			}
			return [x.length]
		}
		return []
	}

	export function _foreach2(x, s, k, f) {
		if (k === s.length - 1) {
			return f(x)
		}
		var i,
			n = s[k],
			ret = Array(n)
		for (i = n - 1; i >= 0; i--) {
			ret[i] = _foreach2(x[i], s, k + 1, f)
		}
		return ret
	}

	export function cloneV(x) {
		var _n = x.length
		var i,
			ret = Array(_n)

		for (i = _n - 1; i !== -1; --i) {
			ret[i] = x[i]
		}
		return ret
	}

	export function clone(x) {
		if (typeof x !== "object") return x
		var V = cloneV
		var s = dim(x)
		return _foreach2(x, s, 0, V)
	}

	export function diag(d) {
		var i,
			i1,
			j,
			n = d.length,
			A = Array(n),
			Ai
		for (i = n - 1; i >= 0; i--) {
			Ai = Array(n)
			i1 = i + 2
			for (j = n - 1; j >= i1; j -= 2) {
				Ai[j] = 0
				Ai[j - 1] = 0
			}
			if (j > i) {
				Ai[j] = 0
			}
			Ai[i] = d[i]
			for (j = i - 1; j >= 1; j -= 2) {
				Ai[j] = 0
				Ai[j - 1] = 0
			}
			if (j === 0) {
				Ai[0] = 0
			}
			A[i] = Ai
		}
		return A
	}

	export function rep(s, v, k) {
		if (typeof k === "undefined") {
			k = 0
		}
		var n = s[k],
			ret = Array(n),
			i
		if (k === s.length - 1) {
			for (i = n - 2; i >= 0; i -= 2) {
				ret[i + 1] = v
				ret[i] = v
			}
			if (i === -1) {
				ret[0] = v
			}
			return ret
		}
		for (i = n - 1; i >= 0; i--) {
			ret[i] = rep(s, v, k + 1)
		}
		return ret
	}

	export function identity(n) {
		return diag(rep([n], 1, 0))
	}

	export function inv(a) {
		var s = dim(a),
			abs = Math.abs,
			m = s[0],
			n = s[1]
		var A = clone(a),
			Ai,
			Aj
		var I = identity(m),
			Ii,
			Ij
		var i, j, k, x
		for (j = 0; j < n; ++j) {
			var i0 = -1
			var v0 = -1
			for (i = j; i !== m; ++i) {
				k = abs(A[i][j])
				if (k > v0) {
					i0 = i
					v0 = k
				}
			}
			Aj = A[i0]
			A[i0] = A[j]
			A[j] = Aj
			Ij = I[i0]
			I[i0] = I[j]
			I[j] = Ij
			x = Aj[j]
			for (k = j; k !== n; ++k) Aj[k] /= x
			for (k = n - 1; k !== -1; --k) Ij[k] /= x
			for (i = m - 1; i !== -1; --i) {
				if (i !== j) {
					Ai = A[i]
					Ii = I[i]
					x = Ai[j]
					for (k = j + 1; k !== n; ++k) Ai[k] -= Aj[k] * x
					for (k = n - 1; k > 0; --k) {
						Ii[k] -= Ij[k] * x
						--k
						Ii[k] -= Ij[k] * x
					}
					if (k === 0) Ii[0] -= Ij[0] * x
				}
			}
		}
		return I
	}

	export function dotMMsmall(x, y) {
		var i, j, k, p, q, r, ret, foo, bar, woo, i0
		p = x.length
		q = y.length
		r = y[0].length
		ret = Array(p)
		for (i = p - 1; i >= 0; i--) {
			foo = Array(r)
			bar = x[i]
			for (k = r - 1; k >= 0; k--) {
				woo = bar[q - 1] * y[q - 1][k]
				for (j = q - 2; j >= 1; j -= 2) {
					i0 = j - 1
					woo += bar[j] * y[j][k] + bar[i0] * y[i0][k]
				}
				if (j === 0) {
					woo += bar[0] * y[0][k]
				}
				foo[k] = woo
			}
			ret[i] = foo
		}
		return ret
	}

	export function dotMV(x, y) {
		var p = x.length,
			i
		var ret = Array(p)
		for (i = p - 1; i >= 0; i--) {
			ret[i] = dotVV(x[i], y)
		}
		return ret
	}

	export function dotVV(x, y) {
		var i,
			n = x.length,
			i1,
			ret = x[n - 1] * y[n - 1]
		for (i = n - 2; i >= 1; i -= 2) {
			i1 = i - 1
			ret += x[i] * y[i] + x[i1] * y[i1]
		}
		if (i === 0) {
			ret += x[0] * y[0]
		}
		return ret
	}

	export function transpose(x) {
		var i,
			j,
			m = x.length,
			n = x[0].length,
			ret = Array(n),
			A0,
			A1,
			Bj
		for (j = 0; j < n; j++) ret[j] = Array(m)
		for (i = m - 1; i >= 1; i -= 2) {
			A1 = x[i]
			A0 = x[i - 1]
			for (j = n - 1; j >= 1; --j) {
				Bj = ret[j]
				Bj[i] = A1[j]
				Bj[i - 1] = A0[j]
				--j
				Bj = ret[j]
				Bj[i] = A1[j]
				Bj[i - 1] = A0[j]
			}
			if (j === 0) {
				Bj = ret[0]
				Bj[i] = A1[0]
				Bj[i - 1] = A0[0]
			}
		}
		if (i === 0) {
			A0 = x[0]
			for (j = n - 1; j >= 1; --j) {
				ret[j][0] = A0[j]
				--j
				ret[j][0] = A0[j]
			}
			if (j === 0) {
				ret[0][0] = A0[0]
			}
		}
		return ret
	}
}
//#endregion
