import "luxedo-canvas" // DONT DELETE
import type { Calibration } from "luxedo-data"
import { openConfirmOverlay, openOverlay } from "svelte-comps/overlay"
import MaskEditor from "./MaskEditor.svelte"

export function openMaskEditorOverlay(calibration: Calibration) {
	openOverlay(MaskEditor, {
		classHeading: "no-underline",
		classOverlay: "no-pad",
		beforeClose: () =>
			new Promise<void>((res, rej) => {
				openConfirmOverlay({
					buttons: {
						confirm: {
							text: "Close",
							onClick: res,
						},
						deny: {
							text: "Cancel",
							onClick: rej,
						},
					},
					prompt: ["Are you sure you want to exit?", "Any unsaved masks will not be saved."],
				})
			}),
		props: {
			calibration,
		},
	})
}
