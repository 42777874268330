// export const POLL_INTERVAL_FAST = 0.5
export const POLL_INTERVAL_FAST = 2 // for development

export const POLL_INTERVAL_SLOW = 5

export const POLL_SLOWDOWN_AFTER_S = 30

const LOG_LEVEL = {
	ERROR: 0,
	INFO: 1,
	DEBUG: 2,
}
Object.freeze(LOG_LEVEL)
export { LOG_LEVEL }

export const GPQ_ROUTE = "/gpq"
export const CSRF_ROUTE = "/csrf_token"
