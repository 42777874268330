<script lang="ts">
	import { ArrowBack } from "svelte-comps/icons"

	export let onBack: () => void
</script>

<div class="flex-row submenu-heading">
	<button on:click={() => onBack()} class="icon back-button">
		<ArrowBack />
	</button>
	<slot />
</div>

<style>
	.submenu-heading {
		align-items: center;
		height: 4rem;
		margin-bottom: 1rem;
	}

	.back-button {
		height: 2rem;
		margin-right: 0.5rem;
	}

	.back-button > :global(svg) {
		height: 1.5rem;
		width: fit-content;
	}
</style>
