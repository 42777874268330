export class DevOpsError extends Error {
	name = "Device Operation Error"
	originalError: Error
	constructor(msg, originalError?) {
		super(msg)
		this.originalError = originalError
	}
}

export const CALIBRATION_ERROR_CODES = {
	default: "C-401", // Any other calibration error (aka - who knows what happened here)
	active_to_waiting: "C-402", // If the calibration process has started, but the pnc state goes from capturing to waiting
	missing_camera: "C-403", // If device_on_error returns missing_camera
	device_disconnected: "C-404", // If the front-end loses connection with the device
	should_be_capturing: "C-405", // If the pnc status is not capturing when it should be
	unknown_but_online: "C-406", // If the pnc status is reporting, but the calibration failed
	backend_error_prefix: "C-3", // The prefix for if an error occured while processing the data
}

export class CalibrationError extends Error {
	name = "Calibration Error"

	declare code: string
	declare description: string

	constructor(msg: string, code?: string, description?: string) {
		super(msg)
		this.code = code
		this.description = description
	}
}

export class CalibrationFailure extends Error {
	name = "Calibration Failure"

	declare images: Array<string>
	declare description: string

	constructor(images: Array<string>, msg?: string, description?: string) {
		super(msg ?? "Calibration failed...")
		this.images = images
		this.description = description
	}
}
