import { type BaseDeviceRawData, Device, type DeviceStatusAppearance, StatusColors } from "."
import type { EidosFirmware, StatusRPiDevice } from "./DeviceRPi"

type StatusBrowserDevice = StatusRPiDevice | "INIT"
type EidosBrowserDevice = EidosFirmware

export interface DeviceBrowserRawData extends BaseDeviceRawData {
	status: StatusBrowserDevice
	type_id: "dev_synth" | "dev_caxedo"
	product_id?: number
	proj_dim_w?: number
	proj_dim_h?: number
	cam_dim_w?: number
	cam_dim_h?: number
	eidos: EidosBrowserDevice
}

export interface DeviceBrowser extends Device<DeviceBrowserRawData> {
	_eidos: EidosBrowserDevice
	_status: StatusBrowserDevice
	typeId: "dev_synth" | "dev_caxedo"
}

export class DeviceBrowser extends Device<DeviceBrowserRawData> {
	constructor(data: DeviceBrowserRawData) {
		super(data)
	}

	protected importResolution(data: DeviceBrowserRawData) {
		this.resX = data.proj_dim_w ?? 0
		this.resY = data.proj_dim_h ?? 0
	}

	protected importData(data: DeviceBrowserRawData): void {
		super.importData(data)
	}

	protected exportData(): Partial<DeviceBrowserRawData> {
		return {
			name: this.name,
			ui_color: this._color,
			proj_dim_h: this.resY == 0 ? undefined : this.resY,
			proj_dim_w: this.resX == 0 ? undefined : this.resX,
		}
	}

	protected statusAppearanceMap: Record<StatusBrowserDevice, DeviceStatusAppearance> = {
		OFF: { text: "Offline", color: StatusColors.off },
		LOST: { text: "Offline", color: StatusColors.off },
		NEW: { text: "Offline", color: StatusColors.off },
		INIT: { text: "Setting Up", color: StatusColors.initializing },
		UPDATING: { text: "Updating...", color: StatusColors.updating },
		IDLE: { text: "Idle", color: StatusColors.idle },
		PROJECT: { text: "Playing", color: StatusColors.playing },
		PROJ_TT: { text: "Playing", color: StatusColors.playing },
		PNC: { text: "Capturing...", color: StatusColors.projectAndCapture },
		DORMANT: { text: "Searching...", color: StatusColors.initializing },
	}

	// #region ================= Getters =================

	get isOnline() {
		const status = this._status
		return (
			status == "IDLE" ||
			status == "PROJ_TT" ||
			status == "PROJECT" ||
			status == "PNC" ||
			status == "DORMANT"
		)
	}

	get isReady() {
		return (
			this._status == "IDLE" ||
			this._status == "PROJ_TT" ||
			this._status == "PROJECT" ||
			this._status == "DORMANT"
		)
	}

	// #endregion ============== Getters =================

	isUpdateAvailable(isBeta?: boolean): boolean {
		return false
	}

	update(): void {
		throw new Error("Cannot update a LuxLink device")
	}

	static ONLINE_STATUSES = ["IDLE", "PROJ_TT", "PROJECT", "PNC", "DORMANT", "INIT"]
}
