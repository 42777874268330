<script lang="ts">
	import { type Folder, Media } from "luxedo-data"
	import { GridIcon } from "svelte-comps/icons"
	import MediaTile from "../../reusable/file-system/tiles/MediaTile.svelte"
	import FolderTile from "../../reusable/file-system/tiles/FolderTile.svelte"
	import { MediaLibraryController } from "./MediaLibraryController"

	export let searchInput: string
	export let filterUpdater
	export let filterOperation: (media: Array<Media | Folder | any>) => Array<Media | Folder | any>

	let media: Array<Media | Folder> = []
	let dirMedia: Array<Media | Folder> = []

	MediaLibraryController.setDirectoryUpdateListener((newMedia) => {
		dirMedia = newMedia
		filterMedia()
	})

	function onFolderClick(folder: Folder) {
		MediaLibraryController.updateActiveDirectory(folder.id)
	}

	function filterMedia() {
		if (searchInput) {
			media = [
				...dirMedia.filter((media) => media.name.toLowerCase().includes(searchInput.toLowerCase())),
			]
		} else media = [...dirMedia]

		media = filterOperation(media)
	}

	// #endregion

	$: (searchInput || !searchInput || filterUpdater) && filterMedia()
</script>

<div id="library-list">
	{#if media.length}
		<div class="library-list">
			{#each media as m (m.id)}
				{#if m instanceof Media}
					<MediaTile media={m} />
				{:else}
					<FolderTile onClick={onFolderClick} folder={m} />
				{/if}
			{/each}
		</div>
	{:else}
		<div class="empty-indicator">
			<GridIcon />
			<div class="empty-text">
				Upload videos, images or audio to use when creating your scenes. <br /> Click
				<button
					on:click={() => MediaLibraryController.openNewMediaOverlay()}
					class="create-quick-action">Upload Media</button
				> to upload media into this folder.
			</div>
		</div>
	{/if}
</div>

<style>
</style>
