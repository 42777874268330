<script lang="ts">
	import { DataHandlerFolder, type Folder, type Media, Scene } from "luxedo-data"
	import UrlBar from "../../../reusable/file-system/UrlBar.svelte"
	import SearchBarInput from "../../../reusable/input/SearchBarInput.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { FileSystemMedia } from "../../../../modules/file-system/FileSystemMedia"
	import { MediaLibraryController } from "../MediaLibraryController"
	import { getCurrentUser } from "../../../../stores/UserStore"
	import MediaLibraryFilter from "./MediaLibraryFilter.svelte"

	export let searchInput: string = undefined

	let isSearchOpen: boolean = false

	let activeFolder: Folder
	let path: Array<{ id: number; name: string }>
	let root: { id: number; name: string } = {
		id: getCurrentUser().directories.media,
		name: "My Media",
	}

	MediaLibraryController.subscribe((ctx) => {
		path = ctx.path
		activeFolder = ctx.activeFolder
	})

	function onSearch(searchValue: string) {
		searchInput = searchValue
	}

	function onCancelSearch() {
		searchInput = undefined
	}

	/**
	 * Handles when a dragged object is dropped onto a path element
	 * @param e DragEvent object (contains dataTransfer data)
	 * @param id Id of the folder being dropped onto
	 */
	async function handleDrop(e: DragEvent, content: Folder | Media | Scene, moveToId: number) {
		e.preventDefault()

		if (content instanceof Scene) return

		try {
			const folder = DataHandlerFolder.get(moveToId)
			await FileSystemMedia.move(content, folder)
			Toast.success("File moved successfully.")
		} catch (error) {
			Toast.error(`Unable to move file, please try again later.`)
		}
	}

	async function createFolder() {
		const id = await DataHandlerFolder.createEntry({
			name: "New Folder",
			parent_id: activeFolder.id,
		})
		await FileSystemMedia.refreshData()
		setTimeout(() => {
			const folderTile = document.getElementById(`folder-tile-${id}`)
			folderTile.scrollIntoView({
				behavior: "smooth",
			})
			const label = folderTile.querySelector(".tile-label-container .tile-label") as HTMLSpanElement
			label.contentEditable = "true"
			const range = document.createRange()
			range.selectNodeContents(label)
			if ("blur" in document.activeElement) (document.activeElement as HTMLButtonElement).blur()
			const currentSelection = window.getSelection()
			currentSelection.removeAllRanges()
			currentSelection.addRange(range)
			folderTile.classList.add("focused")
			setTimeout(() => {
				;(label as HTMLInputElement).focus()
			})
			setTimeout(() => {
				folderTile.classList.remove("focused")
			}, 2500)
		})
	}
</script>

<div id="library-options">
	<h3>My Media</h3>
	<button class="create-button outline-button alt" on:click={createFolder}>Create Folder</button>
	<button class="create-button outline-button" on:click={MediaLibraryController.openNewMediaOverlay}
		>Upload Media</button
	>
	<UrlBar
		root={root}
		path={path}
		updateActiveDirectory={MediaLibraryController.updateActiveDirectory}
		handleDrop={handleDrop}
	/>
	<MediaLibraryFilter />
	<SearchBarInput bind:isOpen={isSearchOpen} onClose={onCancelSearch} onSearch={onSearch} />
</div>

<style>
	h3 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-text-light);
	}

	.create-button {
		margin-left: 1rem;
		padding: 0rem 1rem;
	}

	#library-options {
		display: flex;
		flex-direction: row;
		padding: 1rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		align-items: center;
		white-space: nowrap;

		position: sticky;
		top: 0;
		z-index: 10;
		box-shadow: var(--shadow-small);
	}

	#library-options :global(.current-path) {
		margin: 0 1rem;
	}
</style>
