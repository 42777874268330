import { AnimationPreset } from "."
import { EditorAsset } from "../../asset"
import { Keyframe, KeyframeList } from ".."

export class PresetKeyframes<T extends EditorAsset> extends KeyframeList {
	preset: AnimationPreset<T>

	constructor(preset: AnimationPreset<T>, compiledKeyframes: Keyframe[]) {
		super()
		this.preset = preset
		this.keyframes = compiledKeyframes
	}

	serialize(forExport?: boolean) {
		return this.keyframes
	}
}
