/**
 * @file asset/canvas/Ellipse.ts
 * @author Josiah Eakle 2023
 * @description An extension of the fabric ellipse with Editor data. Based on ./Polygon.ts
 */

import { fabric } from "fabric"
import { EditorClass } from "../.."
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../modules/serialize"
import { type AssetOptions, CanvasAsset } from ".."

interface EllipseOptions extends AssetOptions {
	/** The radius (in pixels) in the X axis */
	rx: number
	/** The radius (in pixels) in the Y axis */
	ry: number
}

export interface Ellipse extends fabric.Ellipse, CanvasAsset {
	/**
	 * Create a regular polygon (with the same side lengths and angles) with the specified number of sides
	 */
}

@CanvasAsset.Mixin
export class Ellipse extends fabric.Ellipse implements Serializable {
	declare editor: EditorClass

	/**
	 * Create a fabric Ellipse object suited for the Luxedo editor
	 * @param options
	 * @param objectOptions fabric options for the ellipse's appearance
	 */
	constructor(editor: EditorClass, options: EllipseOptions, objectOptions?: fabric.IEllipseOptions) {
		super(objectOptions)
		this.editor = editor
		this.initAsset(options)

		this.rx = options.rx
		this.ry = options.ry
		this.width = this.rx * 2
		this.height = this.ry * 2
	}

	scaleTo(width: number, height: number) {
		const wScale = width / this.width
		const hScale = height / this.height

		this.scaleX = wScale
		this.scaleY = hScale
	}

	//#region    ===========================		   	    Serialization		 		==============================

	serialize(forExport?: boolean): Partial<this> {
		const serialdata = convertInstanceToObject(this, {
			forExport,
			propertyGetters: {
				getBaseValue: this.AnimatableProperties,
			},
		})
		return serialdata
	}

	static async loadJSON(editor: EditorClass, data: Partial<Ellipse>, id?: string) {
		const poly = new Ellipse(
			editor,
			{
				rx: data.rx,
				ry: data.ry,
			},
			data
		)

		return poly
	}

	//#endregion =====================================================================================================

	perPixelTargetFind = true
	objectCaching = false
	strokeUniform = true
}

registerSerializableConstructor(Ellipse)
