import { fabric } from "fabric"

import { Mode, ModeOptions } from "../../modules/mode"
import { EditorClass } from "../.."
import { PointControl } from "../../fabric-plugins"
import { CanvasAsset, Path, Ellipse } from "../../asset"
import { POINT_EDIT_DEFAULT } from "./EditPoints"
import { TransformBase } from "./TransformBaseMode"

export interface EllipseEditOptions extends ModeOptions {
	target: Ellipse
	editStyle?: fabric.IEllipseOptions
	pointOptions?: Partial<fabric.Control>
}

export interface EditEllipse extends TransformBase<EllipseEditOptions>, EllipseEditOptions {
	/** Add the asset to the editor per the options */
	complete(): void
}

export class EditEllipse extends TransformBase<EllipseEditOptions> {
	cancellable: boolean = true
	declare target: Ellipse

	//#region    ===========================		   	   		Setup			 		==============================

	constructor(editor: EditorClass, options: EllipseEditOptions) {
		super(editor, options)

		options.editStyle = options.editStyle ?? {}
		options.editStyle = {
			...POINT_EDIT_DEFAULT,
			...options.editStyle,
		}

		this.target = options.target

		if (this.target instanceof Path) {
			throw "Use EditPath instead of EditPoints"
		}

		this.updatePoints()
	}

	get modeTitle() {
		return "Editing Ellipse"
	}

	protected absolutePoints: {
		rx: fabric.Point
		ry: fabric.Point
	}

	protected updatePoints() {
		if (!this.absolutePoints) {
			this.absolutePoints = {
				rx: new fabric.Point(0, 0),
				ry: new fabric.Point(0, 0),
			}
		}

		this.absolutePoints.rx.setFromPoint(this.target.toAbsolutePoint(new fabric.Point(this.target.rx, 0)))
		this.absolutePoints.ry.setFromPoint(this.target.toAbsolutePoint(new fabric.Point(0, -this.target.ry)))
		this.editor.canvas.requestRenderAll()
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Interface					==============================

	protected savedValues: Partial<Ellipse>
	save(): void {
		this.savedValues = {
			angle: this.target.angle,
			rx: this.target.rx,
			ry: this.target.ry,
		}
	}

	loadSave(): void {
		this.target.setOptions(this.savedValues)
		this.editor.canvas.requestRenderAll()
	}

	protected updateTargetPosition() {
		const relativePoint = new fabric.Point(this.target.rx, this.target.ry)
		// Pick a point from the path and determine its exact position
		const anchorPosition = this.target.toAbsolutePoint(relativePoint)

		this.target.width = this.target.rx * 2
		this.target.height = this.target.ry * 2

		// Find the point's absolute position after the change
		const afterPosition = this.target.toAbsolutePoint(relativePoint)
		const diff = afterPosition.subtract(anchorPosition)

		// Adjust the shape in accordance with the change
		this.target.left -= diff.x
		this.target.top -= diff.y
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Implementation		 		==============================

	protected onActivate(options: EllipseEditOptions): void {
		this.save()

		this.setSelection(this.target)
		this.setStyle(this.options.editStyle)
		this.initControls()

		this.onMove = (e) => {
			this.updatePoints()
		}
		this.target.on("moving", this.onMove)
	}

	onMove: (e: fabric.IEvent<MouseEvent>) => any

	protected onDeactivate(): void {
		super.onDeactivate()

		this.restoreStyle()
		this.save()
		this.restoreControls()

		this.target.off(this.onMove)
		this.editor.canvas.requestRenderAll()
	}

	protected onComplete(...args: any): void {
		this.save()
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood		 		==============================
	private oldControls: ControlBlock

	private initControls() {
		this.oldControls = this.target.controls

		this.target.controls = {}
		this.target.controls["rx"] = new PointControl(
			this.absolutePoints.rx,
			this.onPointEdit.bind(this, "rx"),
			this.options.pointOptions
		)
		this.target.controls["ry"] = new PointControl(
			this.absolutePoints.ry,
			this.onPointEdit.bind(this, "ry"),
			this.options.pointOptions
		)

		this.editor.canvas.requestRenderAll()
	}

	/** Update the points and ellipse properties based on the movement of a point (in the absolute space!) */
	private onPointEdit(prop: "rx" | "ry", newAbsolutePt: fabric.Point) {
		const relativePt = this.target.toRelativePoint(newAbsolutePt)

		const newDim = relativePt.distanceFrom(new fabric.Point(0, 0))
		let angle: number

		if (prop == "rx") {
			angle = Math.asin(relativePt.y / newDim) * (180 / Math.PI)
		} else {
			angle = Math.asin(relativePt.x / newDim) * (180 / Math.PI)
		}

		this.target[prop] = newDim
		this.target.angle += angle

		this.updatePoints()
		this.updateTargetPosition()
	}

	private restoreControls() {
		this.target.controls = this.oldControls
	}

	private oldStyle: fabric.IPolylineOptions
	private setStyle(style: fabric.IPolylineOptions) {
		this.oldStyle = {}
		for (const [prop, val] of Object.entries(style)) {
			this.oldStyle[prop] = this.target[prop]
			this.target[prop] = val
		}
	}
	private restoreStyle() {
		if (!this.oldStyle) return
		for (const [prop, val] of Object.entries(this.oldStyle)) {
			this.target[prop] = val
		}
	}

	//#endregion =====================================================================================================
}

type ControlBlock = fabric.Object["controls"]
