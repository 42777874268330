import { fabric } from "fabric"

import { EditorClass } from "../.."
import { CurvedPointData, Path, PathMessageType, PathSubscriber } from "../../asset"
import { EditPoints, PointEditOptions, TransformMode, deletablePointCursorHandler } from "."
import { IEvent } from "fabric/fabric-impl"
import { CurveControl } from "../../fabric-plugins/custom-controls/CurveControl"
import { FabricEvent } from "../../modules/context-menu"
import { calcCenter, deepCopyPoints } from "../../fabric-plugins"

export interface PathEditOptions extends PointEditOptions {
	/** The path to edit */
	target: Path

	/** Optional - point to the subscriber that initiated this edit. This is just used for bookkeeping. */
	caller?: PathSubscriber
}

export interface EditPath extends EditPoints, PathEditOptions {
	/** Add the asset to the editor per the options */
	complete(): void
}

export class EditPath extends EditPoints {
	//#region		  ======================================	 Initialization	  ======================================

	declare target: Path

	public cancellable: boolean = true
	protected cancelOnSelection: boolean = true
	protected unlockSelection: boolean = false

	constructor(editor: EditorClass, options: PathEditOptions) {
		super(editor, {
			...PATH_EDIT_DEFAULTS,
			...options,
		})

		this.target.subscribe(this)

		this.selection = options.target
		this.caller = options.caller
		this.setStyle(options.editStyle)
	}

	//#endregion	======================================	 Initialization	  ======================================
	//#region 		======================================	 Event Handlers	  ======================================

	protected savedCurvedData: CurvedPointData

	/**
	 * Saves the initial path data - used when the operation is cancelled.
	 * Very odd wording for this method...
	 */
	save(): void {
		super.save()
		this.savedCurvedData = { ...this.target.curvedPointData }
	}

	/**
	 * Loads the initial path data - used when the operation is cancelled.
	 */
	loadSave(): void {
		super.loadSave()
		this.target.curvedPointData = { ...this.savedCurvedData }
		this.target.refresh()
		this.target.notifySubscribers("point:edit")
	}

	/**
	 * Update selection if applicable - replacement for _onSelect given odd behavior when selection can be changed.
	 * Used to maintain transform mode while switching transformed object.
	 */
	protected _onClick = (e: IEvent<MouseEvent>) => {
		if (e.target && e.target !== this.target) {
			this.editor.selection.unlock()
			this.editor.selection.set(e.target)
			this.editor.mode.set(TransformMode, {
				functionClearMode: this.clearEditorMode,
			})
		}
	}

	/**
	 * Called by the subscribed path when the path has been modified
	 * @param path the path object
	 * @param messageType the type of operation
	 * @param points the points
	 * @param modifiedIndex the modified point index
	 * @param modifiedValue the new point value
	 */
	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	) {
		if (modifiedIndex === undefined || (path.curvedPointData[modifiedIndex] && path.curvedPointData[modifiedIndex + 1]))
			return
		else {
			this.updatePoints()

			if (!path.curvedPointData[modifiedIndex]) this.resetCurveControl(modifiedIndex)
			if (!path.curvedPointData[modifiedIndex + 1]) this.resetCurveControl(modifiedIndex + 1)

			if (path.closed && modifiedIndex === 0 && !path.curvedPointData[this.points.length - 1])
				this.resetCurveControl(this.points.length - 1)
		}
	}

	/**
	 * Called to maintain internal reference to path points
	 */
	protected updatePoints() {
		this.absolutePoints = deepCopyPoints(this.target.points)

		for (let i = 0; i < this.points.length; i++) {
			this.absolutePoints[i].setFromPoint(this.target.toAbsolutePoint(this.points[i]))
		}

		if (this.isClosed) {
			this.absolutePoints[this.points.length - 1] = this.absolutePoints[0]
		}

		this.editor.canvas.requestRenderAll()
	}

	/**
	 * Called when the mode activates.
	 * Initializes lisetners and custom controls.
	 */
	protected onActivate(options: PathEditOptions): void {
		if (!this.target.registered) this.editor.canvas.addTemporaryObject(this.target)
		this.setSelection(this.target)

		super.onActivate(options)

		this.targetEvents["mouse:up"] = this._onClick
	}

	/**
	 * Called when the user completes the mode.
	 * Saves the new path configuration.
	 */
	protected onComplete(...args: any): void {
		this.save()
	}

	/**
	 * Called when the mode cancels or completes.
	 * Removes listeners and custom controls
	 */
	protected onDeactivate(): void {
		super.onDeactivate()

		if (!this.target.registered) this.editor.canvas.clearTemporaryObjects()

		// this is dumb, but works
		setTimeout(() => {
			if (this.editor.selection.get()[0] instanceof Path) this.editor.selection.clear()
		})

		this.editor.canvas.requestRenderAll()
	}

	/**
	 * Handles whether to render the "create" point while moving the mouse.
	 */
	addPointsMouseMove = (e: FabricEvent) => {
		if (e.e.buttons) return this.clearCreatorControl()
		if (this.hoveredControl) return this.clearCreatorControl()

		const info = this.target.findNearestPoint(e.absolutePointer)

		const threshold = 15 / this.editor.viewport.scale
		const ctrlThreshold = threshold * (e.e.ctrlKey ? 3 : 0)

		const nearestPtDistance = Math.min(...this.absolutePoints.map((e) => info.nearestPoint.distanceFrom(e)))

		if (info.distance > ctrlThreshold) {
			this.clearCreatorControl()
		} else if (!e.e.ctrlKey && nearestPtDistance < 30 / this.editor.viewport.scale) {
			this.clearCreatorControl()
		} else {
			this.setCreatorControl(info.nearestPoint, info.lineIndex + 1, e)
		}
	}

	//#endregion	======================================	 Event Handlers	  ======================================
	//#region   	======================================	 Point Control	  ======================================

	get points(): fabric.Point[] {
		return this.target.points
	}

	protected movePoint(index: number, newPosition: fabric.Point): void {
		this.target.editPoint(index, newPosition)
	}

	/**
	 * Inserts a point at the specified index
	 * @param point the point to add
	 * @param index the index at which to add the point
	 * @param absolutePoint
	 */
	insertPoint(point: fabric.Point, index: number, absolutePoint?: fabric.Point) {
		this.absolutePoints.splice(index, 0, absolutePoint ?? point.clone())
		this.target.insert(point, index)
		this.updatePoints()
		this.initControls()
	}

	/**
	 * Deletes a point at the specified index
	 */
	deletePoint(index: number) {
		const oldPt = this.points[index]
		this.target.delete(index)
		this.target.notifySubscribers("point:delete", index, oldPt)

		this.updatePoints()
		this.initControls()
	}

	/**
	 * Called when the curved point is moved
	 * @param index the index of the standard point
	 * @param point the new position of the control point
	 * @param event the mouse event
	 */
	onCurveEdit = (index: number, point: fabric.Point, event?: MouseEvent) => {
		this.target.curvePoint(index, point)
	}

	//#endregion  ======================================	 Point Control	  ======================================
	//#region     ======================================	Custom Controls	  ======================================

	protected createControl(index: number) {
		let centerPoint: fabric.Point

		const pt1 = this.absolutePoints[index]
		let nextPointIndex = this.points.length - 1 === index ? 0 : index + 1
		const pt2 = this.absolutePoints[nextPointIndex]

		if (this.target.curvedPointData[index + 1]) {
			centerPoint = this.target.toAbsolutePoint(this.target.curvedPointData[index + 1][0]) // assumes cubic
		} else {
			centerPoint = calcCenter([pt1, pt2])
		}

		let options: Partial<fabric.Control> = {
			cursorStyleHandler: deletablePointCursorHandler,
			mouseDownHandler: (e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean => {
				if (!e.altKey) return false
				try {
					this.resetCurveControl(index + 1, true)
				} catch (e) {
					console.warn(e)
					return false
				}
				return true
			},
		}

		const control = new CurveControl(index + 1, centerPoint, this.onCurveEdit, options)
		this.target.controls[`curve${index}`] = control
	}

	/**
	 * Initialized custom controls for the curved points
	 */
	protected initControls(): void {
		super.initControls()

		let lineSegmentAmount = this.points.length - 1

		for (let i = 0; i < lineSegmentAmount; i++) {
			this.createControl(i)
		}

		this.editor.canvas.requestRenderAll()
	}

	/**
	 * Updates the curve control
	 * @param pointIndex
	 */
	resetCurveControl(pointIndex: number, removeCurveData?: boolean) {
		if (removeCurveData) {
			this.target.clearCurve(pointIndex)
		}

		const pt1 = this.absolutePoints[pointIndex - 1]
		const pt2 = this.absolutePoints[pointIndex]

		if (!(pt1 && pt2)) return

		const centerPoint = calcCenter([pt1, pt2])
		const curveControl = this.target.controls[`curve${pointIndex - 1}`] as CurveControl

		if (!curveControl) this.createControl(pointIndex - 1)
		else curveControl.updatePoint(centerPoint)
	}

	//#endregion  ======================================	Custom Controls	  ======================================
}

const PATH_EDIT_DEFAULTS: Partial<PathEditOptions> = {
	allowAddingPoints: true,
	allowDeletingPoints: true,
	minPoints: 2,
}
