/**
 * The animation preset class is designed to add functionality around the AnimatedProperty class
 *
 * Animation presets serve the purpose of animating multiple properties in a way that doesn't require
 * editing keyframes directly. Instead, based on the inputs, the preset will compile into a new set of keyframes
 * which are then assigned to the animatedProperty
 **/

import { EditorClass } from "../.."
import { EditorAsset } from "../../asset"
import { Serializable, convertInstanceToObject } from "../../modules/serialize"
import { Track } from "../../tracks"
import { LocalTimestamp } from "../Animation"
import { PresetKeyframes } from "./PresetKeyframes"

export interface PresetOptions {
	/** The start time of the animation, with 0 being the beginning of the track and 1 being the end */
	startTime: LocalTimestamp

	/** The end time of the animation, with 0 being the beginning of the track and 1 being the end */
	endTime: LocalTimestamp
}

export abstract class AnimationPreset<T extends EditorAsset> implements Serializable {
	startTime: LocalTimestamp
	endTime: LocalTimestamp
	editor: EditorClass

	constructor(editor: EditorClass, track: Track<any>, options: PresetOptions) {
		this.editor = editor
		this.track = track
		this.startTime = options.startTime
		this.endTime = options.endTime

		if (this.onResize) {
			const unbind = this.track.on("track:edittimespan", () => {
				if (!this.track.getRoot()) return unbind.remove()
				this.onResize()
			})
		}
	}

	/** The track which is animated by this preset */
	track: Track<any>

	/**
	 * The properties which this preset generates animations for
	 */
	abstract properties: T["AnimatableProperty"][]

	/** The unique name used to key this animation */
	abstract name: string

	/**
	 * The generated keyframes
	 */
	keyframes: CompiledPresetKeyframes<T>

	/**
	 * Each set of keyframes has a cache - it is VERY IMPORTANT to make sure this is called whenever an aspect of the preset is modified
	 */
	refresh() {
		this.generateKeyframes()
		for (const kfs of Object.values(this.keyframes)) {
			const keyframes = kfs as PresetKeyframes<T>
			keyframes.refresh()
		}
	}

	/**
	 * Called when the track's timestamp is updated
	 */
	onResize?(): void

	/**
	 * Compile the keyframes and set this.keyframe to them
	 */
	abstract generateKeyframes(): void

	abstract type: PresetType

	getValue(property: T["AnimatableProperty"], timestamp: LocalTimestamp) {
		if (!this.keyframes) this.generateKeyframes()

		const ret = this.keyframes[property].interp(timestamp)
		return ret
	}

	//#region 	 ===========================			   Serialization  				==============================

	getDefaultPropertyExports(): { include: string[]; deepCopy: string[]; exclude?: string[] } {
		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		return {
			include: expProps,
			deepCopy: ["keyframes"],
			exclude: ["track"],
		}
	}

	toObject(properties?: string[]): Partial<this> {
		const json = {}

		if (!properties) properties = this.getDefaultPropertyExports().include
		for (const prop of properties) {
			if (prop in this) json[prop] = this[prop]
			else throw new Error(`Unable to find ${prop} in ${this.constructor.name}.`)
		}

		return json
	}

	serializeKeyframes() {
		let serialized = {}
		for (const [name, keyframes] of Object.entries(this.keyframes)) {
			serialized[name] = (keyframes as PresetKeyframes<any>).serialize()
		}
		return serialized
	}

	serialize(forExport?: boolean) {
		let json = {}

		if (forExport) {
			json = convertInstanceToObject(this, {
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
				forExport,
			})
		} else {
			json = convertInstanceToObject(this, {
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
			})
		}

		return json
	}

	//#endregion =========================================================================================
}

export type CompiledPresetKeyframes<T extends EditorAsset> = {
	[prop in T["AnimatableProperty"]]?: PresetKeyframes<T>
}

/**
 * Determines how this set of keyframes interacts with other animations on an asset
 * "base" - These compiled keyframes will be used as the base keyframes for the animated property
 * "offset" - The interpolated value will be added to the animated property's value - resolves first
 * "multiplier" - Multiply the results by the value determined - resolves last
 **/
export type PresetType = "base" | "offset" | "multiplier"
