export default {
	OptomaEH200ST: {
		_NOTES:
			"ALL THE DATA IN THIS FILE IS LIFTED FROM DATASHEETS FOR THE PROJECTOR. THE SAME SHOULD BE FEASIBLE FOR ALL FUTURE PROJECTORS TO ADD.",
		name: "Optoma EH200ST (3000 Lumen)",
		manufacturer: "Optoma",
		native_res: "FHD",
		max_res: "WUXGA",
		throw_ratio: 0.5,
		lumens: 3000,
		display_type: "DLP",
		compatible_res: ["FHD", "WUXGA", "UXGA", "SXGA+", "WXGA+", "WXGA", "SXGA", "XGA", "HDTV"],
	},
	GT1080HDR: {
		name: "Optoma GT1080HDR (3800 Lumen)",
		manufacturer: "Optoma",
		native_res: "FHD",
		max_res: "4KUHD",
		throw_ratio: 0.5,
		lumens: 3800,
		display_type: "DLP",
		compatible_res: ["FHD", "4KUHD"],
	},
	OptomaZH406ST: {
		name: "Optoma ZH406ST (4200 Lumen)",
		manufacturer: "Optoma",
		native_res: "FHD",
		max_res: "4KUHD",
		throw_ratio: 0.5,
		lumens: 4200,
		display_type: "DLP",
		compatible_res: ["HDTV", "WXGA", "WXGA+", "SXGA", "SXGA+", "UXGA", "FHD", "WUXGA", "4KUHD"],
	},
	OptomaZH450ST: {
		name: "Optoma ZH406ST (4200 Lumen)",
		manufacturer: "Optoma",
		native_res: "FHD",
		max_res: "4KUHD",
		throw_ratio: 0.5,
		lumens: 4200,
		display_type: "DLP",
		compatible_res: ["HDTV", "WXGA", "WXGA+", "SXGA", "SXGA+", "UXGA", "FHD", "WUXGA", "4KUHD"],
	},
	OptomaZH606ST: {
		name: "Optoma ZU606TST-W (6000 Lumen)",
		manufacturer: "Optoma",
		native_res: "WUXGA",
		max_res: "4KUHD",
		throw_ratio: 0.79,
		lumens: 6000,
		display_type: "DLP",
		compatible_res: ["FHD", "WUXGA", "4KUHD"],
	},
	_all_other_projectors: {
		name: "Other Projector",
		manufacturer: null,
		native_res: "FHD",
		max_res: "UW5K",
		throw_ratio: null,
		lumens: null,
		display_type: null,
		compatible_res: null,
	},
}
