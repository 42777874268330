import { Entry } from "../entry/Entry"
import { DataHandler } from "./DataHandler"
import type { BundleOf, EntryID, EntryRawData } from "../types"

interface FakeEntry<T extends EntryRawData> extends Entry<T> {
	serverData: T
}

export function mockDatahandler<T extends FakeEntry<any>>(
	dh: DataHandler<any>,
	mockEntries: Array<T>
) {
	const mockEntryIds = mockEntries.map((entry) => entry.id!)

	// @ts-ignore
	const dhFetch = dh.fetch

	async function fetch(specificInstances?: EntryID[]) {
		let entryData = await dhFetch(specificInstances)

		let mockEntryData = {}
		for (const entry of mockEntries) {
			this.entries[entry.id] = entry
			mockEntryData[entry.id] = entry.serverData
		}

		if (!specificInstances) {
			entryData = {
				...entryData,
				...mockEntryData,
			}
		}

		if (specificInstances) {
			for (const id of specificInstances) {
				if (id in mockEntryIds)
					entryData = {
						...entryData,
						[id]: mockEntries.find((entry) => entry.id === id).serverData,
					}
			}
		}

		return entryData
	}

	// @ts-ignore
	dh.fetch = fetch.bind(dh)
}
