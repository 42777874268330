import { DataHandlerFolder, DataHandlerLightshow, DataHandlerScene } from "luxedo-data"
import { getCurrentUser } from "../../../../../stores/UserStore"
import StorageSubscriber from "./Storage_Subscriber.svelte"
import StorageCancel from "./Storage_Cancel.svelte"
import { writable } from "svelte/store"

export const STORAGE_MENUS = {
	Subscriber: StorageSubscriber,
	Cancel: StorageCancel,
}

type StorageSubscriptionCTX = {
	route: keyof typeof STORAGE_MENUS
}

export namespace StoragSubscriptionController {
	const store = writable<StorageSubscriptionCTX>({
		route: "Subscriber",
	})

	export function subscribe(cb: (ctx: StorageSubscriptionCTX) => void) {
		return store.subscribe(cb)
	}

	export function navigateTo(route: keyof typeof STORAGE_MENUS) {
		store.update((ctx) => {
			return { ...ctx, route }
		})
	}
}

export function getUserPlan() {
	const user = getCurrentUser()
	return user.chargebee.storagePlan ?? undefined
}

export function getUserStorage() {
	const user = getCurrentUser()

	let sceneSize = 0
	let mediaSize = DataHandlerFolder.getFolderStorageSize(user.directories.media)

	const allScenes = DataHandlerScene.getMany()
	const allLightshows = DataHandlerLightshow.getMany()

	for (const scene of allScenes) {
		sceneSize += scene.size
	}

	for (const lightshow of allLightshows) {
		sceneSize += lightshow.size
	}

	const spaceUsed = (sceneSize + mediaSize) / (1024 * 1024)
	const spaceAvailable = user.storage.available
	const spaceFree = user.storage.free

	const percentUsed = (spaceAvailable - spaceFree) / (1024 * 1024)
	const percentMedia = mediaSize / spaceAvailable
	const percentScene = sceneSize / spaceAvailable

	return {
		percentUsed,
		percentMedia,
		percentScene,
		spaceUsed,
		spaceAvailable,
		spaceFree,
		sceneStorage: sceneSize,
		mediaStorage: mediaSize,
		base: user.storage.base,
	}
}
