import { RPCClient } from "rpc-client"
import { GPQ } from "./backend/GPQ"
import type {
	LuxedoPlatoAPI,
	LuxedoAdminAPI,
	LuxedoAPI,
	LuxedoDeviceControlAPI,
	LuxedoFontAPI,
	LuxedoLightshowAPI,
	LuxedoLoginAPI,
	LuxedoMediaAPI,
	LuxedoSceneAPI,
	LuxedoSnapshotAPI,
	LuxedoUserAPI,
} from "./routes"
import type { LuxedoDeviceAPI, LuxedoMaskAPI } from "./routes"
import type { LuxedoFilesysAPI } from "./routes"
import type { LuxedoBundlerAPI } from "./routes/bundlers"
import type { LuxedoCakeAPI } from "./routes/cake"
import type { LuxedoTagAPI } from "./routes/tag"

import type { GPQInit } from "./types"
import type { LuxedoPNCAPI } from "./routes/pnc"
import type { LuxedoChargbeeAPI } from "./routes/chargebee"

/**
 * This class implements the backend communications layer for Luxedo
 */
export class GPQClient<T> extends RPCClient<T> {
	protected backend: GPQ

	constructor() {
		super()
		this.backend = new GPQ(this)
	}

	//#region =========== INTERFACE ===========

	/**
	 * Open a connection with the backend
	 * @param config Optional configuration block to point api at a specific domain
	 */
	public async connect(config?: GPQInit) {
		let apiUrl
		if (config && config.apiUrl) apiUrl = config.apiUrl
		return await this.backend.openConnection(apiUrl)
	}

	/**
	 * Terminate / log out session - this just pauses the GPQ polling for now.
	 * This can be used to have a timeout where inactive sessions will stop polling, or something.
	 */
	public terminate() {
		return this.backend.pauseConnection()
	}

	/**
	 * Sets the custom data to be sent with each GPQ request.
	 * See device_control_aquire for example ({maintain_control_of: device_id})
	 * @param key
	 * @param value
	 */
	public addCustomData(key: string, value: string | number) {
		this.backend.addCustomData(key, value)
	}

	/**
	 * Removes the specified key/value pair from the GPQ custom data
	 * @param key
	 */
	public removeCustomData(key: string) {
		this.backend.removeCustomData(key)
	}

	/**
	 * IMPORTANT:
	 * The function that implements RPC with the backend
	 * @param functionName Always take a function name
	 * @param args Can take any number of args (will be determined by APIRoutes)
	 * @returns Always async. Should be formatted to resolve with just the needed data, no extraneous metadata
	 */
	protected async remoteProcedureCall(functionName: string, ...args: any) {
		console.warn(`Calling GPQ route ${functionName} with args: `, { args })

		return await this.backend.callRemoteFunction(functionName, ...args)
	}

	//#endregion

	//#region =========== NAMESPACING (Helps with large APIs) ====

	/**
	 * Various namespaces that will help with making our backend calls more readable
	 * and making writing calls easier
	 */
	public api = {
		all: this.namespaceCaller<LuxedoAPI>(),
		admin: this.namespaceCaller<LuxedoAdminAPI>(),
		cake: this.namespaceCaller<LuxedoCakeAPI>(),
		login: this.namespaceCaller<LuxedoLoginAPI>(),
		user: this.namespaceCaller<LuxedoUserAPI>(),
		device: this.namespaceCaller<LuxedoDeviceAPI>(),
		deviceControl: this.namespaceCaller<LuxedoDeviceControlAPI>(),
		filesys: this.namespaceCaller<LuxedoFilesysAPI>(),
		scene: this.namespaceCaller<LuxedoSceneAPI>(),
		media: this.namespaceCaller<LuxedoMediaAPI>(),
		lightshow: this.namespaceCaller<LuxedoLightshowAPI>(),
		snapshot: this.namespaceCaller<LuxedoSnapshotAPI>(),
		bundlers: this.namespaceCaller<LuxedoBundlerAPI>(),
		fonts: this.namespaceCaller<LuxedoFontAPI>(),
		plato: this.namespaceCaller<LuxedoPlatoAPI>(),
		tag: this.namespaceCaller<LuxedoTagAPI>(),
		pnc: this.namespaceCaller<LuxedoPNCAPI>(),
		chargebee: this.namespaceCaller<LuxedoChargbeeAPI>(),
		mask: this.namespaceCaller<LuxedoMaskAPI>(),
	}

	//#endregion

	//#region =========== IMPLEMENTED BUT OVERLOADABLE ===========

	/**
	 * Handler for endpoint being called by backend
	 * @param functionName The endpoint name
	 * @param args The args to pass into the function
	 */
	public onEndpointCall(functionName: string, ...args: any) {
		console.warn(`Function ${functionName} called by server with args: `, { args })
		if (!(functionName in this.endpoints)) {
			throw RangeError(`Backend tried to call nonexistend endpoint: ${functionName} with ${args}`)
		}

		const calledFunction = this.endpoints[functionName]
		if (calledFunction) calledFunction(...args)
	}

	//#endregion

	public getCsrfToken() {
		return this.backend.csrfToken
	}

	//#region =========== PRIVATE IMPLEMENTATION ===========

	//#endregion
}

export class LuxedoClient extends GPQClient<LuxedoAPI> {}
