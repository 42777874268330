import { Track } from "../tracks"

export * from "./KeyframeList"
export * from "./AnimatedProperty"
export * from "./Animation"
export * from "./presets"

type AnimationErrorReason = "usesKeyframes" | "unknown"

type AnimationErrorOptions = ErrorOptions & {
	track?: Track
	cause?: AnimationErrorReason
}

export class AnimationError extends Error {
	track: Track
	cause: AnimationErrorReason

	constructor(msg: string, opts: AnimationErrorOptions) {
		super(msg, opts)

		this.cause = opts.cause ?? "unknown"
		this.track = opts.track
	}
}
