import { Canvas } from "fabric/fabric-impl"
import { AnimationPreset, PresetKeyframes, PresetType } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { LocalTimestamp, Keyframe, timestampLocalToAbsolute } from "../../animation"
import { Track } from "../../tracks"
import { registerSerializableConstructor } from "../../modules/serialize"
import { EditorClass } from "../.."

export class Scale extends AnimationPreset<CanvasAsset> {
	name = "Scale"
	properties = ["scaleX" as const, "scaleY" as const]
	type: PresetType = "offset"

	scale: number // % scale multiplier

	constructor(
		editor: EditorClass,
		track: Track<CanvasAsset>,
		opts: {
			scale: number
			start: LocalTimestamp
			end: LocalTimestamp
		}
	) {
		const { scale, start, end } = opts
		super(editor, track, {
			startTime: start,
			endTime: end,
		})

		this.scale = scale
		this.generateKeyframes()
	}

	generateKeyframes(): void {
		const keyframes: Keyframe[] = [
			{
				timestamp: this.startTime,
				value: 0,
			},
			{
				timestamp: this.endTime,
				value: this.scale,
			},
		]

		this.keyframes = {
			scaleX: new PresetKeyframes(this, keyframes),
			scaleY: new PresetKeyframes(this, keyframes),
		}
	}

	update(scale: number, start?: LocalTimestamp, end?: LocalTimestamp) {
		if (start) this.startTime = start
		if (end) this.endTime = end
		this.scale = scale

		this.generateKeyframes()
	}

	onResize(): void {
		this.generateKeyframes()
	}

	static async loadJSON(editor: EditorClass, data: Partial<Scale>): Promise<Scale> {
		return new this(editor, data.track, {
			end: data.endTime,
			start: data.startTime,
			scale: data.scale,
		})
	}
}

registerSerializableConstructor(Scale)
