/**
 * Use to listen for media uploading progress.
 */

import { LuxedoRPC } from "luxedo-rpc"
import { writable, type Writable } from "svelte/store"

type ContextType = {
	progress: number
	didComplete?: boolean
	didFail?: boolean
}

export namespace UploadProcessManager {
	const stores: { [index: number]: Writable<ContextType> } = {}

	const setStoreState = (mediaID: number, status: ContextType) => {
		if (!stores[mediaID]) stores[mediaID] = writable(status)
		else stores[mediaID].set(status)
	}

	/**
	 * Use to subscribe to a specific media's processing
	 * @param mediaID the media to listen to
	 * @param updater the callback
	 * @returns
	 */
	export function subscribeTo(mediaID: number, updater: (ctx: ContextType) => void) {
		if (!stores[mediaID])
			stores[mediaID] = writable({
				progress: 1,
			})
		return stores[mediaID].subscribe(updater)
	}

	/**
	 * Use to wait for media processing to complete
	 */
	export async function awaitMediaProcessing(mediaID: number) {
		return new Promise<void>((res, rej) => {
			subscribeTo(mediaID, (ctx) => {
				if (ctx.didComplete) return res()
				if (ctx.didFail) return rej()
			})
		})
	}

	const handleUploadProgress = (mediaID: number, progress: number) => {
		setStoreState(mediaID, { progress })
	}

	const handleComplete = (mediaID: number) => {
		setStoreState(mediaID, { progress: 1, didComplete: true })
	}

	const handleFail = (mediaID: number) => {
		setStoreState(mediaID, { didFail: true, progress: 0 })
	}

	LuxedoRPC.bindEndpoint("mupload_set_progress", handleUploadProgress)
	LuxedoRPC.bindEndpoint("mupload_process_complete", handleComplete)
	LuxedoRPC.bindEndpoint("mupload_process_fail", handleFail)
}
