<script lang="ts">
	import { DataHandlerMask, type Calibration } from "luxedo-data"
	import { AsyncCollapsibleSection } from "svelte-comps/comps"
	import type { CalibratedMask } from "luxedo-data/src/entries/Mask"
	import CalibrationMaskInstance from "./CalibrationMaskInstance.svelte"
	import { openMaskEditorOverlay } from "../../../../../reusable/overlays/mask-editor"
	import { ArrowDown } from "svelte-comps/icons"
	import { MaskSidebarController } from "../../../../../reusable/overlays/mask-editor/sidebar/MaskEditorSidebarController"
	import { tooltip } from "svelte-comps/tooltip"

	const MASK_DESCRIPTION =
		"Masks allow you to control where projected content appears within your projection space. They provide an accurate alignment of projected content within designated areas."

	export let calibration: Calibration

	let masks: Array<CalibratedMask>

	let isExpanded: boolean
	let doMountInstances: boolean = false

	let hasPreviousMasks = false

	DataHandlerMask.addListener(() => refreshData(calibration))

	function refreshData(cal: Calibration) {
		updateMaskList(cal)
		hasPreviousMasks = checkForPreviousMasks()
	}

	function checkForPreviousMasks(): boolean {
		const allMasks = DataHandlerMask.filterByDevice(calibration.device)
		return !!allMasks.length
	}

	function updateMaskList(cal: Calibration) {
		if (!cal) return
		masks = DataHandlerMask.filterByCalibration(cal.id)
	}

	function importMasks() {
		openMaskEditorOverlay(calibration)
		setTimeout(() => {
			MaskSidebarController.goTo("import")
		})
	}

	async function mountMaskInstances() {
		doMountInstances = true
	}

	$: refreshData(calibration)
</script>

<div class="masks-container">
	<AsyncCollapsibleSection beforeExpand={mountMaskInstances} bind:isExpanded>
		<div
			slot="header"
			class="snapshot-heading"
			use:tooltip={{ content: MASK_DESCRIPTION, pointing: "top" }}
		>
			<div class="span">
				<h4>Masks</h4>
				<button class="small icon {isExpanded ? 'open' : ''}"><ArrowDown /></button>
			</div>
			<button
				class="link-button"
				title="Capture New Snapshot"
				on:click|stopPropagation={() => openMaskEditorOverlay(calibration)}
			>
				{#if masks.length}
					Edit Masks
				{:else}
					Create Masks
				{/if}
			</button>
		</div>
		<div class="masks" slot="content">
			{#if masks.length && doMountInstances}
				{#each masks as mask (`${mask.id}-${mask.name}`)}
					<CalibrationMaskInstance maskEntry={mask} {calibration} />
				{/each}
			{:else}
				<p class="info">
					No masks have been configured for this calibration. Click "Create New Masks" {#if hasPreviousMasks}
						or "Import Old Masks"
					{/if} to begin.
				</p>
				<div class="button-container">
					{#if hasPreviousMasks}
						<button class="outline-button alt" on:click|stopPropagation={() => importMasks()}
							>Import Old Masks</button
						>
					{/if}

					<button
						class="outline-button"
						on:click|stopPropagation={() => openMaskEditorOverlay(calibration)}
						>Create New Masks</button
					>
				</div>
			{/if}
		</div>
	</AsyncCollapsibleSection>
</div>

<style>
	.snapshot-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.5rem;
		width: 100%;
	}

	.info {
		width: 100%;
		text-align: center;
		padding: 0.5rem;
		color: var(--color-text);
	}

	.snapshot-heading .link-button {
		color: var(--color-main);
		padding: 0;
	}

	h4 {
		color: var(--color-text);
		font-size: var(--h2);
		margin: 0;
	}

	.masks-container {
		background-color: var(--color-bg);
		border-radius: var(--br);
		padding: 0.5rem;
		margin-top: 1rem;
	}

	.masks {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 0.5rem;
		gap: 0.5rem;
	}

	.masks :global(.mask-instance) {
		width: calc(25% - 0.5rem);
	}

	.masks :global(.tile-container) {
		margin: 0.5rem;
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	div.span {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
