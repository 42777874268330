// This class contains code which makes it easier to traverse string versions and changelogs.

export class Version {
	declare revision_num: number
	declare release_num: number
	declare hotfix_num: number
	declare str: string

	/**
	 * Parse a version number string into its revision, release, and hotfix numbers
	 *
	 * @param {String} version_number Format '2.12.3' or so
	 */
	constructor(version_number) {
		var bits = version_number.split(".")
		this.revision_num = parseInt(bits[0])
		this.release_num = parseInt(bits[1])
		this.hotfix_num = parseInt(bits[2])
		this.str = version_number
	}

	/**
	 * Check if this version is before another one
	 * @param {Version} version
	 *
	 * @returns {Boolean} True if this version is before the provided one, false otherwise.
	 */
	is_before(version) {
		return Version.compare(this, version) < 0
	}

	/**
	 * Check if this version is after another one
	 * @param {Version} version
	 *
	 * @returns {Boolean} True if this version is after the provided one, false otherwise.
	 */
	is_after(version) {
		return Version.compare(this, version) > 0
	}

	/**
	 * Check if this version equals the provided one
	 * @param {Version} version
	 *
	 * @returns {Boolean} True if this version equals the provided one, false otherwise.
	 */
	is_equal(version) {
		return Version.compare(this, version) == 0
	}

	valueOf() {
		// Allows comparison operators. e.g. Version("3.12.1") > Version("3.12.0") will return true. DOES NOT WORK FOR ==, MUST USE THE is_equal FUNCTION
		return this.revision_num * 10000 + this.release_num + this.hotfix_num / 1000
	}

	/**
	 * Determine whether version 1 is equal to, before, or after version 2
	 *
	 * @param {Version} version_1 The first version
	 * @param {Version} version_2 The second version
	 *
	 * @returns {Number} -1 if version_1 is before version_2, 0 if they are equal, 1 if version_1 comes after version_2
	 */
	static compare(version_1, version_2) {
		var comp = version_1.revision_num - version_2.revision_num
		if (comp != 0) return Math.sign(comp)

		comp = version_1.release_num - version_2.release_num
		if (comp != 0) return Math.sign(comp)

		comp = version_1.hotfix_num - version_2.hotfix_num
		return Math.sign(comp)
	}

	/**
	 * Determine whether version 1 is equal to, before, or after version 2
	 *
	 * @param {String} version_1 The first version as a string e.g. "2.6.0"
	 * @param {String} version_2 The second version as a string
	 *
	 * @returns {Number} -1 if version_1 is before version_2, 0 if they are equal, 1 if version_1 comes after version_2
	 */
	static compare_strings(version_1, version_2) {
		var v1 = new Version(version_1),
			v2 = new Version(version_2)

		return Version.compare(v1, v2)
	}

	toString() {
		return this.str
	}
}
