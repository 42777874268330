import EventEmitter from "eventemitter3"
import type { EditorEvent, ListenerFunction } from ".."
import {
	type EventNameEmittable,
	type EventClassDef,
	type EventListenerControls,
	type EventNameOf,
	setListenerNamespace,
	NamedEventListener,
} from "."
import { getEditor } from "../../Editor"

export interface EventedInstance<E extends EditorEvent<any>> {
	/** Emit an event */
	emitEvent(event: E): E
	/** Emit a new event of the specified type with the specified options */
	emitEvent(name: EventNameEmittable<E>, options: E["OptionsType"]): E

	/** Listen for an event of the associated type
	 * @returns Interface with some controls for the bound event, such as a .unbind() function
	 */
	on(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E>

	/**
	 * Listen for an event of the associated type to fire one time
	 * After the event fires once it will be unbound.
	 * @returns Interface with some controls for the bound event, such as a .unbind() function
	 */
	once(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E>

	/**
	 * Get rid of all event listeners still on this instance
	 */
	dispose(): void
}

interface EventedInstanceMixin<E extends EditorEvent<any>> extends EventedInstance<E> {
	_emitter: EventEmitter
	get emitter(): EventEmitter

	/** Emit an event locally to this instance AND to the editor as a whole */
	emitEvent(nameOrEvent: EventNameEmittable<E> | E, options?: E["OptionsType"]): E

	/** Listen for events on this specific class */
	on(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E>

	/** Listen for an ever on this specific class, and clear the function after it has fired once */
	once(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E>

	off<T extends EventClassDef<any>>(listener: NamedEventListener<InstanceType<T>>): void

	dispose(): void
}

/**
 * Class decorator
 * Modifies the class definition to mix in the Evented Instance interface
 */
export function EventedInstance<E extends EditorEvent<any>>(EventClass: EventClassDef<E>) {
	return <T extends ConstructorOf<EventedInstance<E>>>(BaseClass: T): T => {
		return class extends BaseClass implements EventedInstanceMixin<E> {
			_emitter: EventEmitter

			get emitter() {
				if (!this._emitter) this._emitter = new EventEmitter()
				return this._emitter
			}

			/** Emit an event locally to this instance AND to the editor as a whole */
			emitEvent(nameOrEvent: EventNameEmittable<E> | E, options?: E["OptionsType"]): E {
				let event: E = nameOrEvent

				if (typeof nameOrEvent === "string") {
					event = new EventClass(nameOrEvent, options!)
				}

				this.emitter.emit(event.getKey(), event)
				getEditor().emit(event)

				return event
			}

			/** Listen for events on this specific class */
			on(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E> {
				if ((this.emitter as unknown) === "deleted") return

				const listenerData = setListenerNamespace(this.emitter, EventClass, eventName, listener)

				if (this._emitter) this.emitter.addListener(EventClass.name, listenerData.listener)

				return listenerData
			}

			/** Listen for an ever on this specific class, and clear the function after it has fired once */
			once(eventName: EventNameOf<E>, listener: ListenerFunction<E>): EventListenerControls<E> {
				const listenerData = setListenerNamespace(this.emitter, EventClass, eventName, listener)
				this.emitter.addListener(EventClass.name, listenerData.listener)

				return listenerData
			}

			off<T extends EventClassDef<any>>(listener: NamedEventListener<InstanceType<T>>) {
				this.emitter.removeListener(EventClass.name, listener)
			}

			dispose() {
				this.emitter.removeAllListeners()
				this._emitter = "deleted" as unknown as EventEmitter
			}
		}
	}
}

type ConstructorOf<T> = new (...args: any[]) => T
