import { EditorClass } from ".."
import { Path } from "../asset"

/**
 * @file controllers/Paths.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles its paths
 */
export class PathController {
	//#region    ===========================		   Construction & init  	 		==============================

	private editor: EditorClass
	private paths: {
		[id: string]: Path
	} = {}

	constructor(editor: EditorClass) {
		this.editor = editor
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Basic Interface  	 		==============================

	all(): Path[] {
		return Object.values(this.paths)
	}

	isRegistered(path: Path): boolean
	isRegistered(pathID: string): boolean
	isRegistered(path: Path | string) {
		if (typeof path === "string") return path in this.paths
		return path.id in this.paths
	}

	register(path: Path) {
		if (path.id in this.paths) return

		this.paths[path.id] = path
	}

	deregister(path: Path) {
		if (!(path.id in this.paths)) return

		delete this.paths[path.id]
	}

	getPath(pathId: string) {
		if (this.isRegistered(pathId)) return this.paths[pathId]
		return undefined
	}

	visible: boolean = false

	//#endregion =====================================================================================================
}
