<script lang="ts">
	import { DataHandlerSnapshot } from "luxedo-data"
	import { InstructionSteps } from "svelte-comps/instruction-steps"

	export let createOrSelect: "Create your own" | "Working with a professional" = "Create your own"

	export let stepIndex
	export let updateStepIndex: (stepIndex: number) => void
	export let selectedSnapshotId: number

	let resX: number
	let resY: number

	let steps =
		createOrSelect === "Create your own"
			? {
					0: {
						title: "Download your snapshot",
						description: "This will serve as your template when creating your scene.",
					},
					1: {
						title: "Create and decorate your project",
						description: "Create a project with the snapshot resolution.",
					},
					2: {
						title: "Prepare for export",
						description: "Hide your snapshot and export your scene as an MP4.",
					},
					3: {
						title: "Upload your project",
						description: "Upload your masterpiece to begin playing on your Luxedo device!",
					},
				}
			: {
					0: {
						title: "Select your show",
						description: "Browse and select the show you want.",
					},
					1: {
						title: "Download your snapshot",
						description: "This will serve as your template when mapping scene.",
					},
					2: {
						title: "Upload your project",
						description: "Upload your selected show to begin playing on your Luxedo device!",
					},
				}

	function updateResolution() {
		if (createOrSelect === "Working with a professional") return

		if (!selectedSnapshotId) {
			steps = {
				0: {
					title: "Download your snapshot",
					description: "This will serve as your template when creating your scene.",
				},
				1: {
					title: "Create and decorate your project",
					description: `Create a project with the snapshot resolution.`,
				},
				2: {
					title: "Prepare for export",
					description: "Hide your snapshot and export your scene as an MP4.",
				},
				3: {
					title: "Upload your project",
					description: "Upload your masterpiece to begin playing on your Luxedo device!",
				},
			}

			return
		}

		let snapshot = DataHandlerSnapshot.get(selectedSnapshotId)
		resX = snapshot.resolution.w
		resY = snapshot.resolution.h

		steps = {
			0: {
				title: "Download your snapshot",
				description: "This will serve as your template when creating your scene.",
			},
			1: {
				title: "Create your project",
				description: `Create a project with the snapshot resolution ${`(${resX}x${resY})`}.`,
			},
			2: {
				title: "Prepare for export",
				description: "Hide your snapshot and export your scene as an MP4.",
			},
			3: {
				title: "Upload your project",
				description: "Upload your masterpiece to begin playing on your Luxedo device!",
			},
		}
	}

	$: (selectedSnapshotId || !selectedSnapshotId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Creating your Scene</h3>

	<div id="direct-upload-instructions">
		<InstructionSteps onStepClick={updateStepIndex} {steps} index={stepIndex} />

		<!-- <div class="direct-upload-step">
			<span class="step_number {stepIndex === 0 ? 'active' : ''}">1</span>
			<div class="direct-upload-step-text">
				<h3>Download your snapshot</h3>
				This will serve as your template when creating your scene.
			</div>
		</div>

		<div class="direct-upload-step">
			<span class="step_number {stepIndex === 1 ? 'active' : ''}">2</span>

			<div class="direct-upload-step-text">
				<h3>Create a project</h3>
				Create a project with the snapshot resolution (RESXxRESY).
			</div>
		</div>

		<div class="direct-upload-step">
			<span class="step_number">3</span>
			<div class="direct-upload-step-text">
				<h3>Decorate your scene</h3>
				Using your snapshot as a reference decorate your scene.
			</div>
		</div>

		<div class="direct-upload-step">
			<span class="step_number">4</span>
			<div class="direct-upload-step-text">
				<h3>Prepare for export</h3>
				Hide your snapshot and export your scene as an MP4 at the correct resolution.
			</div>
		</div> -->
	</div>
</div>

<style>
	.flex-column {
		flex-basis: 40%;
	}

	#direct-upload-instructions {
		margin-right: 1rem;
		margin-top: 1rem;
		padding-right: 1rem;
		border-right: 1px solid var(--color-border);
		margin-right: 1rem;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
</style>
