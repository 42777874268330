import type { Device } from "luxedo-data"
import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
import NewSceneSelect from "./NewSceneSelect.svelte"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import NewSceneForm from "./standard/NewSceneForm.svelte"

export namespace NewSceneController {
	let overlayID

	export const selectCustom = () => {
		closeSelectionOverlay()
		openOverlay(NewSceneForm, {
			heading: "New Scene",
			classOverlay: "new-scene-overlay",
			classHeading: "no-underline",
			props: {
				createOrSelect: "Working with a professional",
			},
		})
	}

	export const selectStandard = () => {
		closeSelectionOverlay()
		overlayID = openOverlay(NewSceneForm, {
			heading: "New Scene",
			classOverlay: "new-scene-overlay",
			classHeading: "no-underline",
		})
	}

	function closeSelectionOverlay() {
		if (overlayID) closeOverlay(overlayID)
	}

	/**
	 * Opens the new scene form in an overlay
	 * @param options optional config block
	 * @param options.device the relevant device - if passed, this device will be selected in the UI on mount
	 */
	export function openNewSceneOverlay(options?: { device?: Device }) {
		if (options && options.device) SelectedDeviceStore.set(options.device)

		setTimeout(() => {
			overlayID = openOverlay(NewSceneSelect, {
				// heading: "Create a New Scene",
				classOverlay: "new-scene-overlay",
				classHeading: "no-underline",
			})
		})
	}
}
