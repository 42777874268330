<script>
	export let strokeColor = "black"
</script>

<svg data-name="Layer 29" id="Layer_29" viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
	<path
		class="Settingsicon-cls-1 svg-stroke svg-fill"
		d="m196.51,137.53h30.32c.5-4.1.75-8.27.75-12.5s-.26-8.4-.75-12.5h0s-30.32,0-30.32,0h0c-1.86-10.71-6.07-20.61-12.09-29.16l-.09-.03,21.44-21.44c-5.15-6.59-11.09-12.53-17.68-17.68,0,0-21.06,21.06-21.42,21.42-8.53-5.99-18.41-10.18-29.09-12.04,0,0,0-30.32,0-30.32h0c-4.1-.5-8.27-.76-12.5-.76s-8.42.26-12.52.76h.02v30.32h-.02c-10.7,1.87-20.6,6.08-29.14,12.09,0,0-21.44-21.44-21.44-21.44-6.59,5.15-12.53,11.09-17.68,17.68l21.44,21.44c-6.01,8.55-10.23,18.45-12.09,29.17h0s-30.32,0-30.32,0h0c-.5,4.1-.75,8.27-.75,12.5s.26,8.4.75,12.5h30.32c1.86,10.71,6.07,20.62,12.09,29.16l-.09-.03s-21.44,21.44-21.44,21.44c5.15,6.59,11.09,12.53,17.68,17.68l21.44-21.44.08.03c8.55,6.01,18.45,10.22,29.16,12.09,0,0,0,30.32,0,30.32,4.1.5,8.27.75,12.5.75s8.42-.26,12.53-.76h-.03s0-30.32,0-30.32h.03c10.7-1.87,20.6-6.08,29.14-12.09l21.44,21.44c6.59-5.15,12.53-11.09,17.68-17.68,0,0-21.44-21.44-21.44-21.44,6.01-8.55,10.22-18.45,12.09-29.16h0Zm-71.51,29.97c-23.47,0-42.5-19.03-42.5-42.5s19.03-42.5,42.5-42.5,42.5,19.03,42.5,42.5-19.03,42.5-42.5,42.5Z"
		fill={strokeColor}
		stroke={strokeColor}
	/>
</svg>

<style>
	.Settingsicon-cls-1 {
		stroke: #000;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 12px;
	}
</style>
