<script lang="ts">
	import { PauseIcon, PlayIcon, SkipForwardIcon } from "../../../icons"
	import type { Media } from "luxedo-data"
	import type { MediaToolController } from "../MediaToolController"
	import MediaVideoTimeInput from "./MediaVideo_TimeInput.svelte"

	interface Controller extends MediaToolController<any> {}

	export let videoController: Controller
	export let media: Media

	let videoElem: HTMLVideoElement
	let isPlaying: boolean = false
	let timestamp: number

	let updateTimestamp: (newDuration: number) => void // passed upward from child component MediaVideoTimeInput to force updates from the current timestamp
	videoController.subscribe((ctx) => {
		isPlaying = ctx.isPlaying
		timestamp = ctx.timestamp
		if (updateTimestamp) updateTimestamp(ctx.timestamp)
	})

	function initVideoElem() {
		if (!videoElem) return
		videoController.initialize(videoElem, media)
	}

	function handleSpacePress(e: KeyboardEvent) {
		e.preventDefault()
		e.stopPropagation()
		if (e.code === "Space") videoController.play()
	}

	$: videoElem && initVideoElem()
</script>

<div class="video-container">
	<video preload="true" crossorigin="anonymous" bind:this={videoElem} />
	<div class="top-row">
		<div class="video-controls">
			<button
				id="skip-backward-button"
				class="icon skip-button"
				on:click={videoController.skipToStart}
			>
				<SkipForwardIcon />
			</button>
			<button id="play-button" class="icon" on:click={videoController.play}>
				{#if isPlaying}
					<PauseIcon />
				{:else}
					<PlayIcon />
				{/if}
			</button>
			<button
				id="skip-forward-button"
				class="icon skip-button"
				on:click={videoController.skipToEnd}
			>
				<SkipForwardIcon />
			</button>
		</div>
		<MediaVideoTimeInput
			bind:duration={timestamp}
			{media}
			bind:updateDuration={updateTimestamp}
			readonly
		/>
	</div>
	<slot />
</div>
<svelte:document on:keydown={handleSpacePress} />

<style>
	.video-container {
		position: relative;
		width: 100%;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.top-row {
		position: absolute;
		bottom: 1.25rem;
		right: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* width: 100%; */
	}

	.video-controls {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.top-row :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.top-row :global(.input-container) {
		margin: 0;
	}

	.top-row :global(.svg-fill) {
		fill: var(--color-main);
	}

	#skip-backward-button :global(svg) {
		transform: rotate(180deg);
	}

	button {
		width: fit-content;
		padding: 0;
	}

	button :global(svg) {
		height: 1.5rem;
	}

	.skip-button :global(svg) {
		height: 1rem;
	}
</style>
