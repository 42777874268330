<script lang="ts">
	import { DataHandlerDevice, DataHandlerMask, Device } from "luxedo-data"
	import { MaskSidebarController } from "./MaskEditorSidebarController"
	import { MaskEditorController } from "../MaskEditorController"

	let hasPreviousMasks = false

	MaskEditorController.subscribe((ctx) => {
		if (!ctx || !ctx.snapshot) return

		const device = DataHandlerDevice.get(ctx.snapshot.dev_id)
		hasPreviousMasks = checkForPreviousMasks(device)
	})

	function checkForPreviousMasks(device: Device): boolean {
		const allMasks = DataHandlerMask.filterByDevice(device)
		return !!allMasks.length
	}
</script>

<h2>Masks</h2>
<p class="info">
	There are no masks configured for this calibration, click "Create New Masks" {#if hasPreviousMasks}
		or "Import Old Masks"
	{/if} to get started.
</p>

<div class="button-container">
	<button class="outline-button" on:click={() => MaskSidebarController.goTo("new")}
		>Create New Masks</button
	>
	{#if hasPreviousMasks}
		<button class="outline-button alt" on:click={() => MaskSidebarController.goTo("import")}
			>Import Old Masks</button
		>
	{/if}
</div>

<style>
	.button-container {
		flex-direction: column;
		align-items: center;
		margin-top: 1rem;
	}

	.button-container button {
		width: 100%;
		margin-bottom: 0.5rem;
	}
</style>
