import type { APIRoutesBase } from "./types"

/**
 * A singleton for streamlining RPC backend communications
 * Intended to be extended to implement the abstract functions necessary for RPC paradigm.
 *
 * The implementation should not be exported, but should be instantiated as a single instance and imported as needed
 *
 * The APIRoutes Generic should be a type which defines all the backend routes of the server. See tests/testEndpoints.ts
 * for an example.
 */
export abstract class RPCClient<APIRoutes> {
	//#region =========== IMPLEMENTATION ===========

	/**
	 * Open a connection with the backend. Config type should be determined in API implementation.
	 * @param config Configurations block to connect session
	 */
	abstract connect(config?: any): Promise<void>

	/**
	 * Terminate / log out session
	 */
	abstract terminate(): any

	/**
	 * IMPORTANT:
	 * The function that implements RPC with the backend
	 * @param functionName Always take a function name
	 * @param args Can take any number of args (will be determined by APIRoutes)
	 * @returns Always async. Should be formatted to resolve with just the needed data, no extraneous metadata
	 */
	protected abstract remoteProcedureCall(functionName: string, ...args: any): Promise<any>
	//#endregion

	//#region =========== INTERFACE ===============

	/**
	 * Allows you to asynchronously call a function on the backend
	 * Use as:
	 *
	 * 		await this.call.example_backend_function(...args)
	 *
	 * Provides intellisense if an APIRoutes type is defined
	 */
	public call: APIRoutes = this.getCaller<APIRoutes>()

	protected endpoints: Array<(...args) => any> = []

	/**
	 * Bind a function to this session so the backend can call it
	 * @param endpointName The name to expose it to the backend as
	 * @param endpointHandler The function that will handle the calls
	 */
	public bindEndpoint(endpointName: string, endpointHandler: ((...args: any) => any) | undefined) {
		console.warn("Binding endpoint", { endpointName, endpointHandler })
		this.endpoints[endpointName] = endpointHandler
	}

	/**
	 * Optional feature to have namespaced API calls
	 * This allows you to use intellisense with subsections of your API.
	 *
	 * For example if your API has a lot of routes for interacting with the user, you might create a user namespace
	 * that you can call with:
	 * 		this.api.user.user_rename(newName)
	 *
	 * APIRoute must be split into several type files for this to work
	 */
	abstract api: { [index: string]: APIRoutesBase }
	/* Example usage:

	public api = {
		all : this.namespaceCaller<APIRoutes>()
		exampleNamespace : this.namespaceCaller<ExampleAPIRoutesType>()
	} */

	//#endregion

	//#region =========== IMPLEMENTED BUT OVERLOADABLE ===========

	/**
	 * Handler for endpoint being called by backend
	 * @param functionName The endpoint name
	 * @param args The args to pass into the function
	 */
	protected onEndpointCall(functionName: string, ...args: any) {
		if (!(functionName in this.endpoints)) {
			console.warn(`Backend tried to call nonexistend endpoint: ${functionName} with ${args}`)
			return
		}

		console.log("onEndpointCall", { functionName, args })

		const calledFunction = this.endpoints[functionName]
		if (calledFunction) calledFunction(...args)
	}

	//#endregion

	//#region =========== DO NOT TOUCH ===========

	/**
	 * Responsible for calling the backend's RPC routes and returning the processed response
	 * Also handles intellisense
	 */
	protected get callHandler(): ProxyHandler<any> {
		return {
			get(client, functionName) {
				return async (...args: any) => {
					return await client.remoteProcedureCall(functionName, ...args)
				}
			},
		}
	}

	protected getCaller<T>(): T {
		return new Proxy<T | any>(this, this.callHandler)
	}

	protected namespaceCaller<NamespacedAPI extends APIRoutesBase>(): NamespacedAPI {
		return this.getCaller<NamespacedAPI>()
	}

	//#endregion
}
