<script lang="ts">
	import { DataHandlerDevice, DataHandlerSnapshot, DeviceGroup } from "luxedo-data"

	export let selectedSnapshotId: number
	export let selectedDeviceId: number

	let resX: number
	let resY: number

	function updateResolution() {
		const device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			resX = device.resX
			resY = device.resY
		} else {
			const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)
			resX = snapshot.resolution.w
			resY = snapshot.resolution.h
		}
	}

	$: (selectedSnapshotId || selectedDeviceId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Create and decorate your project</h3>
	<p>
		Using the your choice of video editing software, create a new project with the resolution of
		your device
		<span class="highlight">({resX ?? "0"}x{resY ?? "0"})</span>. Import your downloaded snapshot
		and set it as the bottom layer.
	</p>
	<p>
		Mask out your projection space and decorate your scene using your snapshot as a reference. Once
		you are satisfied with your project, move on to the next step.
	</p>
</div>

<style>
	p {
		margin: 1rem 0 0 0;
		color: var(--color-text);
	}

	.highlight {
		color: var(--color-main);
	}
</style>
