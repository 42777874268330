<script lang="ts">
</script>

<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_4_2)">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.52 -61.21L9.52 -0.169991L0.630002 4.96001C-0.0399976 5.34001 -0.0399976 6.31001 0.630002 6.69001L9.52 11.82L9.52 72.88C9.52 76.19 12.2 78.88 15.52 78.88H91.52C94.83 78.88 97.52 76.19 97.52 72.88L97.52 -61.21C97.52 -64.52 94.83 -67.21 91.52 -67.21H15.52C12.21 -67.21 9.52 -64.52 9.52 -61.21Z"
			fill="#2F2B31"
			stroke="#807E83"
			stroke-width="0.25"
			stroke-miterlimit="10"
		/>
	</g>
	<defs>
		<clipPath id="clip0_4_2">
			<rect width="12" height="10" fill="white" transform="matrix(0 -1 1 0 0 12)" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
