<script lang="ts">
	import { DataHandlerDevice, type DeviceGroup, type Device } from "luxedo-data"
	import ProjectorGroupCircle from "./ProjectorGroupCircle.svelte"

	export let group: DeviceGroup
	export let onClick: () => void
	export let deviceIconElem: SVGElement = undefined

	let childDevices: {
		[index: number]: Device
	} = {}

	function getChildDevices() {
		for (const child of group.children) {
			childDevices[child.id] = DataHandlerDevice.get(child.device_id)
		}
	}

	$: group && getChildDevices()
</script>

<div>
	{#if group.children.length > 0 && Object.values(childDevices).length === group.children.length}
		{#each group.children as child}
			{#if childDevices[child.id]}
				<ProjectorGroupCircle deviceColor={childDevices[child.id].color} />
			{/if}
		{/each}
	{:else}
		<ProjectorGroupCircle deviceColor={"var(--color-main-transparent)"} />
	{/if}
</div>

<style>
	div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 2.5rem;
		max-height: 2.5rem;
	}

	div :global(svg) {
		flex: 1;
	}
</style>
