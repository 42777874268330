import { MaskBase, TrackMask } from "../asset"
import { EditorEvent } from "./Event"
import { EventName, NoWildcard } from "./types"

export type MaskEventName = EventName<"added" | "removed" | "renamed">

export interface MaskEventInit {
	trackMask?: TrackMask
	maskBase?: MaskBase
}

export interface MaskEvent extends EditorEvent<MaskEventName>, MaskEventInit {}
export class MaskEvent extends EditorEvent<MaskEventName> {
	OptionsType: MaskEventInit

	constructor(type: NoWildcard<MaskEventName>, options: MaskEventInit) {
		super(type)

		this.trackMask = options.trackMask
		this.maskBase = options.maskBase
	}
}
