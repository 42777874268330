import { type BundleOf, DataHandler } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import { Snapshot, type SnapshotRawData } from "../entries/Snapshot"

class DHSnapshot extends DataHandler<Snapshot> {
	EntryClass = Snapshot

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Snapshot>> {
		const snaps = (await LuxedoRPC.api.snapshot.snapshots_fetch()).snapshots
		return snaps
	}

	protected async pushData(entryData: { [id: number]: Partial<SnapshotRawData> }): Promise<void> {}

	public filterByResolution(width: number, height: number) {
		const all = this.getMany()
		const resSnaps = all.filter((snapshot) => {
			const { w, h } = snapshot.resolution
			if (width === w && h === height) return true
			else return false
		})
		return resSnaps
	}

	public filterByDevice(deviceID: number) {
		const all = this.getMany()
		const deviceSnaps = all.filter((snap) => snap.dev_id === deviceID)
		return deviceSnaps.reverse() ?? []
	}

	public filterByCalibration(calID: number) {
		const all = this.getMany()
		const calSnaps = all.filter((snap) => snap.cal_id === calID)
		return calSnaps.reverse() ?? []
	}

	protected async requestDeleteEntry(entry: Snapshot): Promise<void> {
		return LuxedoRPC.api.snapshot.snapshot_delete(entry.id!)
	}
}

export const DataHandlerSnapshot = new DHSnapshot()
