<script lang="ts">
	import type { Device, DeviceRPi } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import {
		AdvancedSettingsController,
		type AdvancedSettingProperty_Name,
	} from "../ProjectorAdvancedController"
	import { ToggleSwitch } from "svelte-comps/inputs"

	let device: Device
	let availableProperties: Array<AdvancedSettingProperty_Name>

	let cameraExposure: string
	let invertCamera: boolean

	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		availableProperties = AdvancedSettingsController.getAvailableProperties(device)
	})

	AdvancedSettingsController.subscribe((ctx) => {
		if (!ctx.isOnline) return

		invertCamera = ctx.invertCamera
		cameraExposure = String(ctx.cameraExposure)
	})

	/**
	 * Called when the user toggles the invert camera slider
	 * @param newVal boolean - do or do not invert camera
	 */
	function onToggleInvertCamera(newVal: boolean) {
		AdvancedSettingsController.modifyProperty("invertCamera", newVal)
		invertCamera = newVal
	}

	function onCameraExposureChange(val: string) {
		AdvancedSettingsController.modifyProperty("cameraExposure", Number(val))
		cameraExposure = val
	}
</script>

<div class="flex-row projector-heading">
	<h2 class="setting-heading camera">Camera Settings</h2>
</div>
{#if availableProperties.includes("cameraExposure")}
	<div class="select-container">
		<label id="custom-exposure-label" for="camera-exposure">Camera Exposure: </label>
		<select
			id="camera-exposure"
			bind:value={cameraExposure}
			on:change={(e) => onCameraExposureChange(e.currentTarget.value)}
		>
			<option value="-1">Automatic</option>
			<option value="32">Very Low</option>
			<option value="64">Low</option>
			<option value="128">Mid-Low</option>
			<option value="256">Standard</option>
			<option value="512">Mid-High</option>
			<option value="1024">High</option>
			<option value="2048">Very High</option>
		</select>
	</div>
{/if}
{#if availableProperties.includes("invertCamera")}
	<div class="flex-row invert-camera-container">
		<div class="switch-container">
			<span class="label">Invert Camera: </span>
			<ToggleSwitch
				isActive={invertCamera}
				onUpdate={onToggleInvertCamera}
				info={"If your calibration images appear upside down, toggle this to flip the camera orientation."}
			/>
		</div>
	</div>
{/if}

<style>
</style>
