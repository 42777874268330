import deepcopy from "deepcopy"
import { isSerializable, Serializable, Serialized, SerializerMethod } from "."

type Constructor<T extends Serializable> = new (...args: any) => T

export const SERIALIZE_METHOD_NAME = "serialize"

export function defineSerializer<T extends Serializable>(
	Class: Constructor<T>,
	serializationMethod: SerializerMethod<T>
): void {
	if (SERIALIZE_METHOD_NAME in Class) console.warn(`[Serializer] Overriding ${Class.name}.${SERIALIZE_METHOD_NAME}`)

	Object.defineProperty(Class.prototype, SERIALIZE_METHOD_NAME, serializationMethod)
}

interface SerializationOptions<T extends Object> {
	ignoreBranches?: CopyableRoutes<T>
	deepCopyTree?: CopyableRoutes<T>
	deepCopyTreeExceptions?: CopyableRoutes<T>
	customCopyBranches?: {
		[k in keyof T]?: T[k] extends Object ? SerializerMethod<T[k]> : never
	}
}

type CopyableRoutes<T> = T extends Object
	?
			| boolean
			| {
					[k in keyof T]?: CopyableRoutes<T[k]>
			  }
	: boolean

/**
 * Default object serialization routine
 * Allows very granular serialization of an object
 */
export function SerializeObject<T>(target: T, options: SerializationOptions<T>): Object {
	// Recursion base condition ; if we aren't iterating over an object, just return the value
	if (!(typeof target === "object")) return target

	// If the target has its own serialization function, we want to use that instead
	if (isSerializable(target)) return target.serialize()

	const output: Object = {}

	// Iterate through each branch of the target object
	for (const [prop, val] of Object.entries(target)) {
		/* Check if the key is specifically ignored */
		const ignoreBranches = options.ignoreBranches && options.ignoreBranches[prop]
		if (ignoreBranches && ignoreBranches === true) continue

		/* Check if there is a unique copy function for this branch */
		const specialCopyFn = options.customCopyBranches && options.customCopyBranches[prop]
		if (specialCopyFn) {
			const serialContents = specialCopyFn(val)
			output[prop] = serialContents
			continue
		}

		/* Check if there is an exception to specifically do a shallow copy of this branch */
		const deepCopyException = typeof options.deepCopyTreeExceptions === "object" && options.deepCopyTreeExceptions[prop]
		if (deepCopyException === true) {
			output[prop] = val
			continue
		}

		/* Check if the branch is marked for deep copy */
		const deepCopyBranch = typeof options.deepCopyTree === "object" ? options.deepCopyTree[prop] : options.deepCopyTree
		if (deepCopyBranch) {
			/* Deep copy the branch recursively */
			output[prop] = SerializeObject(val, {
				ignoreBranches: ignoreBranches,
				deepCopyTree: deepCopyBranch,
				deepCopyTreeExceptions: deepCopyException,
			})
			continue
		}

		/* Otherwise, shallow copy the output */
		output[prop] = val
	}

	/* Return the fully serialized output */
	return output
}

export function deepCopy<T extends Object>(target: T, options: { properties?: string[]; forExport?: boolean }) {
	let { properties, forExport } = options

	if (typeof target === "function") {
		return undefined
	}

	if (!(typeof target === "object")) {
		return target
	}

	const json: Partial<T> = {}
	if (!properties) properties = Object.keys(target) as (string & keyof T)[]
	for (const prop of properties) {
		if (!(prop in target)) {
			console.warn(`Attempting to serialize ${target.constructor.name} | Cannot find ${prop}.`)
			continue
		}
		const branch = target[prop]

		// If it has a serialize function, use that to deep copy
		if (isSerializable(branch)) {
			json[prop] = branch.serialize(forExport) as any
			continue
		}

		if (target.constructor !== Object) {
			console.warn(
				`${prop} is an instance of ${target.constructor.name} which is not serializable - cannot deep copy`,
				{ target, prop }
			)
			json[prop] = undefined
			continue
		}

		// Finally, for a regular object, use recursion
		json[prop] = deepCopy(branch, options) as any
	}

	return json
}
