import { EditorClass } from "../.."
import { LocalTimestamp, timestampAbsoluteToLocal, Keyframe } from "../../animation"
import { AnimationPreset, PresetKeyframes, PresetOptions } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { registerSerializableConstructor } from "../../modules/serialize"
import { Track } from "../../tracks"

export class FadeIn extends AnimationPreset<CanvasAsset> {
	name = "FadeIn"
	properties = ["opacity" as const]
	type = "multiplier" as const

	duration
	initStart // reference to track initial start for resize
	initDuration // reference to track initial duration for resize

	declare track: Track<CanvasAsset>

	constructor(editor: EditorClass, track: Track<CanvasAsset>, duration: LocalTimestamp) {
		super(editor, track, {
			startTime: 0 as LocalTimestamp,
			endTime: duration,
		})

		this.duration = duration

		this.initStart = this.track.start
		this.initDuration = this.track.duration

		this.generateKeyframes()
	}

	updateDuration(newDuration: LocalTimestamp) {
		this.duration = newDuration
		this.endTime = newDuration

		this.generateKeyframes()
	}

	onResize(): void {
		const absDuration = Math.round(this.initDuration * this.endTime * 10) / 10
		this.endTime = timestampAbsoluteToLocal(absDuration + this.track.start, this.track)

		this.initStart = this.track.start
		this.initDuration = this.track.duration

		this.generateKeyframes()
	}

	generateKeyframes(): void {
		const keyframes: Keyframe[] = []

		keyframes.push({
			timestamp: this.startTime,
			value: 0,
		})

		keyframes.push({
			timestamp: this.endTime,
			value: 1,
		})

		this.keyframes = { opacity: new PresetKeyframes(this, keyframes) }
	}

	static async loadJSON(editor: EditorClass, data: Partial<FadeIn>): Promise<FadeIn> {
		const preset = new this(editor, data.track, data.duration)
		return preset
	}
}

registerSerializableConstructor(FadeIn)
