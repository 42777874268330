<script lang="ts">
	export let textContent: string | Array<string>
	export let align: "center" | "left" | "right" = "center"
</script>

<p class={`para-${align}`}>
	{#if typeof textContent === "string"}
		{@html textContent}
	{:else}
		{#each textContent as text}
			<span>{@html text}</span>
		{/each}
	{/if}
</p>

<style>
	p.para-center {
		text-align: center;
	}

	p.para-left {
		text-align: left;
	}

	p.para-right {
		text-align: right;
	}

	span {
		display: block;
	}
</style>
