<script lang="ts">
	import { GroupOverlapController } from "../steps/blend/GroupOverlapController"

	export let overlapInstance: GroupOverlapController.OverlapInstance
	export let onClick: () => void

	let isSelected: boolean = false

	GroupOverlapController.subscribe(
		(ctx) => (isSelected = overlapInstance.id === ctx.selectedOverlapInstance)
	)

	// let gradientAngle: number

	// let deviceA: DeviceRPi
	// let deviceB: DeviceRPi

	// function updateDevices() {
	// 	if (!overlapInstance) return

	// 	const slotA = overlapInstance.slots[0]
	// 	deviceA = DataHandlerDevice.get(slotA.device_id) as DeviceRPi

	// 	const slotB = overlapInstance.slots[1]
	// 	deviceB = DataHandlerDevice.get(slotB.device_id) as DeviceRPi

	// 	let slotAMid = [slotA.pos_x + slotA.width / 2, slotA.pos_y + slotA.height / 2]
	// 	let slotBMid = [slotB.pos_x + slotB.width / 2, slotB.pos_y + slotB.height / 2]

	// 	gradientAngle = Math.abs(slotBMid[1] - slotAMid[1]) / Math.abs(slotBMid[0] - slotAMid[0])
	// }

	// $: overlapInstance && updateDevices()
</script>

<div
	class="slot-overlap-instance {isSelected ? 'selected' : ''}"
	on:click={() => onClick()}
	style="left: {overlapInstance.rect.left}px; top: {overlapInstance.rect
		.top}px; width: {overlapInstance.rect.right -
		overlapInstance.rect.left}px; height: {overlapInstance.rect.bottom -
		overlapInstance.rect.top}px;"
></div>

<!--  background: linear-gradient({gradientAngle.toFixed(3)}turn, {deviceA.color}, {deviceB.color}) -->

<style>
	.slot-overlap-instance {
		cursor: pointer;
		user-select: none;
		position: absolute;
		border: 4px solid var(--color-border);
		background-color: var(--color-text-light);
		opacity: 0.7;
		z-index: 100;

		transition:
			backdrop-filter 500ms,
			background-color 500ms,
			opacity 500ms;
	}

	.slot-overlap-instance.selected,
	.slot-overlap-instance:hover {
		background-color: var(--color-main);
		opacity: 0.8;
	}
</style>
