<script lang="ts">
	import Paragraph from "../../comps/Paragraph.svelte"
	import type { InputOverlayButton } from "./types"

	export let prompt: string | Array<string> = undefined
	export let buttons: Array<InputOverlayButton>
</script>

<div>
	{#if prompt}
		<Paragraph textContent={prompt} />
	{/if}
	<span class="button-container">
		{#each buttons as button}
			<button class={button.className} on:click={button.onClick}>{button.text}</button>
		{/each}
	</span>
</div>

<style>
	div {
		min-width: 10rem;
		max-width: 40vw;
		width: 100%;
		height: 100%;
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
</style>
