<script lang="ts">
	import CalibrationOverlayAnimation from "./CalibrationOverlay_Animation.svelte"
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { CalibrationOverlayController } from "../CalibrationOverlayController"
	import { ErrorReportIcon } from "svelte-comps/luxedo"

	let progress = 0
	let message
	let description
	let didError

	let unsubscriber = CalibrationOverlayController.subscribe(({ progress: status }) => {
		progress = status.progress
		message = status.message
		description = status.description
		didError = status.didError
	})

	function cancelCalibration() {
		unsubscriber()
		CalibrationOverlayController.cancel()
	}
</script>

<div class="calibration-top">
	{#if didError}
		<ErrorReportIcon />
	{:else}
		<CalibrationOverlayAnimation />
	{/if}

	<ProgressBar width="calc(100% - 8rem)" percentage={progress} />
</div>
<div class="calibration-bottom">
	<h3 class={didError ? "error" : ""}>{@html message ?? "Initializing..."}</h3>
	<p>
		{@html description ??
			`Calibration is the process of shining patterns from a projector in one location, and taking images from a camera in another location, then using mathematical analysis to map the images accurately to the projector.`}
	</p>
</div>

<div class="button-container">
	<button class="link-button" on:click={cancelCalibration}> Cancel </button>
	<!-- TEMP BUTTON -->
	<!-- <button on:click={() => CalibrationController.openTroubleshooter()}>TROUBLESHOOTING</button> -->
</div>

<style>
	h3.error {
		color: var(--color-error);
	}

	.calibration-top :global(.error-report-icon) {
		height: 8rem;
	}

	.calibration-bottom :global(.warn) {
		color: var(--color-main);
	}

	p {
		min-height: 6rem;
	}
</style>
