<script lang="ts">
	/** Very similar to the collapsible section, but with the option to await a function before expanding - this allows for more performant load times. */

	import { ArrowUp } from "../icons"

	export let isExpanded: boolean = false
	export let className: string = ""
	export let beforeExpand: () => Promise<void>

	let contentHeight: number
	let contentContainer: HTMLDivElement

	export async function toggle(expand?: boolean) {
		if (beforeExpand) await beforeExpand()
		setTimeout(() => {
			isExpanded = expand ?? !isExpanded
		})
	}

	const resizeObserver = new ResizeObserver((entries) => {
		const entry = entries[0]
		updateContentHeight(entry.target as HTMLDivElement)
	})

	function updateContentHeight(div: HTMLDivElement) {
		contentHeight = div.offsetHeight
	}

	function initializeResizeObserver(div: HTMLDivElement) {
		if (!div) return

		resizeObserver.observe(contentContainer)
	}

	$: initializeResizeObserver(contentContainer)
</script>

<div class="collapsible-section {isExpanded ? 'expanded' : ''} {className}">
	<div class="section-header" on:click={() => toggle()}>
		{#if $$slots.header}
			<slot name="header" {isExpanded} />
		{:else if $$slots.title}
			<div class="title-container">
				<slot name="title" {isExpanded} />
				<ArrowUp />
			</div>
		{/if}
	</div>
	<div class="section-content-container" style="height: {isExpanded ? contentHeight : 0}px;">
		<div bind:this={contentContainer} class="section-content">
			<slot name="content" />
		</div>
	</div>
</div>

<style>
	.section-header {
		position: relative;
		width: 100%;
		display: flex;
		align-items: start;
		cursor: pointer;
	}

	.collapsible-section {
		position: relative;
		width: 100%;
	}

	.section-content-container {
		position: relative;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;
		height: 0px;
		transition: height 250ms;
	}

	.section-header :global(svg.arrow) {
		transition: transform 250ms;
	}

	.expanded .section-header :global(svg.arrow) {
		transform: rotate(180deg);
	}

	.title-container {
		display: flex;
		flex-direction: row;
	}
</style>
