<script lang="ts">
	import ProjectorIcon from "../../../reusable/icons/ProjectorIcon.svelte"
	import ProjectorInfoAbout from "./about/ProjectorInfo_About.svelte"
	import ProjectorRegistration from "./registration/ProjectorRegistration.svelte"
	import DeviceColorPicker from "./device-color-picker/DeviceColorPicker.svelte"
	import { TextRowSelector } from "svelte-comps/inputs"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openDeviceColorPicker } from "./device-color-picker/DeviceColorPickerContext"
	import { ProjectorRegistrationContext } from "../ProjectorRegistrationContext"
	import {
		DataHandlerDevice,
		type Device,
		DeviceGroup,
		DeviceBrowser,
		DeviceRPi,
		GroupConflictError,
	} from "luxedo-data"
	import ProjectorInfoSync from "./ProjectorInfo_Sync.svelte"
	import { launchLuxLink } from "./LuxLinkLauncher"
	import ProjectorInfoCal from "./calibration/ProjectorInfo_Cal.svelte"
	import { tooltip } from "svelte-comps/tooltip"
	import { openUpdateInformerOverlay } from "../../../reusable/overlays/device-update"
	import ProjectorDownloads from "./downloads/ProjectorDownloads.svelte"
	import ProjectorAdvanced from "./advanced/ProjectorAdvanced.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { asyncConfirmOverlay } from "svelte-comps/overlay"
	import { getCurrentUser } from "../../../../stores/UserStore"
	import { DeviceUpdateManager } from "../../../../modules/device-operation-managers/DeviceUpdateManager"

	const MENU_ORDER = ["Calibration", "Info", "Downloads", "Advanced"]
	const MENU_DEFAULT = {
		Calibration: ProjectorInfoCal,
		Info: ProjectorInfoAbout,
		Advanced: ProjectorAdvanced,
	}

	let MENU_MAP = { ...MENU_DEFAULT }

	let device: Device
	let listenerId: string
	let childDevices: {
		[index: number]: Device
	} = {}

	let activeMenu: keyof typeof MENU_MAP = Object.keys(MENU_MAP)[0] as keyof typeof MENU_MAP
	let deviceIconElem: SVGElement
	let triggerSave: () => Promise<void>

	let isPendingUpdate: boolean
	let isUpdating: boolean

	function openColorInput() {
		openDeviceColorPicker(onColorChange, deviceIconElem)
	}

	async function onColorChange(colorIndex: number) {
		device._color = colorIndex
		await DataHandlerDevice.push([device])
		SelectedDeviceStore.set(device)
	}

	async function triggerUpdate() {
		const [updateVer, isUpdateInBeta] = DeviceUpdateManager.getAvailableUpdate(device as DeviceRPi)
		openUpdateInformerOverlay(device, updateVer, isUpdateInBeta)
	}

	async function reactivateGroup() {
		if (!(device instanceof DeviceGroup)) return

		let conflictingGroups: DeviceGroup[] = []

		// Try to activate the group
		try {
			await device.activate()
		} catch (e) {
			// If it fails with a group conflict, get the list of conflicting groups so we can resolve it
			if (e instanceof GroupConflictError) {
				if (!e.resolvable) {
					Toast.error("ERROR: This group shares devices with an active group which you do not own.")
					return
				}

				conflictingGroups = e.conflictingGroups
				console.warn({ conflictingGroups })
			} else {
				throw e
			}
		}

		// Resolve the group conflict if there are any
		if (conflictingGroups.length > 0) {
			let prompt = [`One or more devices in this group are shared by another active group.\n`]
			prompt.push(`By continuing, the following groups will be deactivated:`)
			for (let group of conflictingGroups) {
				prompt.push(`<b>${group.name}</b>`)
			}
			prompt.push("Are you sure you want to continue?")

			try {
				await asyncConfirmOverlay({
					title: "Group Conflict",
					prompt,
					confirmText: "Continue",
					denyText: "Cancel",
				})
			} catch (e) {
				return
			}

			// Deactivate the conflicting groups
			for (let group of conflictingGroups) {
				await group.deactivate()
			}

			await device.activate()
		}

		Toast.success(`Group ${device.isDeactivated ? "deactivated" : "activated"} successfully!`)
	}

	SelectedDeviceStore.subscribe((dev) => {
		if (listenerId && device) device.removeUpdateListener(listenerId)
		MENU_MAP = { ...MENU_DEFAULT }

		device = dev
		isUpdating = false
		isPendingUpdate = false

		const user = getCurrentUser()
		const isUserBeta = user.hasPriv("beta_opt_in")

		if (device) {
			listenerId = device.addUpdateListener((dev) => {
				device = dev
				isUpdating = device.isUpdating
				setTimeout(() => {
					if (device.isUpdateAvailable(isUserBeta)) {
						isPendingUpdate = true
					} else isPendingUpdate = false
				})
			})
			ProjectorRegistrationContext.set({
				isRegistering: false,
			})

			if (device instanceof DeviceGroup) {
				MENU_MAP["Calibration"] = ProjectorInfoSync
				for (const child of device.children) {
					childDevices[child.id] = DataHandlerDevice.get(child.device_id)
				}
			} else {
				MENU_MAP["Calibration"] = ProjectorInfoCal
			}

			if (device instanceof DeviceRPi && device.compareVersion("3.2.0") >= 0) {
				MENU_MAP["Downloads"] = ProjectorDownloads
				reorderMenu()
			}

			if (!(activeMenu in MENU_MAP)) activeMenu = "Calibration"

			if (device.isUpdateAvailable(isUserBeta)) {
				isPendingUpdate = true
			} else isPendingUpdate = false
		}
	})

	function reorderMenu() {
		const newOrder = {}

		MENU_ORDER.forEach((key) => {
			if (key in MENU_MAP) newOrder[key] = MENU_MAP[key]
		})

		MENU_MAP = { ...newOrder } as typeof MENU_MAP
	}

	let isSaving = false
	async function save() {
		isSaving = true
		await triggerSave()
		isSaving = false
	}
</script>

<div id="projector-info">
	{#if !device}
		<ProjectorRegistration />
	{:else}
		<div class="flex-column">
			<div class="flex-row">
				<DeviceColorPicker />
				<div class="icon-container">
					<ProjectorIcon {device} onClick={openColorInput} bind:deviceIconElem />
					<div class="icon-bg" />

					{#if device instanceof DeviceGroup && Object.values(childDevices).length === device.children.length}
						{#each Object.values(childDevices) as device}
							<div
								style="background-color: {device && 'color' in device ? device.color : ''}"
								class="icon-bg-color"
							/>
						{/each}
					{:else}
						<div style="background-color: {device.color}" class="icon-bg-color" />
					{/if}
				</div>
				<div class="flex-column device-title-row">
					<h1 id="device-name">{device.name}</h1>
					<span class="projector-status">
						<div
							class="projector-status-indicator"
							style="background-color: {device.statusColor};"
						/>
						{device.status}

						{#if device instanceof DeviceGroup && device.isDeactivated}
							<button id="group-reactivate" class="outline-button" on:click={reactivateGroup}>
								Reactivate
							</button>
						{/if}
					</span>
				</div>
			</div>
			{#if isPendingUpdate && !isUpdating && device.isOnline}
				<button
					id="update-device-button"
					class="outline-button"
					on:click={triggerUpdate}
					use:tooltip={{
						content: "An new device version is available. Click now to update your device.",
						pointing: "top",
					}}>Update Now</button
				>
			{/if}
			{#if device instanceof DeviceBrowser && !DeviceBrowser.ONLINE_STATUSES.includes(device._status)}
				<button
					id="LuxLink-launcher"
					class="outline-button"
					on:click={() => launchLuxLink(device instanceof DeviceBrowser ? device : undefined)}
					>Start LuxLink</button
				>
			{/if}
		</div>
	{/if}
	<hr />
	<div class="menu-navigator">
		<TextRowSelector
			bind:selectedOption={activeMenu}
			options={Object.keys(MENU_MAP)}
			optionInfo={{
				Calibration: `Calibration is the process of shining and capturing patterns from your Luxedo in order to accurately map your projection space.`,
				Advanced: `These settings are intended for advanced users or specific troubleshooting scenarios. Most users won't need to tinker with them.`,
			}}
			fontSize="var(--h1)"
		/>

		{#if (triggerSave || isSaving) && device}
			<button on:click={save} disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</button>
		{/if}
	</div>
	<div class="info-container">
		{#if device}
			<svelte:component this={MENU_MAP[activeMenu]} bind:triggerSave />
		{:else}
			<p id="no-selected-device">
				Complete device registration to adjust settings or view device information.
			</p>
		{/if}
	</div>
</div>

<style>
	#projector-info {
		padding: 2rem 2rem 0 2rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	#projector-info > .flex-column > .flex-row > .flex-column {
		overflow: hidden;
	}

	#LuxLink-launcher {
		margin-top: 1rem;
		width: 100%;
	}

	.projector-status {
		line-height: 1rem;
		color: var(--color-text);
		display: flex;
		align-items: center;
	}

	.device-title-row {
		overflow: hidden;
	}

	.projector-status-indicator {
		margin-right: 0.5rem;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 100%;
	}

	h1#device-name {
		font-size: 3rem;
		color: var(--color-text-light);
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.info-container {
		flex-grow: 1;
		position: relative;
		padding: 1rem 0 0 0;
		/* padding: 1rem 2rem 0 2rem; */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		overflow-y: hidden;
	}

	.info-container > :global(div:first-child) {
		overflow-y: auto;
	}

	.menu-navigator {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	#projector-info :global(.text-row-selector button.link-button) {
		border: none;
	}

	.info-container:has(#no-selected-device) {
		justify-content: center;
	}

	#no-selected-device {
		text-align: center;
		font-size: var(--h1);
		color: var(--color-text);
	}

	#update-device-button {
		width: 100%;
		margin-top: 1rem;
	}

	#group-reactivate {
		margin-left: 1rem;
	}

	/* #projector-info :global(.text-row-selector button.link-button) {
		color: var(--color-text-light);
	}

	#projector-info :global(.text-row-selector button.link-button.selected) {
		color: var(--color-main);
	} */
</style>
