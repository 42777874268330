/**
 * 1. Lock the selection
 * 2. Ensure no time change
 */

import { fabric } from "fabric"
import { EditorClass } from "../.."
import { AnimatedProperty, Keyframe } from "../../animation"
import { EventListenerControls } from "../../events/decorators"
import { Track } from "../../tracks"
import { Mode, ModeOptions } from "../../modules/mode/Mode"
import { CanvasAsset } from "../../asset"

export type AnimatableProperty = "left" | "top" | "angle" | "scaleX" | "scaleY" | "opacity"
export type KeyframeList = { [Property in AnimatableProperty]?: Keyframe }

export interface KeyframeEditOptions extends ModeOptions {
	track: Track<CanvasAsset>
	timestamp: number
	keyframes: { [Property in AnimatableProperty]?: Keyframe }
}

export class KeyframeEdit extends Mode<KeyframeEditOptions> {
	cancellable: boolean = true

	public track: Track<CanvasAsset>
	public timestamp: number
	public keyframes: KeyframeList

	private unbindFns: EventListenerControls<any>[] = []

	constructor(editor: EditorClass, options: KeyframeEditOptions) {
		super(editor, options)
		this.selection = options.track.asset
		this.track = options.track
		this.timestamp = options.timestamp
		this.keyframes = options.keyframes
	}

	get modeTitle() {
		return "Editing Keyframe"
	}

	protected onEditedInCanvas = (e: fabric.IEvent<MouseEvent>) => {
		const asset = this.track.asset
		// If dragged, update left and top
		// If scaled, update scaleX or scaleY
		// If rotated, update angle

		// If no animatable property exists, create one for the relevant property
		// otherwise, simply update the animatedproperty

		const useAnimatedProperty = (
			prop: "left" | "top" | "angle" | "scaleX" | "scaleY"
		): [AnimatedProperty<typeof this.track.asset>, number] => {
			let animatedProp: AnimatedProperty<typeof this.track.asset>
			let initialValue: number
			if (this.track.isAnimated()) {
				animatedProp = this.track.animations[prop]
				initialValue = animatedProp.initialEditValue
			} else {
				this.track.animate()
				animatedProp = this.track.animations[prop]
				initialValue = e.transform.original[prop]
			}

			return [animatedProp, initialValue]
		}

		switch (e.action) {
			case "rotate": // Affects ANGLE
				// Rotation values before and after
				let updatedAngle = e.target.angle
				const [animatedAngle, initialAngle] = useAnimatedProperty("angle")

				// Get offset and set/ insert
				const offsetAngle = updatedAngle - initialAngle

				animatedAngle.set(this.timestamp, offsetAngle)

				break
			case "drag": // Affects TOP and LEFT
				console.log(e)

				let updatedTop = e.target.top
				let updatedLeft = e.target.left

				const [animatedTop, initialTop] = useAnimatedProperty("top")
				const [animatedLeft, initialLeft] = useAnimatedProperty("left")

				const offsetTop = updatedTop - initialTop
				const offsetLeft = updatedLeft - initialLeft

				animatedTop.set(this.timestamp, offsetTop)
				animatedLeft.set(this.timestamp, offsetLeft)

				break
			case "scale":
			case "scaleX":
			case "scaleY": // Affects scaleX, scaleY
				let updatedX = e.target.scaleX
				let updatedY = e.target.scaleY

				const [animatedX, initialX] = useAnimatedProperty("scaleX")
				const [animatedY, initialY] = useAnimatedProperty("scaleY")

				const offsetX = updatedX - initialX
				const offsetY = updatedY - initialY

				animatedX.set(this.timestamp, offsetX)
				animatedY.set(this.timestamp, offsetY)

				break
		}
	}

	protected onActivate(options: KeyframeEditOptions): void {
		this.unbindFns.push(this.editor.selection.on("selection:*", () => this.cancel()))
		this.unbindFns.push(this.editor.timeline.on("preview:*", () => this.cancel()))

		if (this.track.asset instanceof CanvasAsset) {
			this.track.asset.on("modified", this.onEditedInCanvas)
		}
	}

	protected onDeactivate(): void {
		for (const ctrls of this.unbindFns) {
			ctrls.remove()
		}
		this.track.asset.off("modified", this.onEditedInCanvas)
	}
}
