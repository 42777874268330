<script lang="ts">
	import { onMount } from "svelte"
	import GroupCanvas from "../../canvas/GroupCanvas.svelte"
	import { GroupEditorController } from "../../GroupEditorController"
	import { GroupOverlapController } from "./GroupOverlapController"

	let slots
	let overlapInstances

	GroupEditorController.Slots.subscribe((ctx) => {
		slots = ctx.slots
	})

	GroupOverlapController.subscribe((ctx) => {
		overlapInstances = ctx.overlapInstances
	})

	function overlapClick(overlapID: string) {
		GroupOverlapController.selectOverlap(overlapID)
	}

	onMount(() => GroupEditorController.Slots.selectSlot(undefined))
</script>

<GroupCanvas {slots} slotOverlaps={overlapInstances} onOverlapClick={overlapClick} />

<style>
</style>
