<script lang="ts">
	import { DataHandlerScene, Scene, type Device, Lightshow } from "luxedo-data"
	import { GridIcon } from "svelte-comps/icons"
	import { ShowLibraryController } from "../../routes/show-library/ShowLibraryController"
	import DesignTile from "../../reusable/file-system/tiles/DesignTile.svelte"
	import { openNewSceneOverlay, openShowOverview } from "../../reusable/overlays"

	export let filterUpdater: number // way to force a filter update
	export let searchInput: string
	export let tagFilter: Array<number> = []
	export let filterOperation: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow>

	let scenes: Array<Scene> = []
	let deviceFilter: Device = undefined

	let isCreatingLightshow = false
	ShowLibraryController.subscribe((ctx) => (isCreatingLightshow = ctx.isEditingLightshow))

	DataHandlerScene.addListener(filterScenes)

	ShowLibraryController.subscribe((ctx) => {
		deviceFilter = ctx.deviceFilter
		if (deviceFilter !== ctx.deviceFilter) filterScenes()
	})

	function getRelevantScenes() {
		if (deviceFilter) scenes = DataHandlerScene.getByDevice(deviceFilter)
		else scenes = DataHandlerScene.getMany()
	}

	function filterScenes() {
		getRelevantScenes()
		if (searchInput) {
			scenes = scenes.filter((scene) =>
				scene.name.toLowerCase().includes(searchInput.toLowerCase())
			)
		}
		if (tagFilter.length) {
			scenes = scenes.filter((scene) => {
				let doesMatch = true
				for (const tagId of tagFilter) {
					if (!scene.tags.includes(tagId)) {
						doesMatch = false
						return
					}
				}
				return doesMatch
			})
		}

		scenes = filterOperation(scenes) as Array<Scene>
	}

	$: (searchInput || !searchInput || tagFilter || filterUpdater) && filterScenes()
</script>

<div id="library-list">
	{#if scenes.length}
		<div class="library-list">
			{#each scenes as design (design.id)}
				{#if !design.new_ver}
					<DesignTile
						showSelectOutline={isCreatingLightshow}
						{design}
						onClick={() => openShowOverview(design)}
					/>
				{/if}
			{/each}
		</div>
	{:else}
		<div class="empty-indicator">
			<GridIcon />
			<div class="empty-text">
				Scenes are rendered videos created using your calibrated snapshot as a guide, allowing you
				to map videos and images onto your projection space. <br /> Click
				<button on:click={() => openNewSceneOverlay()} class="create-quick-action"
					>Create Scene</button
				>
				to create your first Scene!
			</div>
		</div>
	{/if}
</div>

<style>
	#library-list {
		margin-bottom: 1rem;
	}
</style>
