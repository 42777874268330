<script lang="ts">
	import { onMount } from "svelte"
	import { tooltip } from "../tooltip"

	export let isActive: boolean
	export let disabled = false
	export let info: string = undefined

	export let onUpdate: (newVal: boolean) => void = undefined

	let handleClick = () => {
		if (disabled) return
		isActive = !isActive

		if (onUpdate) onUpdate(isActive)
	}

	function handleKeyPress(e: KeyboardEvent) {
		if (e.key === "Enter") {
			handleClick()
		}
	}
</script>

<div
	class="toggle-switch {disabled ? 'disabled' : ''}"
	tabindex="0"
	on:click={handleClick}
	on:keydown={handleKeyPress}
	use:tooltip={{ content: info, pointing: "top" }}
>
	<div class="toggle-switch-indicator-container {isActive ? 'active' : ''}">
		<div class="toggle-switch-indicator" />
	</div>
</div>

<style>
	.toggle-switch {
		cursor: pointer;
		height: 1rem;
		width: 2rem;
		border: 1px solid var(--color-text);
		border-radius: 100vw;
		position: relative;
		overflow: hidden;
	}

	.toggle-switch.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.toggle-switch.disabled .toggle-switch-indicator {
		background-color: var(--color-text);
	}

	.toggle-switch-indicator {
		background-color: var(--color-text-light);
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 100vw;
		margin-top: auto;
		margin-bottom: auto;
	}

	.toggle-switch-indicator-container {
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc(-100% + 0.75rem);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		transition:
			left 250ms ease-in-out,
			background-color 250ms ease-in-out;
	}

	.toggle-switch-indicator-container.active {
		background-color: var(--color-main);
		left: 0;
	}
</style>
