<script lang="ts">
	import { onMount } from "svelte"

	const BORDER_OFFSET = 16 // how far the tooltip needs to be from the edge of the page

	export let content: string
	export let style: string = ""
	export let pointing: "top" | "left"
	export let position: {
		left: number
		top: number
	} // general position (will need to be adjusted based on width and height of tooltip container)

	let exactPosition: {
		left: number
		top: number
	}

	let clientHeight: number
	let clientWidth: number

	let opacity = 0

	let elem: HTMLDivElement

	let transformLeft: number = 0
	let transformTop: number = 0

	function calculateExactPosition(
		position: { left: number; top: number },
		tooltipWidth,
		tooltipHeight
	): { left: number; top: number } {
		if (pointing === "left") {
			return {
				left: position.left + 12,
				top: position.top - tooltipHeight / 2,
			}
		} else if (pointing === "top") {
			return {
				left: position.left - tooltipWidth / 2,
				top: position.top + 12,
			}
		}
	}

	function ensureWithinBounds(): { left: number; top: number } {
		if (!elem) return

		const rect = elem.getBoundingClientRect()
		const pageHeight = window.innerHeight
		const pageWidth = window.innerWidth

		if (rect.left < BORDER_OFFSET) {
			transformLeft = BORDER_OFFSET - rect.left
		}

		if (rect.bottom > pageHeight - BORDER_OFFSET) {
			transformTop = pageHeight - (rect.bottom + BORDER_OFFSET)
		}
	}

	function animateOpacity() {
		opacity = 1
	}

	onMount(animateOpacity)

	$: exactPosition = calculateExactPosition(position, clientWidth, clientHeight)
	$: elem && setTimeout(ensureWithinBounds)
</script>

<div
	class="tooltip pointing-{pointing}"
	style="left: {exactPosition.left}px; top: {exactPosition.top}px; opacity: {opacity}; --transform-left: {transformLeft}px; --transform-top: {transformTop}px; {style}"
	bind:clientHeight
	bind:clientWidth
	bind:this={elem}
>
	{content}
</div>

<style>
	.tooltip {
		position: absolute;
		pointer-events: none;

		padding: 0.5rem 1rem;
		z-index: 10;

		border-radius: 0.5rem;
		background-color: var(--color-bg);
		backdrop-filter: blur(0.5rem);

		border: 1px solid var(--color-border);
		transition: opacity 500ms;
		transition-delay: 500ms;

		max-width: 30vw;

		transform: translateX(var(--transform-left)) translateY(var(--transform-top));
	}

	.tooltip:before {
		content: " ";
		height: 0;
		position: absolute;
		width: 0;
		border: 0.5rem solid transparent;
	}

	.tooltip.pointing-top:before {
		--left-offset-abs: calc(var(--transform-left) + 0.25rem);
		--left-offset: calc(var(--left-offset-abs) * -1);

		top: -1rem;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		transform: translateX(var(--left-offset));
		border-bottom-color: var(--color-border);
	}

	.tooltip.pointing-left:before {
		left: -1rem;
		transform: translateY(0.25rem);
		border-right-color: var(--color-border);
	}
</style>
