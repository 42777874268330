<script lang="ts">
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { CalibrationOverlayController } from "../CalibrationOverlayController"
	import { CalibrationMinimizedController } from "./CalibrationMinimizedController"
	import { openCalibrationOverlay } from ".."
	import type { Device } from "luxedo-data"
	import ProjectorIcon from "../../../icons/ProjectorIcon.svelte"
	import { hideMinimizedCalibration } from "."
	import { onDestroy } from "svelte"
	import type { Unsubscriber } from "svelte/store"
	import ExpandIcon from "../../../icons/ExpandIcon.svelte"

	let isOpen: boolean
	let device: Device

	let posX: number
	let posY: number

	let unsubscribers: Array<Unsubscriber> = []

	unsubscribers.push(
		CalibrationMinimizedController.subscribe((ctx) => {
			isOpen = ctx.isOpen
			device = ctx.device

			posX = ctx.posX
			posY = ctx.posY

			console.log({ posX, posY })
		})
	)

	let message: string
	let progress: number
	let isFailed: boolean
	let isComplete: boolean

	unsubscribers.push(
		CalibrationOverlayController.subscribe((ctx) => {
			message = ctx.progress.message
			progress = ctx.progress.progress

			if ("snapshot" in ctx) isComplete = !!ctx.snapshot
			else isComplete = false

			if ("failImages" in ctx) isFailed = !!ctx.failImages
			else isFailed = false
		})
	)

	function onClick() {
		openCalibrationOverlay(device, true)
		hideMinimizedCalibration()
	}

	onDestroy(() => {
		unsubscribers.forEach((un) => un())
	})
</script>

{#if isOpen}
	<div
		id="calibration-minimized"
		style={posX && posY ? `left: ${posX}px; top: ${posY}px;` : "bottom: 2rem; right: 2rem;"}
		on:click={onClick}
	>
		<div class="minimized-calibration-heading">
			<span>Calibrating {device.name}...</span>
			<button class="icon small">
				<ExpandIcon />
			</button>
		</div>
		<div class="flex-row">
			<div class="left">
				<ProjectorIcon {device} />
			</div>
			<div class="right">
				<div class="calibration-message">
					{#if isFailed}
						<span class="error"> Calibration failed... Click to troubleshoot. </span>
					{:else if isComplete}
						Calibration complete! Click to view the generated snapshot.
					{:else}
						{message}
					{/if}
				</div>
			</div>
		</div>
		<ProgressBar percentage={isComplete ? 1 : progress} />
	</div>
{/if}

<style>
	.minimized-calibration-heading {
		font-size: var(--h3);
		color: var(--color-text);
		margin-bottom: 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	#calibration-minimized {
		position: fixed;
		z-index: 1;

		background-color: var(--color-bg);
		border-radius: var(--br);
		box-shadow: var(--shadow-medium);
		padding: 0.5rem 1rem;
		backdrop-filter: blur(3rem);

		min-width: 16rem;
		width: 30vw;
		max-width: 28rem;
		cursor: pointer;
	}

	#calibration-minimized :global(.progress-bar) {
		margin: 0.5rem 0;
	}

	.calibration-message {
		line-height: 1em;
		margin-bottom: 0.75rem;
	}

	.flex-row {
		align-items: center;
	}

	.flex-row .right {
		margin-left: 0.5rem;
	}

	.left :global(.projector-icon) {
		width: 2rem;
		height: auto;
	}

	.error {
		color: var(--color-error);
	}

	button.icon.small {
		padding: 0;
	}

	button.icon.small :global(svg) {
		height: 1.25rem;
	}

	button.icon.small :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button.icon.small:focus-visible :global(.svg-stroke),
	button.icon.small:hover :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}
</style>
