<script lang="ts">
</script>

<svg width="12" height="24" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		id="indicator-path"
		class="svg-stroke"
		d="M37.5 25L62.5 50L37.5 75"
		stroke="var(--color-text)"
		stroke-width="8.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
</style>
