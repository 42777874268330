import { type TrackContentType, TrackGroup, type TrackLike, Track } from "."
import { EditorClass } from ".."
import type { EditorAsset, MaskBase } from "../asset"

export class RootTrackGroup<T extends EditorAsset> extends TrackGroup<T> {
	private trackColorIndex: number

	constructor(editor: EditorClass, id: "ROOT") {
		super(editor, {
			id,
			name: id,
		})

		this.trackColorIndex = 0
	}

	protected registeredSubnodes: Map<string, TrackLike<T>> = new Map()
	register(node: TrackLike<T>) {
		if (this.registeredSubnodes.has(node.id)) {
			throw ReferenceError("Node is already registered.")
		}

		if (!node.tags?.color) {
			node.tags.color = this.getNextTrackColor()
		}

		this.registeredSubnodes.set(node.id, node)
	}

	deregister(node: TrackLike<T>) {
		// Make sure we're not left with any zombie events, if that's even possible
		setTimeout(() => {
			node.dispose()
		})

		this.registeredSubnodes.delete(node.id)
	}

	//#region    ===========================				Root Interface				==============================

	/** Returns all contained subnodes of the root as an unordered list */
	getAll(type?: "audio" | "video"): TrackLike<T>[] {
		const allTracks = Array.from(this.registeredSubnodes.values())
		if (type) return allTracks.filter((track) => track.contentType === type.toUpperCase())
		else return allTracks
	}

	getNode(id: string): TrackLike<T> {
		if (id === this.id) return this
		return this.registeredSubnodes.get(id)
	}

	//#endregion =====================================================================================================

	// #region ============================== Color Management ==============================

	private COLORS = [
		"FF9D00",
		"4647DE",
		"62BE60",
		"DE5357",
		"9774CC",
		"EFE23F",
		"0087B0",
		"FF5803",
		"4987DE",
		"FF4083",
		"96BF60",
		"CD74CC",
		"F0BE31",
		"04B2A8",
		"B07004",
		"383696",
		"498147",
		"983F40",
		"6C5789",
		"A49D30",
		"045C78",
		"B04206",
		"395F96",
		"688247",
		"AF325B",
		"855789",
		"A57D31",
		"077A74",
	]

	importTrackColorIndex(index: number) {
		this.trackColorIndex = index
	}

	getTrackColorIndex() {
		return this.trackColorIndex
	}

	getNextTrackColor() {
		return `#${
			this.trackColorIndex > 0
				? this.COLORS[this.trackColorIndex++ % this.COLORS.length]
				: this.COLORS[this.trackColorIndex++]
		}`
	}

	previewTrackColor() {
		return `#${this.trackColorIndex > 0 ? this.COLORS[this.trackColorIndex % this.COLORS.length] : this.COLORS[0]}`
	}

	// #endregion ===========================================================================

	//#region    ===========================				Root Behavior				==============================

	attach(child: Track<T> | TrackGroup<T>, index?: number): Track<T> | TrackGroup<T> {
		const track = super.attach(child, index)

		track.generateMask()
		return track
	}

	contains(child: TrackLike<any>): boolean {
		return this.registeredSubnodes.has(child.id)
	}

	getByPositionIndex(index: number, parentIndex: number) {
		const track = Array.from(this.registeredSubnodes.values()).find((track) => {
			if (track.positionIndex === index && track.parent.positionIndex === parentIndex) return true
		})
		return track
	}

	isRoot(): boolean {
		return true
	}

	get parent(): null {
		return null
	}

	/** Root groups don't really have a position. */
	get positionIndex(): number {
		return 0
	}

	get depth(): number {
		return 0
	}

	getRoot(): RootTrackGroup<T> {
		return this
	}

	_getLineageRecursive(_originator: TrackLike<T>): TrackGroup<T>[] {
		// Once it reaches here - the root group has already been added given the way this is implemented
		return []
	}

	//#endregion =====================================================================================================

	//#region    ===========================				Controls API				==============================

	/** Parent tracks cannot be hidden */
	get hidden(): boolean {
		return false
	}
	set hidden(val: boolean) {
		throw "Root cannot be hidden"
	}

	//#endregion =====================================================================================================

	/** Clipping masks are not allowed on the root group. */
	declare clippingMask: never

	getAffectingMasks(): MaskBase[] {
		return []
	}

	/** Disallowed in the root group. */
	insertParentGroup(): never {
		throw "[RootGroup.insertParentGroup] Not available for root groups."
	}

	/**
	 * Disallowed in the root group
	 */
	setMask(maskObject: fabric.Object): never {
		throw "[RootGroup.setMask] Not available for root groups."
	}
	getMask() {
		return null
	}

	/**
	 * Disallowed in the root group
	 */
	clearMask(): never {
		console.warn("clear mask called for root group")
		throw "[RootGroup.clearMask] Not available for root groups."
	}

	/**
	 * Disallowed in the root group
	 */
	maskUpdated(): never {
		throw "[RootGroup.maskUpdated] Not available for root groups."
	}

	//#endregion =====================================================================================================
}
