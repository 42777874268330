import { closeOverlay, openOverlay } from "../../overlay"
import { MediaAlterError, type Media } from "luxedo-data"
import { Toast } from "../../toaster"
import { get, writable, type Unsubscriber, type Writable } from "svelte/store"

import MediaBackgroundRemover from "./bg-remove/MediaBackgroundRemover.svelte"
import MediaTrimmer from "./trimmer/MediaTrimmer.svelte"
import { BackgroundRemoverCanvas as ChromaKeyCanvas } from "./bg-remove/MediaBackgroundRemoveCanvas"
import type { SvelteComponent } from "svelte"

export type MediaToolOptions = "bg-remove" | "trim" | "draw"

export function openMediaToolOverlay(tool: MediaToolOptions, media: Media) {
	let comp: new (...args: any[]) => SvelteComponent
	let heading: string
	switch (tool) {
		case "bg-remove":
			heading = "Remove Background"
			comp = MediaBackgroundRemover
			break
		case "trim":
			comp = MediaTrimmer
			heading = "Trim Duration"
			break
		// case "draw":
		// 	comp = MediaCanvas
		// 	heading = "Edit Media"
		// 	break
	}

	MediaTrimController.reset()

	openOverlay(comp, {
		classHeading: "no-underline",
		heading,
		props: {
			media,
			tool,
		},
	})
}

export class MediaImageController<T> {
	STORE_DEFAULT = {} as T

	declare mediaElem: HTMLImageElement | HTMLVideoElement
	declare media: Media
	declare store: Writable<T>

	constructor() {
		this.store = writable(this.STORE_DEFAULT)
	}

	subscribe(updater: (ctx: T) => void) {
		return this.store.subscribe(updater)
	}

	async initialize(mediaElem: HTMLImageElement | HTMLVideoElement, media: Media) {
		this.media = media
		this.mediaElem = mediaElem
		this.reset()
	}

	reset() {
		this.store.set(this.STORE_DEFAULT)
	}

	protected loadMedia() {
		return this.loadImage()
	}

	protected loadImage() {
		try {
			const source = this.media.getSource()
			if (this.mediaElem instanceof HTMLVideoElement) return false
			this.mediaElem.src = source
			return true
		} catch (e) {
			console.error("ERROR loading media image", e)
			Toast.error("Something went wrong while loading media, please refresh and try again.")
			closeOverlay()
			return false
		}
	}
}

type VideoCTX = {
	isPlaying: boolean
	timestamp: number
	timestampPercent: number
}

export class MediaToolController<T extends VideoCTX> extends MediaImageController<T> {
	declare mediaElem: HTMLVideoElement | HTMLImageElement
	declare media: Media
	declare store: Writable<T>

	STORE_DEFAULT: T = {
		isPlaying: false,
		timestamp: 0,
		timestampPercent: 0,
	} as T

	constructor() {
		super()
		this.store = writable(this.STORE_DEFAULT)
	}

	async initialize(videoElem: HTMLVideoElement | HTMLImageElement, media: Media) {
		await super.initialize(videoElem, media)

		if (!this.loadMedia()) {
			closeOverlay()
			return Toast.error("Unable to load media... Please refresh and try again.")
		} else if (this.mediaElem instanceof HTMLVideoElement) {
			if (!(this.media.fileType === "video"))
				throw new Error("Loading non-video into video element while initializing background removal tool.")
			this.mediaElem.addEventListener("timeupdate", (e) => this.videoUpdateTime(e))
			this.mediaElem.addEventListener("play", (e) => this.videoUpdateStatus(e))
			this.mediaElem.addEventListener("playing", (e) => this.videoUpdateStatus(e))
			this.mediaElem.addEventListener("pause", (e) => this.videoUpdateStatus(e))
			this.mediaElem.addEventListener("ended", (e) => this.videoUpdateStatus(e))

			await new Promise<void>((res) => {
				const handleLoad = () => {
					this.mediaElem.removeEventListener("loadeddata", handleLoad)
					res()
				}
				this.mediaElem.addEventListener("loadeddata", handleLoad)
			})
		} else if (this.mediaElem instanceof HTMLImageElement) {
			if (!(this.media.fileType === "image"))
				throw new Error("Loading non-image into image element while initializing background removal tool.")

			await new Promise<void>((res) => {
				const handleLoad = () => {
					this.mediaElem.removeEventListener("load", handleLoad)
					res()
				}
				this.mediaElem.addEventListener("load", handleLoad)
			})
		}
	}

	skipToDurationPercent = (timestampPercent: number) => {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		this.updateTimestamp(timestampPercent * this.mediaElem.duration)
	}

	skipTo = (timestamp: number) => {
		this.updateTimestamp(timestamp)
	}

	skipToStart = () => {
		this.updateTimestamp(0)
	}

	skipToEnd = () => {
		this.updateTimestamp(this.media.duration)
	}

	pause = () => {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return
		this.mediaElem.pause()
	}

	play = () => {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		if (get(this.store).isPlaying) this.mediaElem.pause()
		else this.mediaElem.play()
	}

	protected updateTimestamp(timestamp: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		let newTimestamp = Math.max(timestamp, 0)
		newTimestamp = Math.min(newTimestamp, this.media.duration)
		this.mediaElem.currentTime = newTimestamp
	}

	protected loadMedia(): boolean {
		if (this.media.fileType === "video") {
			return this.loadVideo()
		} else if (this.media.fileType === "image") {
			return this.loadImage()
		} else {
			return false
		}
	}

	protected loadVideo(): boolean {
		try {
			const source = this.media.getSource()
			const sourceElem = document.createElement("source")
			sourceElem.src = source
			sourceElem.type = "video/mp4"
			this.mediaElem.appendChild(sourceElem)
			return true
		} catch (e) {
			console.error("ERROR Loading Media", e)
			Toast.error("Something went wrong when loading media. Please refresh and try again.")
			closeOverlay()
			return false
		}
	}

	// #region Listeners
	protected videoUpdateStatus(e: Event) {
		this.store.update((ctx) => ({
			...ctx,
			isPlaying: !(e.target as HTMLVideoElement).paused,
		}))
	}

	protected videoUpdateTime(e: Event) {
		this.store.update((ctx) => ({
			...ctx,
			timestamp: (e.target as HTMLVideoElement).currentTime,
			timestampPercent: (e.target as HTMLVideoElement).currentTime / (e.target as HTMLVideoElement).duration,
		}))
	}
	// #endregion Listeners
}

// #region Trim Controller

type TrimCTX = VideoCTX & {
	trimStart: number
	trimEnd: number
	trimStartPercent: number
	trimEndPercent: number
}

export class TrimController extends MediaToolController<TrimCTX> {
	declare store: Writable<TrimCTX>

	STORE_DEFAULT: TrimCTX = {
		isPlaying: false,
		timestamp: 0,
		timestampPercent: 0,
		trimEnd: 0,
		trimStart: 0,
		trimStartPercent: 0,
		trimEndPercent: 0,
	}

	constructor() {
		super()
		this.store = writable(this.STORE_DEFAULT)
	}

	async saveInPlace(progressListener: (progerss: number) => void) {
		const { trimStart, trimEnd } = get(this.store)
		const editData = {
			temporal_crop: {
				t_start: trimStart,
				t_end: trimEnd,
			},
		}

		try {
			await this.media.alterAsync(editData, progressListener)
			Toast.success("Media modified!")
			closeOverlay()
		} catch (e) {
			console.error("[ERROR] ", e)
			if (e instanceof MediaAlterError) {
				Toast.error(e.message)
			} else {
				Toast.error("Unable to modify media, please try again.")
			}
		}
	}

	async initialize(videoElem: HTMLVideoElement, media: Media): Promise<void> {
		await super.initialize(videoElem, media)

		let { trimEnd, trimEndPercent } = get(this.store)

		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		if (this.media !== media || !trimEnd || trimEnd < 1) {
			trimEnd = this.mediaElem.duration
			trimEndPercent = 1
			this.store.update((ctx) => ({
				...ctx,
				trimEnd,
				trimEndPercent,
			}))
		}
	}

	skipToEnd = () => {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		const { trimEnd, timestamp } = get(this.store)

		if (timestamp.toFixed(3) === trimEnd.toFixed(3)) {
			this.updateTimestamp(this.mediaElem.duration)
		} else {
			this.updateTimestamp(trimEnd)
		}
	}

	skipToStart = () => {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		const { trimStart, timestamp } = get(this.store)
		if (timestamp.toFixed(3) === trimStart.toFixed(3)) {
			this.updateTimestamp(0)
		} else {
			this.updateTimestamp(trimStart)
		}
	}

	private updateTrimStart(time: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		let { trimEnd } = get(this.store)

		if (this.mediaElem.duration - time < 1) {
			time = this.mediaElem.duration - 1
		}

		if (trimEnd - time < 1) {
			trimEnd = time + 1
		}

		const endPercent = trimEnd / this.mediaElem.duration
		const startPercent = time / this.mediaElem.duration

		this.store.update((ctx) => ({
			...ctx,
			trimStart: Math.max(0, time),
			trimStartPercent: Math.max(0, startPercent),
			trimEnd: Math.min((this.mediaElem as HTMLVideoElement).duration, trimEnd),
			trimEndPercent: Math.min(endPercent, 1),
		}))
	}

	private updateTrimEnd(time: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		let { trimStart } = get(this.store)
		if (time < 1) {
			time = 1
		}

		if (time - trimStart < 1) {
			trimStart = time - 1
		}

		const endPercent = time / this.mediaElem.duration
		const startPercent = trimStart / this.mediaElem.duration

		this.store.update((ctx) => ({
			...ctx,
			trimStart: Math.max(0, trimStart),
			trimStartPercent: Math.max(0, startPercent),
			trimEnd: Math.min((this.mediaElem as HTMLVideoElement).duration, time),
			trimEndPercent: Math.min(endPercent, 1),
		}))
	}

	setTrimStart(trimTime: number) {
		this.updateTrimStart(trimTime)
	}

	setTrimEnd(trimTime: number) {
		this.updateTrimEnd(trimTime)
	}

	setTrimStartPercent(percent: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		const trimTime = this.mediaElem.duration * percent
		this.updateTrimStart(trimTime)
	}

	setTrimEndPercent(percent: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		const trimTime = this.mediaElem.duration * percent
		this.updateTrimEnd(trimTime)
	}
}

export const MediaTrimController = new TrimController()

// #endregion Trim Controller
// #region Background Remover

type RemoveBGCTX = VideoCTX & {
	isPreviewing: boolean
}

class BGRemoveController extends MediaToolController<RemoveBGCTX> {
	BACKDROP_DEFAULT: "#2c282d"

	STORE_DEFAULT: RemoveBGCTX = {
		isPlaying: false,
		isPreviewing: false,
		timestamp: 0,
		timestampPercent: 0,
	}

	declare store: Writable<RemoveBGCTX>
	declare chromaKeyer: ChromaKeyCanvas
	valueResetListener: () => void

	constructor() {
		super()
		this.store = writable(this.STORE_DEFAULT)
	}

	async saveInPlace(progressListener: (progress: number) => void) {
		const editData = {
			chromakey: {
				key: [
					this.chromaKeyer.keyColorHSV.H / 2,
					this.chromaKeyer.keyColorHSV.S * (255 / 100),
					this.chromaKeyer.keyColorHSV.V * (255 / 100),
				] as [number, number, number],
				tol: [
					this.chromaKeyer.hueThreshold / 2,
					this.chromaKeyer.satThreshold * (255 / 100),
					this.chromaKeyer.valThreshold * (255 / 100),
				] as [number, number, number],
			},
		}

		await this.media.alterAsync(editData, progressListener)
		Toast.success("Media modified!")
		closeOverlay()
	}

	async initialize(
		mediaElem: HTMLVideoElement | HTMLImageElement,
		media: Media,
		canvas?: HTMLCanvasElement
	): Promise<void> {
		if (!canvas) throw new Error("Canvas must be initialized!")
		await super.initialize(mediaElem, media)
		await this.initializeCanvas(canvas)

		this.reset()
		this.resetValues()
	}

	async initializeCanvas(canvasElem: HTMLCanvasElement): Promise<void> {
		this.chromaKeyer = new ChromaKeyCanvas(this.mediaElem, canvasElem)

		this.chromaKeyer.applyCanvasDimensions(this.mediaElem.clientWidth, this.mediaElem.clientHeight)
		this.updateBackdropColor(this.BACKDROP_DEFAULT)
		this.resetValues()
	}

	updateTimestamp(newTimestamp: number) {
		if (!(this.mediaElem instanceof HTMLVideoElement)) return

		super.updateTimestamp(newTimestamp)
		this.chromaKeyer.chromaKeyVideoFrame(this.mediaElem)
	}

	updateRemoveColor(hex: string) {
		this.chromaKeyer.keyColor = hex
		this.chromaKeyer.drawCanvas()
	}

	updateBackdropColor(hex: string) {
		this.chromaKeyer.canvas.style.backgroundColor = hex
		this.chromaKeyer.drawCanvas()
	}

	/**
	 * Sets all internal chromaKey values to the defaults
	 */
	resetValues() {
		this.chromaKeyer.hueThreshold = 30
		this.chromaKeyer.satThreshold = 10
		this.chromaKeyer.valThreshold = 40

		if (this.valueResetListener) this.valueResetListener()
		this.chromaKeyer.drawCanvas()
	}

	onResetValues(cb: () => void) {
		this.valueResetListener = cb
	}

	/**
	 * Toggles if the user is viewing the canvas or the video
	 */
	togglePreview(toggleTo?: boolean) {
		if (toggleTo !== undefined) {
			this.store.update((ctx) => ({
				...ctx,
				isPreviewing: toggleTo,
			}))
		} else {
			const isPreviewing = get(this.store).isPreviewing
			this.store.update((ctx) => ({
				...ctx,
				isPreviewing: !isPreviewing,
			}))
		}
		this.chromaKeyer.drawCanvas()
	}

	/**
	 * Called by input element when value updates
	 */
	onHueChange = (
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) => {
		this.chromaKeyer.hueThreshold = parseInt(e.currentTarget.value)
		this.chromaKeyer.drawCanvas()
	}

	/**
	 * Called by input element when value updates
	 */
	onSaturationChange = (
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) => {
		this.chromaKeyer.satThreshold = parseInt(e.currentTarget.value)
		this.chromaKeyer.drawCanvas()
	}

	/**
	 * Called by input element when value updates
	 */
	onBrightnessChange = (
		e: Event & {
			currentTarget: EventTarget & HTMLInputElement
		}
	) => {
		this.chromaKeyer.valThreshold = parseInt(e.currentTarget.value)
		this.chromaKeyer.drawCanvas()
	}

	protected videoUpdateTime(e: Event) {
		super.videoUpdateTime(e)
		this.chromaKeyer.drawCanvas()
	}
}

export const BackgroundRemoveController = new BGRemoveController()

// #endregion
