import { type BundleOf, DataHandler, type IDMap } from "data-handler"
import { Calibration, type CalibrationRawData } from "../entries/Calibration"
import { LuxedoRPC } from "luxedo-rpc"
import type { Device } from "../entries/Device"

class DHCalibration extends DataHandler<Calibration> {
	EntryClass = Calibration

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Calibration>> {
		const data: BundleOf<Calibration> = await LuxedoRPC.api.bundlers.cal_bundle_down(specificInstances ?? [])
		return data
	}

	protected async pushData(entryData: { [id: number]: Partial<CalibrationRawData> }): Promise<void> {}

	filterByDevice(device: Device, hideFailed?: boolean) {
		let allDeviceCals = Object.values(this.entries)
			.filter((cal) => cal.dev_id === device.id)
			.sort((calA, calB) => {
				if (calA.runTime > calB.runTime) return -1
				else if (calA.runTime < calB.runTime) return 1
				else return 0
			})

		if (hideFailed) allDeviceCals = allDeviceCals.filter((cal) => !cal.didFail)

		return allDeviceCals
	}
}

export const DataHandlerCalibration = new DHCalibration()
