import { get, writable } from "svelte/store"
import type { ROUTES } from "../comps/routes/Routes"
import { navigate } from "svelte-routing"

type NavCtx = keyof typeof ROUTES

export const NavigationStore = writable<NavCtx>(
	(window.location.pathname.split("/")[1] as keyof typeof ROUTES) || "projector"
)

export function navigateTo(route: keyof typeof ROUTES, searchParams?: {[index:string]: string | number}) {
	if (get(NavigationStore) === route && !searchParams) return
	
	let newPath : string = route
	if (searchParams) {
		newPath = `${newPath}?${Object.entries(searchParams).map(([key, value]) => `${key}=${value}`).join("&")}`
	}
	
	navigate(newPath)
	NavigationStore.set(route)
}

type NavigationHandler = (newRoute: keyof typeof ROUTES) => any
export function addNavigationListener(handler: NavigationHandler) {
	NavigationStore.subscribe((route) => {
		handler(route)
	})
}
