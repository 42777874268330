import { v4 as uuid } from "uuid"
import type { Track } from "../tracks"
import type { CanvasAsset } from "."
import type { AudioAsset } from "./audio/Audio"
import { Serializable } from "../modules/serialize"
import type { EventListenerControls } from "../events/decorators"

export interface AssetOptions {
	/** Unique identifier for the asset in a JSON - must be defined when importing an object. If unset, automatically assigns a uuid */
	id?: string
}

export interface Asset extends Serializable {
	id?: string
	behaviorHandlers: Array<EventListenerControls<any>>

	trackId: string
	track: Track

	onDelete(): void
	clearEvents(): void
}

export interface AssetConstructor<T extends Asset> {
	new (...args: any[]): T
}

export class Asset {
	/**
	 * Apply the basic options to a new asset
	 * This handles setting the name, ID, and trackID - whether it is a new item, or it is being passed import info
	 * @param asset
	 * @param options
	 */
	initAsset(options: AssetOptions) {
		this.id = options.id ?? uuid()
		this.behaviorHandlers = []
	}

	//#region    ===========================			  	   Interface				==============================

	bindTrack(track: Track) {
		this.trackId = track.id
		this.track = track
	}

	/**
	 * Used for handling the unbinding of events and the deletion of all references to content, if needed
	 */
	onDelete(): void {
		this.clearEvents()
	}

	clearEvents(): void {
		for (const eventListener of this.behaviorHandlers) {
			eventListener.remove()
		}
		this.behaviorHandlers = []
	}

	//#endregion =====================================================================================================

	/** Minimum time an asset can have on the timeline */
	static MINIMUM_DURATION_S = 1

	//#region    ===========================		  	   Typing for Mixin  			==============================

	static isEditorAsset = Symbol("isEditorAsset")
	static [Symbol.hasInstance](obj) {
		if (!obj) return false
		return Asset.isEditorAsset in obj
	}

	static Mixin<T extends new (...args: any) => Asset>(Base: T) {
		Base.prototype[Asset.isEditorAsset] = true

		console.group(`Mixin ${Base.name}`)

		for (const propName of Object.getOwnPropertyNames(Asset.prototype)) {
			if (propName === "constructor") continue
			if (Object.hasOwn(Base.prototype, propName)) continue
			Base.prototype[propName] = Asset.prototype[propName]
			window.console.warn(`${Base.name}.${propName} = Asset.${propName}`)
		}

		console.groupEnd()
	}

	//#endregion =====================================================================================================

	//#region ===========================		  	   Serialization    			==============================

	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude: string[]
	} {
		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		return {
			include: [...expProps],
			deepCopy: [],
			exclude: [
				"track",
				"clipPath",
				"controls",
				"cacheWidth",
				"cacheHeight",
				"ownCaching",
				"ownMatrixCache",
				"dirty",
				"objectCaching",
				"globalCompositeOperation",
				"paintFirst",
				"aCoords",
				"oCoords",
				"lineCoords",
			],
		}
	}

	// #endregion =====================================================================================================
}

export type EditorAsset = CanvasAsset | AudioAsset
