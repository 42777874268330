<script lang="ts">
	import { NumberRange } from "../.."

	export let strokeWidth: number = 16
</script>

<div class="stroke-width-container">
	<NumberRange
		max={256}
		min={0}
		step={1}
		label="Size: "
		labelUnit="px"
		bind:value={strokeWidth}
		id="stroke-width-input"
	/>
</div>

<style>
	.stroke-width-container {
		width: 100%;
		margin: 0.5rem 0.25rem 0 0.25rem;
	}

	.stroke-width-container :global(#stroke-width-input) {
		width: 100%;
	}

	.stroke-width-container :global(#stroke-width-input input[type="number"]) {
		width: 1.5rem;
	}

	.stroke-width-container :global(#stroke-width-input input[type="range"]) {
		margin-right: 0.5rem;
	}
</style>
