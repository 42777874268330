<script lang="ts">
	import { onMount } from "svelte"
	import QuickSelectPointer from "./QuickSelectPointer.svelte"

	/**
	 * Sort By
	 * ○ Date Created
	 * ● Date Modified
	 * ○ Projector
	 * ○ Tags
	 * ----------------
	 * ○ Descending
	 * ● Ascending
	 */

	type Options = {
		[index: string]: /* data key */ string[] /* options */
	}

	export let options: Options
	export let allowClear: boolean = false
	export let isHidden: boolean = true
	export let remWidth: number = 10
	export let defaultSelection: { [index: string]: string } = undefined

	export let selections: {
		[index: string]: string
	} = {}

	function stopPropagationOnHover(e: MouseEvent) {
		e.stopImmediatePropagation()
	}

	function onClear() {
		selections = { ...defaultSelection } ?? {}
	}

	function checkIfSelected(dataKey: string, opt: string, sels) {
		return selections[dataKey] === opt
	}

	onMount(onClear)
</script>

{#if !isHidden}
	<div class="quick-select-bg" on:mouseover={stopPropagationOnHover} />
{/if}
<!-- <div class="quick-selector-absolute-helper"> -->
<!-- on:click|stopPropagation={} -->
<div class="quick-selector-container">
	<div class="quick-selector {isHidden ? 'hidden' : ''}" style="width: {remWidth}rem; ">
		<QuickSelectPointer />
		{#each Object.entries(options) as [dataKey, opts]}
			<fieldset>
				{#each opts as opt}
					<div
						class="radio-container"
						on:mouseover={stopPropagationOnHover}
						on:click={stopPropagationOnHover}
					>
						<input
							id="{opt.replace(' ', '_')}-input"
							type="radio"
							name={dataKey}
							value={opt}
							bind:group={selections[dataKey]}
							checked={checkIfSelected(dataKey, opt, selections)}
						/>
						<label for="{opt.replace(' ', '_')}-input">{opt}</label>
						<!-- on:mouseup|stopPropagation={() => onSelect(dataKey, opt)} -->
						<!-- on:click={() => onSelect(dataKey, opt)}  -->
					</div>
				{/each}
			</fieldset>
		{/each}
		{#if allowClear}
			<a class="clear-selection-button" href="#" on:click|preventDefault={onClear}
				>Clear selection</a
			>
		{/if}
	</div>
</div>

<style>
	.quick-selector-container {
		position: relative;
		position: absolute;
		height: 0;
		width: 0;
	}

	.quick-select-bg {
		z-index: 5;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	.quick-selector {
		z-index: 15;
		background-color: var(--color-bg-opaque);
		border: 1px solid var(--color-border);
		position: absolute;

		bottom: -1.5rem;
		right: -3.5rem;
		transform: translateY(100%);

		border-radius: 0.5rem;
		transition: opacity 250ms;
	}

	.quick-selector.hidden {
		user-select: none;
		pointer-events: none;
		opacity: 0;
	}

	.quick-selector .radio-container {
		width: 100%;
		padding: 0.5rem;
		background-color: transparent;
		box-shadow: unset;
		border-bottom: none;
		border-radius: unset;
		/* margin: 0 1rem; */
		text-align: left;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: var(--h3);
	}

	fieldset .radio-container:last-of-type {
		border-bottom: 1px solid var(--color-border);
	}

	.quick-selector .radio-container label {
		flex-grow: 1;
	}

	.quick-selector .radio-container input {
		margin-right: 0.5rem;
		height: 1rem;
		width: 1rem;
	}

	.quick-selector .radio-container:hover,
	.quick-selector .radio-container:focus-visible {
		color: var(--color-main);
	}

	.quick-selector .radio-container input:checked {
		background-color: var(--color-main);
	}

	.quick-selector > :global(svg) {
		width: 1.5rem;
		height: 1.5rem;

		position: absolute;
		top: -1.35rem;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: 1rem;

		/* left: -1.8rem; */

		/* top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto; */
		transform: rotate(90deg);
	}

	.clear-selection-button {
		color: var(--color-text);
		border: none;
		text-align: center;
		padding: 0.5rem 0.25rem;
		display: block;
		font-size: var(--h3);
		width: 100%;
	}
</style>
