import { fabric } from "fabric"
import { calcCenter, deepCopyPoints, offsetPoints } from "./pointsUtil"

declare module "fabric" {
	namespace fabric {
		interface Polyline {
			/** Get this.points, but omit the last point if it is a closed shape (last point is a copy of first) */
			getPoints(): fabric.Point[]
			recenterPoints(): void
			recalculateBounds(): void
			/* Set the scale back to 1 while keeping the points in the same places */
			normalizeScale(): void
			get closed(): boolean
			set closed(val: boolean)
			get absolutePoints(): Array<fabric.Point>
		}
	}
}

export class PolylineMod extends fabric.Polyline {
	toAbsolutePoint(localPoint: fabric.Point) {
		const x = localPoint.x - this.pathOffset.x
		const y = localPoint.y - this.pathOffset.y

		const ret = fabric.util.transformPoint(new fabric.Point(x, y), this.calcOwnMatrix())
		return ret
	}

	toRelativePoint(absolutePoint: fabric.Point) {
		const pt = this.toLocalPoint(absolutePoint, this.originX, this.originY)
		pt.x /= this.scaleX
		pt.y /= this.scaleY
		pt.addEquals(this.pathOffset)
		return pt
	}

	get closed() {
		return this.points[0] === this.points[this.points.length - 1]
	}

	set closed(val: boolean) {
		if (this.closed == val) return

		if (!val) {
			this.points.pop()
			return
		} else {
			this.points.push(this.points[0])
			return
		}
	}

	/** Get this.points, but omit the last point if it is a closed shape (last point is a copy of first) */
	getPoints(): fabric.Point[] {
		return this.points.slice(0, this.closed ? -1 : undefined)
	}

	/** Adjust top & left to be at the center of the points, and update the points accordingly. */
	recenterPoints() {
		// For some reason, even if passing an array of fabric points to Polygon, by the time the execution reaches this method,
		// the points become simple objects with just an X and Y. This ensures the points are fabric points.
		if (!(this.points[0] instanceof fabric.Point)) {
			this.points = deepCopyPoints(this.points)
		}

		const relCenter = calcCenter(this.points)

		const newLeft = this.left + relCenter.x - this.pathOffset.x
		const newTop = this.top + relCenter.y - this.pathOffset.y
		this.left += relCenter.x - this.pathOffset.x
		this.top += relCenter.y - this.pathOffset.y

		this.points.forEach((pt, i) => {
			if (this.closed && i == this.points.length - 1) return
			pt.subtractEquals(relCenter)
		})
		this.pathOffset.x = 0
		this.pathOffset.y = 0

		const newPosition = new fabric.Point(newLeft, newTop)
		const position = this.translateToCenterPoint(newPosition, this.originX, this.originY)
		this.left = position.x
		this.top = position.y
	}

	recalculateBounds() {
		// Pick a point from the path and determine its exact position
		const anchorPosition = this.toAbsolutePoint(this.points[0])

		// Update the polygon's dimensions
		/* @ts-ignore */
		this._setPositionDimensions({
			left: false,
			top: false,
		})

		// Find the point's absolute position after the change
		const afterPosition = this.toAbsolutePoint(this.points[0])
		const diff = afterPosition.subtract(anchorPosition)

		// Adjust the shape in accordance with the change
		this.left -= diff.x
		this.top -= diff.y
	}

	/* Set the scale back to 1 while keeping the points in the same places */
	normalizeScale() {
		if (this.scaleX == 1 && this.scaleY == 1) return

		const anchorPoint = this.toAbsolutePoint(this.points[0])
		const { scaleX, scaleY } = this

		for (const pt of this.getPoints()) {
			pt.x *= scaleX
			pt.y *= scaleY
		}

		this.scaleX = 1
		this.scaleY = 1

		// @ts-ignore
		this._setPositionDimensions({
			left: false,
			top: false,
		})

		const offset = anchorPoint.subtract(this.toAbsolutePoint(this.points[0]))

		offsetPoints(this.getPoints(), offset)
	}

	get absolutePoints() {
		const points = deepCopyPoints(this.points)

		for (let i = 0; i < points.length; i++) {
			points[i].setFromPoint(this.toAbsolutePoint(this.points[i]))
		}

		return points
	}
}

/* Add all the functionality to fabric.Polyline */
const properties = Object.getOwnPropertyDescriptors(PolylineMod.prototype)
delete properties["constructor"]
Object.defineProperties(window.fabric.Polyline.prototype, properties)

export function applyPolylineExtension() {
	const properties = Object.getOwnPropertyDescriptors(PolylineMod.prototype)
	delete properties["constructor"]
	Object.defineProperties(window.fabric.Polyline.prototype, properties)
}
