import type { EventName, NoWildcard } from "."
import { EditorEvent } from "./Event"

type TimelineEditEventName = EventName<"edit", "duration">
type TimelinePreviewEventName = EventName<"preview", "play" | "pause" | "stop" | "seek">
type TimelineTickEventName = EventName<"tick">

export type TimelineEventName = TimelineTickEventName | TimelineEditEventName | TimelinePreviewEventName

export interface TimelineEventInit {
	timestamp: number
	startTime?: number
	endTime?: number
}

export interface TimelineEvent extends EditorEvent<TimelineEventName>, TimelineEventInit {}

export class TimelineEvent extends EditorEvent<TimelineEventName> {
	OptionsType: TimelineEventInit
	constructor(eventName: NoWildcard<TimelineEventName>, options: TimelineEventInit) {
		super(eventName)
		this.timestamp = options.timestamp
		this.startTime = options.startTime
		this.endTime = options.endTime
	}
}
