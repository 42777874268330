import type { BundleOf, EntryID, IDMap, RawDataOf } from "data-handler/src/types"
import { LuxedoRPC } from "luxedo-rpc"
import { Media, type MediaRawData } from "../entries/Media"
import { DataHandlerFile } from "./DataHandlerFile"

const TRUE = 1
const FALSE = 0

export class DHMedia extends DataHandlerFile<Media> {
	EntryClass = Media

	protected async fetch(specificInstances?: EntryID[]): Promise<BundleOf<Media>> {
		return await LuxedoRPC.api.bundlers.media_bundle_down(specificInstances ?? [])
	}

	protected async pushData(entryData: { [id: number]: Partial<MediaRawData> }): Promise<void> {
		const pushableData = entryData as IDMap<MediaRawData>
		return await LuxedoRPC.api.bundlers.media_bundle_up(pushableData)
	}

	async requestDeleteEntry(entry: Media): Promise<void> {
		await LuxedoRPC.api.media.media_delete(entry.id!, TRUE)
	}

	async deleteSource(entry: Media): Promise<void> {
		await LuxedoRPC.api.media.media_delete(entry.id!, FALSE)
		this.pull([entry.id!])
		this.ondatachanged([entry.id!])
	}

	protected async requestCreateEntry(entryData: Partial<MediaRawData>): Promise<number> {
		const entryId = (
			await LuxedoRPC.api.media.media_create(
				entryData.name!,
				entryData.parent_id!,
				entryData.storage_mimetype!,
				this.EntryClass.hashRawData(entryData)
			)
		).new_file_id

		return entryId
	}

	public bindAlterEndpoints() {
		LuxedoRPC.bindEndpoint("video_alter_complete", (id) => {
			if (id in this.entries) {
				this.entries[id].alterCompleteListener()
				this.pull([id])
			}
		})
		LuxedoRPC.bindEndpoint("video_alter_cancelled", (id) => {
			if (id in this.entries) {
				this.entries[id].alterCancelListener()
			}
		})
		LuxedoRPC.bindEndpoint("video_alter_fail", (id) => {
			if (id in this.entries) {
				this.entries[id].alterFailListener()
			}
		})
		LuxedoRPC.bindEndpoint("video_alter_progress", (id, progress) => {
			if (id in this.entries) {
				this.entries[id].alterProgressListener(progress)
			}
		})
	}

	/**
	 * Relocates a file on the backend
	 * @param fileToMove The file being moved
	 * @param folderTo The folder of which the content is being moved to
	 */
	public async move(fileToMove: Media, folderTo: number) {
		await LuxedoRPC.api.filesys.fobj_move(fileToMove.id!, folderTo, TRUE)
		await this.pull([fileToMove.id!])
		this.ondatachanged([fileToMove.id!])
	}

	/**
	 * Renames a media file on the backend
	 * @param renameObject Object being renamed
	 * @param newName New media name
	 */
	public async rename(renameObject: Media, newName: string): Promise<void> {
		await LuxedoRPC.api.filesys.fobj_rename(renameObject.id!, TRUE, newName)
		await this.pull([renameObject.id!])
		this.ondatachanged([renameObject.id!])
	}
}

export const DataHandlerMedia = new DHMedia()
