<!-- DOES THIS WORK -->
<script lang="ts">
	import type { SvelteComponent } from "svelte"
	import { tooltip } from "../tooltip"
	/**
	 * A row of buttons - usually used with svg icons (be sure each svg element has either an "svg-fill" or "svg-stroke" class if relevant svg color automation).
	 * Options is an object with keys which will determine the selectedOption value
	 */

	export let options: {
		[index: string]: { comp: new (...args: any[]) => SvelteComponent; title: string }
	}
	export let selectedOption: string | number = undefined
	export let allSelectedOptions: Array<string | number> = []
	export let canSelectMultiple: boolean = false
	export let onSelect: (value: string | number, selected?: Array<string | number>) => void =
		undefined

	export let colorDefault = "var(--color-text-light)"
	export let colorHighlight = "var(--color-main)"
	export let colorBackground = undefined
	export let colorBackgroundHighlight = undefined

	export let updater = 0
	let forceUpdater = 0

	function select(key: keyof typeof options) {
		forceUpdater++
		if (onSelect) {
			onSelect(key)
		} else selectedOption = key
		if (canSelectMultiple) {
			const existingSelectionIndex = allSelectedOptions.findIndex((value) => value === key)
			if (existingSelectionIndex > -1) allSelectedOptions.splice(existingSelectionIndex, 1)
			else allSelectedOptions.push(key)

			allSelectedOptions = [...allSelectedOptions]
		}
	}

	function checkIfActive(key: string, updatedState: any) {
		if (canSelectMultiple) {
			if (allSelectedOptions.includes(key)) return true
			else return false
		}
		if (selectedOption === key) return true
		else return false
	}

	$: updater && (() => (forceUpdater += updater))()
</script>

<div
	class="button-row {canSelectMultiple ? 'multi-select' : ''}"
	style="--color-highlight: {colorHighlight}; --color-default: {colorDefault}"
>
	{#each Object.entries(options) as [key, props]}
		<button
			style="background-color: {checkIfActive(key, forceUpdater)
				? colorBackgroundHighlight
				: colorBackground}"
			type="button"
			on:click={() => select(key)}
			class="small {checkIfActive(key, forceUpdater) ? 'active' : ''}"
			use:tooltip={{ content: props.title, pointing: "top" }}
			><svelte:component this={props.comp} /></button
		>
	{/each}
</div>

<style>
	.button-row {
		--icon-row-width: 2rem;
		align-items: center;
	}

	.button-row button {
		padding: 0.25rem 0;
		height: 1.75rem;
		width: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--color-dark);
		border: 1px solid;
		border-color: var(--color-border);
	}

	.button-row button:first-child {
		padding-left: 0.25rem;
	}

	.button-row button:last-child {
		padding-right: 0.25rem;
	}

	.button-row button:not(:first-child):not(:last-child) {
		width: 1.75rem;
	}

	/* .button-row button.active {
		background-color: var(--color-border);
	} */

	.button-row button > :global(svg) {
		display: block;
		height: 100%;
	}

	button:hover,
	button:focus-visible {
		transform: unset;
	}

	.button-row button:not(.active):hover :global(.svg-fill),
	.button-row button:not(.active):focus-visible :global(.svg-fill) {
		fill: var(--color-highlight);
	}

	.button-row button :global(.svg-fill) {
		fill: var(--color-default);
	}

	.button-row button :global(.svg-stroke) {
		stroke: var(--color-default);
	}

	.button-row button.active :global(.svg-fill) {
		fill: var(--color-highlight);
	}

	.button-row button.active :global(.svg-stroke) {
		stroke: var(--color-highlight);
	}

	.button-row button:not(.active):hover :global(.svg-stroke),
	.button-row button:not(.active):focus-visible :global(.svg-stroke) {
		stroke: var(--color-highlight);
	}

	.button-row button :global(.svg-stroke) {
		stroke: var(--color-default);
	}

	.button-row button.active :global(.svg-stroke) {
		stroke: var(--color-highlight);
	}
</style>
