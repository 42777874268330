/**
 * @file asset/canvas/MediaImage.ts
 * @author Austin Day 2023
 * @description An extension of fabric image with Editor data such as media ID
 */
import { fabric } from "fabric"
import { type AssetOptions, CanvasAsset } from "../.."
import { EditorClass } from "../../.."
import { VideoSource } from "../video/VideoSource"
import { MediaSourceBase } from "../../MediaSource"
import { ElementFlags } from "typescript"
export interface MediaOptions extends AssetOptions {
	/** Unique ID for this image's media, used for importing and exporting */
	mediaId: number

	/** Source URL of the image */
	src: string
}

export interface MediaAsset<T extends MediaSourceBase<any> | HTMLImageElement>
	extends fabric.Image,
		CanvasAsset,
		MediaOptions {
	/**
	 * Create an image that can be manipulated in the editor
	 */
	mediaId: number

	missingSrc: boolean

	isLoading: boolean
}

/**
 * Create a fabric Image with extra properties for the editor
 */
@CanvasAsset.Mixin
export abstract class MediaAsset<T extends MediaSourceBase<any> | HTMLImageElement> extends fabric.Image {
	//#region    ===========================		   		Construction 		 		==============================
	editor: EditorClass

	/**
	 * Create a fabric Image object suited for the luxedo editor
	 * @param options
	 * @param importOptions extra options used when loading from a json file
	 */
	constructor(editor: EditorClass, options: MediaOptions, objectOptions?: fabric.IImageOptions) {
		super(undefined, objectOptions)
		this.editor = editor
		this.initAsset(options)
		this.mediaId = options.mediaId
		this.loadingPromise = this.load(options.src).catch(this.setMissingSource.bind(this))
	}

	protected _content: T
	/**
	 * The underlying image DOM element
	 */
	get content(): T {
		return this._content
	}

	set content(element: T) {
		this._content = element
		if (element instanceof VideoSource) this.setElement(element.video)
		else if (element instanceof HTMLImageElement) this.setElement(element)
	}

	/**
	 * Used for handling the unbinding of events and the deletion of all references to content, if needed
	 */
	onDelete() {
		this.clearEvents()
		this.dispose()
	}

	//#endregion =====================================================================================================

	//#region    ===========================				 Properties	 		 		==============================

	abstract get contentWidth(): number
	abstract get contentHeight(): number

	//#endregion =====================================================================================================

	//#region    ===========================		   	 Resource Loading		 		==============================

	isLoading = true
	public loadingPromise: Promise<any>

	protected async load(url: string): Promise<void> {
		this.isLoading = true
		this.initEmptyContent()

		await this.loadContent(url)

		this.onContentLoaded()
		this.isLoading = false
	}

	protected abstract initEmptyContent(): void

	protected abstract loadContent(url: string): Promise<any>

	protected abstract onContentLoaded(): void

	//#endregion =====================================================================================================

	missingSrc = false
	protected setMissingSource() {
		console.warn("setting missing source")

		this.missingSrc = true

		if (!this.width) this.width = 100
		if (!this.height) this.height = 100
		this.setElement(generatePlaceholderImg(this.width, this.height))
	}

	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude: string[]
	} {
		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		return {
			include: [...expProps, "mediaId"],
			deepCopy: [],
			exclude: ["track", "clipPath"],
		}
	}

	perPixelTargetFind?: boolean = true
}

/**
 * Create an image media object which is a placeholder image indicating that the source is missing
 * @param width Width of the image
 * @param height Height of the image
 */
function generatePlaceholderImg(width: number = 100, height: number = 100) {
	const lineWeight = Math.floor(Math.min(width, height) / 10)

	const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
	svg.setAttribute("viewBox", `0 0 ${width} ${height}`)
	svg.setAttribute("width", `${width}`)
	svg.setAttribute("height", `${height}`)

	const background = document.createElementNS("http://www.w3.org/2000/svg", "rect")
	background.setAttribute("width", `${width}`)
	background.setAttribute("height", `${height}`)
	background.setAttribute("fill", "black")
	background.setAttribute("opacity", "30%")
	svg.appendChild(background)

	const outline = document.createElementNS("http://www.w3.org/2000/svg", "rect")
	outline.setAttribute("width", `${width}`)
	outline.setAttribute("height", `${height}`)
	outline.setAttribute("fill", "none")
	outline.setAttribute("stroke", "red")
	outline.setAttribute("stroke-width", `${lineWeight}`)
	svg.appendChild(outline)

	const line1 = document.createElementNS("http://www.w3.org/2000/svg", "line")
	line1.setAttribute("x1", "0")
	line1.setAttribute("y1", "0")
	line1.setAttribute("x2", `${width}`)
	line1.setAttribute("y2", `${height}`)
	line1.setAttribute("stroke", "red")
	line1.setAttribute("stroke-width", `${lineWeight / 2}`)
	svg.appendChild(line1)

	const line2 = document.createElementNS("http://www.w3.org/2000/svg", "line")
	line2.setAttribute("x1", "0")
	line2.setAttribute("y1", `${height}`)
	line2.setAttribute("x2", `${width}`)
	line2.setAttribute("y2", "0")
	line2.setAttribute("stroke", "red")
	line2.setAttribute("stroke-width", `${lineWeight / 2}`)
	svg.appendChild(line2)

	const text = document.createElementNS("http://www.w3.org/2000/svg", "text")
	text.setAttribute("x", `${width / 2}`)
	text.setAttribute("y", `${height / 2}`)

	const fontSize = Math.floor(width / 10) // Set font size based on width
	text.setAttribute("font-size", `${fontSize}px`) // Use font size in pixels
	text.setAttribute("font-weight", "bold")
	text.setAttribute("font-family", "Open Sans")
	text.setAttribute("text-anchor", "middle")
	text.setAttribute("alignment-baseline", "middle")
	text.setAttribute("fill", "white")
	text.textContent = "Source Missing"
	svg.appendChild(text)

	const serializer = new XMLSerializer()
	const svgString = serializer.serializeToString(svg)
	const encodedSvg = btoa(svgString)
	const dataURL = `data:image/svg+xml;base64,${encodedSvg}`

	const img = new Image(width, height)
	img.src = dataURL
	return img
}
