import { writable } from "svelte/store"

export namespace GroupAlignController {
	type ContextType = {
		step: number
	}

	const CONTEXT_DEFAULT = {
		step: 0,
	}

	const store = writable<ContextType>(CONTEXT_DEFAULT)
	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	export function setStep(stepIndex: number) {
		if (stepIndex < 0 || stepIndex > 1) return
		store.update((ctx) => ({ ...ctx, step: stepIndex }))
	}

	export function next() {
		store.update((ctx) => ({ ...ctx, step: ctx.step + 1 }))
	}

	export function back() {
		store.update((ctx) => ({ ...ctx, step: ctx.step - 1 }))
	}

	export function reset() {
		store.set(CONTEXT_DEFAULT)
	}
}
