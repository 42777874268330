<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_615_66)">
		<path
			d="M38.7788 1.84216H14.6078L2.52234 25.0001L14.6078 48.158H38.7788L50.8643 25.0001L38.7788 1.84216Z"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
		/>
	</g>
	<defs>
		<clipPath id="clip0_615_66">
			<rect width="53" height="50" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
