<script lang="ts">
	import type { Design, Scene } from "luxedo-data"
	import { DragController } from "../../../../stores/DragContext"
	import DragOverIndicator from "./DragOverIndicator.svelte"

	export let index: number
	export let isEnd: boolean = false
	export let isHoverOver: boolean = false
	export let onDrop: (scene: Scene, previousDesignPositionIndex?: number) => void

	function onDragOver() {
		isHoverOver = true
	}

	function onDragLeave() {
		isHoverOver = false
	}

	function onSceneDrop(e: DragEvent, scene: Scene, prevPosIndex?: number) {
		isHoverOver = false
		onDrop(scene, prevPosIndex)
	}
</script>

<div
	class="scene-dropzone {isHoverOver ? 'dragover' : ''} {index === -1 ? 'left' : ''} {isEnd
		? 'right'
		: ''}"
	role="presentation"
	on:dragleave={onDragLeave}
	on:dragover={DragController.onDragOver(onDragOver)}
	on:dragend={DragController.onDragEnd(onDragLeave)}
	on:drop={DragController.onDrop(onSceneDrop)}
>
	<!-- {#if isHoverOver}
		<DragOverIndicator />
	{/if} -->
</div>

<style>
	.scene-dropzone {
		position: absolute;
		z-index: 2;
		right: 0;
		transform: translateX(50%);
		height: 110%;
		width: var(--tile-width);
		flex-grow: 1;
		opacity: 50%;
	}

	.scene-dropzone.left {
		transform: translateX(0);
		left: 0;
		bottom: 4rem;
		height: calc(100% - 4rem);

		z-index: 2;
	}

	.scene-dropzone.right {
		transform: translateX(0);
		width: 100%;
		height: calc(100% - 4rem);

		right: 0;
		bottom: 4rem;
		z-index: 1;
	}

	.scene-dropzone.dragover {
	}
</style>
