<script lang="ts">
	export let strokeColor = "black"
</script>

<svg
	class="plus-icon"
	width="39"
	height="39"
	viewBox="0 0 39 39"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_606_47)">
		<line
			x1="1.5"
			y1="19.5"
			x2="37.5"
			y2="19.5"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
		<line
			x1="19.5"
			y1="37.5"
			x2="19.5"
			y2="1.5"
			stroke={strokeColor}
			class="svg-stroke"
			stroke-width="3"
			stroke-linecap="round"
		/>
	</g>
	<defs>
		<clipPath id="clip0_606_47">
			<rect width="39" height="39" fill="white" />
		</clipPath>
	</defs>
</svg>
