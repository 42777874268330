import { fabric } from "fabric"
import { Transformable, VideoAsset } from ".."
import { PerspectiveTransformer, Corners, deepCopyPoints } from "../../../fabric-plugins"
import { Serializable, convertInstanceToObject } from "../../../modules/serialize"
import { getEditor } from "../../.."
import { VideoSource } from "../video/VideoSource"

export class TransformedAsset extends fabric.Polygon implements Serializable {
	sourceAsset: Transformable

	quality: number

	constructor(sourceMedia: Transformable, quality: number = 0.5) {
		super([], {
			originX: "center",
			originY: "center",
			lockMovementX: true,
			lockMovementY: true,
		})

		this.quality = quality
		this.pathOffset = new fabric.Point(0, 0)

		this.sourceAsset = sourceMedia
		this.points = this.getOriginalCorners()
		this.updateTransform()

		this[Transformable.isTransformableAsset] = true
	}

	//#region    ===========================		 Perspective Transformation			==============================

	get filterScale() {
		return Math.max(Math.min(this.scaleX, this.scaleY, 1) * this.quality, 0.3)
	}

	private getOriginalCorners(): Corners {
		return [
			new fabric.Point(-this.sourceAsset.contentWidth / 2, -this.sourceAsset.contentHeight / 2),
			new fabric.Point(this.sourceAsset.contentWidth / 2, -this.sourceAsset.contentHeight / 2),
			new fabric.Point(this.sourceAsset.contentWidth / 2, this.sourceAsset.contentHeight / 2),
			new fabric.Point(-this.sourceAsset.contentWidth / 2, this.sourceAsset.contentHeight / 2),
		]
	}

	downscaledPoints(): fabric.Point[] {
		const ret = []
		for (const point of this.points) {
			ret.push(point.multiply(this.filterScale))
		}
		return ret
	}

	updateTransform() {
		const pointSet = this.downscaledPoints()
		this.perspectiveMapper = new PerspectiveTransformer(this.getOriginalCorners(), pointSet)
		this.width = this.perspectiveMapper.outputWidth / this.filterScale
		this.height = this.perspectiveMapper.outputHeight / this.filterScale
		this.dirty = true
		getEditor().canvas.requestRenderAll()
	}

	protected cachedTransform: HTMLCanvasElement

	cachedScales: {
		scaleX: number
		scaleY: number
	}
	isCacheDirty(skipCanvas?: boolean): boolean {
		if (!this.cachedTransform || this.dirty || this.sourceAsset.isCacheDirty()) return true
		if (!this.cachedScales || this.cachedScales.scaleX != this.scaleX || this.cachedScales.scaleY != this.scaleY) {
			this.cachedScales = {
				scaleX: this.scaleX,
				scaleY: this.scaleY,
			}
			this.updateTransform()
			return true
		}
		return false
	}

	getTransformedImage() {
		if (this.isCacheDirty()) {
			if (this.sourceAsset.content instanceof VideoSource)
				this.cachedTransform = this.perspectiveMapper.getTransformedImage(this.sourceAsset.content.video)
			else this.cachedTransform = this.perspectiveMapper.getTransformedImage(this.sourceAsset.content)
			this.dirty = false
		}

		return this.cachedTransform
	}

	renderOnCanvas(ctx: CanvasRenderingContext2D): void {
		let outputImage: HTMLCanvasElement

		outputImage = this.getTransformedImage()

		let scaleX = this.filterScale
		let scaleY = this.filterScale

		let w = this.width,
			h = this.height,
			elWidth = outputImage.width,
			elHeight = outputImage.height,
			// the width height cannot exceed element width/height, starting from the crop offset.
			sW = w * scaleX,
			sH = h * scaleY,
			x = -w / 2,
			y = -h / 2,
			maxDestW = Math.min(w, elWidth / scaleX),
			maxDestH = Math.min(h, elHeight / scaleY)

		ctx.drawImage(outputImage, 0, 0, sW, sH, x, y, maxDestW, maxDestH)
	}

	protected perspectiveMapper: PerspectiveTransformer

	//#endregion =====================================================================================================

	//#region    ===========================				  Serialization				==============================

	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude?: string[]
	} {
		const allProps = Object.keys(this)
		const expProps = []
		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}
		return {
			include: [...expProps, "isTransformed"],
			deepCopy: ["transformedContent"],
			exclude: [],
		}
	}

	/** This shouldn't be called overtly, but will be used by media when deep copying this */
	serialize(forExport: boolean) {
		const data = convertInstanceToObject(this, { forExport, propertiesToExclude: ["points"] })
		const points = deepCopyPoints(this.points)
		data.points = points
		return data
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Important Passthroughs			==============================
	get canvas() {
		return getEditor().canvas
	}
	set canvas(v) {
		return
	}
	get width() {
		return this.sourceAsset?.width
	}
	set width(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.width = v
	}

	get height() {
		return this.sourceAsset?.height
	}
	set height(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.height = v
	}

	get left() {
		return this.sourceAsset?.left
	}
	set left(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.left = v
	}

	get top() {
		return this.sourceAsset?.top
	}
	set top(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.top = v
	}

	get scaleX() {
		return this.sourceAsset?.scaleX
	}
	set scaleX(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.scaleX = v
	}

	get scaleY() {
		return this.sourceAsset?.scaleY
	}
	set scaleY(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.scaleY = v
	}

	get angle() {
		return this.sourceAsset?.angle
	}
	set angle(v) {
		if (!this.sourceAsset) return
		this.sourceAsset.angle = v
	}

	//#endregion =====================================================================================================
}
