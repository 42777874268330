<script lang="ts">
	import type { Component } from "../../../../../types/SvelteTypes"
	import { MaskSidebarController } from "./MaskEditorSidebarController"
	import SidebarCreate from "./Sidebar_Create.svelte"
	import SidebarEdit from "./Sidebar_Edit.svelte"
	import SidebarEmpty from "./Sidebar_Empty.svelte"
	import SidebarList from "./Sidebar_List.svelte"
	import SidebarImport from "./Sidebar_Import.svelte"

	let activeMenu: MaskSidebarController.MenuOption = "new"
	MaskSidebarController.subscribe((ctx) => {
		activeMenu = ctx.menu
	})

	const MENU_OPTIONS: { [key in MaskSidebarController.MenuOption]: Component } = {
		empty: SidebarEmpty,
		edit: SidebarEdit,
		list: SidebarList,
		new: SidebarCreate,
		import: SidebarImport,
	}
</script>

<div id="sidebar">
	<svelte:component this={MENU_OPTIONS[activeMenu]} />
</div>

<style>
	#sidebar {
		height: 100%;
		overflow: hidden;
		padding: 1rem 0.5rem 1rem 1rem;
		width: var(--sidebar-width);
		background-color: var(--color-bg-darker);
		display: flex;
		flex-direction: column;
	}

	#sidebar :global(.cancel-button) {
		padding-left: 0;
	}

	#sidebar :global(.cancel-button):hover,
	#sidebar :global(.cancel-button):focus-visible {
		transform: unset;
	}

	#sidebar :global(h2) {
		font-size: var(--h0);
		margin: 0;
	}

	#sidebar :global(.info) {
		color: var(--color-text);
	}

	#sidebar :global(.create-buttons) {
		margin-bottom: 0;
		align-items: center;
	}

	#sidebar :global(.create-buttons:not(:has(.cancel-button)) .create-button) {
		width: 100%;
	}
</style>
