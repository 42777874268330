/**
 * @file asset/canvas/Polygon.ts
 * @author Austin Day 2023
 * @description An extension of the fabric polygon with Editor data
 */
import { fabric } from "fabric"
import { CanvasAsset } from "."
import { type AssetOptions } from "../Asset"
import { deepCopyPoints, fixPointSet } from "../../fabric-plugins/pointsUtil"
import { convertInstanceToObject, registerSerializableConstructor } from "../../modules/serialize"
import { EditorClass, getEditor } from "../../Editor"
import { Path } from "./path"

interface BasePolygonOptions extends AssetOptions {
	points: fabric.Point[]
}

interface RegularPolygonOptions_Dimensions extends AssetOptions {
	numSides: number
	width: number
	height: number
}

interface RegularPolygonOptions_Radius extends AssetOptions {
	numSides: number
	polyRadius: number
}

type RegularPolygonOptions = RegularPolygonOptions_Dimensions | RegularPolygonOptions_Radius

type PolygonOptions = BasePolygonOptions & RegularPolygonOptions

export interface Polygon extends CanvasAsset {}

/**
 * Create a fabric Polygon with extra properties for the editor
 */
@CanvasAsset.Mixin
export class Polygon extends fabric.Polygon {
	//#region    ===========================		   		Construction 		 		==============================

	numSides = undefined

	editor: EditorClass
	constructor(editor: EditorClass, options: BasePolygonOptions, objectOptions?: fabric.IPolylineOptions)
	constructor(editor: EditorClass, options: RegularPolygonOptions, objectOptions?: fabric.IPolylineOptions)
	constructor(editor: EditorClass, options: AssetOptions, objectOptions?: fabric.IPolylineOptions) {
		super(Polygon.calcPoints(options as PolygonOptions), objectOptions)

		this.initAsset(options)
		this.editor = editor
		if ("numSides" in options) this.numSides = options.numSides
		if ("width" in options)
			this.scaleTo(
				(options as RegularPolygonOptions_Dimensions).width,
				(options as RegularPolygonOptions_Dimensions).height
			)

		if ((options as PolygonOptions).points) {
			this.recenterPoints()
		}
	}

	static FromPath(path: Path, options?: fabric.IPolylineOptions): Polygon {
		options = options || {}

		const polygon = new Polygon(
			getEditor(),
			{
				points: deepCopyPoints(path.points),
			},
			{
				...options,
			}
		)

		return polygon
	}

	scaleTo(width: number, height: number) {
		const wScale = width / this.width
		const hScale = height / this.height

		this.scaleX = wScale
		this.scaleY = hScale
	}

	like(shape: fabric.Polyline) {
		this.points = deepCopyPoints(shape.points)

		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left
		this.top = shape.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)
	}

	/**
	 * Get the points of a regular polygon - i.e. a polygon where all the sides and angles are the same.
	 */
	static calcPoints(options: PolygonOptions): fabric.Point[] {
		if (options.points) return options.points

		const points = [] as fabric.Point[]
		const radius = "polyRadius" in options ? options.polyRadius : options.width / 2
		const pi = Math.PI

		/** This makes it so that the first point will always be placed in such a way that the base of the shape is horizontal */
		const phaseOffset = pi / options.numSides + pi / 2

		for (let i = 0; i < options.numSides; i++) {
			points.push(
				new fabric.Point(
					radius * Math.cos((2 * pi * i) / options.numSides + phaseOffset),
					radius * Math.sin((2 * pi * i) / options.numSides + phaseOffset)
				)
			)
		}

		return points
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	    Serialization		 		==============================
	serialize(forExport?: boolean): Partial<this> {
		const serialdata = convertInstanceToObject(this, {
			forExport,
			propertyGetters: {
				getBaseValue: this.AnimatableProperties,
			},
		})

		const points = deepCopyPoints(this.points)
		serialdata["points"] = points

		return serialdata
	}

	static async loadJSON(editor: EditorClass, polygonData: Partial<Polygon>, id?: string) {
		polygonData.points = fixPointSet(polygonData.points)
		const poly = new Polygon(
			editor,
			{
				points: polygonData.points,
				id,
			},
			polygonData
		)

		return poly
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	 Default properties		 		==============================
	perPixelTargetFind = true
	objectCaching = false
	strokeUniform = true
	//#endregion =====================================================================================================
}

registerSerializableConstructor(Polygon)
