<script lang="ts">
	import { ResponsiveStore } from "../stores/ResponsiveStore"
	import type { SideMenuOptions } from "./OverlayController"

	import { ArrowForward } from "../icons"

	let isMobile: boolean
	let isOpen = false
	ResponsiveStore.subscribe((mobile) => (isMobile = mobile))

	export let options: SideMenuOptions

	function toggleSideMenu() {
		isOpen = !isOpen
	}
</script>

<nav
	class="SideMenu"
	style={`${isMobile && !isOpen ? "transform: translateY(-100%)" : "transform: translateX(0)"}`}
>
	{#each Object.values(options) as option}
		<button
			class="icon {option.isActive ? 'active' : ''}"
			on:click={() => {
				option.onClick()
				isOpen = false
			}}>{option.title}</button
		>
	{/each}
	<slot />
</nav>
{#if isMobile}
	<div
		class="blur-bg"
		on:click={toggleSideMenu}
		on:keypress={(e) => {
			if (e.key === "Escape") toggleSideMenu()
		}}
		style={isOpen ? "opacity: 1" : "opacity: 0; pointer-events: none"}
		role="button"
	/>
	<button class="mobile-open-btn icon" on:click={toggleSideMenu}
		><ArrowForward strokeColor={`var(--color-text-light)`} /></button
	>
{/if}

<style>
	nav {
		z-index: 25;
		position: absolute;
		width: var(--side-menu-width);
		height: 100%;
		left: 0;
		background-color: var(--color-bg-darker);
		box-sizing: var(--shadow-large);
		border-right: 1px solid var(--color-accent);
		transition: transform var(--transition-duration);
	}

	.blur-bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: var(--grey-transparent);
		backdrop-filter: blur(0.25rem);
		transition: opacity var(--transition-duration);
	}

	:global(.overlay) > .SideMenu {
		padding-top: 1rem;
		top: 3rem;
		height: calc(100% - 4rem);
	}

	.mobile-open-btn {
		position: absolute;
		background-color: var(--color-accent);
		border-bottom-right-radius: var(--br);
		width: 2rem;
		padding: 0;
		left: 0rem;
		top: 3rem;
		height: 2rem;
	}

	.mobile-open-btn > :global(svg) {
		height: 2rem;
		transform: rotate(90deg);
	}

	button {
		color: var(--color-text-light);
		padding: 0.5rem 1rem;
		width: 100%;
		border-radius: 0;
		transform: unset;
		position: relative;
	}

	button::after {
		transform: translateX(-100%);
		content: "";
		width: 0.5rem;
		height: 100%;
		position: absolute;
		left: 0;
		background-color: var(--color-main);
		border-top-right-radius: var(--br);
		border-bottom-right-radius: var(--br);
		transition: transform var(--transition-duration);
	}

	button.active::after {
		transform: translateX(0);
	}

	button:focus-visible,
	button:hover {
		color: var(--color-main);
	}

	button:last-of-type {
		border-bottom: none;
	}
</style>
