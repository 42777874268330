<script lang="ts">
	import { ColorPickerStore, closeTagColorPicker } from "./TagColorPickerContext"
	import TagColorPickerPointer from "./TagColorPickerPointer.svelte"
	import { DataHandlerTag, type Tag } from "luxedo-data"

	let isOpen = false
	let tag: Tag
	let left: number
	let top: number
	let clickTop: number
	export let isLibraryContext: boolean

	let colorPickerElem: HTMLDivElement

	ColorPickerStore.subscribe((ctx) => {
		if (ctx.isOpen && isLibraryContext !== ctx.isLibraryContext) {
			isOpen = false
		} else {
			isOpen = ctx.isOpen
			if (ctx.isOpen) {
				tag = ctx.tag
				top = ctx.top
				clickTop = ctx.top
				left = ctx.left
			} else {
				tag = undefined
				top = undefined
				left = undefined
			}
		}
	})

	const colorOptions = [
		"DE5357",
		"FF9D00",
		"EFE23F",
		"62BE60",
		"0087B0",
		"4647DE",
		"9774CC",
		"FF4083",
		"FF5803",
		"F0BE31",
		"96BF60",
		"04B2A8",
		"4987DE",
		"CD74CC",
		"983F40",
		"B07004",
		"A49D30",
		"498147",
		"045C78",
		"383696",
		"6C5789",
		"AF325B",
		"B04206",
		"A57D31",
		"688247",
		"077A74",
		"395F96",
		"855789",
	]

	async function updateTrackColor(color: string) {
		tag.color = `#${color}`
		DataHandlerTag.save(tag)
		closeTagColorPicker()
	}

	function ensureBoundsWithinWindow() {
		const { bottom } = colorPickerElem.getBoundingClientRect()
		if (bottom > window.innerHeight) top -= bottom - window.innerHeight + 16
	}

	$: isOpen && colorPickerElem && ensureBoundsWithinWindow()
</script>

{#if isOpen}
	<div class="bg-clear" on:click={closeTagColorPicker} on:keydown={undefined} />
	<div
		id="track-color-picker"
		bind:this={colorPickerElem}
		style="left: {left + 78}px; top: {top - 28}px;"
	>
		{#each colorOptions as color}
			<div
				on:click={() => updateTrackColor(color)}
				class="color-option"
				style="background-color: #{color};"
				on:keydown={undefined}
			/>
		{/each}
		<div id="track-color-point" style="top: {clickTop - top + 20}px;">
			<TagColorPickerPointer />
		</div>
	</div>
{/if}

<style>
	#track-color-picker {
		border: 1px solid var(--color-border);
		position: fixed;
		z-index: 102;
		backdrop-filter: blur(3rem);
		border-radius: var(--br);
		background-color: var(--color-bg);
		padding: 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		gap: 1rem;
	}

	#track-color-point {
		z-index: 101;
		position: fixed;
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
		background-color: var(--color-bg);
		backdrop-filter: blur(3rem);
		left: -1.75rem;
		/* top: 1rem; */
	}

	#track-color-point :global(svg) {
		width: 2rem;
		height: 2rem;
	}

	.color-option {
		cursor: pointer;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 0.25rem;

		transition: transform 250ms;
	}

	.color-option:hover,
	.color-option:focus-visible {
		transform: scale(1.2);
	}

	.bg-clear {
		background-color: var(--color-bg);
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
	}
</style>
