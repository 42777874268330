<script lang="ts">
	import { closeOverlay } from "../../overlay"
	import ErrorReportForm from "../error-reporting/ErrorReportForm.svelte"
	import GuideList from "../error-reporting/GuideList.svelte"

	export let originalError: Error = undefined // The error that triggered the user to send feedback (if relevant)
	export let additionalInfo: string = undefined // Optional string to add more data to feedback sent to sentry

	export let onSubmit: (
		feedback: string,
		options: {
			originalError?: Error
			additionalInfo: string
		}
	) => void

	let error: string = ""
</script>

<div id="user-feedback-container">
	<GuideList />

	<ErrorReportForm bind:error />
</div>
<div class="bottom-row">
	<span>
		Need help? Checkout our
		<a target="_blank" href="https://www.luxedo.com/pages/help">help center</a>.
	</span>
	<div class="button-container">
		<button class="link-button" on:click={() => closeOverlay()}>Cancel</button>
		<button class="save-button" on:click={() => onSubmit(error, { originalError, additionalInfo })}
			>Submit</button
		>
	</div>
</div>

<style>
	#user-feedback-container {
		width: calc(80vw - 6rem);
		height: calc(80vh - 7rem);
		display: grid;
		grid-template-columns: 30% 70%;
		grid-template-rows: 1fr;
		position: relative;
		padding-bottom: 1rem;
	}

	#user-feedback-container :global(#error-report-form-container) {
		margin-left: 1rem;
	}

	.button-container {
		margin-bottom: unset;
		justify-content: flex-end;
		width: fit-content;
	}

	.bottom-row {
		position: absolute;
		width: calc(100% - 6rem);
		bottom: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	a {
		color: var(--color-main);
		border-color: var(--color-main);
	}
</style>
