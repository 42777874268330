<script lang="ts">
	import { Route } from "svelte-routing"
	import { onMount } from "svelte"

	import LoadingPage from "../loaders/LoadingPage.svelte"
	import { pageInit } from "../../../modules/auth/onUserLogin"
	import type { Component } from "../../../types/SvelteTypes"

	export let component: Component
	export let path: string

	let redirectPath: string = "/login"
	let isLoading = true

	function redirect() {
		window.location.replace("/login")
	}

	onMount(async () => {
		if (redirectPath === path)
			throw new Error("Cannot set a redirect path to be the same as path - a loop will occur.")
	})

	async function onRouteMount() {
		const isUserLoggedIn = await pageInit.initPageLoad()
		if (!isUserLoggedIn) return redirect()
		isLoading = false
	}
</script>

<Route {path} let:params>
	{#if isLoading}
		<LoadingPage {onRouteMount} />
	{:else}
		<svelte:component this={component} {params} />
	{/if}
</Route>

<style>
</style>
