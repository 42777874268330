import { type BundleOf, DataHandler } from "data-handler"
import { CalibratedMask, type MaskJson, type MaskRawData } from "../entries/Mask"
import { LuxedoRPC } from "luxedo-rpc"
import { EntryCreationError } from "../types/ErrorTypes"
import type { Device } from "../entries/Device"
import { DataHandlerCalibration } from "./DataHandlerCalilbration"

class DHMask extends DataHandler<CalibratedMask> {
	EntryClass = CalibratedMask

	protected async fetch(specificInstances?: number[]): Promise<BundleOf<CalibratedMask>> {
		const res = await LuxedoRPC.api.bundlers.mask_bundle_down(specificInstances ?? [])
		return res
	}

	protected async pushData(entryData: { [id: number]: Partial<MaskRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.mask_bundle_up(entryData)
	}

	protected async requestCreateEntry(entryData: Partial<MaskRawData>): Promise<number> {
		const { cal_id, name, mask_json } = entryData
		if (!cal_id || !name || !mask_json)
			throw new EntryCreationError("Missing data when creating mask entry", {
				entryType: CalibratedMask,
				entryData,
			})

		const id = await LuxedoRPC.api.mask.create_cal_mask(cal_id, name, mask_json as MaskJson)
		return id
	}

	protected async requestDeleteEntry(entry: CalibratedMask): Promise<void> {
		return await LuxedoRPC.api.mask.delete_cal_mask(entry.id!)
	}

	filterByCalibration(calId: number) {
		return Object.values(this.entries).filter((mask) => mask.cal_id === calId)
	}

	filterByDevice(device: Device) {
		const calibrations = DataHandlerCalibration.filterByDevice(device)
		let masks: Array<CalibratedMask> = []
		for (const cal of calibrations) {
			const maskList = this.filterByCalibration(cal.id!)
			masks = masks.concat(maskList)
		}

		return masks
	}
}

export const DataHandlerMask = new DHMask()
