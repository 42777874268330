import { type BundleOf, DataHandler, type IDMap, type EntryID } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import { type BaseDeviceRawData, Device, DeviceConstructor, type DeviceRawData } from "../entries/Device"

type Constructor<T extends Object> = new (...args: any) => T
class DHDevice extends DataHandler<Device> {
	// @ts-ignore
	EntryClass = DeviceConstructor

	constructor() {
		super()

		LuxedoRPC.bindEndpoint("device_on_status_change", (id, eidos) => {
			if (id in this.entries) this.entries[id].onEidosUpdate(eidos)
		})
	}

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Device>> {
		const data = await LuxedoRPC.api.bundlers.device_bundle_down(specificInstances ?? [])
		return data
	}

	protected async pushData(entryData: { [id: number]: Partial<DeviceRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.device_bundle_up(entryData as IDMap<DeviceRawData>)
	}

	async requestCreateEntry(entryData: Partial<DeviceRawData>): Promise<number> {
		console.error("THIS NEEDS TO BE SET UP FOR A REAL DEVICE, NOT CAXEDO")
		const res = await LuxedoRPC.api.device.device_caxedo_create(entryData.name ?? "New Caxedo")
		return res.dev_id
	}

	protected async requestDeleteEntry(entry: Device<BaseDeviceRawData>): Promise<void> {
		return await LuxedoRPC.api.device.device_detach(entry.id!)
	}

	/**
	 * Loops through the user's devices, getting the lowest unused UIColor index
	 */
	getNextUIColor() {
		let index = 0

		const entryColors = Object.values(this.entries).map((device) => device._color)

		while (entryColors.includes(index)) index++

		return index
	}
}

export const DataHandlerDevice = new DHDevice()
