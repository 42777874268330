<script lang="ts">
	import ErrorReportIcon from "./ErrorReportIcon.svelte"

	export let error: string
</script>

<div id="error-reporter">
	<!-- <script
		defer
		src="https://contact.gorgias.help/api/contact-forms/loader.js?v=3"
		data-gorgias-loader-contact-form
		data-gorgias-contact-form-uid="3tvpft7k"
	></script> -->

	<div class="icon-center-container">
		<ErrorReportIcon />
	</div>
	<div id="error-report-form-container">
		<h3>Report an Error</h3>
		<p>Let us know what happened and we'll take care of it.</p>
		<textarea name="" id="" bind:value={error} />
	</div>
</div>

<style>
	.icon-center-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
	}

	#error-reporter {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		overflow-y: scroll;
		margin-left: 1rem;
		margin-top: -2.4rem;
		padding-top: -2.4rem;
	}

	h3 {
		margin: 0;
		font-size: var(--h2);
	}

	#error-report-form-container p {
		font-size: var(--h3);
		color: var(--color-text);
	}

	textarea {
		resize: none;
		background-color: var(--color-bg-dark);
		border: none;
		height: 6rem;
	}

	textarea:focus-visible {
		border: none;
		outline: none;
	}
</style>
