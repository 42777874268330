<script lang="ts">
	import { PowerIcon } from "svelte-comps/icons"
	import { tooltip } from "svelte-comps/tooltip"

	export let isOn: boolean
	export let isChanging: boolean
	export let togglePower: () => void

	function getTooltip(on: boolean) {
		if (on) {
			return {
				content: "Power off projector",
				pointing: "top" as "top",
			}
		} else {
			return { content: "Power on projector", pointing: "top" as "top" }
		}
	}
</script>

<button
	class="small icon power-button {isOn ? 'ON' : 'OFF'} {isChanging ? 'changing' : ''}"
	title={getTooltip(isOn).content}
	on:click={isChanging ? undefined : togglePower}
	disabled={isChanging}
>
	<PowerIcon />
</button>

<style>
	.power-button.ON {
		--color-icon: var(--color-main);
	}

	.power-button.OFF {
		--color-icon: var(--color-text);
	}

	.power-button :global(.svg-fill) {
		fill: var(--color-icon);
		transition: fill 150ms;
	}

	@keyframes flashing {
		0% {
			fill: var(--color-main);
		}

		50% {
			fill: var(--color-text);
		}

		100% {
			fill: var(--color-main);
		}
	}

	.power-button:disabled {
		opacity: 1;
	}

	.power-button.changing :global(.svg-fill) {
		animation-name: flashing;
		animation-duration: 1500ms;
		animation-iteration-count: infinite;
	}
</style>
