<script lang="ts">
	import { AccountNavigator } from "../../AccountStore"
	import SubmenuHeading from "../SubmenuHeading.svelte"

	function goBack() {
		AccountNavigator.closeSubscription()
	}
</script>

<SubmenuHeading onBack={goBack}>
	<div class="heading-content">
		<h2>Cancel Storage Subscription</h2>
		<span>Click the "Cancel Subscription" button below to cancel your subscription.</span>
	</div>
</SubmenuHeading>

<div class="cancel-text-container">
	<p>
		Are you sure you want to cancel your subscription? You can continue to use extra storage until
		the end of your current billing cycle, after which you will not be charged and the subscription
		will be terminated.
	</p>
	<p class="warning">
		⚠ If your media surpasses your storage capacity after the plan is ended, over-capacity media
		will be deleted after two weeks.
	</p>
</div>

<div class="button-container">
	<button class="link-button" on:click={goBack}>Go Back</button>
	<button class="warn">Cancel Subscription</button>
</div>

<style>
	h2 {
		font-size: var(--h1);
		margin: 0;
	}

	.heading-content {
		display: flex;
		flex-direction: column;
	}

	.heading-content span {
		font-size: var(--h3);
	}

	.warning {
		color: var(--color-error);
	}

	.cancel-text-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p {
		color: var(--color-text);
	}

	.button-container {
		width: fit-content;
		position: absolute;
		bottom: 1rem;
		right: 2rem;
	}
</style>
