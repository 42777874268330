<script lang="ts">
	import { SmallMinusIcon, PlusIcon } from "svelte-comps/icons"
	import SubmenuHeading from "../SubmenuHeading.svelte"
	import { LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import type { ChargebeePlanPrice } from "luxedo-rpc/src/routes/chargebee"
	import { getUserPlan } from "../storage/StorageSubscriptionController"
	import { getCurrentUser } from "../../../../../stores/UserStore"
	import { AccountNavigator } from "../../AccountStore"

	let plan: ChargebeePlanPrice
	let initialQuantity: number
	let quantity: number = 0
	let pricePerUnit: number

	let isPlanAlreadyOwned = false

	let total = 0

	LuxLinkSubscriptionManager.subscribe((ctx) => {
		plan = ctx.plan
		quantity = ctx.addonQuantity ?? 0
		const addonPricePlan = ChargebeeManager.getAddonPricing(plan)
		pricePerUnit = ChargebeeManager.getPricePerUnit(addonPricePlan, quantity)
		total = pricePerUnit * quantity
		if (initialQuantity === undefined) initialQuantity = quantity

		if (plan.id === ChargebeeManager.userPlanSubscriptions?.luxlinkPlan?.price_id)
			isPlanAlreadyOwned = true
		else isPlanAlreadyOwned = false
	})

	function reset() {
		LuxLinkSubscriptionManager.updateAdditionalProjectorAmount(initialQuantity)
		if (isPlanAlreadyOwned) AccountNavigator.closeSubscription()
		else onBack()
	}

	function onBack() {
		LuxLinkSubscriptionManager.navigateTo("Cart")
	}
</script>

<SubmenuHeading onBack={reset}>
	<div class="heading-content">
		<h2>Add LuxLink Projectors</h2>
		<span>Need extra projectors for your house, venue, or business?</span>
	</div>
</SubmenuHeading>

<div class="addon-quantity-container">
	<div class="flex-row">
		<h3>Additional LuxLink Projectors</h3>
		<hr />
	</div>
	<div class="quantity-input-container">
		<div class="quantity-input">
			<button
				class="icon"
				disabled={quantity === 0}
				on:click={() => LuxLinkSubscriptionManager.updateAdditionalProjectorAmount(quantity - 1)}
				><SmallMinusIcon /></button
			>
			<span id="quantity-amount">
				{quantity}
			</span>
			<button
				class="icon"
				on:click={() => LuxLinkSubscriptionManager.updateAdditionalProjectorAmount(quantity + 1)}
				><PlusIcon /></button
			>
		</div>
		<div class="price-info">
			x {ChargebeeManager.formatPrice(pricePerUnit, plan.currency_code)} / {plan.period_unit}
		</div>
	</div>
	<div class="total-container">
		<div>Total:</div>
		<div>{ChargebeeManager.formatPrice(total, plan.currency_code)}</div>
	</div>
</div>

<div class="button-container">
	<button on:click={reset} class="link-button">Cancel</button>
	<button on:click={onBack}>Save</button>
</div>

<style>
	h2 {
		font-size: var(--h1);
		margin: 0;
	}

	.quantity-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.quantity-input-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#quantity-amount {
		width: 5rem;
		text-align: center;
		font-size: var(--text-h1);
		font-family: var(--font-heading);
	}

	.addon-quantity-container {
		background-color: var(--color-bg-darker);
		border-radius: var(--br);
		padding: 1rem;
	}

	.addon-quantity-container .flex-row {
		align-items: center;
	}

	.addon-quantity-container h3 {
		margin-bottom: 0;
		white-space: nowrap;
	}

	.addon-quantity-container hr {
		margin: 0 0 0 1rem;
	}

	.heading-content {
		display: flex;
		flex-direction: column;
	}

	.heading-content > span {
		font-size: var(--h3);
	}

	.total-container {
		margin-top: 1rem;
		color: var(--color-main);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.button-container {
		position: absolute;
		bottom: 1rem;
		right: 2rem;
		width: fit-content;
	}
</style>
