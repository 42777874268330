<script lang="ts">
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="10.735" height="10.735" viewBox="0 0 10.735 10.735">
	<path
		id="palette_FILL0_wght400_GRAD0_opsz24"
		d="M85.368-869.265a5.193,5.193,0,0,1-2.08-.423,5.443,5.443,0,0,1-1.711-1.154,5.444,5.444,0,0,1-1.154-1.711,5.194,5.194,0,0,1-.423-2.08,5.088,5.088,0,0,1,.436-2.093,5.437,5.437,0,0,1,1.181-1.7,5.625,5.625,0,0,1,1.738-1.147,5.355,5.355,0,0,1,2.12-.423,5.56,5.56,0,0,1,2.026.369,5.322,5.322,0,0,1,1.671,1.02,4.879,4.879,0,0,1,1.141,1.543,4.44,4.44,0,0,1,.423,1.926,2.985,2.985,0,0,1-.939,2.368,3.336,3.336,0,0,1-2.281.825h-.993a.191.191,0,0,0-.168.067.252.252,0,0,0-.047.148.967.967,0,0,0,.2.463,1.217,1.217,0,0,1,.2.691,1.249,1.249,0,0,1-.369.993A1.426,1.426,0,0,1,85.368-869.265ZM85.368-874.632Zm-2.952.537a.782.782,0,0,0,.577-.228.782.782,0,0,0,.228-.577.782.782,0,0,0-.228-.577.782.782,0,0,0-.577-.228.782.782,0,0,0-.577.228.782.782,0,0,0-.228.577.782.782,0,0,0,.228.577A.782.782,0,0,0,82.415-874.1Zm1.61-2.147a.782.782,0,0,0,.577-.228.782.782,0,0,0,.228-.577.782.782,0,0,0-.228-.577.782.782,0,0,0-.577-.228.782.782,0,0,0-.577.228.782.782,0,0,0-.228.577.782.782,0,0,0,.228.577A.782.782,0,0,0,84.026-876.243Zm2.684,0a.782.782,0,0,0,.577-.228.782.782,0,0,0,.228-.577.782.782,0,0,0-.228-.577.782.782,0,0,0-.577-.228.782.782,0,0,0-.577.228.782.782,0,0,0-.228.577.782.782,0,0,0,.228.577A.782.782,0,0,0,86.71-876.243Zm1.61,2.147a.782.782,0,0,0,.577-.228.782.782,0,0,0,.228-.577.782.782,0,0,0-.228-.577.782.782,0,0,0-.577-.228.782.782,0,0,0-.577.228.782.782,0,0,0-.228.577.782.782,0,0,0,.228.577A.782.782,0,0,0,88.32-874.1Zm-2.952,3.757a.278.278,0,0,0,.195-.067.225.225,0,0,0,.074-.174.765.765,0,0,0-.2-.443,1.22,1.22,0,0,1-.2-.765,1.127,1.127,0,0,1,.389-.9,1.409,1.409,0,0,1,.953-.336h.939a2.316,2.316,0,0,0,1.516-.517,1.952,1.952,0,0,0,.631-1.6,3.436,3.436,0,0,0-1.241-2.7,4.34,4.34,0,0,0-2.946-1.08,4.311,4.311,0,0,0-3.113,1.248,4.079,4.079,0,0,0-1.288,3.046,4.139,4.139,0,0,0,1.255,3.039A4.139,4.139,0,0,0,85.368-870.338Z"
		transform="translate(-80 880)"
		class="svg-fill"
		fill="rgba(0,0,0,0.5)"
	/>
</svg>

<style>
</style>
