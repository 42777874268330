<script lang="ts">
	export let strokeColor = "black"
</script>

<svg
	class="arrow"
	width="100"
	height="100"
	viewBox="0 0 100 100"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M25 62.5L50 37.5L75 62.5"
		class="svg-stroke"
		stroke={strokeColor}
		stroke-width="8.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
</style>
