<script lang="ts">
	import { ACCOUNT_ROUTES, AccountNavigator } from "./AccountStore"

	let activeRoute: keyof typeof ACCOUNT_ROUTES | string
	AccountNavigator.subscribe(({ route }) => (activeRoute = route))
</script>

<nav id="account-nav">
	<ul>
		{#each Object.keys(ACCOUNT_ROUTES) as title}
			<li class={title === activeRoute ? "active" : ""}>
				<a on:click|preventDefault={() => AccountNavigator.navigate(title)} href="#">{title}</a>
				<div class="link-background" />
			</li>
		{/each}
	</ul>
</nav>

<style>
	#account-nav {
		padding-left: 3rem;
		width: calc(var(--side-menu-width) + var(--nav-padding));
		margin-top: 2rem;

		overflow: hidden;
	}

	li {
		position: relative;
		font-size: var(--h2);
		padding: 0.5rem 1rem;
	}

	li > a {
		/* width: 12rem; */

		position: relative;
		z-index: 2;
		display: inline-block;
		height: 100%;
		width: 100%;
		color: var(--color-text-light);
		transition: color 500ms;
		text-align: end;
	}

	li > .link-background {
		position: absolute;
		top: 0;
		z-index: 1;
		left: 100%;

		width: 100%;
		height: 100%;
		background-color: var(--color-bg);
		border-top-left-radius: var(--br);
		border-bottom-left-radius: var(--br);

		transition: transform 250ms ease;
	}

	li.active > .link-background {
		transition-delay: 250ms;
		transform: translateX(-100%);
	}

	li.active > a {
		color: var(--color-main);
	}
</style>
