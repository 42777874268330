<script lang="ts">
	import { ColorPickerStore, closeDeviceColorPicker } from "./DeviceColorPickerContext"
	import TrackColorPickerPointer from "./DeviceColorPickerPointer.svelte"
	import { PROJECTOR_COLOR_OPTIONS, Device } from "luxedo-data"

	let isOpen = false
	let device: Device
	let onColorChange: Function
	let left: number
	let top: number
	let clickTop: number

	let colorPickerElem: HTMLDivElement

	ColorPickerStore.subscribe((ctx) => {
		isOpen = ctx.isOpen
		if (ctx.isOpen) {
			device = ctx.device
			onColorChange = ctx.onColorChange
			top = ctx.top
			clickTop = ctx.top
			left = ctx.left
		} else {
			device = undefined
			top = undefined
			left = undefined
			onColorChange = undefined
		}
	})

	function updateTrackColor(index: number) {
		if (device instanceof Device) device._color = index
		else onColorChange(index)
		closeDeviceColorPicker()
	}

	function ensureBoundsWithinWindow() {
		const { bottom } = colorPickerElem.getBoundingClientRect()
		if (bottom > window.innerHeight) top -= bottom - window.innerHeight + 32
	}

	$: isOpen && colorPickerElem && ensureBoundsWithinWindow()
</script>

{#if isOpen}
	<div class="bg-clear" on:click={closeDeviceColorPicker} on:keydown={undefined} />
	<div
		id="track-color-picker"
		bind:this={colorPickerElem}
		style="left: {left + 96}px; top: {top - 8}px;"
	>
		{#each PROJECTOR_COLOR_OPTIONS as color, i}
			<div
				on:click={() => updateTrackColor(i)}
				class="color-option"
				style="background-color: #{color};"
				on:keydown={undefined}
			/>
		{/each}
		<div id="track-color-point" style="top: {clickTop - top + 20}px;">
			<TrackColorPickerPointer />
		</div>
	</div>
{/if}

<style>
	#track-color-picker {
		border: 1px solid var(--color-border);
		position: absolute;
		z-index: 102;
		backdrop-filter: blur(3rem);
		border-radius: var(--br);
		background-color: var(--color-bg);
		padding: 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		gap: 1rem;
	}

	#track-color-point {
		z-index: 101;
		position: absolute;
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
		background-color: var(--color-bg);
		backdrop-filter: blur(3rem);
		left: -1.75rem;
		/* top: 1rem; */
	}

	#track-color-point :global(svg) {
		width: 2rem;
		height: 2rem;
	}

	.color-option {
		cursor: pointer;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 0.25rem;

		transition: transform 250ms;
	}

	.color-option:hover,
	.color-option:focus-visible {
		transform: scale(1.2);
	}

	.bg-clear {
		background-color: var(--color-bg);
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
	}
</style>
