<script lang="ts">
	export let strokeColor = "#817E84"
</script>

<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M20.4399 7.64001L13.4399 14.64L12.39 13.59L17.59 8.39001H0.439941V6.89001H17.59L12.39 1.69002L13.4399 0.640015L20.4399 7.64001Z"
		class="svg-fill"
		fill={strokeColor}
	/>
</svg>

<style>
</style>
