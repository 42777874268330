import { EditorEvent } from "./Event"
import type { EventName, KeyframeEvent, NoWildcard } from "."
import type { TrackContentType, TrackLike } from "../tracks"

type OperationEventName = EventName<"tracklist", "add" | "move" | "delete" | "beforemove">
type TrackEditEventName = EventName<"edit", "rename" | "lock" | "mute" | "hide">
type TrackSelectionEventName = EventName<"selection">
type TrackSpecificEventName = EventName<"track", "edittimespan">
type TrackAnimationEventName = EventName<"animation", "enable" | "disable" | "update">
type TrackMaskEventName = EventName<"mask", "add" | "remove" | "update">
export type TrackEventName =
	| TrackEditEventName
	| TrackSelectionEventName
	| OperationEventName
	| TrackSpecificEventName
	| TrackAnimationEventName
	| TrackMaskEventName

export interface TrackEventOptions<T extends TrackEventName> {
	track: TrackLike
	keyframeEvent?: T extends TrackAnimationEventName ? KeyframeEvent : never
}

export interface TrackEvent<T extends NoWildcard<TrackEventName> = any>
	extends EditorEvent<TrackEventName>,
		TrackEventOptions<T> {
	track: TrackLike
	contentType: TrackContentType<any>
}

export class TrackEvent<
	T extends NoWildcard<TrackEventName> = any
> extends EditorEvent<TrackEventName> {
	OptionsType: TrackEventOptions<T>
	constructor(type: T, options: TrackEventOptions<T>) {
		super(type)
		this.track = options.track
		this.contentType = options.track.contentType
		this.keyframeEvent = options.keyframeEvent
	}
}
