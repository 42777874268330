/**
 * Simply used for typing as all datahandlers used with FileSystem entries will have specific methods (e.g., move and rename)
 */

import { DataHandler, Entry } from "data-handler"
import type { F_Entry } from "../types/FileTypes"

export abstract class DataHandlerFile<T extends F_Entry> extends DataHandler<T> {
	public abstract move(objectToMove: T, moveTo: number): Promise<void>
	public abstract rename(objectToRename: T, newName: string): Promise<void>

	getByFolder(folderID: number) {
		return Object.values(this.entries).filter((entry) => entry.parent_id === folderID)
	}
}
