// TypeScript-like code in JavaScript

import { fabric } from "fabric"

type QBCacheString = `(${number},${number}),(${number},${number}),(${number},${number})`

interface QuadraticBezierEquidistantPointCache {
	A: fabric.Point
	B: fabric.Point
	C: fabric.Point

	/** The number of equally spaced points to generate and keep cached */
	resolution: number

	/**
	 * Array of length resolution, stores the equally spaced points
	 * e.g. If resolution = 100, the 50th element will contain the exact halfway point of the curve by length
	 */
	equidistantPointsCache: fabric.Point[]

	/**
	 * Array of length resolution, stores the derivatives of the curve at each point
	 */
	pointDerivativesCache: fabric.Point[]

	/**
	 * Get the point on the curve at the given position
	 * @param t A point between 0 and 1 indicating the position on the curve, where 0 is the start
	 * @param interp If false, returns one of the exact equidistant points, if true, adds an interpolation step to slightly improve accuracy
	 */
	getPointByPosition(t: number): fabric.Point
	getPointByPosition(t: number, interp: boolean): fabric.Point

	/**
	 * Get the nearest point on the curve to the given point
	 */
	getNearestPoint(point: fabric.Point): fabric.Point

	/** Get the length of the curve */
	getCurveLength(): number
}

class QuadraticBezierEquidistantPointCache {
	/** @param {fabric.Point} A the start control point of the bezier which the curve always passes through */
	A!: fabric.Point

	/** @param {fabric.Point} B the middle control point of the bezier which the curve tends towards but does not always pass through */
	B!: fabric.Point

	/** @param {fabric.Point} C the end control point of the bezier which the curve always passes through */
	C!: fabric.Point

	/** @param {number} resolution the number of equally spaced points to generate and keep cached */
	resolution!: number

	private _cacheString!: QBCacheString
	equidistantPointsCache!: fabric.Point[]
	pointDerivativesCache!: fabric.Point[]
	originalTValues!: number[] // The value of t (from the original QB equation) for each point

	private curveLength!: number

	constructor(A: fabric.Point, B: fabric.Point, C: fabric.Point, resolution: number = 100) {
		this.A = A
		this.B = B
		this.C = C
		this.resolution = resolution
		this.regenerateCache()
	}

	private isCacheValid(): boolean {
		return this._cacheString === this.generateCacheString()
	}

	/**
	 * Get the point on the curve at the given position
	 * @param t A point between 0 and 1 indicating the position on the curve, where 0 is the start
	 * @param interp
	 */
	getPointByPosition(t: number, interp = false): fabric.Point {
		if (!this.isCacheValid()) {
			this.regenerateCache()
		}

		// Find the index of the two points in the cache which are closest to the target t
		let tAsIndex = t * this.resolution

		// If we aren't interpolating or don't need to, just return the point at the index
		if (tAsIndex % 1 == 0 || !interp) {
			const i = Math.round(tAsIndex)
			return this.equidistantPointsCache[i]
		}

		// Interpolate between our two best guesses for the nearest point
		const i = Math.floor(tAsIndex)

		if (i == this.resolution - 1) {
			return this.equidistantPointsCache[i]
		}

		const interpPos = tAsIndex - i // The position between the two nearest points
		const [t1, t2] = [this.originalTValues[i], this.originalTValues[i + 1]]
		const interpolatedT = t1 * (1 - interpPos) + t2 * interpPos
		return calculateQuadraticBezierPoint(interpolatedT, this.A, this.B, this.C)
	}

	getDerivativeByPosition(t: number, interp = false): fabric.Point {
		if (!this.isCacheValid()) {
			this.regenerateCache()
		}

		// Find the index of the two points in the cache which are closest to the target t
		let tAsIndex = t * this.resolution

		// If we aren't interpolating or don't need to, just return the point at the index
		if (tAsIndex % 1 == 0 || !interp) {
			const i = Math.round(tAsIndex)
			return this.pointDerivativesCache[i]
		}

		// Interpolate between our two best guesses for the nearest point
		const i = Math.floor(tAsIndex)

		if (i == this.resolution - 1) {
			return this.pointDerivativesCache[i]
		}

		const interpPos = tAsIndex - i // The position between the two nearest points
		const [t1, t2] = [this.originalTValues[i], this.originalTValues[i + 1]]
		const interpolatedT = t1 * (1 - interpPos) + t2 * interpPos
		return quadraticBezierDerivative(interpolatedT, this.A, this.B, this.C)
	}

	getNearestPoint(point: fabric.Point): fabric.Point {
		if (!this.isCacheValid()) {
			this.regenerateCache()
		}

		const pt1 = this.nearestPointGradientDescent(point, 0.2)
		const pt2 = this.nearestPointGradientDescent(point, 0.8)

		return pointDistanceSquared(pt1, point) < pointDistanceSquared(pt2, point) ? pt1 : pt2
	}

	private nearestPointGradientDescent(
		point: fabric.Point,
		initialGuessPosition: number,
		tolerance: number | null = null,
		maxIterations = 50
	): fabric.Point {
		let guess = initialGuessPosition
		tolerance = tolerance ?? 0.1 / this.resolution
		let iter = 0
		let stepSize = 0.1
		let pt!: fabric.Point
		let interp = false
		let previousDot = Infinity

		while (true) {
			if (++iter > maxIterations || stepSize < tolerance) {
				break
			}

			pt = this.getPointByPosition(guess, interp)
			const deriv = this.getDerivativeByPosition(guess, interp)
			const dot = (point.x - pt.x) * deriv.x + (point.y - pt.y) * deriv.y

			// Unlikely case that the guess is perfectly perpendicular to the curve, we have found a solution
			if (dot == 0) {
				return pt
			}

			const direction = dot > 0 ? 1 : -1
			if (Math.abs(dot) > previousDot) {
				stepSize *= 0.5
				if (stepSize < 2 / this.resolution) {
					interp = true
				}
			}

			if (guess == 0 && direction == -1) {
				return this.A
			} else if (guess == 1 && direction == 1) {
				return this.C
			}

			guess += stepSize * direction
			if (guess < 0) {
				guess = 0
			} else if (guess > 1) {
				guess = 1
			}

			previousDot = dot
		}

		return pt
	}

	getCurveLength(): number {
		if (!this.isCacheValid()) {
			this.regenerateCache()
		}
		return this.curveLength
	}

	private generateCacheString(): QBCacheString {
		// Represent the curves points as a string, containing each x,y pair
		// Truncating the curve points to 2 decimal places
		return `(${this.A.x.toFixed(2)},${this.A.y.toFixed(2)}),(${this.B.x.toFixed(2)},${this.B.y.toFixed(
			2
		)}),(${this.C.x.toFixed(2)},${this.C.y.toFixed(2)})` as QBCacheString
	}

	/**
	 * A cache of many extra points used to find equally spaced points
	 */
	private calcCache!: {
		/** The number of non-equally spaced points to generate for finding equally spaced points */
		numSegments: number

		/**
		 * array of numSegments non-equally spaced points, used for finding equally spaced points
		 */
		points: fabric.Point[]

		/** array of numSegments derivatives of the points array */
		derivatives: fabric.Point[]

		/**
		 * Stores the length of the curve up to each point
		 * Contains numSegments elements
		 * e.g. arcLengths[n] = segmentLengths[0] + segmentLengths[1] + ... + segmentLengths[n-1]
		 */
		arcLengths: number[]

		/** Keep track of the length, so we can calculate the length of the whole curve */
		totalLength: number
	}

	regenerateCache(): void {
		this.calcCache = {
			numSegments: 10 * this.resolution,
			points: [],
			derivatives: [],
			arcLengths: [],
			totalLength: 0,
		}

		// Generate the points and derivatives we need for finding out best equidistant points
		this.cacheGenPointsAndDerivatives()

		// Now generate the arc length data we need
		this.cacheGenLengths()

		// We now have a set of ~1000 points, their derivatives, and their arc lengths, as well as the total length of the curve

		// Now we find the best equidistant points
		// Start with no equidistant points
		this.equidistantPointsCache = []
		this.pointDerivativesCache = []
		this.originalTValues = []

		// We know the first and last points
		this.equidistantPointsCache.push(this.calcCache.points[0])
		this.pointDerivativesCache.push(this.calcCache.derivatives[0])
		this.originalTValues.push(0)

		const equidistantSegmentLength: number = this.calcCache.totalLength / this.resolution
		const tolerance = equidistantSegmentLength * 0.001 // Allow about 0.1% error
		let desiredArcLength = equidistantSegmentLength // We already have the point with an arcLength of 0, so our next one will have one single equidistant segment in its arc
		let previousArcLength = 0
		let previousT = 0
		let arcLength = 0

		// Iterate through all of the points we generated and extract the equidistant points
		for (let i = 1; i < this.calcCache.numSegments; i++) {
			let t = i / this.calcCache.numSegments
			if (t >= 1 || desiredArcLength >= this.calcCache.totalLength - tolerance) break

			arcLength = this.calcCache.arcLengths[i]

			let newPoint: fabric.Point | null = null
			let newDerivative: fabric.Point | null = null

			// If our arcLength is less than the desired arcLength, we know we haven't found the points we need yet. Move on to the next point
			if (arcLength < desiredArcLength) {
				previousArcLength = arcLength
				previousT = t
				continue
			} else if (Math.abs(arcLength - desiredArcLength) < tolerance) {
				// We have found an equidistant point exactly, how nice
				newPoint = this.calcCache.points[i]
				newDerivative = this.calcCache.derivatives[i]
				this.originalTValues.push(t)
			} else {
				// We know that the equidistant point is between this point and the previous point
				const segmentLength = arcLength - previousArcLength
				const diff = desiredArcLength - previousArcLength
				const middleT = previousT + (diff / segmentLength) * (t - previousT)

				newPoint = calculateQuadraticBezierPoint(middleT, this.A, this.B, this.C)
				newDerivative = quadraticBezierDerivative(middleT, this.A, this.B, this.C)
				this.originalTValues.push(middleT)
			}

			// We have found an equidistant point, add it to the list
			this.equidistantPointsCache.push(newPoint)
			this.pointDerivativesCache.push(newDerivative)
			previousArcLength = arcLength
			previousT = t

			desiredArcLength += equidistantSegmentLength
		}

		// Add the last point
		this.equidistantPointsCache.push(new fabric.Point(this.C.x, this.C.y))
		this.pointDerivativesCache.push(quadraticBezierDerivative(1, this.A, this.B, this.C))

		if (this.equidistantPointsCache.length > this.resolution + 1) {
			console.warn({ equidistantPointsCache: this.equidistantPointsCache, resolution: this.resolution })
			throw new Error(
				`Failed to generate equidistant points (got ${this.equidistantPointsCache.length} points, expected ${
					this.resolution + 1
				})`
			)
		}

		this.curveLength = this.calcCache.totalLength
		this._cacheString = this.generateCacheString()

		// Finally, delete the temporary calcCache to free up memory
		//@ts-ignore
		this.calcCache = null
	}

	/**
	 * Called by regenerateCache to generate the points and derivatives for the calcCache
	 * These points can then be used to quickly find things like the length of the curve
	 */
	private cacheGenPointsAndDerivatives(): void {
		for (let i = 0; i < this.calcCache.numSegments; i++) {
			const t = i / this.calcCache.numSegments
			this.calcCache.points.push(calculateQuadraticBezierPoint(t, this.A, this.B, this.C))
			this.calcCache.derivatives.push(quadraticBezierDerivative(t, this.A, this.B, this.C))
		}
	}

	/**
	 * Called by regenerateCache to generate values for the calcCache:
	 * - arcLengths
	 * - totalLength
	 */
	private cacheGenLengths(): void {
		const stepSize = 1 / this.calcCache.numSegments

		// Iterate through each point generated in the cacheGenPointsAndDerivatives function
		this.calcCache.arcLengths.push(0) // This is the length of the curve up to the first point
		for (let i = 1; i < this.calcCache.numSegments; i++) {
			// Find the t values for this point and the previous point
			const t2 = i / this.calcCache.numSegments
			const t1 = t2 - stepSize

			// Get their derivatives
			const d1 = this.calcCache.derivatives[i - 1]
			const d2 = this.calcCache.derivatives[i]
			const dBetween = quadraticBezierDerivative((t1 + t2) / 2, this.A, this.B, this.C)

			// Calculate the length of the segment between these two points
			const segmentLength =
				(stepSize / 6) *
				(Math.sqrt(d1.x * d1.x + d1.y * d1.y) +
					4 * Math.sqrt(dBetween.x ** 2 + dBetween.y ** 2) +
					Math.sqrt(d2.x * d2.x + d2.y * d2.y))

			// Add the length to the total length and store it in the arcLengths array
			this.calcCache.totalLength += segmentLength
			this.calcCache.arcLengths.push(this.calcCache.totalLength)
		}
	}
}

function calculateQuadraticBezierPoint(t: number, A: fabric.Point, B: fabric.Point, C: fabric.Point) {
	const x = (1 - t) * (1 - t) * A.x + 2 * (1 - t) * t * B.x + t * t * C.x
	const y = (1 - t) * (1 - t) * A.y + 2 * (1 - t) * t * B.y + t * t * C.y
	return new fabric.Point(x, y)
}

function quadraticBezierDerivative(t: number, A: fabric.Point, B: fabric.Point, C: fabric.Point) {
	const dx = 2 * (1 - t) * (B.x - A.x) + 2 * t * (C.x - B.x)
	const dy = 2 * (1 - t) * (B.y - A.y) + 2 * t * (C.y - B.y)

	return new fabric.Point(dx, dy)
}

function pointDistanceSquared(pt1: fabric.Point, pt2: fabric.Point) {
	return (pt1.x - pt2.x) ** 2 + (pt1.y - pt2.y) ** 2
}

export { QuadraticBezierEquidistantPointCache }
