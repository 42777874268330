import { getEditor } from "../../../.."
import { Polygon } from "../../Polygon"
import { Path, PathMessageType } from "../../path"
import { MaskBase } from "./MaskBase"
import { TrackMask } from "./TrackMaskInstance"
import { fabric } from "fabric"

/**
 * The actual shape implementation within the track mask group.
 * This shape will listen to the provided path to update when needed - this results in multiple different shapes being able to subscribe to the same mask base
 */
export class MaskLinkedShape extends Polygon {
	baseID: string
	trackMask: TrackMask

	constructor(maskBase: MaskBase, trackMask: TrackMask) {
		super(
			getEditor(),
			{
				points: maskBase.points,
			},
			{
				angle: maskBase.angle,
				left: maskBase.left,
				top: maskBase.top,
				scaleX: maskBase.scaleX,
				scaleY: maskBase.scaleY,
				originX: maskBase.originX,
				originY: maskBase.originY,
				absolutePositioned: true,
			}
		)

		this.baseID = maskBase.id
		this.trackMask = trackMask
		this.pathOffset = maskBase.pathOffset

		this.fill = "#fff" // This object is never rendered, but must have a fill to act as a clipPath

		maskBase.path.subscribe(this)

		setTimeout(() => {
			// Forces the canvas to render this as soon as it is created
			this.like(maskBase.path)
			this.editor.canvas.renderAll()
		})
	}

	get name() {
		return "[" + getEditor().masks.get(this.baseID).name + "]"
	}

	getBase() {
		return getEditor().masks.get(this.baseID)
	}

	/**
	 * Updates this to reflect the path of the provided shape / path
	 */
	like(shape: fabric.Polyline) {
		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left - this.trackMask.left
		this.top = shape.top - this.trackMask.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)

		this.dirty = true
		this.trackMask.dirty = true
	}

	/**
	 * Required for path.subscribe to work - updates this with the new points
	 */
	onPathUpdate(path: Path, messageType: PathMessageType, points: fabric.Point[]) {
		switch (messageType) {
			case "unsubscribed":
			case "deleted":
				this.trackMask.remove(this)
				return
			case "point:add":
			case "point:delete":
			case "point:edit":
				this.like(path)
				this.recalculateBounds()
				break
		}

		this.trackMask.dirty = true
		this.dirty = true
	}
}
