<script lang="ts">
	import type { Media } from "luxedo-data"

	/**
	 * Time input which converts a MM:ss:mmm input into a timestamp.
	 */
	export let duration
	export let media: Media
	export let label = undefined

	export let readonly = false

	let maxMin: number = 99
	let maxSec: number = 59
	let maxMil: number = 999

	let minutes: string | number = 30
	let seconds: string | number = 0
	let milliseconds: string | number = 0

	let minElem: HTMLSpanElement
	let secElem: HTMLSpanElement
	let milElem: HTMLSpanElement

	export function updateDuration(newDuration: number) {
		const [min, sec, mil] = durationToUserTime(newDuration)
		minutes = min.toString().padStart(2, "0")
		seconds = sec.toString().padStart(2, "0")
		milliseconds = mil.toString().padStart(3, "0")
		duration = newDuration
	}

	/**
	 * Updates the proper value, ensuring the value does not reach past the max value (defined by maxMin, maxSec...)
	 * @param property
	 * @param value
	 * @returns true if the value was updated properly, false if the value is larger than the max
	 */
	function updateValue(propName: "min" | "sec" | "mil", value: string | number) {
		const checkMax = (max) => {
			if (Number(value) > max) return false
			else return true
		}

		let isValid
		switch (propName) {
			case "mil":
				isValid = checkMax(maxMil)
				if (isValid) {
					milliseconds = value.toString().padStart(maxMil.toString().length, "0")
				}
				break
			case "min":
				isValid = checkMax(maxMin)
				if (isValid) {
					minutes = value.toString().padStart(maxMin.toString().length, "0")
				}
				break
			case "sec":
				isValid = checkMax(maxSec)
				if (isValid) {
					seconds = value.toString().padStart(maxSec.toString().length, "0")
				}
				break
		}

		return isValid
	}

	/**
	 * Selects all of the text of the selected input
	 * @param target
	 */
	const selectAll = (target: HTMLSpanElement) => {
		const range = document.createRange()
		range.selectNodeContents(target)
		const windowSelection = window.getSelection()
		windowSelection.removeAllRanges()
		windowSelection.addRange(range)
	}

	/**
	 * Places the user's text cursor at the end of the string
	 * @param target
	 */
	const selectEnd = (target: HTMLSpanElement) => {
		const range = document.createRange()
		range.setStart(target, 1)
		range.setEndAfter(target)
		const windowSelection = window.getSelection()
		windowSelection.removeAllRanges()
		windowSelection.addRange(range)
	}

	/**
	 * Returns event listener to update proper value
	 * @param property
	 */
	function onChange(property: "mil" | "min" | "sec") {
		return (
			e: KeyboardEvent & {
				currentTarget: HTMLSpanElement
			}
		) => {
			e.stopImmediatePropagation()

			const fnKeys = ["Tab", "Backspace"]
			const target = e.currentTarget

			if (e.key === "Tab") return

			if (e.key === "Enter") window.getSelection().removeAllRanges()
			if (!fnKeys.includes(e.key) && (isNaN(Number(e.key)) || e.key === " ")) {
				e.preventDefault()
				return
			}

			/**
			 * Gets the max length for the property being updated
			 */
			const getMaxLength = () => {
				switch (property) {
					case "min":
						return maxMin.toString().length
					case "sec":
						return maxSec.toString().length
					case "mil":
						return maxMil.toString().length
				}
			}

			const getPrevValue = () => {
				switch (property) {
					case "min":
						return minutes
					case "sec":
						return seconds
					case "mil":
						return milliseconds
				}
			}

			setTimeout(() => {
				const maxLength = getMaxLength()
				const prevValue = getPrevValue().toString()

				let newValue

				newValue = `${prevValue}${e.key}`

				if (newValue.length >= maxLength) {
					newValue = newValue.slice(1)
				}

				console.log({ newValue, maxLength })

				if (e.key === "Backspace") {
					newValue = "0"
				}

				let isValid = updateValue(property, newValue)
				if (isValid) {
					duration = userTimeToDuration(Number(minutes), Number(seconds), Number(milliseconds))
					target.textContent = newValue
				}

				setTimeout(() => selectEnd(target))
			})
		}
	}

	function userTimeToDuration(min: number, sec: number, mil: number): number {
		let totalMilliseconds = mil
		totalMilliseconds += min * 60 * 1000
		totalMilliseconds += sec * 1000
		return totalMilliseconds / 1000
	}

	function durationToUserTime(durationInSeconds: number): [number, number, number] {
		let remainingDuration = durationInSeconds

		const minutes = Math.floor(remainingDuration / 60)
		remainingDuration -= minutes * 60

		const milliseconds = Math.floor((remainingDuration % 1) * 1000)
		remainingDuration -= milliseconds / 1000
		const seconds = Math.floor(remainingDuration)

		return [minutes, seconds, milliseconds]
	}

	function ensureTimestampWithinLimits() {
		duration = userTimeToDuration(Number(minutes), Number(seconds), Number(milliseconds))
	}

	/**
	 * Selects all of the text, to ensure a keypress can overwrite the value
	 */
	function handleInputFocus(
		e: FocusEvent & {
			currentTarget: HTMLSpanElement
		}
	) {
		selectAll(e.currentTarget)
	}

	function resetValuesFromScene() {
		if (!media || !media.duration) {
			minutes = 0
			seconds = 0
			milliseconds = 0
			return
		} else {
			const [min, sec, mil] = durationToUserTime(media.duration)
			minutes = min
			seconds = sec.toString().padStart(2, "0")
			milliseconds = mil.toString().padStart(3, "0")
		}
	}

	$: media && resetValuesFromScene()
</script>

<!-- on:focus={handleInputFocus} -->
<div class="input-container">
	{#if label}
		<span class="label">{label} </span>
	{/if}
	<div class="duration-input">
		<span
			bind:this={minElem}
			on:focus={handleInputFocus}
			on:keydown={onChange("min")}
			on:blur={ensureTimestampWithinLimits}
			contenteditable={!readonly}>{minutes}</span
		>
		<span>: </span>
		<span
			bind:this={secElem}
			on:focus={handleInputFocus}
			on:keydown={onChange("sec")}
			on:blur={ensureTimestampWithinLimits}
			contenteditable={!readonly}>{seconds}</span
		>
		<span>. </span>
		<span
			bind:this={milElem}
			on:focus={handleInputFocus}
			on:keydown={onChange("mil")}
			on:blur={ensureTimestampWithinLimits}
			contenteditable={!readonly}>{milliseconds}</span
		>
	</div>
</div>

<style>
	.input-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		line-height: 1.25em;
	}

	.label {
		color: var(--color-text);
	}
</style>
