<script lang="ts">
</script>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M7.74005 4.75V7.73999L9.24005 9.23999M14.48 7.73999C14.48 11.46 11.4601 14.48 7.74005 14.48C4.02005 14.48 1 11.46 1 7.73999C1 4.01999 4.02005 1 7.74005 1C11.4601 1 14.48 4.01999 14.48 7.73999Z"
		stroke="#4647DE"
		stroke-width="1.25"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
</style>
