<script lang="ts">
	export let imageLeft: string
	export let imageRight: string
</script>

<div class="flex-row">
	<svg
		id="highlight-img-left"
		class="outline"
		width="1501"
		height="1043"
		viewBox="0 0 1501 1043"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<clipPath id="blue-outline">
				<path
					d="M28.2463 2.83777H1473.07C1492.82 2.83777 1505.08 24.252 1495.12 41.2812L917.073 1027.48C912.476 1035.32 904.09 1040.13 895.024 1040.13H28.2463C14.1569 1040.13 2.70654 1028.68 2.70654 1014.58V28.3815C2.70654 14.2899 14.1569 2.83777 28.2463 2.83777Z"
				/>
			</clipPath>
		</defs>
		<image xlink:href={imageLeft} clip-path="url(#blue-outline)" />
		<path
			opacity="0.8"
			d="M28.2463 2.83777H1473.07C1492.82 2.83777 1505.08 24.252 1495.12 41.2812L917.073 1027.48C912.476 1035.32 904.09 1040.13 895.024 1040.13H28.2463C14.1569 1040.13 2.70654 1028.68 2.70654 1014.58V28.3815C2.70654 14.2899 14.1569 2.83777 28.2463 2.83777Z"
			stroke="#4647DE"
			stroke-width="4"
		/>
	</svg>

	<svg
		id="highlight-img-right"
		class="outline"
		width="1501"
		height="1043"
		viewBox="0 0 1501 1043"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<clipPath id="orange-outline">
			<path
				d="M604.283 2.93579H1472.68C1486.77 2.93579 1498.22 14.3879 1498.22 28.4796V1014.68C1498.22 1028.77 1486.77 1040.23 1472.68 1040.23H27.8081C8.09991 1040.23 -4.20178 1018.81 5.75873 1001.78L582.277 15.58C586.874 7.74654 595.259 2.93579 604.326 2.93579H604.283Z"
			/>
		</clipPath>
		<image xlink:href={imageRight} clip-path="url(#orange-outline)" />
		<path
			opacity="0.8"
			d="M604.283 2.93579H1472.68C1486.77 2.93579 1498.22 14.3879 1498.22 28.4796V1014.68C1498.22 1028.77 1486.77 1040.23 1472.68 1040.23H27.8081C8.09991 1040.23 -4.20178 1018.81 5.75873 1001.78L582.277 15.58C586.874 7.74654 595.259 2.93579 604.326 2.93579H604.283Z"
			stroke="#FAAE1B"
			stroke-width="4"
		/>
	</svg>
</div>

<style>
	.flex-row {
		height: fit-content;
	}

	svg.outline {
		height: fit-content;
	}

	#highlight-img-left {
		transform: translateX(20%);
	}

	#highlight-img-right {
		transform: translateX(-20%) translateY(3rem);
	}

	image {
		height: 100%;
		width: auto;
		object-fit: cover;
	}
</style>
