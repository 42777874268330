import { EditorClass, getEditor, setEditor } from "luxedo-canvas"
import { ClippingMask } from "luxedo-canvas/asset"
import { DataHandlerSnapshot, type Calibration, type Snapshot, DataHandlerMask, type MaskBaseJson } from "luxedo-data"
import { get, writable } from "svelte/store"
import { CalibratedMask, type MaskJson } from "luxedo-data"
import { MaskSidebarController } from "./sidebar/MaskEditorSidebarController"
import { TransformMode } from "luxedo-canvas/lib"
import { MaskBase } from "luxedo-canvas/asset"
import { Toast } from "svelte-comps/toaster"
import { closeOverlay } from "svelte-comps/overlay"

export namespace MaskEditorController {
	export type ContextType = {
		calibration: Calibration
		snapshot: Snapshot
		editor: EditorClass
	}

	const store = writable<ContextType>()

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	// #region Initialization

	let Editor: EditorClass // needs new instance as this lives in an overlay and needs to be initialized any time the user opens the mask editor

	/**
	 * Initializes the editor API, pulling the relevant snapshot and populating the masks
	 */
	export function initialize(cal: Calibration) {
		Editor = new EditorClass()
		setEditor(Editor)

		const allSnaps = DataHandlerSnapshot.filterByCalibration(cal.id)
		const snap = allSnaps.find((snap) => snap.is_default) ?? allSnaps[0]
		if (!snap) throw new Error("Cannot initialize the mask editor without a snapshot.")

		const allMasks = DataHandlerMask.filterByCalibration(cal.id)

		store.update((ctx) => ({ ...ctx, calibration: cal, snapshot: snap, editor: Editor }))

		setTimeout(async () => {
			initializeCanvas(snap)
			await initializeMasks(allMasks)
			if (allMasks.length) MaskSidebarController.goTo("list")
		})
	}

	export function clearPreview(maskId?: string | number) {
		if (maskId) {
			let mask: MaskBase

			if (typeof maskId === "string") {
				mask = Editor.masks.get(maskId)
			} else {
				mask = Editor.masks.all().find((base) => base.entryId === maskId)
			}

			if (mask) mask.delete()
		} else {
			Editor.masks.all().forEach((mask) => mask.delete())
		}
	}

	export async function previewMask(maskData: CalibratedMask) {
		const maskJson = CalibratedMask.unbindMaskJson(maskData.mask_json)
		maskData.mask_json = maskJson
		const mask = await importMask(maskData)

		mask.stroke = "#faae1b"
		mask.selectable = false
		mask.hoverCursor = "default"
		mask.path.selectable = false
	}

	export async function importMasks(masks: Array<CalibratedMask>) {
		for (const maskData of masks) {
			const maskJson = CalibratedMask.unbindMaskJson(maskData.mask_json)
			maskData.mask_json = maskJson
			await importMask(maskData)
		}
	}

	/**
	 * Draws the provided masks onto the canvas
	 */
	async function initializeMasks(masks: Array<CalibratedMask>) {
		for (const maskData of masks) {
			await importMask(maskData)
		}
	}

	async function importMask(mask: CalibratedMask) {
		const { calibration } = get(store)

		let base: MaskBase

		if (mask.mask_json.__CONSTRUCTOR__ === "ClippingMask") {
			// @ts-ignore
			const clipMask = await ClippingMask.loadJSON(Editor, mask.mask_json)
			base = await MaskBase.fromClippingMask(clipMask)
		} else {
			base = await MaskBase.loadJSON(Editor, mask.mask_json)
		}

		if (mask.cal_id !== calibration.id) {
			base.entryId = undefined
		}

		base.register()
		Editor.canvas.requestRenderAll()
		return base
	}

	/**
	 * Initializes the canvas and sets the snapshot background
	 */
	function initializeCanvas(snapshot: Snapshot) {
		Editor.initialize(
			"canvas-container",
			{
				width: snapshot.resolution.w,
				height: snapshot.resolution.h,
				duration: 0,
			},
			{
				canvasColor: "#2a272c",
				backgroundColor: "#f0f8ff00",
			}
		)

		Editor.canvas.setBackground(snapshot.src)
	}

	// #endregion Initialization

	/**
	 * Called when the overlay is closed. This resets the state, ensuring the data is fresh when opening again.
	 */
	export function reset() {
		Editor.masks.all().forEach((mask) => mask.delete())
		Editor = undefined
		store.set({
			calibration: undefined,
			editor: Editor,
			snapshot: undefined,
		})

		MaskSidebarController.reset()
	}

	/**
	 * Saves masks from the canvas and closes the overlay
	 */
	export async function save() {
		let idList = []
		try {
			for (const mask of Editor.masks.all()) {
				const data: MaskBaseJson = mask.serialize(true) as MaskBaseJson
				data["pathData"] = mask.path.serialize(true)

				if (data["entryId"]) {
					const maskEntry = DataHandlerMask.get(data.entryId)
					maskEntry.name = data.name
					maskEntry.mask_json = data
					await DataHandlerMask.save(maskEntry)
				} else {
					const id = await DataHandlerMask.createEntry({
						name: mask.name,
						cal_id: get(store).calibration.id,
						mask_json: JSON.stringify(data),
					})
					idList.push(id)
				}
			}

			setTimeout(async () => {
				await DataHandlerMask.pull(idList)
			})

			closeOverlay(undefined, true)
			Toast.success("Masks successfully saved!")
		} catch (e) {
			Toast.error("An error occurred while saving, please try again.")
			console.error("An error occurred while saving mask data", e)
		}
	}

	export function clearMaskHighlight(mask: MaskBase) {
		mask.path.stroke = "#ffffff10"
		mask.path.strokeWidth = 2
		Editor.canvas.requestRenderAll()
	}

	export function highlightMask(mask: MaskBase) {
		mask.path.stroke = "#faae1b"
		mask.path.strokeWidth = 4
		Editor.canvas.requestRenderAll()
	}

	export function selectMask(mask: MaskBase) {
		Editor.selection.set(mask)
	}

	export function editMask(mask: MaskBase) {
		MaskSidebarController.goTo("edit", mask)
		TransformMode.ActivateTransform(Editor, mask)
	}

	export function cancelEdit() {
		Editor.mode.cancel()
		MaskSidebarController.goTo("list")
	}

	export function completeEdit() {
		Editor.mode.get().complete()
		MaskSidebarController.goTo("list")
	}
}
