<script lang="ts">
	import { DataHandlerLightshow, type Device, type Lightshow, type Scene } from "luxedo-data"
	import { GridIcon } from "svelte-comps/icons"
	import LightshowTile from "../../reusable/file-system/tiles/LightshowTile.svelte"
	import { ShowLibraryController } from "./ShowLibraryController"
	import { openShowOverview } from "../../reusable/overlays"

	let lightshows: Array<Lightshow> = []
	let deviceFilter: Device = undefined

	ShowLibraryController.subscribe((ctx) => {
		deviceFilter = ctx.deviceFilter
		if (deviceFilter !== ctx.deviceFilter) filterShows()
	})

	export let filterUpdater: number
	export let searchInput: string
	export let tagFilter: Array<number> = []
	export let filterOperation: (content: Array<Scene | Lightshow>) => Array<Scene | Lightshow>

	DataHandlerLightshow.addListener(filterShows)

	function getRelevantScenes() {
		if (deviceFilter) lightshows = DataHandlerLightshow.getByDevice(deviceFilter)
		else lightshows = DataHandlerLightshow.getMany()
	}

	function filterShows() {
		getRelevantScenes()
		if (searchInput) {
			lightshows = lightshows.filter((scene) =>
				scene.name.toLowerCase().includes(searchInput.toLowerCase())
			)
		}
		if (tagFilter.length) {
			lightshows = lightshows.filter((scene) => {
				let doesMatch = true
				for (const tagId of tagFilter) {
					if (!scene.tags.includes(tagId)) {
						doesMatch = false
						return
					}
				}
				return doesMatch
			})
		}

		lightshows = filterOperation(lightshows) as Array<Lightshow>
		lightshows = lightshows.filter((show) => show.name !== "__temp_ls__")
	}

	$: (searchInput || !searchInput || tagFilter || filterUpdater) && filterShows()
</script>

<div id="library-list">
	{#if lightshows.length}
		<div class="library-list">
			{#each lightshows as lightshow (lightshow.id)}
				<LightshowTile {lightshow} onClick={() => openShowOverview(lightshow)} />
			{/each}
		</div>
	{:else}
		<div class="empty-indicator">
			<GridIcon />
			<div class="empty-text">
				Lightshows are rendered playlists of scenes you can schedule, allowing you to create a
				seamless masterpiece from your individual scenes. <br />Click
				<button
					class="create-quick-action"
					on:click={() => ShowLibraryController.LightshowEditor.createLightshow()}
					>Create Lightshow</button
				> to create your first lightshow!
			</div>
		</div>
	{/if}
</div>

<style>
</style>
