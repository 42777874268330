import type { DeviceTypeName } from "./types"

export const TYPE_NAMES: Record<DeviceTypeName, string> = {
	dev_caxedo: "Sweet Lumens",
	dev_luxedo: "Luxedo Projection System",
	dev_synth: "LuxLink",
	dev_luxcast: "LuxCast",
	dev_amalgam: "Projector Group",
	dev_group: "Projector Group",
}

export const PROJECTOR_COLOR_OPTIONS = [
	"DE5357",
	"FF9D00",
	"EFE23F",
	"62BE60",
	"0087B0",
	"4647DE",
	"9774CC",
	"FF4083",
	"FF5803",
	"F0BE31",
	"96BF60",
	"04B2A8",
	"4987DE",
	"CD74CC",
	"983F40",
	"B07004",
	"A49D30",
	"498147",
	"045C78",
	"383696",
	"6C5789",
	"AF325B",
	"B04206",
	"A57D31",
	"688247",
	"077A74",
	"395F96",
	"855789",
]

export const StatusColors = {
	updating: "var(--ct-purple)",
	idle: "var(--ct-yellow)",
	playing: "var(--ct-green)",
	initializing: "var(--ct-orange)",
	projectAndCapture: "var(--ct-blue)",
	greyOut: "var(--color-text)",
	off: "var(--ct-red)",
}

type FirmwareProduct = {
	name: string
	proj_res: [number?, number?]
	cam_res: [number?, number?]
	fw_name: string
	desc: string
	in_production: boolean
}

export const FirmwareProductByID: Record<number, FirmwareProduct> = {
	1: {
		name: "LTX2000",
		proj_res: [1024, 768],
		cam_res: [1920, 1080],
		fw_name: "MAIN",
		desc: "Normal res, Pi 3",
		in_production: false,
	},
	2: {
		name: "LTX2010",
		proj_res: [1280, 800],
		cam_res: [1920, 1080],
		fw_name: "MAIN",
		desc: "New res, Pi 3",
		in_production: false,
	},
	3: {
		name: "LTX2011",
		proj_res: [1280, 800],
		cam_res: [1920, 1080],
		fw_name: "MAIN",
		desc: "New res, Pi 4",
		in_production: true,
	},
	4: {
		name: "LTX2001",
		proj_res: [1024, 768],
		cam_res: [1920, 1080],
		fw_name: "MAIN",
		desc: "Old res, Pi 4",
		in_production: true,
	},
	5: {
		name: "LuxCast Classic",
		proj_res: [1920, 1080],
		cam_res: [undefined, undefined],
		fw_name: "MAIN",
		desc: "LuxCast Classic",
		in_production: true,
	},
	6: {
		name: "LuxOmni Classic",
		proj_res: [1920, 1080],
		cam_res: [undefined, undefined],
		fw_name: "MAIN",
		desc: "LuxOmni Classic",
		in_production: true,
	},
	7: {
		name: "LuxOmni Commercial",
		proj_res: [1920, 1080],
		cam_res: [undefined, undefined],
		fw_name: "MAIN",
		desc: "LuxOmni Commercial",
		in_production: true,
	},
}

export namespace CALIBRATION {
	export const STEPS = {
		SETUP: 0,
		CAPTURE: 1,
		CALCULATING: 2,
		SNAPSHOT: 3,
		COMPLETE: 4,
	}

	export const STEP_WEIGHT = {
		0: 0,
		1: 0.75,
		2: 0.24,
		3: 0.01,
		4: 0,
	}

	export const STEP_OFFSET = {
		0: 0,
		1: 0,
		2: 0.75,
		3: 0.99,
		4: 1,
	}
}
