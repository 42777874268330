import { Entry } from "data-handler"
import type { FileRawData } from "../types/FileTypes"
import { LuxedoRPC } from "luxedo-rpc"

// NOTE - Not sure what TB means
export type MediaSourceKeys = "audio" | "compressed" | "thumbnail" | "editorPreview"

type MediaAlterOptions = {
	chromakey?: {
		key: [number, number, number]
		tol: [number, number, number]
	}
	temporal_crop?: {
		t_start: number
		t_end: number
	}
}

export class MediaAlterError extends Error {
	constructor(msg) {
		super(msg)
	}
}

export class Media extends Entry<MediaRawData> {
	alterFailListener?: () => void
	alterCompleteListener?: () => void
	alterCancelListener?: () => void
	alterProgressListener?: (progress: number) => void

	declare owner_id
	declare parent_id
	declare name: string
	declare mimetype: string
	declare size?: number
	declare is_src_deleted?: boolean
	declare frame_amount?: number
	declare fps?: number
	declare updated_at?: Date
	declare src: {
		[k in MediaSourceKeys]?: string
	}

	constructor(data: MediaRawData) {
		super(data)
	}

	protected importData(data: MediaRawData): void {
		this.name = data.name
		this.mimetype = data.storage_mimetype
		this.owner_id = data.owner_id
		this.parent_id = data.parent_id
		this.size = data.size_kb
		this.is_src_deleted = data.source_deleted
		this.fps = data.fps
		this.frame_amount = data.framecnt
		this.updated_at = data.updated_at ? new Date(data.updated_at) : undefined

		if (data.source_deleted) {
			this.src = {
				audio: data.srcA,
				compressed: this.getPlaceholderSrc(),
				thumbnail: this.getPlaceholderSrc(),
				editorPreview: this.getPlaceholderSrc(),
			}
		} else {
			this.src = {
				audio: data.srcA,
				compressed: data.srcC,
				thumbnail: data.srcT,
				editorPreview: data.srcTB,
			}

			if (this.fileType === "audio") {
				this.src.audio = data.srcC
				this.src.editorPreview = data.srcC
			}
		}
	}

	private getPlaceholderSrc() {
		if (this.fileType === "audio") return "/images/Missing Audio.png"
		else return "/images/Missing Content.png"
	}

	protected exportData(): Partial<MediaRawData> {
		return {
			name: this.name,
			source_deleted: this.is_src_deleted,
		}
	}

	getSource() {
		if (!this.src.compressed) {
			throw new Error(`No compressed source for media (${this.id} : ${this.name})`)
		} else if (this.is_src_deleted) {
			return this.getPlaceholderSrc()
		} else {
			if (this.fileType !== "audio") return this.src.editorPreview
			else return this.src.compressed
		}
	}

	get duration() {
		return this.frame_amount! / this.fps! ?? NaN
	}

	/**
	 * Parses mimetype to return 'image', 'video' or 'audio'
	 */
	public get fileType(): "image" | "video" | "audio" {
		const type = this.mimetype.split("/")[0]
		if (type !== "image" && type !== "video" && type !== "audio")
			throw new Error(`File ${this.name} is not 'image', 'video', or 'audio'.`)
		return type
	}

	/**
	 * Sends alter request to backend, does not listen for completion
	 */
	public async alter(editData: MediaAlterOptions) {
		return await LuxedoRPC.api.media.media_alter(this.id!, editData)
	}

	/**
	 * Sends alter request to backend, listens for video_alter_progress
	 */
	public async alterAsync(editData: MediaAlterOptions, progressListener?: (progress: number) => void): Promise<void> {
		console.log("alter async called")
		return new Promise(async (res, rej) => {
			const clearListeners = () => {
				this.alterCancelListener = undefined
				this.alterCompleteListener = undefined
				this.alterFailListener = undefined
				this.alterProgressListener = undefined
			}

			const handleFail = () => {
				rej(new MediaAlterError("Media update failed... Refresh and try again. If this continues, contact support. "))
				clearListeners()
			}

			const handleCancel = () => {
				rej(new MediaAlterError("Media update cancelled..."))
				clearListeners()
			}

			const handleComplete = () => {
				res()
				clearListeners()
			}

			this.alterCancelListener = handleCancel
			this.alterCompleteListener = handleComplete
			this.alterFailListener = handleFail
			this.alterProgressListener = progressListener

			this.alter(editData)
		})
	}
}

export interface MediaRawData extends FileRawData {
	// RAW
	med_type_id: number
	storage_mimetype: string
	rev?: number
	source_deleted?: boolean
	fps?: number
	framecnt?: number
	// AUX
	has_audio?: boolean
	srcTB?: string
}
