<script lang="ts">
	export let deviceColor: string
</script>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M7.59 13.18C10.6773 13.18 13.18 10.6773 13.18 7.59C13.18 4.50273 10.6773 2 7.59 2C4.50273 2 2 4.50273 2 7.59C2 10.6773 4.50273 13.18 7.59 13.18Z"
		stroke={deviceColor}
		stroke-width="4"
		stroke-miterlimit="10"
		opacity={0.5}
	/>
</svg>

<style>
</style>
