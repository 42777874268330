<script lang="ts">
	import { DeviceRPi, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import AdvancedActionsHdmiFix from "./AdvancedActions_HDMIFix.svelte"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowUp } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import {
		ProjectorActionsController,
		type ProjectorActionType,
	} from "./ProjectorActionsController"
	import { LuxedoRPC } from "luxedo-rpc"

	let device: Device

	let expanded: boolean
	let isDisabled: boolean = false

	SelectedDeviceStore.subscribe((dev) => {
		device = dev
	})

	let availActions: Array<ProjectorActionType> = []
	ProjectorActionsController.subscribe((actions) => {
		availActions = actions
	})

	async function stopPlayback() {
		Toast.text("Stopping playback...")
		await device.platoCall("clear_override", [])
		disableButtons()
	}

	async function restartDevice() {
		Toast.text("Restarting device... This may take a few minutes.")
		await device.platoCall("reboot", [])
		disableButtons()
	}

	async function rollbackDevice() {
		Toast.text(
			"Rolling back firmware version... Please give up to 10 minutes before restarting your device."
		)
		await LuxedoRPC.api.deviceControl.device_rollback(device.id)
		disableButtons()
	}

	function disableButtons() {
		isDisabled = true
		setTimeout(() => {
			isDisabled = false
		}, 8000)
	}
</script>

<div class="advanced-actions">
	<CollapsibleSection className="projector-heading" bind:expanded>
		<div class="flex-row heading {expanded ? 'open' : ''}" slot="header">
			<h2 class="setting-heading">Actions</h2>
			{#if !expanded}
				<span class="info">click to access additional device actions</span>
			{/if}
			<button class="icon small"><ArrowUp /></button>
		</div>
		<div slot="content">
			<div class="buttons">
				{#if availActions.includes("REBOOT")}
					<div class="flex-row">
						<button class="outline-button alt" on:click={restartDevice} disabled={isDisabled}
							>Restart device</button
						>
						<span class="info">Reboot device completely. </span>
					</div>
				{/if}
				{#if availActions.includes("STOP_PLAYBACK")}
					<div class="flex-row">
						<button class="outline-button" on:click={stopPlayback} disabled={isDisabled}
							>Stop playback</button
						>
						<span class="info">Stop any current projection. </span>
					</div>
				{/if}
				{#if availActions.includes("HDMI_FIX")}
					<div class="flex-row">
						<AdvancedActionsHdmiFix {device} disabled={isDisabled} />
						<span class="info">Fixes an issue where the connected projector can flash blue. </span>
					</div>
				{/if}
				{#if availActions.includes("FW_ROLLBACK")}
					<div class="flex-row">
						<button
							class="outline-button"
							id="rollback-button"
							on:click={rollbackDevice}
							disabled={isDisabled}>Rollback beta update</button
						>
						<span class="info">Rolls back to the previous firmware version.</span>
					</div>
				{/if}
			</div>
		</div>
	</CollapsibleSection>
</div>

<style>
	.advanced-actions :global(.projector-heading) {
		background-color: var(--color-bg-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border-radius: var(--br);
		width: 100%;
		padding: 1rem 1rem;
		margin-top: 1rem;
	}

	h2 {
		margin: 0;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	.info {
		color: var(--color-text);
	}

	.heading {
		width: 100%;
		justify-content: space-between;
	}

	.heading button.icon :global(svg) {
		transition: transform 250ms;
	}

	.heading.open button.icon :global(svg) {
		transform: rotateX(180deg);
	}

	.buttons {
		padding-top: 1rem;
	}

	.buttons button {
		flex-shrink: 0;
	}

	.buttons > .flex-row:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.buttons > .flex-row .info {
		margin-left: 1rem;
	}

	#rollback-button {
		--border-color: var(--color-alt-5);
	}
</style>
