import { fabric } from "fabric"
import { EditorClass } from "../../.."
import { CanvasAsset } from "../../../asset"
import { TrackLike } from "../../../tracks"
import { Serializable } from "../../../modules/serialize"

type FabricConstructor = new (...args: any) => fabric.Object
type AbstractFabricConstructor = abstract new (...args: any) => fabric.Object

export interface MaskOptions {
	id?: string
	inverted?: boolean
	boundTrack?: TrackLike<CanvasAsset>
	overflow?: "hidden" | "faded"
}

export interface Mask extends fabric.Object, MaskOptions, Serializable {
	editor: EditorClass
	invert(): void
	bindTrack(track: TrackLike<CanvasAsset>): void
	detach(): void
}

export class Mask extends fabric.Object {
	//#region    ===========================				Construction				==============================
	static Mixin<T extends FabricConstructor | AbstractFabricConstructor>(Base: T) {
		Base.prototype[Mask.isMask] = true

		for (const [prop, descriptor] of Object.entries(Object.getOwnPropertyDescriptors(Mask.prototype))) {
			if (prop === "constructor") continue
			if (Object.hasOwn(Base.prototype, prop)) continue

			Object.defineProperty(Base.prototype, prop, descriptor)
		}
	}

	editor: EditorClass

	static isMask = Symbol("isMask")
	static [Symbol.hasInstance](obj) {
		if (!obj) return false
		return Mask.isMask in obj
	}

	//#endregion =====================================================================================================

	invert() {
		this.inverted = !this.inverted
		this.boundTrack.maskUpdated()
	}

	bindTrack(track: TrackLike) {
		this.boundTrack = track
	}

	detach() {
		this.boundTrack = null
	}

	// This was added to make objects consistent in available properties
	get track(): TrackLike<CanvasAsset> {
		return this.boundTrack
	}

	set track(track: TrackLike<CanvasAsset>) {
		this.bindTrack(track)
	}

	get clipPath(): fabric.Object | undefined {
		if (!this.boundTrack) return undefined
		return this.boundTrack.parent?.getMask()
	}

	set clipPath(cpath: fabric.Object | undefined) {
		return
	}
}
