/**
 * In order to allow touch interactions to behave similarly to drag interactions, use this class to ensure the same functionality for both.
 * Use the onDragStart method for both dragstart and touch events - this method checks if the touch event satisfies a "drag start" based on the user interaction.
 */

import type { Design, Folder, Media, Scene, LightshowBreak } from "luxedo-data"

export type DraggableContent = Scene | LightshowBreak | Media | Folder

class DragContext {
	MIN_TOUCH_DRAG_DISTANCE = 50 // The min touch movement before a drag event is initiated from touch
	TOUCH_DRAG_DELAY = 400 // The time between the touch down and the check against MIN_TOUCH_DRAG_DISTANCE

	// #region Drag Content state management

	public lastTouchPosition = {
		x: undefined,
		y: undefined,
	} // Used to track the last mouse position for triggering a drag event from touch

	public dragContent: DraggableContent
	public dragLightshowDesignIndex: number // Only used when the user is changing the position of a scene within a lightshow

	public droppableTouchHover: Element // Use to track if touch event is hovering a droppable element
	private dragElement: Element // The element which was the target for the initial drag event (only touch events)

	// #endregion Drag Content state management
	// #region Standard Drag functionality

	/**
	 * Handles touch events to start a "drag" event as well as standard drag start events; this allows touch events to function very similarly to drag/drop events.
	 * @param content The draggable content (scene or media) being dragged
	 * @returns an event handler to called on both touch and drag start events
	 */
	onDragStart(content: DraggableContent, onDragFn: (e?: DragEvent) => void, lightshowIndex?: number) {
		const handleDrag = () => {
			this.dragContent = content
			onDragFn()
		}

		return (e: DragEvent | TouchEvent) => {
			this.dragLightshowDesignIndex = lightshowIndex
			if (e instanceof DragEvent) {
				this.dragContent = content
				onDragFn(e)
			} else if (e instanceof TouchEvent) {
				this.onTouchDragStart(e, handleDrag)
			}
		}
	}

	/**
	 * Triggered from drop or touch end events; calls passed fn with "drop" event
	 * @param dropFn function to be called on drop/touch end events
	 * @returns event handler to be called on both drop and touch end events
	 */
	onDrop(dropFn: (e: DragEvent, content: DraggableContent, positionIndex?: number) => void) {
		return (e: DragEvent) => {
			e.preventDefault()
			e.stopPropagation()
			dropFn(e, this.dragContent, this.dragLightshowDesignIndex)

			this.clearDragContent()
		}
	}

	/**
	 * Triggered from a drag over event; calls the passed fn with a drag event
	 * @param dragOverFn Called with a drag event when triggered
	 * @returns event handler to be called on drag over events
	 */
	onDragOver(dragOverFn: (e?: DragEvent) => void) {
		return (e: DragEvent) => {
			e.preventDefault()
			dragOverFn(e)
		}
	}

	/**
	 * Cancels any current drag operation.
	 * @param onDragEnd called when drag end or touch events are triggered
	 * @returns event handler to be called on drag end or touch end events
	 */
	onDragEnd(onDragEnd?: (e?: DragEvent) => void) {
		return (e) => {
			onDragEnd(e)
			this.clearDragContent()
		}
	}

	// #endregion Standard Drag functionality
	// #region Touch Drag functionality

	private onTouchDragStart(e: TouchEvent, onDragStart: () => void) {
		const initMousePos = { x: e.touches[0].clientX, y: e.touches[0].clientY }

		const mousePos = {
			x: undefined,
			y: undefined,
		}

		this.dragElement = e.target as Element

		const updateMousePosition = (e: TouchEvent) => {
			mousePos.x = e.touches[0].clientX
			mousePos.y = e.touches[0].clientY
		}

		document.addEventListener("touchmove", updateMousePosition)

		// After delay check mouse position, if user started to drag initiate drag event
		setTimeout(() => {
			document.removeEventListener("touchmove", updateMousePosition) // Remove event handler after delay
			const xDiff = Math.abs(initMousePos.x - mousePos.x)
			const yDiff = Math.abs(initMousePos.y - mousePos.y)
			// If the touch position has moved enough within the delay, initiate a dragstart event and test for droppable elements
			if (xDiff >= this.MIN_TOUCH_DRAG_DISTANCE || yDiff >= this.MIN_TOUCH_DRAG_DISTANCE) {
				const dragEvent = new DragEvent("dragstart", {
					dataTransfer: new DataTransfer(),
					clientX: e.touches[0].clientX,
					clientY: e.touches[0].clientY,
				})

				this.initializeTouchHandlers()
				dispatchEvent(dragEvent)
				onDragStart()
			}
		}, this.TOUCH_DRAG_DELAY)
	}

	/**
	 * Initiates touch listeners needed for touch drag events
	 */
	private initializeTouchHandlers() {
		document.addEventListener("touchmove", this.onTouchDragMove)
		document.addEventListener("touchend", this.onTouchDragEnd)
		document.addEventListener("touchcancel", this.onTouchDragCancel)
	}

	/**
	 * Clears touch listeners used for touch drag events
	 */
	private removeTouchHandlers() {
		document.removeEventListener("touchmove", this.onTouchDragMove)
		document.removeEventListener("touchend", this.onTouchDragEnd)
		document.removeEventListener("touchcancel", this.onTouchDragCancel)
	}

	private clearDragContent() {
		this.dragLightshowDesignIndex = undefined
		this.dragContent = undefined
		this.lastTouchPosition = {
			x: undefined,
			y: undefined,
		}
	}

	private onTouchDragMove = (e: TouchEvent) => {
		const mousePosX = e.touches[0].clientX
		const mousePosY = e.touches[0].clientY

		let elem
		const elems = document.elementsFromPoint(mousePosX, mousePosY)
		for (const e of elems) {
			if (e === this.dragElement) continue
			else {
				elem = e
				break
			}
		}

		if (this.droppableTouchHover && elem !== this.droppableTouchHover) {
			const event = new DragEvent("dragleave", {
				clientX: mousePosX,
				clientY: mousePosY,
			})
			this.droppableTouchHover.dispatchEvent(event)
		}

		if (elem) {
			const dragOverEvent = new DragEvent("dragover", {
				clientX: mousePosX,
				clientY: mousePosY,
			})
			elem.dispatchEvent(dragOverEvent)
			this.droppableTouchHover = elem
			this.lastTouchPosition = {
				x: mousePosX,
				y: mousePosY,
			}
		}
	}

	private onTouchDragEnd = (e: TouchEvent) => {
		const dropEvent = new DragEvent("drop", {
			clientX: this.lastTouchPosition.x,
			clientY: this.lastTouchPosition.y,
		})

		this.droppableTouchHover?.dispatchEvent(dropEvent)

		const dragEndEvent = new DragEvent("dragend", {
			clientX: this.lastTouchPosition.x,
			clientY: this.lastTouchPosition.y,
		})

		this.dragElement.dispatchEvent(dragEndEvent)
		this.removeTouchHandlers()
		this.clearDragContent()
	}

	private onTouchDragCancel = (e: TouchEvent) => {
		this.removeTouchHandlers()
		this.clearDragContent()
	}

	// #endregion Touch Drag functionality
}

const dragContext = new DragContext()
export { dragContext as DragController }
