import EventEmitter from "eventemitter3"
import { EditorEvent, ListenerFunction } from ".."
import { EventClassDef, EventListenerControls, EventNameOf, NamedEventListener } from "."

export abstract class EventManager {
	eventEmitter: EventEmitter
	constructor() {
		this.eventEmitter = new EventEmitter()
	}

	/**
	 * Fire an event
	 */
	emit(event: EditorEvent<any>) {
		this.eventEmitter.emit(event.constructor.name, event)
	}

	/**
	 * Add a listener for an event
	 * @param eventName
	 * @param eventName
	 * @param listener
	 * @returns an object with some controls for this listener
	 */
	on<T extends EventClassDef<any>>(
		Event: T,
		eventName: EventNameOf<InstanceType<T>>,
		listener: ListenerFunction<InstanceType<T>>
	): EventListenerControls<InstanceType<T>> {
		const listenerData = setListenerNamespace(this.eventEmitter, Event, eventName, listener)
		this.eventEmitter.addListener(Event.name, listenerData.listener)

		return listenerData
	}

	/**
	 * Add a listener for a single instance of an event
	 * @param eventName
	 * @param eventName
	 * @param listener
	 */
	once<T extends EventClassDef<any>>(
		Event: T,
		eventName: EventNameOf<InstanceType<T>>,
		listener: ListenerFunction<InstanceType<T>>
	): EventListenerControls<InstanceType<T>> {
		const listenerData = setListenerNamespace(this.eventEmitter, Event, eventName, listener, true)
		this.eventEmitter.addListener(Event.name, listenerData.listener)

		return listenerData
	}

	/**
	 * Remove a listener for an event
	 * @param Event the event type
	 * @param listener the listener to remove - this is NOT the listener you passed into the function originally, but is the listener returned by the this.on or this.once function call.
	 *
	 * @example
	 *  const listener = (e)=>{console.log(e.msg)}
	 * 	const namedListener = this.on(MyEvent, "event:specific", listener)
	 *  this.off(MyEvent, listener) // This won't do anything and intellisense won't like it
	 *  this.off(MyEvent, namedListener) // This will properly unbind the listener
	 */
	off<T extends EventClassDef<any>>(Event: T, listener: NamedEventListener<InstanceType<T>>) {
		this.eventEmitter.removeListener(Event.name, listener)
	}
}

export function setListenerNamespace<T extends EditorEvent<any>>(
	emitter: EventEmitter,
	EventClass: new (...args: any) => T,
	eventName: string,
	listener: ListenerFunction<T>,
	once: boolean = false
): EventListenerControls<T> {
	const listenerData: EventListenerControls<T> = {
		listener: null,
		remove: null,
	}

	const hasWildcard = eventName.indexOf("*") != -1
	if (!hasWildcard) {
		listenerData.listener = ((event) => {
			if (event.eventName === eventName) {
				if (once) listenerData.remove()
				return listener(event)
			}
		}) as NamedEventListener<T>
	} else {
		let stringMatch = eventName.split("*")[0]
		listenerData.listener = ((event) => {
			// Convert eventName to regex and check if the event name matches it
			if (event.eventName.startsWith(stringMatch)) {
				if (once) listenerData.remove()
				return listener(event)
			}
		}) as NamedEventListener<T>
	}

	listenerData.remove = () => {
		emitter.removeListener(EventClass.name, listenerData.listener)
	}
	return listenerData
}
