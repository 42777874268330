<script lang="ts">
	import { onDestroy, onMount } from "svelte"
	import VideoTutorial from "../../../YouTubeVideoTutorial.svelte"
	import { GroupEditorController } from "../GroupEditorController"

	onMount(() => {
		GroupEditorController.Grid.activate("simple", false)
	})

	onDestroy(() => {
		GroupEditorController.Grid.deactivate()
	})
</script>

<VideoTutorial
	title="Align each projector in real space"
	description={`For the best possible result, adjust each projector so that the projection of each is overlapping its neighbor by at least 6 inches. Ensure the projection size is consistent between devices. Once aligned as best as possible, use the internal projector's 4-corner alignment tool to align each relative to each other. 
	<br /><!--Watch this video before getting started: -->`}
/>
<!-- videoURL="https://www.youtube.com/embed/AjUkEHkYx0I" -->

<div id="step-buttons">
	<button id="back-step-button" class="link-button" on:click={() => GroupEditorController.back()}>
		Back
	</button>
	<button
		id="next-step-button"
		class="outline-button"
		on:click={() => GroupEditorController.next()}
	>
		Next
	</button>
</div>

<style>
</style>
