<script lang="ts">
</script>

<svg width="147" height="23" viewBox="0 0 147 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_20_2)">
		<path
			d="M14.684 19.7329H3.44561C3.17915 19.731 2.92412 19.6244 2.73566 19.436C2.64266 19.3429 2.56903 19.2323 2.51902 19.1106C2.46901 18.9889 2.44362 18.8585 2.44431 18.727V0.0959473H6.14963e-05V20.9647C-0.00156035 21.1257 0.0289126 21.2855 0.0897013 21.4346C0.15049 21.5837 0.240376 21.7192 0.354113 21.8332C0.467845 21.948 0.603114 22.0391 0.752158 22.1015C0.901202 22.1638 1.06109 22.1961 1.22264 22.1965H15.9527V19.7439H14.684V19.7329Z"
			fill="#FFB703"
		/>
		<path
			d="M36.2468 2.54204V13.9897C36.2679 15.4436 35.7289 16.8499 34.7414 17.9172C33.7539 18.9845 32.3937 19.631 30.9425 19.7227C30.1858 19.7626 29.4288 19.6474 28.7182 19.3842C28.0076 19.121 27.3583 18.7154 26.8101 18.1922C26.2583 17.6756 25.8191 17.0508 25.5199 16.3567C25.2207 15.6626 25.0679 14.9142 25.0711 14.1584V0.0959473H22.5992V13.9639C22.6043 15.0707 22.8292 16.1656 23.261 17.1847C23.6928 18.2039 24.3228 19.1271 25.1145 19.9007C26.5893 21.3693 28.5846 22.1958 30.6659 22.2002H30.8125C32.9163 22.1544 34.9193 21.2898 36.3958 19.7904C37.8722 18.2909 38.7055 16.2747 38.7187 14.1704V0.0959473H36.2468V2.54204Z"
			fill="#FFB703"
		/>
		<path
			d="M53.6601 6.68448L47.8755 0.963423C47.8186 0.911238 47.7568 0.864617 47.6911 0.824199L47.0106 0.152057L45.327 1.86146L51.9655 8.40219L52.8045 9.23199C52.9158 9.34314 53.0479 9.43116 53.1933 9.49097C53.3388 9.55079 53.4946 9.58122 53.6518 9.58051C53.8001 9.58733 53.9481 9.56322 54.0865 9.50973C54.2249 9.45624 54.3507 9.37453 54.4558 9.2698L55.3483 8.37729L61.9481 1.79323L60.2516 0.0976562L53.6601 6.68448Z"
			fill="#FFB703"
		/>
		<path
			d="M55.3444 13.9019L54.5063 13.0721C54.2776 12.8492 53.97 12.7258 53.6506 12.7291C53.5023 12.7224 53.3543 12.7467 53.2159 12.8003C53.0774 12.854 52.9517 12.9359 52.8467 13.0408L51.9569 13.9323L45.3655 20.5099L47.062 22.2055L53.6506 15.6168L59.427 21.3453C59.486 21.398 59.5499 21.4449 59.6179 21.4854L60.2983 22.1576L61.9828 20.4482L55.3379 13.9102L55.3444 13.9019Z"
			fill="#FFB703"
		/>
		<path
			d="M83.8773 19.73H72.6435C72.5112 19.7292 72.3802 19.7026 72.2581 19.6516C72.1365 19.601 72.0261 19.527 71.9332 19.4336C71.8403 19.3402 71.7669 19.2295 71.717 19.1076C71.6665 18.9861 71.641 18.8556 71.6422 18.724V0.0930176H69.197V20.9618C69.196 21.1227 69.2267 21.2823 69.2875 21.4313C69.3482 21.5804 69.4378 21.716 69.5511 21.8303C69.6649 21.9451 69.8004 22.0364 69.9496 22.0987C70.0988 22.161 70.2588 22.1933 70.4206 22.1936H85.1496V19.741H83.8773V19.73Z"
			fill="white"
		/>
		<path
			d="M92.6659 0.0662198C92.4561 0.0585266 92.2469 0.0938188 92.0513 0.169926C91.8556 0.246033 91.6776 0.361349 91.5281 0.508785C91.3849 0.648125 91.2718 0.815316 91.1957 1.00005C91.1195 1.18479 91.0821 1.38315 91.0855 1.58292V22.2H94.2148V1.58292C94.2173 1.38161 94.1776 1.182 94.0982 0.996982C94.0188 0.811967 93.9015 0.645657 93.7538 0.508785C93.611 0.366431 93.4412 0.253973 93.2544 0.177985C93.0676 0.101997 92.8675 0.0640011 92.6659 0.0662198Z"
			fill="white"
		/>
		<path
			d="M146.301 19.6323L138.493 9.54924L145.985 2.46822C146.279 2.13169 146.428 1.80437 146.428 1.48905C146.422 1.11163 146.275 0.750094 146.017 0.474837C145.899 0.327194 145.75 0.207843 145.581 0.125642C145.411 0.0434415 145.225 0.000498811 145.037 0C144.636 0 144.237 0.179179 143.838 0.537532L132.617 11.4117V0.00276591H129.488V22.2001H132.617V15.144L136.157 11.7934L143.838 21.6875C144.154 22.0661 144.523 22.2557 144.944 22.2564C145.38 22.2589 145.802 22.1014 146.13 21.8138C146.298 21.6764 146.432 21.5025 146.523 21.3053C146.613 21.1081 146.657 20.8929 146.652 20.676C146.659 20.298 146.534 19.9293 146.301 19.6323Z"
			fill="white"
		/>
		<path
			d="M118.503 1.45658V16.8219L106.115 0.603727C105.976 0.425237 105.79 0.288435 105.578 0.208184C105.347 0.113587 105.1 0.0653398 104.85 0.0661942C104.654 0.0612543 104.459 0.0965854 104.277 0.170007C104.095 0.243429 103.93 0.353385 103.792 0.493084C103.65 0.636005 103.539 0.806421 103.466 0.993844C103.392 1.18127 103.358 1.38173 103.365 1.5829V22.1999H106.115V5.56598L118.538 21.75C118.677 21.907 118.858 22.0219 119.059 22.0819C119.278 22.1558 119.508 22.1932 119.739 22.1925C119.942 22.1994 120.143 22.1635 120.331 22.0872C120.519 22.011 120.689 21.8961 120.83 21.75C120.971 21.6032 121.081 21.4298 121.155 21.2398C121.228 21.0498 121.263 20.8471 121.257 20.6436V0.000732422H118.503V1.45658Z"
			fill="white"
		/>
	</g>
	<defs>
		<clipPath id="clip0_20_2">
			<rect width="147" height="23" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
