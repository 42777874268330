<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_107_13)">
		<!-- <g opacity=".5">
			<path
				d="M19.3063 5.33952C20.0904 4.09116 21.9096 4.09116 22.6937 5.33952L38.9944 31.2934C39.8309 32.6254 38.8736 34.3572 37.3007 34.3572H4.69929C3.12644 34.3572 2.16909 32.6254 3.00563 31.2934L19.3063 5.33952Z"
				stroke="#817E84"
				stroke-width="4"
			/>
			<path d="M21 12V24" stroke="#817E84" stroke-width="4" stroke-linecap="round" />
			<circle cx="21" cy="29" r="1" stroke="#817E84" stroke-width="2" />
		</g> -->
		<!-- <g opacity=".5"> -->
		<path
			d="M19.3063 5.33952C20.0904 4.09116 21.9096 4.09116 22.6937 5.33952L38.9944 31.2934C39.8309 32.6254 38.8736 34.3572 37.3007 34.3572H4.69929C3.12644 34.3572 2.16909 32.6254 3.00563 31.2934L19.3063 5.33952Z"
			class="svg-stroke"
			stroke={strokeColor}
			stroke-width="4"
		/>
		<path
			d="M21 12V24"
			class="svg-stroke"
			stroke={strokeColor}
			stroke-width="4"
			stroke-linecap="round"
		/>
		<circle cx="21" cy="29" r="1" class="svg-stroke" stroke={strokeColor} stroke-width="2" />
		<!-- </g> -->
	</g>
	<defs>
		<clipPath id="clip0_107_13">
			<rect width="42" height="37" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
	.svg-stroke {
		opacity: 75%;
	}
</style>
