import type { Device } from "luxedo-data"
import { Controller } from "svelte-comps/stores"

class CalMinController extends Controller<{
	isOpen: boolean
	device: Device
	posX: number // left position of minimized component
	posY: number // top position of minimized component
}> {
	constructor() {
		super({ isOpen: false, device: undefined, posX: undefined, posY: undefined })
	}

	open = (device: Device) => {
		this.store.set({ device, isOpen: true, posX: undefined, posY: undefined })
	}

	hide = () => {
		this.store.set({
			device: undefined,
			isOpen: false,
			posX: undefined,
			posY: undefined,
		})
	}

	// relativeMousePos: { x: number; y: number } // where on the component was the initial click?
	// onMouseDown(e: MouseEvent & { currentTarget: HTMLButtonElement }) {
	// 	console.log("mouse down")

	// 	const rect = e.currentTarget.getBoundingClientRect()
	// 	const relativeX = e.clientX - rect.left
	// 	const relativeY = e.clientY - rect.top
	// 	this.relativeMousePos = {
	// 		x: relativeX,
	// 		y: relativeY,
	// 	}

	// 	document.addEventListener("mousemove", this.handleMouseMove)
	// }

	// onMouseUp(e: MouseEvent & { currentTarget: HTMLButtonElement }) {
	// 	console.log("mouse up")

	// 	document.removeEventListener("mousemove", this.handleMouseMove)
	// }

	// handleMouseMove = (e: MouseEvent) => {
	// 	console.log("mouse move", e)

	// 	this.store.update((ctx) => ({
	// 		...ctx,
	// 		posX: e.clientX + this.relativeMousePos.x,
	// 		posY: e.clientY + this.relativeMousePos.y,
	// 	}))
	// }
}

export const CalibrationMinimizedController = new CalMinController()
