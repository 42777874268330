import type { DateTime } from "luxon"

export namespace TimeUtils {
	/**
	 * Convert min, sec and milliseconds to a timestamp
	 * @param min
	 * @param seconds
	 * @param milliseconds
	 * @returns
	 */
	export function userTimeToTimestamp(
		min: number | string,
		seconds: number | string,
		milliseconds: number | string
	): number {
		const milValue = Number(milliseconds) / 1000
		const secValue = Number(seconds)
		const minValue = Number(min) * 60
		return minValue + secValue + milValue
	}

	/**
	 * Converts a number (timestamp) into minutes, seconds and milliseconds. Can return an array of strings or numbers, depending on usecase.
	 * @param timestamp The timestamp to convert
	 * @param asStrings Set to true if strings are desired
	 */
	export function timestampToUserTime(timestamp: number): [number, number, number]
	export function timestampToUserTime(timestamp: number, asStrings: true): [string, string, string]
	export function timestampToUserTime(timestamp: number, asStrings?: true) {
		const millsec = Math.floor((timestamp % 1) * 1000)
		const seconds = Math.floor(timestamp % 60)
		const minutes = Math.floor(timestamp / 60)

		if (!asStrings) return [minutes, seconds, millsec]

		let milStr = String(millsec).padStart(3, "0")
		let secStr = String(seconds).padStart(2, "0")
		let minStr = String(minutes)

		return [minStr, secStr, milStr]
	}

	export function secondsToMinutes(time: number): `${string}:${string}` {
		const minutes = Math.floor(time / 60)
		let seconds = (time % 60).toString()
		if (seconds.length === 1) seconds = `0${seconds}`
		return `${minutes}:${seconds}`
	}

	export function renderTime(time: number) {
		if (!time) return `00:00`
		const [min, sec, mil] = timestampToUserTime(time, true)
		return `${min}:${sec}`
	}

	export function secondsToReadableTime(seconds: number) {
		const levels = [
			["milliseconds", 1000],
			["seconds", 60],
			["minutes", 60],
			["hours", 24],
			["days", 7],
			["weeks", 30 / 7], // Months are intuitively around 30 days
			["months", 12.1666666666666666], // Compensate for bakari-da in last step
			["years", 10],
			["decades", 10],
			["centuries", 10],
			["millenia", 10],
		]
		var value = seconds * 1000
		var name: string = ""
		var step: number = 1
		for (var i = 0, max = levels.length; i < max; ++i) {
			value /= step
			// @ts-ignore
			name = levels[i][0]
			// @ts-ignore
			step = levels[i][1]
			if (value < step) {
				break
			}
		}
		var num = value.toFixed(0)
		if (num == "1") {
			name = name.slice(0, -1)
		}
		var human_readable = value.toFixed(0) + " " + name
		return human_readable
	}

	export function dateTimeToString(dateTime: DateTime) {
		return dateTime
			.toISO({
				includeOffset: false,
			})
			.split(".")[0]
	}
}
