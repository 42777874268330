<script lang="ts">
	import { Guides } from "luxedo-data"
</script>

<div id="retry-container">
	<p class="heading">
		Once you have made adjustments to your setup and are ready, click "Retry" to begin a new
		calibration.
	</p>
</div>
<div class="additional-info">
	<p>
		To learn more about calibration, check out
		<a target="_blank" href={Guides.default.Device["Calibrating Your Device"]}>this article</a>.
	</p>
	<p>
		If none of the common issues helped, checkout our <a
			target="_blank"
			href={Guides.default.Device["Troubleshooting Calibration"]}
			>detailed calibration troubleshooting guide</a
		> for advanced troubleshooting.
	</p>
</div>

<!-- 


environment lighting: 
-pitch black
-neighborhood lights
-city lights
-daylight

spotlight

advanced:
-exposure (1-5)
-exposure mode (exact, adaptive, auto-detect)
-image dimming (0, 10, ..., 50%)
-level of detail (medium, high, very high)
-exposure mode (best contrast, best brightness)




 -->

<style>
	#retry-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		color: var(--color-text);
	}

	.environment-lighting-container {
		line-height: 1em;
	}

	.cal-warning {
		line-height: 1em;
		display: block;
		font-size: var(--h3);
	}

	.toggle-switch-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 1.5rem;
	}

	.toggle-switch-input .label {
		margin-right: 1rem;
		color: var(--color-text);
	}

	.heading {
		color: var(--color-text-light);
		margin-bottom: 1rem;
		font-size: var(--h2);
	}

	p {
		margin-bottom: 0;
	}

	.additional-info a {
		color: var(--color-text);
		border-color: var(--color-alt);
		transition:
			color 250ms,
			border-color 250ms;
	}

	.additional-info a:hover {
		color: var(--color-text-light);
		border-color: var(--color-main);
	}

	.advanced-options-container {
		flex-basis: 60%;
	}

	#advanced-options {
		overflow: hidden;
		transition: height 250ms;
	}

	#advanced-options.closed {
		height: 0;
	}

	#advanced-options.open {
		height: 100%;
	}

	#advanced-option-dropdown {
		padding: 0;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		font-size: var(--h1);
	}
	#advanced-option-dropdown :global(svg) {
		transition: transform 250ms;
		margin-left: 0.5rem;
		height: 2rem;
	}
	#advanced-option-dropdown.open :global(svg) {
		transform: rotateX(180deg);
	}
	.select-container label {
		color: var(--color-text);
	}

	.error {
		color: var(--ct-red);
	}

	.warn {
		color: var(--color-main);
	}

	.additional-info {
		justify-self: flex-end;
		color: var(--color-text);
	}

	.additional-info > p {
		font-size: var(--h3);
	}

	.legacy-cal-warning {
		color: var(--color-main);
		margin-bottom: 1rem;
	}
</style>
