<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_1_92)">
		<mask
			id="mask0_1_92"
			style="mask-type:luminance"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="75"
			height="75"
		>
			<path d="M75 0H0V75H75V0Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1_92)">
			<mask
				id="mask1_1_92"
				style="mask-type:luminance"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="75"
				height="75"
			>
				<path d="M75 0H0V75H75V0Z" fill="white" />
			</mask>
			<g mask="url(#mask1_1_92)">
				<path
					d="M64.8 9.39005C66.43 9.39005 67.86 10.4801 68.28 12.0601C68.58 13.1801 68.9 14.37 69.08 15.04C69.17 15.39 69.22 15.74 69.22 16.1V54.1C69.22 56.09 67.61 57.7 65.62 57.7H17.3199C15.3299 57.7 13.72 56.09 13.72 54.1V19.16C13.72 17.17 15.3299 15.5601 17.3199 15.5601H45.92C47.77 15.5601 49.4 14.3101 49.88 12.5201L50 12.0601C50.42 10.4901 51.85 9.39005 53.48 9.39005H64.81H64.8ZM64.8 5.79004H53.47C50.22 5.79004 47.36 7.98004 46.52 11.13L46.4 11.5901C46.34 11.8101 46.14 11.9601 45.92 11.9601H17.3199C13.3499 11.9601 10.12 15.19 10.12 19.16V54.1C10.12 58.07 13.3499 61.3 17.3199 61.3H65.62C69.59 61.3 72.8199 58.07 72.8199 54.1V16.1C72.8199 15.43 72.73 14.76 72.56 14.11L72.5099 13.94L71.7599 11.14C70.9199 8.00005 68.06 5.80005 64.81 5.80005"
					fill="#817E84"
				/>
				<path
					d="M29.97 26.88C29.97 28.1 28.96 29.0901 27.72 29.0901C26.48 29.0901 25.47 28.1 25.47 26.88C25.47 25.66 26.48 24.67 27.72 24.67C28.96 24.67 29.97 25.66 29.97 26.88Z"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
					stroke-linecap="round"
				/>
				<path
					d="M18.03 51.22L31.97 37.28"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
					stroke-linecap="round"
				/>
				<path
					d="M31.97 37.28L41.27 46.58"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
					stroke-linecap="round"
				/>
				<path
					d="M41.27 46.58L55.05 32.8101"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
					stroke-linecap="round"
				/>
				<path
					d="M55.05 32.8L64.3 42.0601"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
					stroke-linecap="round"
				/>
				<path
					d="M4.82996 24.44V54.1C4.82996 60.99 10.42 66.58 17.31 66.58H60.33"
					stroke="#817E84"
					stroke-width="3.6"
					stroke-miterlimit="10"
				/>
			</g>
		</g>
	</g>
	<defs>
		<clipPath id="clip0_1_92">
			<rect width="75" height="75" fill="white" />
		</clipPath>
	</defs>
</svg>
