<script lang="ts">
	import { ArrowBack } from "svelte-comps/icons"
	import { MaskSidebarController } from "./MaskEditorSidebarController"

	export let title: string
	export let goBack: () => void = undefined
</script>

<div class="flex-row heading-container">
	<button class="icon" on:click={goBack ? goBack : MaskSidebarController.goBack}
		><ArrowBack /></button
	>
	<div class="heading">
		<h3>{title}</h3>
	</div>
</div>

<style>
	.heading-container h3 {
		font-size: var(--h0);
		margin: 0;
	}

	.heading-container {
		margin-bottom: 1rem;
		align-items: center;
		padding-right: 0.5rem;
	}

	.heading-container button {
		margin-right: 0.5rem;
	}

	.heading-container button :global(svg) {
		height: 2rem;
		width: auto;
	}
</style>
