import { writable } from "svelte/store"
import { DataHandlerUser, User } from "luxedo-data"
import * as Sentry from "@sentry/svelte"

let user: User
export const UserStore = writable<User>(null)

DataHandlerUser.addListener((changedIds) => {
	if (changedIds.length > 0) {
		UserStore.set(DataHandlerUser.get(changedIds[0]))
	}
})

UserStore.subscribe((v) => {
	user = v
	if (!v) return
	Sentry.setUser({
		email: user.email,
		id: user.id,
		username: `${user.firstname} ${user.lastname}`,
		ip_address: "{{auto}}",
	})
})

/**
 * ONLY to be called in places where the UserStore may not yet be defined (e.g., inside a DataHandler)
 */
export function getCurrentUser() {
	return user
}
