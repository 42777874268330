import { v4 as uuid } from "uuid"
import { fabric } from "fabric"
import { EditorClass } from "../../.."
import { CanvasAsset, Path, PathMessageType, PathSubscriber, Polygon } from "../../../asset"
import { TrackLike } from "../../../tracks"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../../modules/serialize"
import { MaskOptions, Mask, MultiMask } from "."
import { fixPointSet } from "../../../fabric-plugins"

export interface ClippingMaskOptions extends MaskOptions {
	strokeWidth?: number
	stroke?: string
	fill?: string | fabric.Pattern | fabric.Gradient | undefined
}

export interface ClippingMask extends ClippingMaskOptions, Mask {
	clipPath: fabric.Object | undefined
	parent: MultiMask
}

@Mask.Mixin
export class ClippingMask extends fabric.Polygon implements PathSubscriber, Serializable {
	boundTrack: TrackLike<CanvasAsset>

	path?: Path
	entryId?: number
	data?: { [index: string]: any }
	selectionPath?: Polygon

	constructor(editor: EditorClass, path: Path, options?: ClippingMaskOptions) {
		options = options ?? {}

		super(path.points, {
			...ClippingMask.CLIPPING_MASK_DEFAULTS,
			...options,
			...CLIPPING_MASK_FORCED,
		})

		this.editor = editor
		this.id = options.id ?? uuid()

		this.overflow = options?.overflow ?? "hidden"

		this.path = path
		this.path.register()
		this.path.subscribe(this)

		this.resetSelectionPath()
	}

	unlock() {
		this.lockMovementX = false
		this.lockMovementY = false
		this.lockRotation = false
		this.lockScalingFlip = false
		this.lockScalingX = false
		this.lockScalingY = false
		this.lockSkewingX = false
		this.lockSkewingY = false
		this.lockUniScaling = false

		this.path.lockMovementX = false
		this.path.lockMovementY = false
		this.path.lockRotation = false
		this.path.lockScalingFlip = false
		this.path.lockScalingX = false
		this.path.lockScalingY = false
		this.path.lockSkewingX = false
		this.path.lockSkewingY = false
		this.path.lockUniScaling = false

		this.selectionPath.lockMovementX = false
		this.selectionPath.lockMovementY = false
		this.selectionPath.lockRotation = false
		this.selectionPath.lockScalingFlip = false
		this.selectionPath.lockScalingX = false
		this.selectionPath.lockScalingY = false
		this.selectionPath.lockSkewingX = false
		this.selectionPath.lockSkewingY = false
		this.selectionPath.lockUniScaling = true
	}

	lock() {
		this.lockMovementX = true
		this.lockMovementY = true
		this.lockRotation = true
		this.lockScalingFlip = true
		this.lockScalingX = true
		this.lockScalingY = true
		this.lockSkewingX = true
		this.lockSkewingY = true
		this.lockUniScaling = true

		this.path.lockMovementX = true
		this.path.lockMovementY = true
		this.path.lockRotation = true
		this.path.lockScalingFlip = true
		this.path.lockScalingX = true
		this.path.lockScalingY = true
		this.path.lockSkewingX = true
		this.path.lockSkewingY = true
		this.path.lockUniScaling = true

		this.selectionPath.lockMovementX = true
		this.selectionPath.lockMovementY = true
		this.selectionPath.lockRotation = true
		this.selectionPath.lockScalingFlip = true
		this.selectionPath.lockScalingX = true
		this.selectionPath.lockScalingY = true
		this.selectionPath.lockSkewingX = true
		this.selectionPath.lockSkewingY = true
		this.selectionPath.lockUniScaling = true
	}

	scaleTo(width: number, height: number) {
		const wScale = width / this.width
		const hScale = height / this.height

		this.scaleX = wScale
		this.scaleY = hScale

		this.path.like(this)
		this.selectionPath.like(this)
	}

	resetSelectionPath() {
		this.selectionPath = Polygon.FromPath(this.path, {
			stroke: "#41414110",
			strokeWidth: 10,
			fill: "transparent",
			width: 100,
			height: 100,
		})
		this.selectionPath.selectable = false

		this.selectionPath.like(this)
		this.selectionPath.perPixelTargetFind = true
		this.initializePathUpdaters()
	}

	initializePathUpdaters() {
		const updatePaths = () => {
			this.path.like(this)
			this.selectionPath.like(this)
		}

		this.on("moving", updatePaths)
		this.on("scaling", updatePaths)
		this.on("rotating", updatePaths)

		this.selectionPath.on("mousedown", () => {
			this.select()
		})
	}

	select() {
		console.log(this)

		if (!this.parent) {
			this.editor.selection.set(this)
		} else {
			this.parent.select()
		}
	}

	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	) {
		switch (messageType) {
			case "unsubscribed":
			case "deleted":
				this.detach()
				break
			case "point:add":
			case "point:delete":
			case "point:edit":
				this.recalculateBounds()
				this.boundTrack && this.boundTrack.maskUpdated(true)
			default:
				return
		}
		this.editor.canvas.requestRenderAll()
	}

	like(shape: fabric.Polyline) {
		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left
		this.top = shape.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)
	}

	getMaskCenter(): fabric.Point {
		let xTotal = 0
		let yTotal = 0

		for (const { x, y } of this.points) {
			xTotal += (x - this.pathOffset.x) * this.scaleX + this.left
			yTotal += (y - this.pathOffset.y) * this.scaleY + this.top
		}

		const averageX = xTotal / this.points.length
		const averageY = yTotal / this.points.length

		return new fabric.Point(averageX, averageY)
	}

	//#region ====================================    Serialization    ====================================

	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude?: string[]
	} {
		return {
			include: ["name", "inverted", "id"],
			deepCopy: [],
			exclude: ["path", "boundPath"],
		}
	}

	serialize(forExport?: boolean) {
		const json = convertInstanceToObject(this, {
			forExport,
			propertiesToInclude: ["entryId", "data", "id"],
		})

		if (forExport) json["id"] = this.id
		json["pathId"] = this.path.id

		return json
	}

	static async loadJSON(editor: EditorClass, data: Partial<ClippingMask>) {
		const pathData = data["pathData"]
		pathData["track"] = data["track"]

		const path = await Path.loadJSON(editor, pathData, pathData.id)

		data.points = fixPointSet(data.points)

		const instance = new ClippingMask(editor, path, data)
		instance.inverted = data.inverted
		instance.entryId = data.entryId
		instance.data = data.data
		instance.path.like(instance)
		// @ts-ignore
		delete instance.pathData

		return instance
	}

	//#endregion ==========================================================================================

	static CLIPPING_MASK_DEFAULTS: fabric.IPolylineOptions = {
		strokeWidth: 2,
		stroke: "#ffffff10",
		fill: "#ffffff08",
		angle: 0,
	}
}

const CLIPPING_MASK_FORCED: fabric.IPolylineOptions = {
	absolutePositioned: true,
	originX: "center",
	originY: "center",
	objectCaching: false,
	noScaleCache: true,
	strokeUniform: true,
}

registerSerializableConstructor(ClippingMask)
