<script lang="ts">
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		Lightshow,
		type Device,
		type Scene,
		TimetableEvent,
		type FullCalendarEvent,
		type EventOccurrence,
		LightshowBreak,
	} from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { DateTime } from "luxon"
	import { SettingsIcon } from "svelte-comps/icons"
	import { ShowLibraryController } from "../../../show-library/ShowLibraryController"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { ScheduleController } from "../../../schedule/ScheduleController"
	import UpcomingEvent from "./UpcomingEvent.svelte"
	import { openShowOverview } from "../../../../reusable/overlays"

	let device: Device
	let listenerId: string

	let activeEvent: TimetableEvent
	let activeFCEvent: FullCalendarEvent
	let activeLightshow: Lightshow
	let activeShow: Scene

	let orderedEvents: Array<EventOccurrence>
	let lightshowSceneUpateInterval: number

	SelectedDeviceStore.subscribe(initializeDeviceListener)

	function initializeDeviceListener(dev: Device) {
		if (device && listenerId && dev && dev.id !== device.id) device.removeUpdateListener(listenerId)

		if (!dev) return
		device = dev

		listenerId = dev.addUpdateListener(onEidosUpdate)
	}

	/**
	 * Gets the currently playing scene, parsing the lightshow to find it if applicable
	 */
	async function getActiveScene(id: number) {
		const lightshow = DataHandlerLightshow.get(id)
		activeLightshow = lightshow
		if (lightshow) {
			if (!lightshow.duration) await DataHandlerLightshow.pull([id])

			const startTime = DateTime.fromMillis(device.eidos.proj_play_starttime * 1000, {
				zone: "utc",
			})

			const timestamp = DateTime.now().diff(startTime).as("seconds") % lightshow.duration
			const sequence = lightshow.getScenesAndTimestamps()

			let prevScene = undefined
			for (const [start, scene] of Object.entries(sequence)) {
				const startTime = Number(start)
				if (timestamp < startTime) return prevScene

				prevScene = scene
			}

			// If the loop ended, the last scene is active
			const lastScene = Object.values(sequence)[Object.values(sequence).length - 1]
			return lastScene
		} else return DataHandlerScene.get(id)
	}

	async function getActiveEvent(projectId: number, projectStartTime: number) {
		if (isNaN(projectStartTime)) return undefined
		return device.timetableManager.findEvent(
			projectId,
			DateTime.fromSeconds(projectStartTime, { zone: "utc" })
		)
	}

	function listenForLightshowSceneChanges(id: number) {
		lightshowSceneUpateInterval = setInterval(async () => {
			activeShow = await getActiveScene(id)
		}, 1000)
	}

	/**
	 * Called when the device eidos has an update. Updates the active show if relevant.
	 * @param dev
	 */
	async function onEidosUpdate(dev: Device) {
		if (!dev) return

		if (lightshowSceneUpateInterval) clearInterval(lightshowSceneUpateInterval)
		orderedEvents = device?.timetableManager.getEventsInOrder(10, { from: DateTime.now() })

		activeLightshow = undefined
		if (dev.eidos.proj_id) {
			activeEvent = await getActiveEvent(dev.eidos.proj_id, dev.eidos.proj_play_starttime)
			if (!activeEvent) {
				return (activeShow = undefined)
			}
			activeFCEvent = await ScheduleController.Calendar.getEventByEventId(activeEvent.id)
			activeShow = await getActiveScene(dev.eidos.proj_id)
			if (DataHandlerLightshow.get(dev.eidos.proj_id))
				listenForLightshowSceneChanges(dev.eidos.proj_id)
		} else {
			activeShow = undefined
		}
	}

	function verifyDelete(): Promise<void> {
		return new Promise((res, rej) => {
			openConfirmOverlay({
				buttons: {
					confirm: {
						text: "Yes",
						onClick: res,
					},
					deny: {
						text: "No",
						onClick: rej,
					},
				},
				prompt: [
					"Are you sure you want to cancel this event?",
					"This may cancel the current playback.",
				],
			})
		})
	}

	async function cancelEvent() {
		try {
			await verifyDelete()
			await device.timetableManager.deleteEvent(activeEvent)
			Toast.success("Event cancelled.")
		} catch (e) {
			console.log("User cancelled the event cancel.", e)
		}
	}
</script>

{#if activeShow}
	<h1 id="now-playing-heading">Now Playing</h1>
	<div class="now-playing-instance">
		<div class="show-heading">
			<div class="show-info">
				<h3 class="show-title">
					{activeShow.name ?? "Break"}
				</h3>
				{#if activeLightshow}
					<div class="lightshow-info">
						<span>Playing from {activeLightshow.name} </span>
						<button
							id="edit-lightshow-button"
							class="icon"
							title="Edit Lightshow"
							on:click={() => ShowLibraryController.LightshowEditor.editLightshow(activeLightshow)}
							><SettingsIcon /></button
						>
					</div>
				{/if}
				<span class="last-updated">
					Last Updated: {activeShow instanceof LightshowBreak
						? "n/a"
						: DateTime.fromJSDate(activeShow.render_ver).toFormat("DDD")}</span
				>
				<div class="button-container">
					<button class="small error now-playing" on:click={cancelEvent}>Cancel Event</button>
					<button
						class="small alt now-playing"
						on:click={() => ScheduleController.EventEditor.editEvent(activeEvent)}
						>Edit Schedule</button
					>
					<button
						class="small now-playing"
						on:click={() => ShowLibraryController.editShow(activeShow)}>Edit Scene</button
					>
				</div>
			</div>
		</div>
	</div>
{/if}

<h1 id="upcoming-events-heading" class={activeShow ? "with-now-playing" : ""}>Upcoming Events</h1>
<div class="upcoming-events">
	{#if orderedEvents && orderedEvents.length}
		{#each orderedEvents as occurrence}
			<UpcomingEvent {occurrence} />
		{/each}
	{:else}
		<p>Looks like you don't have any upcoming scheduled events for this device.</p>
		<div class="info">
			Scheduling allows you to tell your projector to play any of your rendered Scenes or Lightshows
			at any time. All of your scheduled events will show up in the Schedule page, where you can
			manage existing events or schedule new ones.
		</div>
		<button
			class="outline-button"
			on:click={() =>
				ScheduleController.EventEditor.editEvent(undefined, {
					device,
				})}>Schedule New Event</button
		>
	{/if}
</div>
<p class="help">
	Having trouble? check out our
	<a target="_blank" href="https://www.luxedo.com/pages/help">help center</a> for assistance.
</p>

<style>
	.now-playing-instance {
		display: flex;
		flex-direction: column;
		background-color: var(--color-bg);
		padding: 0.5rem;
		border-radius: var(--br);
	}

	#upcoming-events-heading.with-now-playing {
		font-size: var(--h1);
		margin-top: 1rem;
	}

	.show-heading {
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	.show-title {
		color: var(--color-text-light);
		font-size: var(--h1);
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.last-updated {
		color: var(--color-text);
		line-height: 1.5em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: var(--h3);
	}

	.lightshow-info {
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	.show-info {
		font-size: var(--h2);
		width: 100%;
		padding: 0.25rem 0.25rem 0.25rem 0.25rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		justify-content: flex-end;
	}

	.show-info .button-container {
		margin: 0.5rem 0 0 0;
		justify-content: space-between;
		gap: 0.5rem;
	}

	button.icon {
		width: 1rem;
		height: auto;
		padding: 0;
		margin-left: 0.5rem;
	}

	button.small {
		padding: 0.1rem 0.5rem;
		margin-bottom: 0;
	}

	.upcoming-events {
		overflow-y: auto;
		margin-right: -0.5rem;
		padding-right: 0.5rem;
	}

	#edit-lightshow-button {
		--color-icon: var(--color-text-light);
	}

	#edit-lightshow-button:hover,
	#edit-lightshow-button:focus-visible {
		--color-icon: var(--color-main);
	}

	#edit-lightshow-button :global(.svg-fill) {
		fill: var(--color-icon);
	}

	#edit-lightshow-button :global(.svg-stroke) {
		stroke: var(--color-icon);
	}
</style>
