import { DataHandlerMedia, Media } from "luxedo-data"
import { MediaAsset, MediaOptions, Transformable } from ".."
import { Serializable, registerSerializableConstructor } from "../../../modules/serialize"
import { Track } from "../../../tracks"
import { VideoSource } from "./VideoSource"
import { EditorClass, getEditor } from "../../.."

export interface VideoAsset extends MediaAsset<VideoSource>, Transformable<VideoSource> {
	mediaId: number
	getTrack(): Track<VideoAsset>
}

@Transformable.Mixin
export class VideoAsset extends MediaAsset<VideoSource> implements Serializable {
	declare media: Media

	_width: number
	_height: number
	scaleX?: number
	scaleY?: number

	constructor(editor: EditorClass, options: MediaOptions, objectOptions?: fabric.IImageOptions) {
		super(editor, options, objectOptions)

		this.scaleX = objectOptions.scaleX ?? 1
		this.scaleY = objectOptions.scaleY ?? 1
		this.width = objectOptions.width ?? 100
		this.height = objectOptions.height ?? 100
	}

	// #region ========================== Properties ==========================

	get contentWidth(): number {
		return this.content.video.videoWidth
	}

	get contentHeight(): number {
		return this.content.video.videoHeight
	}

	// #endregion ======================= Properties ==========================
	// #region ==========================  Controls  ==========================

	get duration() {
		if (this.content.video.duration) return this.content.video.duration
		else return this.media.duration
	}

	get currentTime() {
		return this.content.currentTime
	}

	set currentTime(timestamp: number) {
		if (timestamp > this.content.video.duration) throw RangeError("Position cannot be greater than duration")
		this.content.currentTime = timestamp
	}

	get volume() {
		return this.content.volume
	}

	set volume(newVolume: number) {
		this.content.volume = newVolume
	}

	set width(w: number) {
		this._width = w
		if (this.content) this.content.video.width = w
	}

	get width() {
		return this._width
	}

	set height(h: number) {
		this._height = h
		if (this.content) this.content.video.height = h
	}

	get height() {
		return this._height
	}

	// #endregion ======================== Controls  ==========================
	// #region ========================== Track Logic ==========================

	bindTrack(track: Track<any>): void {
		super.bindTrack(track)

		const onTick = getEditor().timeline.on("tick", (e) => {
			if (!getEditor().timeline.playing) this.content.pause()
			if (!this.visible) return this.content.pause()

			if (getEditor().timeline.playing && this.content.video.paused) {
				this.currentTime = (e.timestamp - this.track.start) % this.duration
				this.content.play()
			}
		})

		const onSeek = getEditor().timeline.on("preview:seek", (e) => {
			if (!this.visible) return

			this.currentTime = (e.timestamp - this.track.start) % this.duration
		})

		const onStop = getEditor().timeline.on("preview:stop", (e) => {
			if (!this.visible) return

			this.currentTime = (e.timestamp - this.track.start) % this.duration
		})

		this.behaviorHandlers.push(onTick, onSeek, onStop)
	}

	// #endregion ======================= Track Logic ==========================
	// #region ========================== Lifecycle  ==========================

	onDelete(): void {
		super.onDelete()
		this.content.pause()
		this.content.video.remove()
		this.dispose()
		delete this.content
	}

	protected initEmptyContent(): void {
		this.content = new VideoSource(this.media, document.createElement("video"))
	}

	protected async load(url: string): Promise<void> {
		this.media = DataHandlerMedia.get(this.mediaId)

		this.isLoading = true

		this.initEmptyContent()

		await this.loadContent(url)
		this.onContentLoaded()

		this.content.currentTime = 0
		this.isLoading = false
	}

	protected loadContent(url: string): Promise<any> {
		return this.content.load()
	}

	protected onContentLoaded(): void {
		this.width = this.content.video.videoWidth
		this.height = this.content.video.videoHeight

		this.setCoords()

		this.content.video.oncanplay = (e) => {
			this.lastCachedVideoTS = -1
			this.content = this.content
			this.editor.canvas.requestRenderAll()
		}

		getEditor().canvas.requestRenderAll()
	}

	// #endregion ======================= Lifecycle  ==========================
	// #region =========================== Caching  ===========================

	private lastCachedVideoTS = 0
	isCacheDirty(skipCanvas?: boolean): boolean {
		if (this.currentCacheTime != this.lastCachedVideoTS) {
			this.lastCachedVideoTS = this.currentCacheTime
			return true
		}
		return super.isCacheDirty(skipCanvas)
	}

	private maxFrameRateWhenTransformed = 5
	private get currentCacheTime() {
		return getEditor().timeline.playing
			? Math.ceil(this.currentTime * this.maxFrameRateWhenTransformed) / this.maxFrameRateWhenTransformed
			: this.currentTime
	}

	// #endregion ======================= Caching ==========================
	// #region ======================== Serialization =========================

	serialize(forExport?: boolean): Partial<this> {
		const data = super.serialize(forExport)
		data["volume"] = this.volume

		return data
	}

	getDefaultPropertyExports(forExport?: boolean): { include: string[]; deepCopy: string[]; exclude: string[] } {
		return {
			include: ["width", "height", "scaleX", "scaleY"],
			deepCopy: [],
			exclude: [],
		}
	}

	static async loadJSON(editor: EditorClass, data: Partial<VideoAsset> & { media?: any }, id?: string) {
		let instance: VideoAsset
		let volume: number

		// importing the volume in the constructor throws an error as the content has not yet been initialized in the prototype stack
		if ("volume" in data) {
			volume = data.volume
			delete data.volume
		} else volume = 1

		if (data.media) {
			instance = new VideoAsset(editor, { mediaId: data.media.id, src: data.media.src.editorPreview, id }, data)
		} else {
			instance = new VideoAsset(editor, { mediaId: data.mediaId, src: data.src, id }, data)
		}

		await instance.loadingPromise

		instance.volume = volume

		if (!data.isTransformed) return instance
		instance = Transformable.applySerializedTransformations(instance, data)

		return instance
	}

	// #endregion ===================== Serialization =========================
}

registerSerializableConstructor(VideoAsset)
