export default {
	XGA: {
		width: 1024,
		height: 768,
		aspect_ratio: "4:3",
		names: ["XGA"],
		supported: true,
	},
	SXGA: {
		width: 1280,
		height: 1024,
		aspect_ratio: "5:4",
		names: ["SXGA", "Super XGA"],
		supported: true,
	},
	"SXGA+": {
		width: 1400,
		height: 1050,
		aspect_ratio: "4:3",
		names: ["SXGA+", "Super XGA Plus"],
		supported: true,
	},
	WXGA: {
		width: 1280,
		height: 800,
		aspect_ratio: "16:10",
		names: ["WXGA", "Wide XGA"],
		supported: true,
	},
	"WXGA+": {
		width: 1440,
		height: 900,
		aspect_ratio: "16:10",
		names: ["WXGA+", "Wide XGA+"],
		supported: true,
	},
	UXGA: {
		width: 1600,
		height: 1200,
		aspect_ratio: "4:3",
		names: ["UXGA"],
		supported: true,
	},
	HDTV: {
		width: 1280,
		height: 720,
		aspect_ratio: "16:9",
		names: ["HDTV", "HD", "720p"],
		supported: true,
	},
	"HD+": {
		width: 1600,
		height: 900,
		aspect_ratio: "16:9",
		names: ["HD+", "900p"],
		supported: true,
	},
	FHD: {
		width: 1920,
		height: 1080,
		aspect_ratio: "16:9",
		names: ["FHD", "Full HD", "1080p"],
		supported: true,
	},
	WQHD: {
		width: 2560,
		height: 1440,
		aspect_ratio: "16:9",
		names: ["WQHD", "1440p"],
		supported: false,
	},
	"4KUHD": {
		width: 3840,
		height: 2160,
		aspect_ratio: "16:9",
		names: ["4K", "UHD", "4KUHD", "4K UHD", "2160p"],
		supported: true,
	},
	WSXGA: {
		width: 1440,
		height: 900,
		aspect_ratio: "16:10",
		names: ["WSXGA"],
		supported: true,
	},
	WUXGA: {
		width: 1920,
		height: 1200,
		aspect_ratio: "16:10",
		names: ["WUXGA"],
		supported: true,
	},
	WQXGA: {
		width: 2560,
		height: 1600,
		aspect_ratio: "16:10",
		names: ["WQXGA"],
		supported: true,
	},
	"UW-FHD": {
		width: 2560,
		height: 1080,
		aspect_ratio: "21:9",
		names: ["UW-FHD", "UltraWide FHD"],
		supported: false,
	},
	"UW-QHD": {
		width: 3440,
		height: 1440,
		aspect_ratio: "21:9",
		names: ["UW-QHD", "Ultra-Wide QHD"],
		supported: false,
	},
	UW5K: {
		width: 5120,
		height: 2160,
		aspect_ratio: "21:9",
		names: ["WUHD", "UW5K", "Ultra-Wide 5K"],
		supported: false,
	},
}
