import { EditorClass } from "../.."
import { LocalTimestamp, timestampAbsoluteToLocal, Keyframe } from "../../animation"
import { AnimationPreset, PresetKeyframes, PresetOptions } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { registerSerializableConstructor } from "../../modules/serialize"
import { Track } from "../../tracks"

export class FadeOut extends AnimationPreset<CanvasAsset> {
	name = "FadeOut"
	properties = ["opacity" as const]
	type = "multiplier" as const

	duration: LocalTimestamp
	initStart // reference to track initial start for resize
	initDuration // reference to track initial duration for resize

	declare track: Track<CanvasAsset>

	constructor(editor: EditorClass, track: Track<CanvasAsset>, duration: LocalTimestamp) {
		super(editor, track, {
			startTime: (1 - duration > 0 ? 1 - duration : 0) as LocalTimestamp,
			endTime: 1 as LocalTimestamp,
		})

		this.duration = duration

		this.initStart = this.track.start
		this.initDuration = this.track.duration

		this.generateKeyframes()
	}

	updateDuration(newDuration: LocalTimestamp) {
		this.duration = newDuration
		this.startTime = (1 - newDuration > 0 ? 1 - newDuration : 0) as LocalTimestamp

		this.generateKeyframes()
	}

	onResize(): void {
		const absDuration = Math.round(this.initDuration * this.duration * 10) / 10
		this.startTime = timestampAbsoluteToLocal(this.track.end - absDuration, this.track)

		this.initStart = this.track.start
		this.initDuration = this.track.duration

		this.generateKeyframes()
	}

	generateKeyframes(): void {
		const keyframes: Keyframe[] = []

		let ts = this.endTime - this.duration
		if (ts < 0) ts = 0

		keyframes.push({
			timestamp: ts as LocalTimestamp,
			value: 1,
		})

		keyframes.push({
			timestamp: this.endTime,
			value: 0,
		})

		this.keyframes = { opacity: new PresetKeyframes(this, keyframes) }
	}

	static async loadJSON(editor: EditorClass, data: Partial<FadeOut>): Promise<FadeOut> {
		const preset = new this(editor, data.track, data.duration)
		return preset
	}
}

registerSerializableConstructor(FadeOut)
