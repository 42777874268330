<script lang="ts">
	import Paragraph from "../../comps/Paragraph.svelte"
	import type { InputOverlayButton } from "./types"

	export let prompt: string | Array<string> = undefined
	export let invertWarning: boolean = false
	export let buttons: {
		confirm: InputOverlayButton
		deny?: InputOverlayButton
	}

	export let closeOverlay: () => void

	function handleKeyPress(e: KeyboardEvent) {
		if (e.key === "Enter") {
			buttons.confirm.onClick()
			closeOverlay()
		}
	}
</script>

<div class="overlay-confirm-container">
	{#if prompt}
		<Paragraph textContent={prompt} />
	{/if}
	<span class="button-container">
		<button
			class="{invertWarning ? '' : 'cancel'} link-button"
			on:click={() => {
				buttons.deny?.onClick()
				closeOverlay()
			}}>{buttons.deny?.text ?? "Cancel"}</button
		>
		<button
			class={invertWarning ? "cancel" : ""}
			on:click={() => {
				buttons.confirm.onClick()
				closeOverlay()
			}}>{buttons.confirm.text}</button
		>
	</span>
</div>
<svelte:window on:keypress={handleKeyPress} />

<style>
	div {
		min-width: 10rem;
		max-width: 40vw;
		width: 100%;
		height: 100%;
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
</style>
