import { MenuSections, MenuOptionState } from "../../controllers"
import { FabricEvent } from "./types"

const DefaultMenuStyle: Partial<HTMLDivElement["style"]> = {
	position: "absolute",
	backgroundColor: "#333",
	color: "#fff",
	padding: "10px",
	zIndex: "1000",
	width: "200px",
	borderRadius: "4px",
}

const DefaultItemStyle: Partial<HTMLSpanElement["style"]> = {
	padding: "5px 0",
	cursor: "pointer",
}

const DefaultDividerStyle: Partial<HTMLDivElement["style"]> = {
	width: "90%",
	padding: "0px 5%",
	margin: "3px 0px",
	height: "1px",
	backgroundColor: "#777",
}

export function renderDefaultContextMenu<T>(
	menuConfig: MenuSections,
	target: T,
	event: MouseEvent,
	fabricEvent?: FabricEvent
) {
	console.log("DEFAULT", { menuConfig, target, event, fabricEvent })

	// Create a div to contain the menu
	const menu = document.createElement("div")
	Object.assign(menu.style, DefaultMenuStyle)

	menu.classList.add("editorContextMenu")

	// Position the menu at the event position
	menu.style.left = `${event.pageX}px`
	menu.style.top = `${event.pageY}px`

	let divider: HTMLSpanElement
	// Add each menu item to the menu

	for (const section of this.editor.contextMenu.getActiveMenuLayout()) {
		for (const optionState of section) {
			const menuItem = renderDefaultContextMenuItem(optionState)

			menu.appendChild(menuItem)
		}

		divider = document.createElement("div")
		divider.classList.add("context-divider")
		Object.assign(divider.style, DefaultDividerStyle)
		menu.appendChild(divider)
	}

	// Remove the last divider
	if (divider) divider.remove()

	// Append the menu to the body
	document.body.appendChild(menu)

	// Check if the menu goes off the bottom of the screen, and shift it upwards if necessary
	const rect = menu.getBoundingClientRect()
	const offsetBottom = window.innerHeight - rect.bottom
	if (offsetBottom < 0) {
		menu.style.top = `${parseFloat(menu.style.top) + offsetBottom}px`
	}

	// Return the menu element
	return menu
}

export function renderDefaultContextMenuItem(optionData: MenuOptionState): HTMLElement | undefined {
	// If the showIf option is defined but the conditions aren't met, simply return undefined
	if (!optionData.visible) return

	// Create an item element
	const item = document.createElement("div")

	Object.assign(item.style, DefaultItemStyle)

	const optName = optionData.label

	if (typeof optName === "string") item.textContent = optName
	else item.appendChild(optName)

	// If disabled, grey out the item
	if (optionData.greyedOut) {
		item.style.color = "#aaa"
		item.style.pointerEvents = null
		item.style.opacity = "0.5"
		return item
	}

	// Set the action handler
	item.onclick = () => {
		const stayOpen = optionData.action()
		if (!stayOpen) {
			this.editor.contextMenu.clear()
		}
	}

	return item
}

export function clearContextMenus() {
	document.querySelectorAll(".editorContextMenu").forEach((elem) => elem.remove())
}
