<script lang="ts">
	import type { Media } from "luxedo-data"

	export let media: Media

	$: console.log(media.src.audio)
</script>

<audio controls src={media.src.audio}></audio>

<style>
	audio {
		width: 50vw;
		margin-top: 5rem;
	}
</style>
