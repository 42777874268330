import { openOverlay } from "svelte-comps/overlay"
import BlendZoneEditor from "./BlendZoneEditor.svelte"
import type { OverlapInstance } from ".."
import type { DeviceGroup } from "luxedo-data"
import { BlendZoneEditorController } from "./BlendZoneEditorController"

export function openCustomBlendOverlay(
	overlap: OverlapInstance,
	slotID: number,
	group: DeviceGroup,
	onSave: (imageData: string | null) => any,
	onClose: () => any
) {
	BlendZoneEditorController.reset()
	return openOverlay(BlendZoneEditor, {
		classHeading: "no-underline",
		classOverlay: "no-pad can-grow",
		props: {
			overlap,
			slotID,
			group,
			onSave,
			beforeClose: onClose,
		},
	})
}
