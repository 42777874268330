import { EditorClass } from ".."
import { ModeEvent } from "../events"
import { EventedInstance } from "../events/decorators"
import type { Mode, ModeOptionsArg, ModeConstructor } from "../modules/mode"

export interface ModeController extends EventedInstance<ModeEvent> {}

/**
 * @file controllers/ModeController.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles its mode state
 */
@EventedInstance(ModeEvent)
export class ModeController {
	//#region    ===========================		   Construction & init  	 		==============================

	declare editor: EditorClass
	private currentMode?: Mode<any>

	constructor(editor: EditorClass) {
		this.editor = editor
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Basic Interface  	 		==============================

	/**
	 * The current mode the editor is in. Default is undefined
	 */
	get() {
		return this.currentMode
	}

	/** Exit the current mode with cancel(). If there is no current mode, return silently. */
	cancel() {
		if (!this.currentMode) return
		this.currentMode.cancel()
	}

	/**
	 * Put the editor into the specified mode
	 * If editor is already in a mode, tries to cancel it first
	 *
	 * @param EditMode The class of mode to change to
	 * @returns The mode instance, which exposes basic controls and handlers.
	 */
	set<T extends ModeConstructor<any>>(
		EditMode: T,
		options: ModeOptionsArg<T>
	): T extends new (...args: any) => infer U ? U : never {
		this.cancel()

		options.functionClearMode = this.unset.bind(this)
		const newMode = new EditMode(this.editor, options)
		this.currentMode = newMode
		newMode.activate(options)

		this.emitEvent("activated", {
			mode: newMode,
		})

		return newMode
	}

	async setAsync<T extends ModeConstructor<any>>(
		EditMode: T,
		options: ModeOptionsArg<T>
	): Promise<Mode<typeof options>> {
		const mode = this.set(EditMode, options)

		return new Promise((res, rej) => {
			mode.onexit = (md, cancelled) => {
				if (cancelled) return rej(md)
				return res(md)
			}
		})
	}

	/**
	 * Exit the current mode - this function should be passed to a mode when it is instantiated.
	 */
	private unset(cancelled: boolean): ModeEvent {
		if (!this.currentMode) return

		const eventType = cancelled ? "cancelled" : "completed"

		const mode = this.currentMode
		this.currentMode = undefined

		const eventLeaveMode = this.emitEvent(eventType, {
			mode: mode,
		})

		this.emitEvent("deactivated", {
			mode,
		})

		return eventLeaveMode
	}

	//#endregion =====================================================================================================
}
