import { LuxedoRPC } from "luxedo-rpc"
import { v4 as uuid } from "uuid"

type ProgressReportCallback = (progress: number) => void

/**
 * To be used with a data type which uses progress reporting (e.g., direct uploads, lightshow rendering, calibration)
 *
 * or it was, initially. Then I realized all of the fuckin backend routes report progress with different data so what is the point.
 */
export abstract class ProgressContext<T> {
	protected status: {
		[index: number]: number
	}

	protected progressCallbacks: {
		[index: number]: ProgressReportCallback
	}

	protected completeCallbacks: {
		[index: number]: () => void
	}

	protected failCallbacks: {
		[index: number]: (...args: any) => void
	}

	constructor(endpoints: { progress: string; complete?: string; fail?: string }) {
		this.completeCallbacks = {}
		this.progressCallbacks = {}
		this.failCallbacks = {}
		this.status = {}

		const { progress, complete, fail } = endpoints

		LuxedoRPC.bindEndpoint(progress, (progress, id) => this.handleProgressReport(progress, id))
		if (complete) LuxedoRPC.bindEndpoint(complete, this.onSuccess)
		if (fail) LuxedoRPC.bindEndpoint(fail, this.onFail)
	}

	protected async handleProgressReport(progress: number, id: number) {
		console.log(progress, id)
		this.status[id] = progress
		const callbacks = Object.values(this.progressCallbacks)
		callbacks.forEach((cb) => cb(progress))
	}

	private onFail = (...args) => {
		const callbacks = Object.values(this.failCallbacks)
		callbacks.forEach((cb) => cb(...args))
	}

	private onSuccess = () => {
		const callbacks = Object.values(this.completeCallbacks)
		callbacks.forEach((cb) => cb())
	}

	public subscribe(
		id: number,
		callbacks: {
			progress: ProgressReportCallback
			fail?: (...args: any) => void
			success?: () => void
		}
	) {
		const { progress, fail, success } = callbacks
		const callbackId = uuid()

		this.progressCallbacks[callbackId] = progress

		if (fail) {
			this.failCallbacks[callbackId] = fail
		}

		if (success) {
			this.completeCallbacks[callbackId] = success
		}

		return callbackId
	}

	public unsubscribe(id: number, callbackId: number) {
		if (this.progressCallbacks[id] && this.progressCallbacks[id][callbackId])
			delete this.progressCallbacks[id][callbackId]
		if (this.completeCallbacks[id] && this.completeCallbacks[id][callbackId])
			delete this.completeCallbacks[id][callbackId]
		if (this.failCallbacks[callbackId]) delete this.failCallbacks[callbackId]
	}

	protected abstract getCompletedData(id: number): Promise<T>
}
