import { Scene, type SceneRawData } from "../entries/Scene"
import { LuxedoRPC } from "luxedo-rpc"
import { DataHandlerFile } from "./DataHandlerFile"
import { Device } from "../entries/Device"
import type { BundleOf, IDMap } from "data-handler"
import type { Tag } from "../entries/Tag"

const TRUE = 1
const FALSE = 0

class DHScene extends DataHandlerFile<Scene> {
	// ! Ensure DataHandlerTag is initialized BEFORE initializing DataHandlerScene

	EntryClass = Scene

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Scene>> {
		return await LuxedoRPC.api.bundlers.project_bundle_down(specificInstances ?? [])
	}

	protected async pushData(entryData: { [id: number]: Partial<SceneRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.project_bundle_up(entryData as IDMap<SceneRawData>)
	}

	protected async requestCreateEntry(entryData: Partial<SceneRawData>): Promise<number> {
		const { name, parent_id, target_device_id, res_x, res_y, direct_upload, description } = entryData

		if (!name) throw new Error("No name when creating scene")
		const { new_file_id } = await LuxedoRPC.api.scene.project_create(
			name,
			parent_id!,
			{
				w: res_x,
				h: res_y,
			},
			0,
			target_device_id!,
			{},
			direct_upload ? TRUE : FALSE
		)

		return new_file_id
	}

	public async rename(objectToRename: Scene, newName: string): Promise<void> {
		await LuxedoRPC.api.filesys.fobj_rename(objectToRename.id!, TRUE, newName)
		await this.pull([objectToRename.id!])
		this.ondatachanged([objectToRename.id!])
	}

	public async move(objectToMove: Scene, moveTo: number): Promise<void> {
		console.error("Move is not implemented within DataHandlerScene.")
	}

	async requestDeleteEntry(entry: Scene): Promise<void> {
		return await LuxedoRPC.api.scene.project_delete(entry.id!)
	}

	getByDevice(device: Device | number) {
		const devId = device instanceof Device ? device.id : device
		return Object.values(this.entries).filter((scene) => scene.target_device_id === devId)
	}

	/**
	 * Gets the new version of the scene (if applicable)
	 * @param scene the old scene
	 * @returns the new scene
	 */
	getByOldVersion(scene: Scene) {
		return Object.values(this.entries).find((s) => s.old_ver === scene.id)
	}

	public async toggleTag(design: Scene, tag: Tag) {
		if (design.tags.includes(tag.id!)) {
			await LuxedoRPC.api.tag.tag_project_remove(tag.id!, design.id!)
			design.tags.splice(design.tags.indexOf(tag.id!), 1)
		} else {
			await LuxedoRPC.api.tag.tag_project_add(tag.id!, design.id!)
			design.tags.push(tag.id!) // this might not be a good practice, but in theory it should work
		}
		this.ondatachanged([design.id!])
	}
}

export const DataHandlerScene = new DHScene()
