<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_615_70)">
		<path
			d="M34.5925 1.84216H15.4076L1.84216 15.4076V34.5925L15.4076 48.158H34.5925L48.158 34.5925V15.4076L34.5925 1.84216Z"
			stroke={strokeColor}
			stroke-width="3"
			stroke-linecap="round"
			class="svg-stroke"
		/>
	</g>
</svg>

<style>
</style>
