import { writable, type Writable } from "svelte/store"
import LuxLinkAdditionalProjectors from "./LuxLink_AdditionalProjectors.svelte"
import LuxLinkCart from "./LuxLink_Cart.svelte"
import LuxLinkSubscriber from "./LuxLink_Subscriber.svelte"
import LuxLinkCancel from "./LuxLink_Cancel.svelte"
import type { ChargebeePlanPrice } from "luxedo-rpc/src/routes/chargebee"

export const LUXLINK_MENUS = {
	Subscriber: LuxLinkSubscriber,
	Cart: LuxLinkCart,
	Cancel: LuxLinkCancel,
	"Additional Projectors": LuxLinkAdditionalProjectors,
}

type LuxLinkSubscritionNavigatorCTX = {
	route: keyof typeof LUXLINK_MENUS
	plan?: ChargebeePlanPrice
	addonQuantity?: number
}

class LuxLinkSubscritionNavigator {
	declare store: Writable<LuxLinkSubscritionNavigatorCTX>
	constructor() {
		this.store = writable<LuxLinkSubscritionNavigatorCTX>({
			route: "Subscriber",
		})
	}

	navigateTo(route: keyof typeof LUXLINK_MENUS) {
		this.store.update((ctx) => ({ ...ctx, route, plan: ctx.plan }))
	}

	subscribe(cb: (route: LuxLinkSubscritionNavigatorCTX) => void) {
		return this.store.subscribe(cb)
	}

	selectPlan(price: ChargebeePlanPrice) {
		this.store.update((ctx) => {
			return {
				route: "Cart",
				plan: price,
			}
		})
	}

	updateAdditionalProjectorAmount(newQuantity: number) {
		if (newQuantity < 0) newQuantity = 0
		this.store.update((ctx) => {
			return {
				...ctx,
				addonQuantity: newQuantity,
			}
		})
	}
}

export const LuxLinkSubscriptionManager = new LuxLinkSubscritionNavigator()
