import { DataHandler } from "data-handler"
import { User, type UserRawData } from "../entries/User"
import type { BundleOf, EntryID, IDMap, RawDataOf } from "data-handler/src/types"

import { LuxedoRPC } from "luxedo-rpc"
import type { BundledAccountData } from "luxedo-rpc/src/types/BundledTypes"

export class DHUser extends DataHandler<User> {
	EntryClass = User

	protected async fetch(): Promise<BundleOf<User>> {
		const data = await LuxedoRPC.api.bundlers.account_bundle_down()

		const userId: EntryID = parseInt(Object.keys(data)[0])
		const rawData = data[userId]

		return {
			[userId]: rawData,
		}
	}

	protected async pushData(entryData: BundleOf<User>): Promise<void> {
		const pushableData = entryData as unknown as IDMap<BundledAccountData>
		return await LuxedoRPC.api.bundlers.account_bundle_up(pushableData)
	}
}

export const DataHandlerUser = new DHUser()
