import { Entry, type EntryRawData } from "data-handler"

export interface FolderRawData extends EntryRawData {
	name: string // Title of the file/folder
	owner_id: number // ID of the user who owns the file/folder
	parent_id: number // ID of the folder which contains this file/folder
	created_at: string // Date of creation
	updated_at: string // Date of last update
	type_id: string // Used for backend junction
}

export class Folder extends Entry<FolderRawData> {
	declare name: string
	declare owner_id: number // ID of the user who owns the file/folder
	declare parent_id: number // ID of the folder which contains this file/folder
	declare created_at: Date // Date of creation
	declare updated_at: Date // Date of last update
	declare type_id: string // Used for backend junction

	constructor(data: FolderRawData) {
		super(data)
	}

	protected importData(data: FolderRawData): void {
		this.name = data.name
		this.owner_id = data.owner_id
		this.parent_id = data.parent_id
		this.type_id = data.type_id
		this.created_at = new Date(data.created_at)
		this.updated_at = new Date(data.updated_at)
	}

	protected exportData(): Partial<FolderRawData> {
		return {
			name: this.name,
			parent_id: this.parent_id,
		}
	}
}
