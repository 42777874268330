<script lang="ts">
	import { OverlayController } from "./OverlayController"
	import OverlayInstance from "./OverlayInstance.svelte"

	let activeOverlays: OverlayController.ContextType = {}

	function onKeyDown(e: KeyboardEvent) {
		const overlayIds = Object.keys(activeOverlays)
		if (e.key === "Escape") OverlayController.closeOverlay(overlayIds[overlayIds.length - 1])
	}

	OverlayController.subscribe((overlays) => (activeOverlays = overlays))
</script>

<div
	class="overlay-container {activeOverlays && Object.keys(activeOverlays).length
		? 'open'
		: 'closed'}"
>
	{#if activeOverlays}
		{#each Object.entries(activeOverlays) as [id, overlay]}
			<OverlayInstance {id} component={overlay.component} options={overlay.options} />
		{/each}
	{/if}
</div>
<svelte:window on:keydown={onKeyDown} />

<style>
	.overlay-container {
		z-index: 99;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
	}

	.overlay-container.closed {
		pointer-events: none;
	}
</style>
