import { Entry } from "data-handler"

export class EntryCreationError<T extends typeof Entry<any>> extends Error {
	name = "EntryCreationError"
	entryType?: T
	entryData?: any
	constructor(
		message: string,
		info?: {
			entryType: T
			entryData: any
		}
	) {
		super(message)
		this.entryType = info?.entryType
		this.entryData = info?.entryData
	}
}
