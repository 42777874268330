<script lang="ts">
	import { Scene, type Lightshow } from "luxedo-data"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowDown } from "svelte-comps/icons"
	import DesignTile from "../../../../reusable/file-system/tiles/DesignTile.svelte"
	import LightshowTile from "../../../../reusable/file-system/tiles/LightshowTile.svelte"
	import { tooltip } from "svelte-comps/tooltip"
	import { openShowOverview } from "../../../../reusable/overlays"

	export let shows: Array<Scene | Lightshow>

	export let heading: string
	export let emptyText: string
	export let info: string = undefined

	let isExpanded: boolean = true
</script>

<CollapsibleSection className="download-list" bind:expanded={isExpanded}>
	<h2
		class="setting-heading"
		slot="header"
		use:tooltip={info
			? {
					pointing: "top",
					content: "",
				}
			: undefined}
	>
		{heading}
		<button class="small icon {isExpanded ? 'open' : ''}"><ArrowDown /></button>
	</h2>
	<div slot="content" class="flex-row show-list">
		{#if shows.length > 0}
			{#each shows as show}
				{#if show instanceof Scene}
					<DesignTile design={show} onClick={() => openShowOverview(show)} />
				{:else}
					<LightshowTile lightshow={show} onClick={() => openShowOverview(show)} />
				{/if}
			{/each}
		{:else}
			<span class="info"> {emptyText} </span>
		{/if}
	</div>
</CollapsibleSection>

<style>
	.setting-heading {
		margin: 0;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		font-size: var(--h1);
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	.info {
		color: var(--color-text);
	}

	.show-list {
		overflow: hidden;
		overflow-x: auto;
		gap: 1rem;
		padding-top: 0.5rem;
	}

	.show-list:not(:has(.info)) {
		padding-bottom: 1rem;
	}
</style>
