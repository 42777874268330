import { MediaAsset, TransformedAsset } from ".."
import { Serializable } from "../../../modules/serialize"
import { fixPointSet } from "../../../fabric-plugins"
import { VideoSource } from "../video/VideoSource"

export abstract class Transformable<T extends HTMLImageElement | VideoSource = any>
	extends MediaAsset<T>
	implements Serializable
{
	static Mixin<T extends { new (...args: any[]): MediaAsset<HTMLImageElement | VideoSource> }>(Base: T) {
		Base.prototype[Transformable.isTransformableAsset] = true

		for (const propName of Object.getOwnPropertyNames(Transformable.prototype)) {
			if (propName == "constructor" || typeof Transformable.prototype[propName] !== "function") continue

			Object.defineProperty(
				Base.prototype,
				propName,
				Object.getOwnPropertyDescriptor(Transformable.prototype, propName)
			)
		}

		return Base
	}

	static isTransformableAsset = Symbol("isTransformableAsset")
	static [Symbol.hasInstance](obj) {
		if (!obj) return false
		return Transformable.isTransformableAsset in obj
	}

	//#region    ===========================		 Perspective Transformation			==============================
	transformedContent: TransformedAsset
	isTransformed: boolean

	perspectiveTransform(quality?: number) {
		if (!this.transformedContent) {
			this.transformedContent = new TransformedAsset(this, quality ?? 1)
		}

		this.perPixelTargetFind = true
		this.isTransformed = true
		this.editor.canvas.requestRenderAll()
	}

	clearTransform() {
		this.isTransformed = false
		this.perPixelTargetFind = false
		this.transformedContent = null

		this.width = this.contentWidth
		this.height = this.contentHeight
		this.editor.canvas.requestRenderAll()
	}

	protected declare _element: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement

	_render(ctx: CanvasRenderingContext2D): void {
		if (!this.isTransformed) return super._render(ctx)
		this.transformedContent.renderOnCanvas(ctx)
	}
	//#endregion =====================================================================================================

	//#region    ===========================		   	    Serialization		 		==============================
	getDefaultPropertyExports(forExport?: boolean): {
		include: string[]
		deepCopy: string[]
		exclude: string[]
	} {
		const base = super.getDefaultPropertyExports()

		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		const exported = {
			include: [...base.include, "isTransformed"],
			deepCopy: [...base.deepCopy, "transformedContent"],
			exclude: [...base.exclude],
		}

		return exported
	}

	static applySerializedTransformations<T extends Transformable>(asset: T, data: Partial<T>): T {
		const transformData = data.transformedContent as Partial<TransformedAsset>
		delete asset.transformedContent

		const points = fixPointSet(transformData.points)

		asset.perspectiveTransform(transformData.quality)
		asset.transformedContent.points = points
		asset.transformedContent.updateTransform()
		asset.transformedContent.recalculateBounds()

		asset.top = data.top
		asset.left = data.left

		return asset
	}

	//#endregion =====================================================================================================
}
