<script lang="ts">
	import type { Media } from "luxedo-data"
	import MediaEditorContainer from "../base/MediaTool_Container.svelte"
	import { BackgroundRemoveController } from "../MediaToolController"
	import MediaToolTimeline from "../base/MediaTool_Timeline.svelte"
	import { ColorPicker } from "../../../inputs"
	import { onDestroy, onMount } from "svelte"
	import MediaBackgroundRemoverVideo from "./MediaBackgroundRemover_Video.svelte"

	let tool: "bg-remove" = "bg-remove"

	export let media: Media
	let isPreviewingChanges: boolean = false

	let unsubscriber = BackgroundRemoveController.subscribe((ctx) => {
		isPreviewingChanges = ctx.isPreviewing
	})

	let canUseDropper = false // False if their browser does not support it

	let backdropColor: string = "#2c282d"
	let removeColor: string = "#ffffff"

	let hueInput: HTMLInputElement
	let satInput: HTMLInputElement
	let valInput: HTMLInputElement

	function checkDropperAvailability() {
		// @ts-ignore
		if (window.EyeDropper) canUseDropper = true
		else canUseDropper = false
	}

	async function onDropperClick() {
		// @ts-ignore
		const eyeDropper = new EyeDropper()
		const newColor = await eyeDropper.open()
		removeColor = newColor.sRGBHex
	}

	function onColorUpdate(color: string, isRemoveColor = true) {
		if (isRemoveColor) {
			removeColor = color
			BackgroundRemoveController.updateRemoveColor(color)
		} else {
			backdropColor = color
			BackgroundRemoveController.updateBackdropColor(color)
		}
	}

	function resetValues() {
		hueInput.value = "30"
		satInput.value = "10"
		valInput.value = "40"
	}

	BackgroundRemoveController.onResetValues(resetValues)

	onMount(checkDropperAvailability)
	onDestroy(unsubscriber)
</script>

<MediaEditorContainer {tool}>
	<MediaBackgroundRemoverVideo {media} {isPreviewingChanges}>
		<div id="backdrop-container">
			<div id="backdrop-picker">
				<ColorPicker
					hex={backdropColor}
					label="Backdrop"
					on:input={(event) => {
						onColorUpdate(event.detail.hex, false)
					}}
				/>
			</div>
		</div>
	</MediaBackgroundRemoverVideo>
	{#if media.fileType === "video"}
		<MediaToolTimeline videoController={BackgroundRemoveController} />
	{/if}
	<div id="selected-color-container">
		<div class="input-container">
			<!-- <label for="color-selector">Remove color: </label> -->
			<ColorPicker
				hex={removeColor}
				on:input={(event) => {
					onColorUpdate(event.detail.hex, true)
				}}
			/>
			<span id="hex-color-indicator">{removeColor}</span>
			{#if canUseDropper}
				<button class="outline-button small" on:click={onDropperClick}>
					Select Color from Image
				</button>
			{/if}
		</div>
	</div>

	<div id="bg-remove-inputs">
		<div class="input-container">
			<label for="color-tolerance-input">Color Tolerance: </label>
			<input
				type="range"
				id="color-tolerance-input"
				max="360"
				value={30}
				bind:this={hueInput}
				on:change={BackgroundRemoveController.onHueChange}
			/>
		</div>
		<div class="input-container">
			<label for="color-tolerance-input">Saturation Tolerance: </label>
			<input
				type="range"
				id="color-tolerance-input"
				max="100"
				value={10}
				bind:this={satInput}
				on:change={BackgroundRemoveController.onSaturationChange}
			/>
		</div>
		<div class="input-container">
			<label for="color-tolerance-input">Brightness Tolerance: </label>
			<input
				type="range"
				id="color-tolerance-input"
				max="100"
				value={40}
				bind:this={valInput}
				on:change={BackgroundRemoveController.onBrightnessChange}
			/>
		</div>
		<div class="button-container">
			<button
				class="outline-button alt small"
				on:click={() => BackgroundRemoveController.resetValues()}>Reset</button
			>
			<button
				class="outline-button small"
				on:click={() => BackgroundRemoveController.togglePreview(!isPreviewingChanges)}
				>{isPreviewingChanges ? "Stop Preview" : "Apply"}</button
			>
		</div>
	</div>
	<span id="backdrop-information">
		Note: Your backdrop color is for visualization only, and will be replaced with a transparent
		background when saved.
	</span>
</MediaEditorContainer>

<style>

	#backdrop-container {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}

	#backdrop-information {
		position: absolute;
		bottom: 1rem;
		line-height: 1em;
		left: 3rem;
		font-size: var(--h3);
		max-width: 50%;
		color: var(--color-text);
	}

	#backdrop-picker {
		padding: 0.5rem;
		border-radius: var(--br);
		background-color: var(--color-bg-opaque);
	}

	#backdrop-picker :global(label) {
		font-size: var(--h3);
		color: var(--color-text-light);
	}

	#bg-remove-inputs {
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		padding: 1rem;
		width: 100%;
	}

	#bg-remove-inputs .input-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: unset;
	}

	#bg-remove-inputs input[type="range"] {
		border: 1px solid var(--color-main);
	}

	#bg-remove-inputs label {
		width: 9rem;
		font-size: var(--h2);
		color: var(--color-text);
	}

	#selected-color-container {
		margin-bottom: 0.75rem;
		margin-top: 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	#selected-color-container .input-container {
		display: flex;
		align-items: center;
		width: fit-content;
		margin: unset;
	}

	#selected-color-container .outline-button {
		position: relative;
		top: 1px;
		margin-left: 0.25rem;
		padding: 0.1em 0.5em;
	}

	#selected-color-container button :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	#selected-color-container button:focus-visible :global(.svg-fill),
	#selected-color-container button:hover :global(.svg-fill) {
		fill: var(--color-main);
	}

	#bg-remove-inputs .button-container {
		margin: 0.5rem 0 0 0;
		padding: 0 20%;
	}

	#selected-color-container :global(.color-picker) {
		position: relative;
		top: 1px;
	}

	#hex-color-indicator {
		margin: 0 0.75rem;
		width: 5rem;
		text-align: center;
	}
</style>
