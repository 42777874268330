import { fabric } from "fabric"
import { getEditor } from "../Editor"

declare module "fabric" {
	namespace fabric {
		interface Object {
			toAbsolutePoint(localPoint: fabric.Point): fabric.Point
			toRelativePoint(absolutePoint: fabric.Point): fabric.Point
			__corner: string
		}
	}
}

export class ObjectMod extends fabric.Object {
	toAbsolutePoint(localPoint: fabric.Point) {
		const ret = fabric.util.transformPoint(
			new fabric.Point(localPoint.x, localPoint.y),
			this.calcTransformMatrix()
		)
		return ret
	}

	toRelativePoint(absolutePoint: fabric.Point) {
		const pt = this.toLocalPoint(absolutePoint, this.originX, this.originY)
		pt.x /= this.scaleX
		pt.y /= this.scaleY
		return pt
	}

	get canvas() {
		return getEditor().canvas
	}

	set canvas(b) {
		return
	}
}

const test = new fabric.Object()
test.canvas

/* Add all the functionality to fabric.Polyline */
const properties = Object.getOwnPropertyDescriptors(ObjectMod.prototype)
delete properties["constructor"]
Object.defineProperties(window.fabric.Object.prototype, properties)

export function applyFabricExtension() {
	const properties = Object.getOwnPropertyDescriptors(ObjectMod.prototype)
	delete properties["constructor"]
	Object.defineProperties(window.fabric.Object.prototype, properties)
}
