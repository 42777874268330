import { Entry, type EntryRawData } from "data-handler"
import { v4 as uuid } from "uuid"
import type { MaskBase } from "../../../luxedo-canvas/src/asset"

export type ClippingMaskJson = {
	id?: string
	name?: string
	points?: fabric.Point[]
	pathData: {
		id?: string
	}
	pathId: string
	path?: {
		id?: any
	}
	entryId?: number
	__CONSTRUCTOR__: "ClippingMask"
} & fabric.Polygon

export type MaskBaseJson = MaskBase & {
	pathData: {
		id?: string
	}
	pathId: string
	__CONSTRUCTOR__: "MaskBase"
}

export type MaskJson = MaskBaseJson | ClippingMaskJson

export interface MaskRawData extends EntryRawData {
	id: number
	user_id: number
	cal_id: number
	name: string
	json_path: string
	mask_json: Partial<MaskJson>
}

export interface CalibratedMask {
	user_id: number
	cal_id: number
	name: string
	json_path: string
	mask_json: Partial<MaskJson>
}

export class CalibratedMask extends Entry<MaskRawData> {
	protected async importData(data: MaskRawData): Promise<void> {
		this.name = data.name
		this.user_id = data.user_id
		this.cal_id = data.cal_id
		this.json_path = data.json_path
		this.mask_json = await this.getJson(this.json_path)
		this.mask_json.name = this.name
		this.mask_json.entryId = data.id
	}

	async getJson(jsonPath: string) {
		const res = await fetch(jsonPath)
		return await res.json()
	}

	protected exportData(): Partial<MaskRawData> {
		return {
			name: this.name,
			mask_json: this.mask_json,
		}
	}

	static unbindMaskJson(maskJson: Partial<MaskJson>) {
		const tempPathId = uuid() // fixes issue where paths cannot be created more than once
		maskJson.pathId = tempPathId
		maskJson.path!.id = tempPathId
		maskJson.pathData!.id = tempPathId
		// maskJson.entryId = undefined
		return maskJson
	}
}
