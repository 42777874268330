/**
 * @file edit-modes/PolygonEditMode.ts
 * @author Austin Day 2023
 * @description An edit mode which allows you to edit the points of a polygon
 */

import { EditPoints, PointEditOptions } from "."
import type { ModeOptions } from "../../modules/mode/Mode"
import type { Transformable, TransformedAsset } from "../../asset/canvas/mixins"
import { EditorClass } from "../.."
import {
	deepCopyPoints,
	getQuadrilateralIsConvex as isQuadrilateralConvex,
} from "../../fabric-plugins"
import { Point } from "fabric/fabric-impl"

interface PerspectiveEditArgs extends ModeOptions {
	/** The Polygon that will be edited */
	transformTarget: Transformable
	/** Number from 0 to 1 */
	quality?: number
}

export interface PerspectiveEditMode extends EditPoints {
	/** Stop editing and apply transformation to the shape */
	complete(): void
}

/** Edit mode for editing polygon points */
export class PerspectiveEditMode extends EditPoints {
	cancellable = true
	declare target: TransformedAsset

	constructor(editor: EditorClass, options: PerspectiveEditArgs) {
		options.transformTarget.perspectiveTransform(options.quality)
		const target = options.transformTarget.transformedContent

		const optionBlock: PointEditOptions = {
			...options,
			target,
			editStyle: {
				strokeWidth: 2,
				stroke: "#ffffff80",
				fill: undefined,
			},
		}

		super(editor, optionBlock)
	}

	get modeTitle() {
		return "Editing Persepective"
	}

	//#region    ===========================		   	   Implementation		 		==============================

	protected onActivate(options: PointEditOptions): void {
		super.onActivate(options)

		this.setSelection(this.target)
	}

	protected onDeactivate(): void {
		super.onDeactivate()
		this.target.updateTransform()

		this.updateTargetPosition()
		this.editor.canvas.requestRenderAll()

		if (this.editor.selection.get()[0] === this.target) {
			this.editor.selection.set(this.target.sourceAsset)
		}
	}

	beforePointsEdit = (pointsOld: Point[], pointsNew: Point[]) => {
		if (!isQuadrilateralConvex(pointsNew)) throw EvalError("Transformed assets cannot be concave")
	}

	onPointsEdit = () => {
		this.target.updateTransform()
		this.updateTargetPosition()
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood		 		==============================

	loadSave(): void {
		super.loadSave()
		this.updateTargetPosition()
	}

	//#endregion =====================================================================================================
}
