<script lang="ts">
	import { LightshowBreak } from "luxedo-data"

	export let lightshowBreak: LightshowBreak

	export let updateIndicator = 0
	export let readonly = false

	export let onUpdate: (newTime: number) => void

	let min: string | number = lightshowBreak?.min ?? 0
	let sec = String(lightshowBreak?.sec ?? 30).padStart(2, "0")

	let minElem: HTMLSpanElement
	let secElem: HTMLSpanElement

	/**
	 * Updates the proper value, ensuring the value does not reach past the max value (defined by maxMin, maxSec...)
	 * @param property
	 * @param value
	 * @returns true if the value was updated properly, false if the value is larger than the max
	 */
	function updateValue(propName: "min" | "sec" | "mil", value: string) {
		const checkMax = (max) => {
			if (Number(value) > max) return false
			else return true
		}

		let isValid
		switch (propName) {
			case "min":
				isValid = checkMax(59)
				if (isValid) min = value
				break
			case "sec":
				isValid = checkMax(59)
				if (isValid) sec = value
				break
		}
		return isValid
	}

	/**
	 * Places the user's text cursor at the end of the string
	 * @param target
	 */
	const selectEnd = (target: HTMLSpanElement) => {
		const range = document.createRange()
		range.setStart(target, 1)
		range.setEndAfter(target)
		const windowSelection = window.getSelection()
		windowSelection.removeAllRanges()
		windowSelection.addRange(range)
	}

	function onChange(property: "min" | "sec") {
		return (
			e: KeyboardEvent & {
				currentTarget: HTMLSpanElement
			}
		) => {
			e.stopImmediatePropagation()
			const fnKeys = ["Tab", "Backspace", "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"]

			if (e.key === "Enter") window.getSelection().removeAllRanges()
			if (!fnKeys.includes(e.key) && (isNaN(Number(e.key)) || e.key === " ")) {
				e.preventDefault()
				return
			}

			const target = e.currentTarget

			setTimeout(() => {
				let value = target.innerText
				if (value.length > 2) {
					value = value.slice(1)
					target.innerText = value
					selectEnd(target)
				} else if (value.length < 2) {
					value = value.padStart(2, "0")
					target.innerText = value
					selectEnd(target)
				}

				let isValid
				let iteration

				do {
					isValid = updateValue(property, value)
					if (!isValid) {
						value = target.innerText.slice(1)
						target.innerText = value.padStart(2, "0")
						selectEnd(target)
					}

					iteration++
					if (iteration > 5) console.error("Timestamp input iterated more than 5 times")
				} while (!isValid && iteration < 10)

				onUpdate(new LightshowBreak(Number(min), Number(sec)).duration)
			})
		}
	}

	function handleInputFocus(
		e: FocusEvent & {
			currentTarget: HTMLSpanElement
		}
	) {
		const target = e.currentTarget
		const range = document.createRange()
		range.selectNodeContents(target)
		const windowSelection = window.getSelection()
		windowSelection.removeAllRanges()
		windowSelection.addRange(range)
	}

	function resetValuesFromBreak() {
		minElem.innerText = String(lightshowBreak.min)
		secElem.innerText = String(lightshowBreak.sec).padStart(2, "0")
	}

	$: updateIndicator && resetValuesFromBreak()
</script>

<div class="input-container">
	<span
		tabindex="0"
		role="textbox"
		bind:this={minElem}
		on:keydown={onChange("min")}
		on:focus={handleInputFocus}
		contenteditable={!readonly}
	>
		{min}
	</span>
	<span>:</span>
	<span
		role="textbox"
		tabindex="0"
		bind:this={secElem}
		on:keydown={onChange("sec")}
		on:focus={handleInputFocus}
		contenteditable={!readonly}>{sec}</span
	>
</div>

<style>
	span {
		cursor: text;
	}
</style>
