import { Entry, type EntryRawData } from "data-handler"

export class Tag extends Entry<TagRawData> {
	declare title
	declare color

	protected importData(data: TagRawData): void {
		this.title = data.title
		this.color = data.color
	}

	protected exportData(): Partial<TagRawData> {
		return {
			title: this.title,
			color: this.color,
		}
	}

	static colors = [
		"#FF9D00",
		"#4647DE",
		"#62BE60",
		"#DE5357",
		"#9774CC",
		"#EFE23F",
		"#0087B0",
		"#FF5803",
		"#4987DE",
		"#FF4083",
		"#96BF60",
		"#CD74CC",
		"#F0BE31",
		"#04B2A8",
		"#B07004",
		"#383696",
		"#498147",
		"#983F40",
		"#6C5789",
		"#A49D30",
		"#045C78",
		"#B04206",
		"#395F96",
		"#688247",
		"#AF325B",
		"#855789",
		"#A57D31",
		"#077A74",
	]
}

export interface TagRawData extends EntryRawData {
	id: number
	user_id: number
	title: string
	color: string
}
