<script lang="ts">
	import CheckboxInput from "../../../../input/CheckboxInput.svelte"
	import YouTubeVideoTutorial from "../../../../YouTubeVideoTutorial.svelte"
	import { GroupEditorController } from "../../GroupEditorController"
	import { GroupAlignController } from "./GroupAlignController"

	let hasInitialData: boolean = false
	let hasVerifiedContinue: boolean = false
	let informOfWarning = false

	GroupEditorController.subscribe((ctx) => (hasInitialData = ctx.hasInitialData))

	function handleNext() {
		if (hasInitialData && !hasVerifiedContinue) {
			informOfWarning = true
			setTimeout(() => (informOfWarning = false), 1500)
		} else {
			GroupAlignController.next()
		}
	}
</script>

<YouTubeVideoTutorial
	title="Align snapshots"
	description={`Starting with the leftmost projector, adjust the position of the new snapshots relative to each other so that they align with the physical projection space.
	<br />
	<!--Watch this video before getting started: -->`}
/>

{#if hasInitialData}
	<div class="warning-container {informOfWarning ? 'show-background-warning' : ''}">
		<p class="warn">
			⚠ This device group has already been configured. Please confirm that you want to edit the
			configuration of this device group. If you proceed, any existing settings may be changed.
		</p>
		<CheckboxInput
			bind:isChecked={hasVerifiedContinue}
			label="I understand and wish to edit the configuration."
		/>
	</div>
{/if}
<!-- videoURL="https://www.youtube.com/embed/AjUkEHkYx0I" -->

<div id="step-buttons">
	<button id="back-step-button" class="link-button" on:click={() => GroupEditorController.back()}>
		Back
	</button>
	<button id="next-step-button" class="outline-button" on:click={handleNext}> Next </button>
</div>

<style>
	.warn {
		color: var(--color-main);
	}

	.warning-container {
		margin: -1rem;
		padding: 1rem;
		transition: background-color 500ms;
		border-radius: 1rem;
	}

	.warning-container.show-background-warning {
		background-color: var(--light-transparent);
	}
</style>
