<script lang="ts">
	import { onMount } from "svelte"

	let loadingText = "Loading"
	let ellipsisIndex = 0

	export let onRouteMount: () => void = undefined

	onMount(() => {
		if (onRouteMount) onRouteMount()
	})

	setInterval(() => {
		if (ellipsisIndex > 3) ellipsisIndex = 0
		loadingText = `Loading${".".repeat(ellipsisIndex)}`
		ellipsisIndex++
	}, 750)
</script>

<div class="Loading">
	<span class="loader" />
	<br />
	{loadingText}
</div>

<style>
	.Loading {
		width: 100vw;
		height: 100vh;
		background: var(--color-bg-darker);
		color: var(--color-main);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
	}

	.loader {
		width: 10rem;
		height: 10rem;
		display: inline-block;
		position: relative;
	}

	.loader::after,
	.loader::before {
		content: "";
		box-sizing: border-box;
		width: 10rem;
		height: 10rem;
		border-radius: 50%;
		border: 2px solid var(--color-main);
		position: absolute;
		left: 0;
		top: 0;
		animation: animloader 2s linear infinite;
	}

	.loader::after {
		animation-delay: 1s;
	}

	@keyframes animloader {
		0% {
			transform: scale(0);
			opacity: 1;
		}
		100% {
			transform: scale(1);
			opacity: 0;
		}
	}
</style>
