<script lang="ts">
	export let strokeColor = "black"
</script>

<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<line
		x1="1.5"
		y1="11.5"
		x2="21.5"
		y2="11.5"
		stroke={strokeColor}
		class="svg-stroke"
		stroke-width="3"
		stroke-linecap="round"
	/>
</svg>

<style>
</style>
