<script lang="ts">
	import "luxedo-canvas"

	import {
		DataHandlerSnapshot,
		type CalibratedMask,
		type Snapshot,
		Calibration,
		type MaskJson,
	} from "luxedo-data"
	import { MaskInstance } from "svelte-comps/luxedo"
	import { openMaskEditorOverlay } from "../../../../../reusable/overlays/mask-editor"

	export let maskEntry: CalibratedMask
	export let calibration: Calibration

	let snapshot: Snapshot
	let maskJson: MaskJson

	async function getClippingMaskData(entry: CalibratedMask) {
		const calSnaps = DataHandlerSnapshot.filterByCalibration(entry.cal_id)

		snapshot = calSnaps.find((snap) => snap.is_default)
		if (!snapshot) snapshot = calSnaps[calSnaps.length - 1]
	}

	async function getMaskJson(entry: CalibratedMask) {
		maskJson = await entry.getJson(entry.json_path)
	}

	function open() {
		openMaskEditorOverlay(calibration)
	}

	$: getClippingMaskData(maskEntry)
	$: getMaskJson(maskEntry)
</script>

{#if maskJson}
	<MaskInstance mask={maskJson} {snapshot} onClick={open} />
{/if}

<style>
</style>
