import { LocalTimestamp, assertTimeIsZeroToOne } from "."
import { KeyframeEvent } from "../events"
import { EventedInstance } from "../events/decorators"

export interface Keyframe {
	/** The time (in the timeline) where this keyframe lives - must be 0 to 1 */
	timestamp: LocalTimestamp

	/** The value for the animated property to take at this keyframe's location */
	value: number
}

export interface KeyframeList extends Iterable<Keyframe>, EventedInstance<KeyframeEvent> {}

export interface TimestampKeyframeMap {
	[index: number]: {
		opacity?: Keyframe
		left?: Keyframe
		top?: Keyframe
		angle?: Keyframe
		scaleX?: Keyframe
		scaleY?: Keyframe
	}
}

export class KeyframeDeletionError extends Error {}

@EventedInstance(KeyframeEvent)
export class KeyframeList implements Iterable<Keyframe> {
	//#region    ===========================			   Initialization				==============================

	/** The first keyframe in the list */
	keyframes: Keyframe[]

	constructor() {
		this.keyframes = []
		// this.insert(0, 0)
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Properties				==============================

	get last(): Keyframe {
		return this.keyframes[this.keyframes.length - 1]
	}

	get length(): number {
		return this.keyframes.length
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Accessors	 				==============================

	/**
	 * Gets the keyframe at the specified timestamp, returns undefined if none exist
	 * @param time
	 * @returns
	 */
	getByTimestamp(time: number) {
		return this.keyframes.find((key) => Number(key.timestamp) === Number(time))
	}

	//#endregion =====================================================================================

	//#region    ===========================				  Interface 				==============================

	/**
	 * Insert a new empty keyframe into the list
	 * @param timestamp
	 */
	insert(timestamp: number, value: number): Keyframe {
		timestamp = Math.min(1, Math.max(0, timestamp))
		assertTimeIsZeroToOne(timestamp)
		const keyframe: Keyframe = {
			timestamp,
			value,
		}

		this.keyframes.push(keyframe)
		this.sort()

		this.emitEvent("add", {})
		return keyframe
	}

	sort() {
		this.keyframes.sort((a, b) => a.timestamp - b.timestamp)
	}

	/** Move a keyframe to the specified time */
	move(keyframe: Keyframe, time: number) {
		if (time === keyframe.timestamp) return

		assertTimeIsZeroToOne(time)
		keyframe.timestamp = time
		this.sort()

		this.emitEvent("move", {})
	}

	/** Set a keyframe to the specified value */
	set(timestamp: number, value: number)
	set(keyframe: Keyframe, value: number)
	set(keyframeOrNumber: Keyframe | number, value: number) {
		let keyframe
		if (typeof keyframeOrNumber === "number") {
			keyframe = this.getByTimestamp(keyframeOrNumber) ?? this.insert(keyframeOrNumber, value)
		} else {
			keyframe = keyframeOrNumber
			if (value === keyframeOrNumber.value) return
		}

		keyframe.value = value
		this.refresh()

		this.emitEvent("edit", {})
	}

	/**
	 * Remove a keyframe from the list
	 * @param keyframe the keyframe to remove
	 */
	delete(keyframe: Keyframe): void {
		this.keyframes.splice(this.keyframes.indexOf(keyframe), 1)
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Keyframe Interpolation			==============================

	protected cachedTime: number
	protected cachedValue: number
	protected cacheIndex: number = 0

	interp(timestamp: LocalTimestamp) {
		if (this.cachedTime == timestamp) return this.cachedValue

		this.cachedTime = timestamp

		// Reset the pointer for iteration if we're too far ahead
		if (this.keyframes[this.cacheIndex]?.timestamp > timestamp) {
			this.cacheIndex = 0
		}

		// If we are STILL too far ahead, we know we are before the first keyframe and can use its value
		if (this.keyframes[this.cacheIndex]?.timestamp > timestamp) {
			this.cachedValue = this.keyframes[this.cacheIndex].value
			return this.cachedValue
		}

		let next = this.cacheIndex + 1
		// Iterate forward until we know we're at the right time
		while (this.keyframes[next] && this.keyframes[next].timestamp < timestamp) {
			this.cacheIndex += 1
			next++
		}

		let prevKf = this.keyframes[this.cacheIndex]
		let nextKf = this.keyframes[next]

		// If we're past the last keyframe, or we are exactly on a keyframe, we know the value will be equal to the current keyframe
		if (!nextKf || timestamp == prevKf.timestamp) {
			this.cachedValue = prevKf.value
			return this.cachedValue
		}

		// Do the interpolation
		const valueRange = nextKf.value - prevKf.value
		const timeRange = nextKf.timestamp - prevKf.timestamp
		const interp = (timestamp - prevKf.timestamp) / timeRange

		const result = prevKf.value + valueRange * interp

		this.cachedValue = result
		return result
	}

	/** When keyframes or the parent track are edited the cache needs to be cleared */
	refresh() {
		this.cachedTime = null
		this.cachedValue = null
	}

	//#endregion =====================================================================================================

	//#region    ===========================				   Iteration				==============================

	[Symbol.iterator](): Iterator<Keyframe> {
		let index = 0
		let val: Keyframe = this.keyframes[0]
		return {
			next: () => {
				if (!val) {
					return {
						done: true,
						value: undefined,
					}
				}

				const retval = {
					done: false,
					value: val,
				}

				index++
				val = this.keyframes[index]
				return retval
			},
		}
	}

	toArray() {
		return this.keyframes.slice()
	}

	//#endregion =====================================================================================================
}
