import { EditorEvent } from "./Event"
import type { EventName, NoWildcard } from "."
import { Track } from "../tracks"
import { AnimatedProperty } from "../animation"

export type KeyframeEventName = EventName<"add" | "remove" | "edit" | "move">

export interface KeyframeEventOptions {
	track?: Track
	animatedProperty?: AnimatedProperty<any>
}

export interface KeyframeEvent extends EditorEvent<KeyframeEventName>, KeyframeEventOptions {}

export class KeyframeEvent extends EditorEvent<KeyframeEventName> {
	OptionsType: KeyframeEventOptions
	constructor(type: NoWildcard<KeyframeEventName>, options: KeyframeEventOptions) {
		super(type)
		this.track = options.track
		this.animatedProperty = options.animatedProperty
	}
}
