<script lang="ts">
	import { DataHandlerCalibration, type Calibration, type Device } from "luxedo-data"
	import CalibrationListEntry from "./CalibrationListEntry.svelte"

	export let device: Device

	let calibrationList: Array<Calibration> = []
	let listWidth: number
	let tileWidth: number

	DataHandlerCalibration.addListener(updateCalibrationList)

	function updateCalibrationList() {
		if (!device) return

		calibrationList =
			DataHandlerCalibration.filterByDevice(device)
				.filter((cal) => !cal.didFail)
				.sort((calA, calB) => {
					if (calA.runTime > calB.runTime) return -1
					else if (calA.runTime < calB.runTime) return 1
					else return 0
				}) ?? []
	}

	function updateTileWidth(elemWidth: number) {
		if (!elemWidth) return

		const width = elemWidth - 16 * 3

		const TILE_WIDTH_PREFFERED = 96
		const TILE_MARGIN = 16

		const tileAmountPerRow = Math.floor(width / (TILE_WIDTH_PREFFERED + TILE_MARGIN))
		const newTileWidth = Math.floor(width / tileAmountPerRow) - TILE_MARGIN

		tileWidth = newTileWidth
	}

	$: device && updateCalibrationList()
	$: updateTileWidth(listWidth)
</script>

<div class="calibration-list" bind:clientWidth={listWidth} style="--tile-width: {tileWidth}px;">
	{#each calibrationList as cal (cal.id)}
		<CalibrationListEntry calibration={cal} />
	{/each}
</div>

<style>
	.calibration-list {
		--mask-preview-height: 8rem;
		background-color: var(--color-bg-dark);
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		position: relative;
		padding: 0 1rem 1rem 1rem;
		border-radius: var(--br);
		box-sizing: border-box;
	}

	.calibration-list::-webkit-scrollbar-thumb {
		background-color: var(--color-bg);
	}
</style>
