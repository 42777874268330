<script lang="ts">
	import { ToastStore } from "./ToastStore"

	export let topMargin = "calc(var(--header-height) + 0.5rem)"
	export let errorBackground: string = undefined
	export let errorColor: string = undefined

	let textContent
	let isOpen
	let type = "text"

	let action: Function
	let actionString: string

	ToastStore.subscribe((v) => {
		isOpen = v.isOpen
		if (v.isOpen) {
			textContent = v.textContent
			type = v.type
			if ("action" in v) {
				action = v.action
				actionString = v.actionString
			} else {
				action = undefined
				actionString = undefined
			}
		}
	})
</script>

<div
	class="Toast {type} {isOpen ? 'open' : ''}"
	style={`--top-margin: ${topMargin}; ${
		type === "error" && errorColor ? `color: ${errorColor};` : ""
	} ${type === "error" && errorBackground ? `background-color: ${errorBackground};` : ""}`}
>
	<button class="close-toast-button icon" on:click={() => ToastStore.set({ isOpen: false })} />
	{#if type === "error"}
		<span class="type-indicator"> ⚠ </span>
	{:else if type === "success"}
		<span class="type-indicator">✓</span>
	{/if}
	{textContent}

	{#if action}
		<br />
		<button
			class="outline-button alt small"
			on:click={() => {
				action()
				ToastStore.set({ isOpen: false })
			}}>{actionString}</button
		>
	{/if}
</div>

<style>
	.Toast {
		z-index: 120;
		user-select: none;
		font-size: var(--text-small);
		position: fixed;
		border-radius: var(--br);
		color: var(--color-text-light);
		right: 1rem;
		background-color: var(--color-bg-dark);
		box-shadow: var(--shadow-medium);
		width: 16rem;
		padding: 0.5rem 2rem 0.5rem 0.75rem;
		background-color: var(--color-bg-dark);
		transform: translateX(200%);
		top: var(--top-margin);
		transition: transform var(--transition-duration) ease-in;
	}

	.Toast.open {
		transform: translateX(0%);
	}

	.type-indicator {
		margin-right: 0.5rem;
		float: left;
	}

	.Toast.error {
		background-color: var(--red);
		color: var(--color-text-light);
	}

	.Toast.success {
		background-color: var(--ct-green);
	}

	.close-toast-button {
		z-index: 20;
		padding: 0.5rem;
		position: absolute;
		top: 0;
		right: 0;
		transform: unset;
	}

	.close-toast-button::after {
		content: "\00d7";
		font-family: var(--heading-font);
		font-size: var(--text-h5);
	}

	.Toast.error .close-toast-button {
		/* color: var(--color-bg-dark); */
	}

	.Toast.success .close-toast-button:hover,
	.Toast.success .close-toast-button:focus-visible {
		color: var(--light);
	}

	.outline-button {
		margin-top: 1rem;
	}

	@media only screen and (max-width: 700px) {
		.Toast {
			width: 90%;
			height: fit-content;
			bottom: 1rem;
			top: unset;
			right: 0;
			left: 0;
			margin-left: auto;
			margin-right: auto;
			transform: translateY(calc(100% + 1rem));
		}

		.Toast.open {
			transform: translateY(0);
		}
	}
</style>
