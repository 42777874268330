import { DataHandlerScene, Scene, type FullCalendarEvent, type Lightshow } from "luxedo-data"
import { openOverlay } from "svelte-comps/overlay"
import ShowPreview from "./ShowOverview.svelte"
import type { EventImpl } from "@fullcalendar/core/internal"
import { Toast } from "svelte-comps/toaster"

export async function openShowOverview(
	scene: Scene | Lightshow,
	event?: FullCalendarEvent | EventImpl
) {
	if (!scene)
		return Toast.error(
			"This show cannot be viewed. It may have been deleted or is owned by another user."
		)

	if (scene instanceof Scene && !scene.render_ver) {
		await DataHandlerScene.pull([scene.id])
		scene = DataHandlerScene.get(scene.id)
	}

	openOverlay(ShowPreview, {
		props: { scene, event },
		classOverlay: "new-scene-overlay",
		classHeading: "no-underline no-pad",
	})
}
