<script lang="ts">
	import { NavigationStore } from "../../stores/NavigationContext"
	import Link from "../reusable/navigation/Link.svelte"
	import { ROUTES } from "../routes/Routes"

	let activeRoute: keyof typeof ROUTES | string
	NavigationStore.subscribe((route) => (activeRoute = route))
</script>

<nav id="page-nav">
	<ul>
		{#each Object.entries(ROUTES) as [route, title]}
			<li class={route === activeRoute ? "active" : ""}>
				<Link to={route}>{title}</Link>
				<div class="link-background" />
			</li>
		{/each}
	</ul>
</nav>

<style>
	#page-nav {
		padding-left: 3rem;
		width: calc(var(--side-menu-width) + var(--nav-padding));
		margin-top: 2rem;

		overflow: hidden;
	}

	li {
		position: relative;
		font-size: var(--h2);
		padding: 0.5rem 1rem;
	}

	li > :global(a) {
		position: relative;
		z-index: 2;
		display: inline-block;
		height: 100%;
		width: 100%;
		color: var(--color-text-light);
		transition: color 500ms;
	}

	li > .link-background {
		position: absolute;
		top: 0;
		z-index: 1;
		left: 100%;

		width: 100%;
		height: 100%;
		background-color: var(--color-bg);
		border-top-left-radius: var(--br);
		border-bottom-left-radius: var(--br);

		transition: transform 250ms ease;
	}

	li.active > .link-background {
		transition-delay: 250ms;
		transform: translateX(-100%);
	}

	li.active > :global(a) {
		color: var(--color-main);
	}
</style>
