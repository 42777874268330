import { fabric } from "fabric"
import { getEditor } from "../.."

type EditFn = (index: number, point: fabric.Point, event?: MouseEvent) => void

export class CurveControl extends fabric.Control {
	index: number
	point: fabric.Point
	onEdit: EditFn

	POINT_SIZE = 5
	POINT_COLOR = "#ffffff"

	constructor(index: number, controlPoint: fabric.Point, callback: EditFn, options?: Partial<fabric.Control>) {
		super(options)
		this.point = controlPoint
		this.index = index
		this.onEdit = callback
	}

	positionHandler(dim, finalmatrix, fabricObject, currentControl) {
		return fabric.util.transformPoint(this.point, getEditor().canvas.viewportTransform)
	}

	actionHandler = (eventData: MouseEvent, transform: fabric.Transform, x: number, y: number) => {
		const previousValue = this.point.clone()

		this.point.setXY(x, y)

		try {
			this.onEdit(this.index, this.point, eventData)
			return true
		} catch (e) {
			console.error("Curve control error", e)
			this.point.setFromPoint(previousValue)
			return false
		}
	}

	updatePoint(updatedPoint: fabric.Point) {
		this.point = updatedPoint
	}

	render(
		ctx: CanvasRenderingContext2D,
		left: number,
		top: number,
		styleOverride: any,
		fabricObject: fabric.Object
	): void {
		ctx.save()
		ctx.strokeStyle = this.POINT_COLOR
		ctx.lineWidth = 5
		ctx.beginPath()
		ctx.arc(left, top, this.POINT_SIZE, 0, 2 * Math.PI)
		ctx.stroke()
		ctx.restore()
	}

	get canvas() {
		return getEditor().canvas
	}
	set canvas(v) {
		return
	}

	actionName = "curveEdit"
}
