import { EditorClass } from "../.."
import { getEditor } from "../../Editor"
import { Ellipse, Polygon } from "../../asset"
import Mode, { ModeOptions } from "../../modules/mode"
import { fabric } from "fabric"

// If the user drags their mouse 3px or less, this will count as a single click
const MIN_SHAPE_RADIUS = 3

// #region Option Types

interface AddShapeOptions_Base extends ModeOptions {
	left: number
	top: number
	width: number
	height: number
	shape: "Ellipse" | "Polygon" | "Line"
	fill: string
	stroke: string
	strokeWidth: number

	temporaryStroke?: string
	temporaryStrokeWidth?: number

	name?: string
	opacity?: number
	angle?: number
}

interface AddShapeOptions_Line extends AddShapeOptions_Base {
	shape: "Line"
}

interface AddShapeOptions_Ellipse extends AddShapeOptions_Base {
	shape: "Ellipse"
}

interface AddShapeOptions_Polygon extends AddShapeOptions_Base {
	shape: "Polygon"
	numSides: number
}

export type AddShapeOptions = AddShapeOptions_Ellipse | AddShapeOptions_Polygon | AddShapeOptions_Line

// #endregion Option Types

export class AddShape extends Mode<AddShapeOptions> {
	cancellable: boolean = true
	cancelReason: string = undefined

	shape: Ellipse | Polygon

	layer: string
	left: number
	top: number
	width: number
	height: number
	opacity: number
	angle: number
	fill: string
	stroke: string
	strokeWidth: number

	shapeOption: "Ellipse" | "Polygon" | "Line"
	sideAmount: number = undefined
	name: string

	isTemporaryShapeAttatched: boolean
	isMouseDown: boolean

	previewPoint: fabric.Point
	initialPoint: fabric.Point
	endPoint: fabric.Point

	defaultHoverCursor: string
	defaultCursor: string

	fabricOptions: {
		stroke: string
		strokeWidth: number
	}

	listeners: []

	constructor(editor: EditorClass, options: AddShapeOptions) {
		const addShapeOptions = { ...SHAPE_MODE_DEFAULTS, ...options }
		super(editor, addShapeOptions)

		this.initialPoint = undefined
		this.previewPoint = undefined

		this.sideAmount = "numSides" in addShapeOptions ? addShapeOptions.numSides : undefined
		this.shapeOption = addShapeOptions.shape
		this.width = addShapeOptions.width
		this.height = addShapeOptions.height
		this.angle = addShapeOptions.angle
		this.opacity = addShapeOptions.opacity / 100
		this.fill = addShapeOptions.fill
		this.stroke = addShapeOptions.temporaryStroke ?? addShapeOptions.stroke
		this.strokeWidth = addShapeOptions.temporaryStrokeWidth ?? addShapeOptions.strokeWidth
		this.name = addShapeOptions.name
		this.left = addShapeOptions.left
		this.top = addShapeOptions.top

		this.fabricOptions = {
			stroke: addShapeOptions.stroke,
			strokeWidth: addShapeOptions.strokeWidth,
		}

		this.isTemporaryShapeAttatched = false
		this.cancelReason = undefined
	}

	get modeTitle() {
		return `Adding Shape (${this.shapeOption})`
	}

	public showPreview(position: { left: number; top: number }) {
		const { left, top } = position
		this.previewPoint = new fabric.Point(left, top)
		this.generatePreviewShape()

		this.editor.canvas.clearTemporaryObjects()
		this.editor.canvas.addTemporaryObject(this.shape)
	}

	private initializeListeners() {
		this.clearSelection()
		this.cancelReason = undefined

		this.editor.canvas.selection = false
		this.editor.canvas.on("mouse:up", this._canvasClickUp)
		this.editor.canvas.on("mouse:down", this._canvasClickDown)

		document.addEventListener("keypress", this._keypress)
	}

	private clearListeners() {
		// this.restoreSelection()

		this.editor.canvas.selection = true
		this.editor.canvas.off("mouse:up", this._canvasClickUp)
		this.editor.canvas.off("mouse:down", this._canvasClickDown)
		this.editor.canvas.off("mouse:move", this._canvasMouseMove)
		document.removeEventListener("keypress", this._keypress)
	}

	protected _keypress = (e: KeyboardEvent) => {
		e.stopImmediatePropagation()
		if (e.key === "Enter") {
			this.complete()
		} else if (e.key === "Escape" || e.key === "Backspace" || e.key === "Delete") {
			this.cancel()
		}
	}

	protected _canvasClickDown = (e: fabric.IEvent<MouseEvent>) => {
		if (e.button === 3) return

		this.isMouseDown = true

		if (!this.initialPoint) this.initialPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)

		this.editor.canvas.on("mouse:move", this._canvasMouseMove)
	}

	protected _canvasClickUp = (e: fabric.IEvent<MouseEvent>) => {
		if (e.button === 3) return

		this.isMouseDown = false

		this.endPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)

		// If a different canvas asset was clicked, selected it and cancel this mode (as long as the click was a single click - no drag)
		if (e.target && e.target !== this.shape && !this.isTemporaryShapeAttatched) {
			const target = e.target
			this.cancelReason = "selection"
			this.cancel()
			return setTimeout(() => {
				this.editor.selection.set(target)
			})
		}

		if (!this.isTemporaryShapeAttatched) {
			this.generateShape()
		}

		this.editor.canvas.clearTemporaryObjects()
		this.complete()
	}

	protected _canvasMouseMove = (e: fabric.IEvent<MouseEvent>) => {
		const newPoint = new fabric.Point(e.absolutePointer.x, e.absolutePointer.y)
		this.updateShape(newPoint)

		if (!this.isTemporaryShapeAttatched) {
			this.clearSelection()
			this.editor.canvas.addTemporaryObject(this.shape)
			this.isTemporaryShapeAttatched = true
		}
	}

	protected onActivate(options: AddShapeOptions): void {
		this.initializeListeners()

		this.defaultCursor = this.editor.canvas.defaultCursor
		this.defaultHoverCursor = this.editor.canvas.hoverCursor

		this.editor.canvas.hoverCursor = "crosshair"
		this.editor.canvas.defaultCursor = "crosshair"
	}

	protected generateShape(updatedEndPoint?: fabric.Point) {
		let endPoint
		if (updatedEndPoint) {
			endPoint = updatedEndPoint
		} else if (this.endPoint) {
			endPoint = this.endPoint
		} else {
			endPoint = { x: this.initialPoint.x + 1, y: this.initialPoint.y + 1 }
		}

		if (this.shapeOption === "Ellipse") {
			this.shape = AddShape.CreateEllipse(
				this.initialPoint,
				endPoint,
				{
					fill: this.fill,
					stroke: this.stroke,
					strokeWidth: this.strokeWidth,
					angle: this.angle,
					opacity: this.opacity,
					width: this.width,
					height: this.height,
				},
				this.isMouseDown
			)
		} else if (this.shapeOption === "Polygon") {
			this.shape = AddShape.CreatePolygon(
				this.initialPoint,
				endPoint,
				this.sideAmount,
				{
					fill: this.fill,
					stroke: this.stroke,
					strokeWidth: this.strokeWidth,
					angle: this.angle,
					opacity: this.opacity,
					width: this.width,
					height: this.height,
				},
				this.isMouseDown
			)
		} else if (this.shapeOption === "Line")
			this.shape = AddShape.CreateLine(this.initialPoint, endPoint, {
				fill: this.fill,
				stroke: this.stroke,
				strokeWidth: this.strokeWidth,
				angle: this.angle,
				opacity: this.opacity,
				width: this.width,
				height: this.height,
			})

		this.priorSelection = [this.shape]
	}

	protected generatePreviewShape() {
		if (this.shapeOption === "Ellipse") {
			this.shape = AddShape.CreateEllipse(
				this.previewPoint,
				this.previewPoint,
				{
					fill: this.fill,
					stroke: this.options.temporaryStroke ?? this.stroke,
					strokeWidth: this.options.temporaryStrokeWidth ?? this.strokeWidth,
					angle: this.angle,
					opacity: this.opacity / 2,
					width: this.width,
					height: this.height,
				},
				this.isMouseDown
			)
		} else if (this.shapeOption === "Polygon") {
			this.shape = AddShape.CreatePolygon(
				this.previewPoint,
				this.previewPoint,
				this.sideAmount,
				{
					fill: this.fill,
					stroke: this.options.temporaryStroke ?? this.stroke,
					strokeWidth: this.options.temporaryStrokeWidth ?? this.strokeWidth,
					angle: this.angle,
					opacity: this.opacity / 2,
					width: this.width,
					height: this.height,
				},
				this.isMouseDown
			)
		} else if (this.shapeOption === "Line")
			this.shape = AddShape.CreateLine(this.previewPoint, this.previewPoint, {
				fill: this.fill,
				stroke: this.options.temporaryStroke ?? this.stroke,
				strokeWidth: this.options.temporaryStrokeWidth ?? this.strokeWidth,
				angle: this.angle,
				opacity: this.opacity,
				width: this.width,
				height: this.height,
			})
	}

	protected updateShape(endPoint: fabric.Point) {
		if (!this.shape) return this.generateShape()
		if (this.shapeOption === "Line") return this.updateLine(endPoint)

		const width = AddShape.getWidth(this.initialPoint, endPoint)
		const height = AddShape.getHeight(this.initialPoint, endPoint)

		const left = AddShape.getLeft(this.initialPoint, endPoint, width)
		const top = AddShape.getTop(this.initialPoint, endPoint, height)

		this.shape.opacity = this.opacity
		this.shape.left = left
		this.shape.top = top

		this.shape.scaleTo(width, height)

		this.editor.canvas.requestRenderAll()
	}

	protected updateLine(endPoint: fabric.Point) {
		this.generateShape(endPoint)
		this.editor.canvas.clearTemporaryObjects()
		this.editor.canvas.addTemporaryObject(this.shape)
		this.editor.canvas.requestRenderAll()
	}

	onComplete(...args: any): void {
		if (!this.initialPoint && !this.endPoint) {
			this.initialPoint = new fabric.Point(this.left, this.top)
			this.endPoint = new fabric.Point(this.left, this.top)
		}

		if (this.endPoint.distanceFrom(this.initialPoint) < MIN_SHAPE_RADIUS) {
			if (this.shape) {
				this.shape.opacity = this.opacity
				return
			}

			if (this.shapeOption === "Ellipse")
				this.shape = AddShape.CreateEllipse(
					this.initialPoint,
					this.endPoint,
					{
						fill: this.fill,
						stroke: this.fabricOptions.stroke,
						strokeWidth: this.fabricOptions.strokeWidth,
						angle: this.angle,
						opacity: this.opacity,
						width: this.width,
						height: this.height,
					},
					this.isMouseDown
				)
			else if (this.shapeOption === "Polygon")
				this.shape = AddShape.CreatePolygon(
					this.initialPoint,
					this.endPoint,
					this.sideAmount,
					{
						fill: this.fill,
						stroke: this.fabricOptions.stroke,
						strokeWidth: this.fabricOptions.strokeWidth,
						angle: this.angle,
						opacity: this.opacity,
						width: this.width,
						height: this.height,
					},
					this.isMouseDown
				)
			else if (this.shapeOption === "Line")
				this.shape = AddShape.CreateLine(this.initialPoint, this.endPoint, {
					fill: this.fill,
					stroke: this.fabricOptions.stroke,
					strokeWidth: this.fabricOptions.strokeWidth,
					angle: this.angle,
					opacity: this.opacity,
					width: this.width,
					height: this.height,
				})
		} else {
			this.generateShape(this.endPoint)
		}

		if (this.shapeOption === "Line") this.shape.perPixelTargetFind = false
	}

	protected onDeactivate(): void {
		this.clearListeners()
		this.editor.canvas.clearTemporaryObjects()

		this.editor.canvas.hoverCursor = this.defaultHoverCursor ?? "move"
		this.editor.canvas.defaultCursor = this.defaultCursor ?? "default"
	}

	static getWidth = (initialPoint: fabric.Point, endPoint: fabric.Point) => Math.abs(endPoint.x - initialPoint.x)

	static getHeight = (initialPoint: fabric.Point, endPoint: fabric.Point) => Math.abs(endPoint.y - initialPoint.y)

	static getLeft = (initialPoint: fabric.Point, endPoint: fabric.Point, width: number) =>
		(initialPoint.x <= endPoint.x ? initialPoint.x : endPoint.x) + width / 2

	static getTop = (initialPoint: fabric.Point, endPoint: fabric.Point, height: number) =>
		(initialPoint.y <= endPoint.y ? initialPoint.y : endPoint.y) + height / 2

	static getRectanglePoints = (initialPoint: fabric.Point, endPoint: fabric.Point) => [
		new fabric.Point(initialPoint.x, initialPoint.y),
		new fabric.Point(initialPoint.x, endPoint.y),
		new fabric.Point(endPoint.x, endPoint.y),
		new fabric.Point(endPoint.x, initialPoint.y),
	]

	static getLinePoints = (initialPoint: fabric.Point, endPoint: fabric.Point) => [
		new fabric.Point(initialPoint.x, initialPoint.y),
		new fabric.Point(endPoint.x, endPoint.y),
	]

	static async CreateAsync(editor: EditorClass, options: AddShapeOptions): Promise<Polygon | Ellipse> {
		const mode = editor.mode.set(this, options)

		return new Promise((res, rej) => {
			mode.onexit = (m, cancelled) => {
				if (mode.cancelReason) return rej(mode.cancelReason)
				if (cancelled) return rej(mode.shape)
				if (!mode.shape) return rej(undefined)
				res(mode.shape)
			}
		})
	}

	static CreatePolygon(
		initialPoint: fabric.Point,
		endPoint: fabric.Point,
		sideAmount: number,
		options: Partial<AddShapeOptions>,
		ignoreMinRadius?: boolean
	) {
		const { fill, stroke, strokeWidth, angle, opacity, temporaryStroke, temporaryStrokeWidth } = options

		const width = this.getWidth(initialPoint, endPoint)
		const height = this.getHeight(initialPoint, endPoint)
		const left = this.getLeft(initialPoint, endPoint, width)
		const top = this.getTop(initialPoint, endPoint, height)

		if (!ignoreMinRadius && endPoint.distanceFrom(initialPoint) < MIN_SHAPE_RADIUS) {
			const newPoly = new Polygon(
				getEditor(),
				{
					numSides: sideAmount,
					width: options.width,
					height: options.height,
				},
				{
					left,
					top,
					stroke: temporaryStroke ?? options.stroke,
					strokeWidth: temporaryStrokeWidth ?? options.strokeWidth,
					opacity: options.opacity,
					angle: options.angle,
					fill: options.fill,
				}
			)

			return newPoly
		}

		let polygon: Polygon

		if (sideAmount === 4) {
			// If the shape is a square/rectangle - simply get the initial and end points to create each corner
			const points = this.getRectanglePoints(initialPoint, endPoint)

			polygon = new Polygon(
				getEditor(),
				{
					points,
				},
				{
					fill,
					stroke: temporaryStroke ?? options.stroke,
					strokeWidth: temporaryStrokeWidth ?? options.strokeWidth,
					angle,
					opacity,
					left: this.getLeft(initialPoint, endPoint, width),
					top: this.getTop(initialPoint, endPoint, height),
				}
			)
		} else {
			// If the shape is a non 4-sided polygon, create a new shape from the side amount
			let tempPolygon = new Polygon(
				getEditor(),
				{
					numSides: sideAmount,
					polyRadius: width / 2,
				},
				{
					fill,
					stroke: temporaryStroke ?? options.stroke,
					strokeWidth: temporaryStrokeWidth ?? options.strokeWidth,
					angle,
					opacity,
					left: this.getLeft(initialPoint, endPoint, width),
					top: this.getTop(initialPoint, endPoint, height),
				}
			)
			tempPolygon.scaleTo(width, height)
			let newPoints = tempPolygon.points.map((point) => tempPolygon.toAbsolutePoint(point))

			polygon = new Polygon(
				getEditor(),
				{
					points: newPoints,
				},
				{
					fill,
					stroke: temporaryStroke ?? options.stroke,
					strokeWidth: temporaryStrokeWidth ?? options.strokeWidth,
					angle,
					opacity,
					left: this.getLeft(initialPoint, endPoint, width),
					top: this.getTop(initialPoint, endPoint, height),
				}
			)
		}

		return polygon
	}

	static CreateLine(initialPoint: fabric.Point, endPoint: fabric.Point, options: Partial<AddShapeOptions>) {
		const sortLeftPoints = () => [Math.min(endPoint.x, initialPoint.x), Math.max(endPoint.x, initialPoint.x)]
		const sortTopPoints = () => [Math.min(endPoint.y, initialPoint.y), Math.max(endPoint.y, initialPoint.y)]

		const [minX, maxX] = sortLeftPoints()
		const [minY, maxY] = sortTopPoints()

		const calculateLeftOffset = () => (maxX - minX) / 2
		const calculateTopOffset = () => (maxY - minY) / 2

		const { fill, stroke, strokeWidth, angle, opacity, temporaryStroke, temporaryStrokeWidth } = options
		const points = this.getLinePoints(initialPoint, endPoint)

		const line = new Polygon(
			getEditor(),
			{
				points,
			},
			{
				fill,
				stroke: temporaryStroke ?? options.stroke,
				strokeWidth: temporaryStrokeWidth ?? options.strokeWidth,
				angle,
				opacity,
				left: minX + calculateLeftOffset(),
				top: minY + calculateTopOffset(),
			}
		)

		return line
	}

	static CreateEllipse(
		initialPoint: fabric.Point,
		endPoint: fabric.Point,
		options: Partial<AddShapeOptions>,
		ignoreMinRadius?: boolean
	) {
		const getLeft = () => (initialPoint.x <= endPoint.x ? initialPoint.x : endPoint.x) + width / 2
		const getTop = () => (initialPoint.y <= endPoint.y ? initialPoint.y : endPoint.y) + height / 2

		const width = Math.abs(endPoint.x - initialPoint.x)
		const height = Math.abs(endPoint.y - initialPoint.y)

		if (!ignoreMinRadius && endPoint.distanceFrom(initialPoint) < MIN_SHAPE_RADIUS) {
			return new Ellipse(
				getEditor(),
				{
					rx: options.width / 2,
					ry: options.height / 2,
				},
				{
					left: getLeft(),
					top: getTop(),
					stroke: options.temporaryStroke ?? options.stroke,
					strokeWidth: options.temporaryStrokeWidth ?? options.strokeWidth,
					opacity: options.opacity,
					angle: options.angle,
					fill: options.fill,
				}
			)
		}

		const ellipse = new Ellipse(
			getEditor(),
			{
				rx: width / 2,
				ry: height / 2,
			},
			{
				left: getLeft(),
				top: getTop(),
				stroke: options.temporaryStroke ?? options.stroke,
				strokeWidth: options.temporaryStrokeWidth ?? options.strokeWidth,
				opacity: options.opacity,
				angle: options.angle,
				fill: options.fill,
			}
		)

		return ellipse
	}
}

const DEFAULT_BORDER = "#008ff4"
const DEFAULT_FILL = "#32515f"

export const SHAPE_MODE_DEFAULTS: AddShapeOptions = {
	top: 0,
	left: 0,
	fill: DEFAULT_FILL,
	stroke: DEFAULT_BORDER,
	temporaryStroke: DEFAULT_BORDER,
	strokeWidth: 4,
	temporaryStrokeWidth: 4,
	numSides: 4,
	shape: "Polygon",
	width: 100,
	height: 100,
	opacity: 100,
	angle: 0,
}
