import { fabric } from "fabric"

import { AudioAsset, CanvasAsset, MaskBase } from "./asset"
import {
	ModeController,
	ViewportController,
	SelectionController,
	Canvas,
	TimelineController,
	PathController,
	ContextMenuController,
	ClipboardController,
} from "./controllers"
import { RootTrackGroup } from "./tracks"
import { EventManager } from "./events/decorators"

type EditorConfig = {
	backgroundColor: `#${string}`
	canvasColor: `#${string}`
}

type SceneConfig = {
	width: number
	height: number
	duration: number
}

export class EditorClass extends EventManager {
	//#region    ===========================		  		Initialization				==============================

	/** The HTML element which contains the canvas */
	public element: HTMLElement

	//#region    ===========================			Interface Controllers			==============================

	/** Controller which extends the fabric canvas - handles all rendering and canvas events */
	public canvas: Canvas

	/** Controller for manipulating the canvas's view */
	public viewport: ViewportController

	/** Controller for selections made in the editor */
	public selection: SelectionController

	/** Controller for the timeline and preview playback */
	public timeline: TimelineController

	/** Root of all tracks */
	public tracks: RootTrackGroup<any>

	/** Controller for the editor's "mode" */
	public mode: ModeController

	/** Controller for active paths in the editor */
	public paths: PathController

	/** Controller for active masks in the editor */
	public masks: MaskController

	/** Control context menus */
	public contextMenu: ContextMenuController

	/** Controller for clipboard */
	public clipboard: ClipboardController

	//#endregion =====================================================================================================

	constructor() {
		super()

		this.tracks = new RootTrackGroup(this, "ROOT")

		this.selection = new SelectionController(this)

		this.mode = new ModeController(this)
		this.paths = new PathController(this)
		this.masks = new MaskController(this)

		this.timeline = new TimelineController(this, {
			duration: 10,
		})

		this.contextMenu = new ContextMenuController(this)

		this.clipboard = new ClipboardController(this)
	}

	/**
	 * Set up your asset as a luxedo editor so it can be interfaced with via the API
	 * @param canvasContainerID The ID of the asset to put the editor in (Without the "#")
	 * @param config Settings for the editor itself
	 */
	initialize(canvasContainerID: string, sceneConfig: SceneConfig, config: EditorConfig) {
		if (this.canvas) throw ReferenceError("Editor is already initialized")

		this.timeline.setDuration(sceneConfig.duration, "init")

		// Initialize the blank canvas
		this.element = document.getElementById(canvasContainerID)!
		this.canvas = new Canvas(this, {
			container: this.element,
			canvasWidth: sceneConfig.width,
			canvasHeight: sceneConfig.height,
			backgroundColor: config.backgroundColor,
			canvasColor: config.canvasColor,
		})

		this.viewport = new ViewportController(this)

		this.eventEmitter.emit("ready")
		this._ready = true
	}

	private _ready: boolean = false
	/**
	 * Set up an event to fire when the editor has been initialized
	 * If the editor is already loaded, the listener will fire immediately
	 */
	onReady(listener: () => any) {
		if (!this._ready) this.eventEmitter.once("ready", listener)
		else listener()
	}

	//#endregion =====================================================================================================

	//#region    ===========================	     Editor Property Accessors 	 		==============================

	/** The width of the canvas - i.e. the actual work area */
	get sceneWidth(): number {
		return this.canvas.canvasWidth
	}

	/** The height of the canvas (i.e. the actual work area) in pixels */
	get sceneHeight(): number {
		return this.canvas.canvasHeight
	}

	get sceneCenter(): fabric.Point {
		return new fabric.Point(this.sceneWidth / 2, this.sceneHeight / 2)
	}

	//#endregion =====================================================================================================
}

import { ObjectMod, applyFabricExtension, applyPointExtension, applyPolylineExtension } from "./fabric-plugins"
import { MaskController } from "./controllers/Mask"

applyFabricExtension()
applyPointExtension()
applyPolylineExtension()
// applyPathExtension()

let EditorInstance = undefined
export function setEditor(editor: EditorClass) {
	EditorInstance = editor
}
export function getEditor(): EditorClass {
	if (!EditorInstance) EditorInstance = new EditorClass()
	return EditorInstance
}
