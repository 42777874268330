import { EditorClass } from "../.."
import { LocalTimestamp, Keyframe, timestampLocalToAbsolute } from "../../animation"
import { AnimationPreset, PresetKeyframes } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { registerSerializableConstructor } from "../../modules/serialize"
import { Track } from "../../tracks"

export class Rotation extends AnimationPreset<CanvasAsset> {
	name = "Rotation"
	properties = ["angle" as const]
	type = "offset" as const

	speed // rotations per second

	constructor(
		editor: EditorClass,
		track: Track<CanvasAsset>,
		opts: {
			speed: number
			start: LocalTimestamp
			end: LocalTimestamp
		}
	) {
		const { speed, start, end } = opts

		super(editor, track, {
			startTime: start,
			endTime: end,
		})

		this.speed = speed
		this.generateKeyframes()
	}

	generateKeyframes(): void {
		const duration =
			timestampLocalToAbsolute(this.endTime, this.track) -
			timestampLocalToAbsolute(this.startTime, this.track)
		// Amount of times this asset will rotate for its duration
		const rotations = duration * this.speed
		const angle = rotations * 360

		const keyframes: Keyframe[] = [
			{
				timestamp: this.startTime,
				value: 0,
			},
			{
				timestamp: this.endTime,
				value: angle,
			},
		]

		this.keyframes = {
			angle: new PresetKeyframes(this, keyframes),
		}
	}

	update(speed: number, start?: LocalTimestamp, end?: LocalTimestamp) {
		if (start) this.startTime = start
		if (end) this.endTime = end
		this.speed = speed

		this.generateKeyframes()
	}

	onResize(): void {
		this.generateKeyframes()
	}

	static async loadJSON(editor: EditorClass, data: Partial<Rotation>): Promise<Rotation> {
		return new this(editor, data.track, {
			end: data.endTime,
			start: data.startTime,
			speed: data.speed,
		})
	}
}

registerSerializableConstructor(Rotation)
