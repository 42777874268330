import Resolutions from "./Resolutions"
import Projectors from "./Projectors"

export type Resolution = {
	width: number
	height: number
	aspect_ratio: string
	supported: boolean
	names: Array<string>
}

class SupportedResoultionRequester {
	declare resolutions: {
		[index: string]: Resolution
	}

	constructor() {
		this.resolutions = {}
		this.refreshResolutions()
	}

	refreshResolutions() {
		try {
			this.resolutions = Resolutions
		} catch (error) {
			console.error("Error requesting supported resolutions.", error)
		}
	}

	getByNames(names: Array<string>) {
		let allRes = {}
		for (const name of names) {
			allRes[name] = this.resolutions[name]
		}
		return allRes
	}

	getByResolution(x: number, y: number) {
		for (const [name, resolution] of Object.entries(this.resolutions)) {
			if (resolution.width === x && resolution.height === y) return name
		}
		return undefined
	}
}

export type ThirdPartyProjector = {
	name: string
	manufacturer: string | null
	native_res: string
	max_res: string
	throw_ratio: number | null
	lumens: number | null 
	display_type: string | null
	compatible_res: Array<string> | null
}

class ThirdPartyProjectorRequester {

	declare projectors: {
		[index: string]: ThirdPartyProjector
	}

	declare resolutionManager: SupportedResoultionRequester
	declare isInitialized: boolean
	declare pathBase: string

	constructor() {
		this.isInitialized = false
		this.resolutionManager = new SupportedResoultionRequester()
		this.projectors = {}
	}

	initialize() {

		if (!this.isInitialized) {
			this.refreshThirdPartyProjectors()
			this.isInitialized = true
		}
	}

	getSupportedResolutions(projectorName?: string): { [index: string]: Resolution } {
		let projector 
		if (projectorName) projector = this.projectors[projectorName]
		if (!projector || projectorName === "_all_other_projectors")
			return this.resolutionManager.resolutions
		return this.resolutionManager.getByNames(projector.compatible_res)
	}

	async refreshThirdPartyProjectors() {
		this.resolutionManager.refreshResolutions()

		try {
			this.projectors = Projectors
		} catch (error) {
			console.error("Error requesting supported projectors.", error)
		}
	}
}

export const ThirdPartyProjectorManager = new ThirdPartyProjectorRequester()
