<script lang="ts">
	import { CollapsibleSection } from "svelte-comps/comps"
	import type { Component } from "../../../../types/SvelteTypes"
	import ScheduleInputsDevice from "./ScheduleInputs_Device.svelte"
	import ScheduleInputsShow from "./ScheduleInputs_Show.svelte"
	import ScheduleInputsTime from "./ScheduleInputs_Time.svelte"
	import { ArrowBack, ArrowDown, ArrowForward, PlusIcon, SmallPlusIcon } from "svelte-comps/icons"
	import { ScheduleController, TimetableOverlapError } from "../ScheduleController"

	import { Toast } from "svelte-comps/toaster"

	type Step = {
		title: string
		description: string
		component: Component
	}

	const steps: Array<Step> = [
		{
			title: "Select your device",
			description: "Select the device to schedule playback",
			component: ScheduleInputsDevice,
		},
		{
			title: "Select your show",
			description: "Select the show you would like to play",
			component: ScheduleInputsShow,
		},
		{
			title: "Select the time range",
			description: "Select the time, date and whether your show will repeat",
			component: ScheduleInputsTime,
		},
	]

	let stepIndex: number = 0
	let isValid: boolean // Controls whether the next button is disabled - passed up from input component

	ScheduleController.subscribe((ctx) => (stepIndex = ctx.currentStep))

	function handleBack() {
		ScheduleController.updateStepIndex(stepIndex - 1)
	}

	async function handleNext() {
		try {
			await ScheduleController.updateStepIndex(stepIndex + 1)
		} catch (e) {
			console.error("Error clicking next or schedule.", e)
			if (e instanceof TimetableOverlapError)
				Toast.error("Event would overlap an existing event, please adjust the time and try again.")
			else Toast.error("Unable to continue, please try again.")
		}
	}
</script>

<div class="new-event-heading">
	<h2>New Event</h2>
	<button class="icon" id="close-scheduler" on:click={() => ScheduleController.EventEditor.cancel()}
		><PlusIcon /></button
	>
</div>

<div id="schedule-inputs">
	{#each steps as step, i}
		<div class="step {i === stepIndex ? 'active' : ''}">
			<div class="step-button {i === stepIndex ? 'active' : ''}">
				<span class="step-number">{i + 1}</span>
				<span class="step-text">
					<h3>{step.title}</h3>
					<span class="step-description">{step.description}</span>
				</span>
			</div>
			<div class="step-content {i !== stepIndex ? 'hidden' : ''}">
				{#if i === stepIndex}
					<svelte:component this={step.component} bind:isValid />
				{:else}
					<svelte:component this={step.component} />
				{/if}
			</div>
			{#if i === stepIndex}
				<div class="next-container">
					<button class="link-button" on:click={handleBack}
						>{stepIndex === 0 ? "Cancel" : "Back"}</button
					>
					<button class="outline-button" disabled={!isValid} on:click={handleNext}>
						{#if stepIndex === steps.length - 1}
							Schedule
						{:else}
							Next <ArrowDown />
						{/if}
					</button>
				</div>
			{/if}
		</div>
	{/each}
</div>

<style>
	.new-event-heading h2 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-text-light);
	}

	#close-scheduler {
		color: var(--color-text);
	}

	#close-scheduler :global(svg) {
		transform: rotate(45deg);
		height: 2rem;
		width: 2rem;
	}

	#close-scheduler :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	#close-scheduler:hover :global(.svg-stroke),
	#close-scheduler:focus-visible :global(.svg-stroke) {
		stroke: var(--color-error);
	}

	.new-event-heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		padding-bottom: 1rem;
		margin-right: 2rem;
		height: 6rem;
	}

	#schedule-inputs {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: calc(100% - 6rem);
	}

	.step {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex-basis: 3rem;
	}

	.next-container {
		margin-bottom: 1rem;
		padding-right: 2rem;
		display: flex;
		justify-content: flex-end;
	}

	.next-container .outline-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		transform: unset;
		box-shadow: var(--shadow-small);
	}

	.next-container .outline-button:hover,
	.next-container .outline-button:focus-visible {
		color: var(--color-text-dark);
	}

	.next-container .outline-button :global(svg) {
		height: 1rem;
		width: fit-content;
	}

	.step.active {
		flex-grow: 1;
	}

	h3 {
		margin: 0;
		color: var(--color-text-light);
		font-size: var(--h2);
		transition:
			font-size 250ms,
			color 500ms;
	}

	.step-description {
		transition:
			font-size 250ms,
			color 500ms;
	}

	.step-button {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		align-items: center;
		min-height: 3rem;
	}

	.step-content {
		margin-left: 3.5rem;
		margin-bottom: 1rem;
		overflow-y: auto;
		height: 100%;
	}

	.step-content.hidden {
		display: none;
	}

	.step-content:global(:has(.show-selector)) {
		margin-left: unset;
	}

	.step-number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 2.5rem;
		height: 2.5rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.step-text {
		position: relative;
		font-size: var(--h3);
		padding-right: 3rem;
		max-width: calc(100% - 4rem);
		line-height: 1em;
	}

	.step-button.active .step-text h3 {
		color: var(--color-main);
		font-size: var(--h1);
	}

	.step-button.active .step-text .step-description {
		color: var(--color-text-light);
		font-size: var(--h2);
	}
</style>
