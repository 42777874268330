import { fabric } from "fabric"
import { Serializable } from "../modules/serialize"
import { TrackGroup } from "../tracks"
import { EditorClass, getEditor } from ".."

/**
 * @file controllers/Clipboard.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles the clipboard
 */
export class ClipboardController {
	//#region    ===========================		   Construction & init  	 		==============================

	editor: EditorClass
	constructor(editor: EditorClass) {
		this.editor = editor
	}

	//#endregion =====================================================================================================
	//#region    ===========================		   	   Basic Interface  	 		==============================

	/** The current contents of the clipboard */
	contents: Serializable
	private contentConstructor: typeof Serializable
	contentPasteCount: number = 0

	/** Extra info about the context, which can be stored on copy, and may be useful when pasting */
	metadata: ClipboardMetadata = {}

	protected lastCopyOptions: ClipboardContextInfo & ClipboardCopyData

	/**
	 * Copy an object to the clipboard by serializing it and storing the serialized version
	 * @param target The object to copy
	 * @param options Extra context information + options
	 **/
	copy<T extends Serializable>(target: T, options?: ClipboardContextInfo & ClipboardCopyData) {
		if (!target) return

		this.lastCopyOptions = options

		options = options ?? { origin: "auto" }

		// Serialize the target
		const serialized = target.serialize()

		const metadata = options.metadata ?? {}

		// Get extra context from target.onCopy
		if (target.onCopy) {
			const copyData = target.onCopy(serialized, options)

			if (copyData) {
				Object.assign(metadata, copyData)
			}
		}

		this.contents = serialized

		// Save the constructor so we know how to rebuild it
		this.contentConstructor = target.constructor as unknown as typeof Serializable
		this.metadata = metadata

		this.contentPasteCount = 0

		return true
	}

	/**
	 * - Deserialize the clipboard contents
	 * - Call onPaste if applicable
	 * - If an invalid paste error occurs,
	 * - Return the new object
	 **/
	async paste(options?: ClipboardContextInfo, location?: PasteLocationData) {
		const pastedInst = await this.contentConstructor.loadJSON(getEditor(), this.contents)

		console.warn(this.contentConstructor)

		options = options ?? { origin: "auto" }
		if (!pastedInst.onPaste) return pastedInst

		pastedInst.onPaste(options, location, this.metadata)
		this.contentPasteCount += 1

		this.copy(pastedInst, this.lastCopyOptions)
	}

	clear() {
		this.contents = null
		this.metadata = {}
		this.contentConstructor = null
	}

	canPaste(options?: ClipboardContextInfo, location?: PasteLocationData): boolean {
		if (!this.contents) return false

		options = options ?? { origin: "auto" }

		const checkFunction = this.metadata.fnCheckCanPaste
		if (!checkFunction) return true

		return checkFunction(options, location)
	}

	isEmpty(): boolean {
		return !this.contents
	}

	//#endregion =====================================================================================================
	//#region    ===========================		   	   Default Behavior  	 		==============================
	//#endregion =====================================================================================================
	//#region    ===========================		   	   Under the Hood   	 		==============================
	//#endregion =====================================================================================================
}

export interface ClipboardContextInfo {
	/** @property The origin of the clipboard event */
	origin?: "canvas" | "timeline" | "auto" | "gui"

	/** @property If the originator was a context menu */
	contextMenu?: boolean

	/** @property If the originator was a keyboard shortcut */
	keyboard?: boolean

	/** @property Calling event, if applicable */
	canvasEvent?: fabric.IEvent<MouseEvent>
	event?: Event
}

export interface ClipboardCopyData {
	/** @property Data to add to the clipboard's context metadata */
	metadata?: any
}

/** Information about the paste location that will be passed to the target's "onPaste" method (if it has one) */
export type PasteLocationData = {
	timeStart?: number
	relativeTime?: number
	canvasPosition?: fabric.Point
	targetGroup?: TrackGroup
	trackIndex?: number
}

export type ClipboardMetadata = {
	fnCheckCanPaste?: (origin: ClipboardContextInfo, location?: PasteLocationData) => boolean
}
