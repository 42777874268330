import { v4 as uuid } from "uuid"
import { Track, TrackGroup } from "."
import { RootTrackGroup } from "./RootGroup"
import Asset, { EditorAsset, CanvasAsset, MaskBase, TrackMask, MaskData, ClippingMask } from "../asset"
import { EventListenerControls, EventedInstance } from "../events/decorators"
import { TrackEvent } from "../events"
import { MultiMask, type Mask } from "../asset"
import { Serializable } from "../modules/serialize"
import { EditorClass, getEditor } from ".."
import { MaskController } from "../controllers/Mask"

export interface TrackBaseOptions {
	/** Import the ID from a source JSON */
	id?: string
	name: string
	tags?: { [index: string]: string }
}

export type TrackContentType<T extends EditorAsset> = "VIDEO" | "AUDIO" | "GROUP"

export interface TrackLike<T extends EditorAsset> extends EventedInstance<TrackEvent> {
	__CONSTRUCTOR__: "Track" | "TrackGroup"
}
/**
 * Basic organizational structure of the editor
 * The editor timeline / tracklist is made up of groups and of tracks
 * Tracks contain a single asset in them
 * Groups contain other groups or tracks
 */

@EventedInstance(TrackEvent)
export class TrackLike<T extends EditorAsset = any> implements Serializable {
	//#region    ===========================			   Initialization				==============================
	id: string
	name: string
	contentType: TrackContentType<T>
	tags: { [index: string]: string }
	editor: EditorClass

	constructor(editor: EditorClass, options: TrackBaseOptions) {
		this.editor = editor
		this.id = options.id ?? uuid()
		this.name = options.name
		this._muted = false
		this.tags = {}
	}

	/**
	 * Must be called when bound to a group in the editor
	 * Track/Group cannot be used for anything before this is called
	 * @param root
	 * @param options
	 */
	initialize(root: RootTrackGroup<T>) {
		if (!root)
			throw "The passed root is undefined. Have you been using group/track constructors instead of (group).addAsset / (group).createSubgroup?"

		this._root = root
		this.contentType = root.contentType
		root.register(this)
	}

	get initialized() {
		return !!this._root
	}

	//#endregion =====================================================================================================
	//#region    ===========================				  Properties	 	 		==============================

	cachedParent?: TrackGroup<T>
	/**
	 * Backlink for track tree - null indicates that it is at the top level of the editor
	 * Uses lazy-load caching to compromise between efficiency and avoiding state redundancy
	 **/
	get parent(): TrackGroup<T> {
		// @ts-ignore to make my life easier I typed the TrackGroup to only allow Tracks | TrackGroups as children, but these abstractions throw a warning as a result
		if (this.cachedParent && this.cachedParent.children.includes(this)) return this.cachedParent
		this.cachedParent = null
		this.getRoot()?.refreshCache()
		return this.cachedParent
	}

	/** position in the current parent order */
	get positionIndex(): number {
		// @ts-ignore to make my life easier I typed the TrackGroup to only allow Tracks | TrackGroups as children, but these abstractions throw a warning as a result
		return this.parent.indexOf(this)
	}

	/**
	 * Gets the depth of the current node in the hierarchy.
	 * @returns The number of parents this group is wrapped in
	 * @returns 0 if node is in the top level
	 */
	get depth(): number {
		return this.parent.depth + 1
	}

	/**
	 * Returns true if this track is a root track
	 * @returns
	 */
	isRoot(): boolean {
		return false
	}

	//#endregion =====================================================================================================
	//#region    ===========================				  Predicates	 	 		==============================

	protected _root: RootTrackGroup<any>
	getRoot(): RootTrackGroup<T> {
		return this._root
	}

	/**
	 * Returns a list of all the groups which are ancestors of this. Ordered from top level -> parent.
	 **/
	getLineage(): TrackGroup<T>[] {
		const lineage = this._getLineageRecursive(this)
		return lineage
	}

	/**
	 * Internal function for building the lineage tree
	 * @private
	 * @param _originator used to detect infinite loops
	 * @returns
	 */
	_getLineageRecursive(_originator: TrackLike<T>): TrackGroup<T>[] {
		// Unless I am missing something - this will always be called with _originator as this for the first pass through
		// if (this === _originator) throw ReferenceError(`Infinite loop detected at ${this.name}`)
		const parent = this.parent
		return parent?._getLineageRecursive(_originator).concat(parent) ?? []
	}

	assertHasVideo(): asserts this is TrackLike<CanvasAsset> {
		if (this.contentType !== "VIDEO") throw TypeError(`Not a video track ("${this.contentType}" !== "VIDEO")`)
	}

	//#endregion =====================================================================================================
	//#region    ===========================				  Interface  				==============================

	getAssets(): Array<Asset> {
		throw new Error(`Missing implementation for getAssets on ${this.constructor.name}.`)
	}

	/** Wrap the track in a new group
	 * - Creates a group above this track
	 * - Move into the new group
	 * @returns The new middleman group
	 */
	insertParentGroup(options?: Partial<TrackBaseOptions>, positionIndex?: number): TrackGroup<T> {
		const middleGroup = this.parent.createSubgroup(
			{
				id: options?.id || uuid(),
				name: options?.name || this.name,
			},
			positionIndex
		)

		// If the base track (another track will be dropped onto this one) has a mask, change the mask parent from the track to the new group
		// Logistically, this will feel the same as the track existing in a masked group already
		if (this.hasOwnMask()) {
			const mask = this.getMask()
			this.clearMask()
			middleGroup.setMask(mask)
		}

		// @ts-ignore to make my life easier I typed the TrackGroup to only allow Tracks | TrackGroups as children, but these abstractions throw a warning as a result
		middleGroup.attach(this)
		return middleGroup
	}

	//#endregion =====================================================================================================
	//#region    ===========================								Controls API						 		==============================

	/** Mute this group/track and its contents */
	protected _hidden: boolean
	get hidden(): boolean {
		if (this._hidden) return true
		if (!this.parent) return false
		return this.parent._hidden
	}

	set hidden(val: boolean) {
		this._hidden = val
		this.emitEvent("edit:hide", {
			track: this,
		})
		getEditor().canvas.requestRenderAll()
	}

	/** Lock the group/track and its contents - prevent from being selected, moved, etc. */
	protected _locked: boolean
	get locked(): boolean {
		if (this._locked) return true
		if (!this.parent) return false
		return this.parent._locked
	}

	set locked(val: boolean) {
		this._locked = val
		this.emitEvent("edit:lock", {
			track: this,
		})
	}

	/** Mute this group/track and its contents */
	protected _muted: boolean
	get muted(): boolean {
		return this._muted
	}
	set muted(val: boolean) {
		this._muted = val
		this.emitEvent("edit:mute", {
			track: this,
		})
	}

	//#endregion =====================================================================================================
	//#region    =========================================== Clipping Masks ==========================================

	trackMask: TrackMask
	maskData: MaskData = {
		ids: [],
		inverted: false,
	}

	/**
	 * Invert the track mask
	 */
	invertMask() {
		this.maskData.inverted = !this.maskData.inverted

		this.generateMask()
	}

	/**
	 * Add a mask base instance to the track mask
	 */
	addMask(mask: MaskBase) {
		this.maskData.ids.push(mask.id)

		this.generateMask()

		this.initializeMaskRefresher()

		this.emitEvent("mask:add", {
			track: this,
		})
		getEditor().masks.emitEvent("added", {
			maskBase: mask,
		})
	}

	/**
	 * Remove a mask base instance from the trackMask
	 */
	removeMask(mask: MaskBase) {
		const maskIndex = this.maskData.ids.findIndex((id) => id === mask.id)
		this.maskData.ids.splice(maskIndex, 1)

		this.generateMask()

		if (this.maskData.ids.length === 0) this.clearMaskRefresher()

		this.emitEvent("mask:remove", {
			track: this,
		})

		getEditor().masks.emitEvent("removed", {
			maskBase: mask,
		})
	}

	/**
	 * Returns own mask if applicable, otherwise returns parent's mask
	 */
	getMask() {
		// adding this.id below fixes odd stack overflow issue - please don't remove (unless you can fix this with a better solution)
		if (this.id && !this.trackMask) return this.parent?.getMask()

		return this.trackMask
	}

	/**
	 * Directly apply a trackMask to this track
	 * @param mask
	 * @param inverted
	 */
	setMask(mask: TrackMask, inverted?: boolean) {
		this.maskData = {
			ids: mask.masks.map((shape) => shape.baseID),
			inverted,
		}

		this.trackMask = mask

		this.initializeMaskRefresher()
	}

	/**
	 * Returns true if this track has any applied masks
	 */
	hasOwnMask(): boolean {
		return this.maskData.ids.length > 0
	}

	/**
	 * Called when a subscribed mask is updated
	 * @param evented if true, will trigger an event
	 */
	maskUpdated(evented: boolean = true): void {
		this.generateMask()

		if (!evented) return

		this.emitEvent("mask:update", {
			track: this,
		})
	}

	/**
	 * Clear mask data and removes the trackMask
	 */
	clearMask(): void {
		this.maskData = {
			ids: [],
			inverted: false,
		}

		delete this.trackMask

		this.emitEvent("mask:remove", {
			track: this,
		})
	}

	/**
	 * Combines all parent masks to create a combined track mask
	 */
	maskBaseOrderCache: string = ""
	generateMask() {
		const lineage = [...this.getLineage(), this]
		const maskOrder = lineage.map((track) => this.editor.masks.getTrackMask(track)).filter((m) => !!m)

		let newMaskBaseOrder = maskOrder.flatMap((trackMask) => trackMask.masks.map((base) => base.id)).join(",")
		if (newMaskBaseOrder === this.maskBaseOrderCache) return

		this.maskBaseOrderCache = newMaskBaseOrder

		if (maskOrder.length) this.trackMask = MaskController.Intersect(maskOrder)
		else this.trackMask = undefined

		setTimeout(() => {
			this.editor.canvas.requestRenderAll()
		})
	}

	/**
	 * Returns a list of all the mask base instances applied to this track (or any parents)
	 */
	getAffectingMasks() {
		let masks = this.maskData.ids.map((id) => this.editor.masks.get(id))
		masks = masks.concat(this.parent?.getAffectingMasks() ?? [])
		return masks
	}

	listenerControls: EventListenerControls<any>
	clearMaskRefresher() {
		if (this.listenerControls) this.listenerControls.remove()
	}

	initializeMaskRefresher() {
		if (this.listenerControls) return

		this.listenerControls = this.editor.tracks.on("tracklist:*", (e) => {
			setTimeout(() => {
				this.generateMask()
			})
		})

		this.listenerControls = this.on("tracklist:*", (e) => {
			setTimeout(() => {
				this.generateMask()
			})
		})

		// this.on("tracklist:*", () => setTimeout(() => this.generateMask()))
	}

	//#endregion =========================================== Clipping Masks ==========================================
	//#region    =========================================== Serialization ===========================================

	/** Gets all of the properties available to export for the current instance.
	 * @returns an array of properties needed to export
	 */
	getDefaultPropertyExports() {
		const allProps = Object.keys(this)
		const expProps = []

		for (const prop of allProps) {
			// If this is a 'hidden' property, omit it
			if (prop.charAt(0) !== "_") expProps.push(prop)
		}

		return {
			include: [...expProps, "positionIndex", "hidden", "muted", "locked", "maskData"],
			deepCopy: [],
			exclude: ["cachedParent", "asset"],
		}
	}

	/** Converts a TrackLike instance to an object representation.
	 * Does not include instance properties (e.g., assets or animation presets) as those must be deep copied to avoid shared references.
	 * @param properties [OPTIONAL] the properties to include (if undefined, all properties will be included).
	 */
	toObject(properties?: string[]): Partial<this> {
		const json = {}

		if (!properties) properties = this.getDefaultPropertyExports().include
		for (const prop of properties) {
			if (prop in this) json[prop] = this[prop]
			else throw new Error(`Unable to find ${prop} in ${this.constructor.name}.`)
		}

		return json
	}

	/** Serializes this instance, returning an object representation.
	 * This is intented to be the BASE behavior - extended by Tracks and Groups to pass properties to include and deep copy.
	 */
	serialize(forExport?: boolean) {
		throw new Error(`Missing implementation for serialize on ${this.constructor.name}.`)
	}

	//#endregion    ===================================================================================
}
