import { Folder, type FolderRawData } from "../entries/Folder"
import { LuxedoRPC } from "luxedo-rpc"

import { DataHandlerFile } from "./DataHandlerFile"
import { DataHandlerMedia } from "./DataHandlerMedia"
import type { Media } from "../entries/Media"
import type { BundleOf } from "data-handler"
import type { User } from "../entries/User"

const FALSE = 0
const TRUE = 1

export class DHFolder extends DataHandlerFile<Folder> {
	EntryClass = Folder

	private folderStorageCache = {}

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Folder>> {
		return await LuxedoRPC.api.bundlers.folder_bundle_down(specificInstances ?? [])
	}

	protected async requestCreateEntry(entryData: Partial<FolderRawData>): Promise<number> {
		return (await LuxedoRPC.api.filesys.folder_create(entryData.name!, entryData.parent_id!))
			.new_folder_id
	}

	protected async requestDeleteEntry(entry: Folder): Promise<void> {
		return await LuxedoRPC.api.filesys.folder_delete(entry.id!)
	}

	protected async pushData(entryData: { [id: number]: Partial<FolderRawData> }): Promise<void> {}

	async pull(specificInstances?: number[]): Promise<number[]> {
		const ret = await super.pull(specificInstances)

		this.calculateAllFolderStorage(specificInstances)

		return ret
	}

	/**
	 * Relocates a folder on the backend
	 * @param folderToMove The folder being moved
	 * @param folderTo The folder of which the content is being moved to
	 */
	public async move(folderToMove: Folder, folderToID: number) {
		const parentID = folderToMove.parent_id
		await LuxedoRPC.api.filesys.fobj_move(folderToMove.id!, folderToID, 0)
		await this.pull([folderToMove.id!])
		this.ondatachanged([folderToMove.id!, folderToID, parentID])
	}

	/**
	 * Renames a file on the backend
	 * @param objectToRename The folder being renamed
	 * @param newName The new name of the folder
	 */
	public async rename(objectToRename: Folder, newName: string): Promise<void> {
		await LuxedoRPC.api.filesys.fobj_rename(objectToRename.id!, FALSE, newName)
		await this.pull([objectToRename.id!])
		this.ondatachanged([objectToRename.id!])
	}

	public getFolderStorageSize(folderID: number) {
		const folder = this.entries[folderID]
		if (this.folderStorageCache[folderID] === undefined) {
			console.error(`No size available for folder ${folder.name} (id: ${folder.id}) `)
			return 0
		}
		return this.folderStorageCache[folderID]
	}

	public calculateAllFolderStorage(specificInstances?: number[]) {
		if (specificInstances) {
			for (const id of specificInstances) {
				if (id in this.folderStorageCache) delete this.folderStorageCache[id]
			}
		} else {
			this.folderStorageCache = {}
			Object.values(this.entries).forEach((folder) => {
				try {
					this.folderStorageCache[folder.id!] = this.calculateFolderStorageSize(
						folder.id!,
						// @ts-ignore
						DataHandlerMedia
					)
				} catch (e) {
					console.error(
						`trying to get ${(folder.id, folder.name)} storage size with datahandlermedia`,
						{ folder }
					)
				}
			})
		}
	}

	private calculateFolderStorageSize<T extends DataHandlerFile<any>>(
		folderID: number,
		contentDataHandler: T
	) {
		let sizeKB = 0

		const folderFolders = this.getByFolder(folderID)
		const folderContent: Array<Media> = contentDataHandler.getByFolder(folderID)

		folderContent.forEach((entry) => (sizeKB += entry.size!))
		folderFolders.forEach(
			(folder) => (sizeKB += this.calculateFolderStorageSize(folder.id!, contentDataHandler))
		)

		return sizeKB
	}

	/**
	 * Checks if the provided file/folder is a public asset
	 * @param file The file to check
	 * @param user The current user (needed because they have a reference to the public folders)
	 */
	checkIfPublic(file: Folder | Media, user: User): boolean {
		const publicFolders = user.directories.public
		const userMediaRoot = user.directories.media

		if (file instanceof Folder) {
			if (publicFolders.includes(file.id!)) return true
			if (file.id === userMediaRoot) return false
		}
		if (publicFolders.includes(file.parent_id)) return true

		const parentFolder = this.get(file.parent_id)
		return this.checkIfPublic(parentFolder, user)
	}
}

export const DataHandlerFolder = new DHFolder()
