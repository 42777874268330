import { DataHandlerScene, type Scene } from "luxedo-data"
import { ProgressContext } from "./ProgressContext"
import { LuxedoRPC } from "luxedo-rpc"

class DirectUploadProgressCtx extends ProgressContext<Scene> {
	async processUpload(sceneId: number) {
		await LuxedoRPC.api.scene.project_upload_process(sceneId)
	}

	async getCompletedData(id: number): Promise<Scene> {
		await DataHandlerScene.pull([id])
		const scene = DataHandlerScene.get(id)
		return scene
	}
}

export const DirectUploadProgressContext = new DirectUploadProgressCtx({
	progress: "proj_upload_set_progress",
	complete: "proj_upload_process_complete",
	fail: "proj_upload_process_fail",
})
