/**
 * @file asset/canvas/MediaImage.ts
 * @author Austin Day 2023
 * @description An extension of fabric image with Editor data such as media ID
 */
import { EditorClass } from "../.."
import { registerSerializableConstructor } from "../../modules/serialize"
import { MediaAsset } from ".."
import { Transformable } from "./mixins/Transformable"

export interface ImageAsset extends MediaAsset<HTMLImageElement>, Transformable<HTMLImageElement> {}

/**
 * Create a fabric Image with extra properties for the editor
 */
@Transformable.Mixin
export class ImageAsset extends MediaAsset<HTMLImageElement> {
	//#region    ===========================				  Properties				==============================

	get contentWidth(): number {
		return this.content.naturalWidth
	}
	get contentHeight(): number {
		return this.content.naturalHeight
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	 Default properties		 		==============================
	crossOrigin = "anonymous"
	//#endregion =====================================================================================================

	static async loadJSON(
		editor: EditorClass,
		data: Partial<ImageAsset> & {
			media?: any
		},
		id?: string
	) {
		let clone: ImageAsset
		if (data.media) {
			clone = new ImageAsset(
				editor,
				{
					mediaId: data.media.id,
					src: data.media.src.editorPreview,
					id,
				},
				data
			)
		} else {
			clone = new ImageAsset(
				editor,
				{
					mediaId: data.mediaId,
					src: data.src,
					id,
				},
				data
			)
		}

		await clone.loadingPromise

		if (!data.isTransformed) return clone

		clone = Transformable.applySerializedTransformations(clone, data)

		return clone
	}

	//#region    ===========================		   	 Resource Loading		 		==============================
	protected initEmptyContent() {
		const content = new Image()
		content.crossOrigin = "anonymous"

		this.content = content
	}

	protected async loadContent(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.content.crossOrigin = "anonymous"
			this.content.onload = resolve
			this.content.onerror = reject
			this.content.src = url
		})
	}

	protected onContentLoaded(): void {
		this.setOptions({
			width: this.content.width,
			height: this.content.height,
		})

		this.setCoords()
		this.editor.canvas.requestRenderAll()
	}
	//#endregion =====================================================================================================
}

registerSerializableConstructor(ImageAsset)
