import { EditorClass } from ".."
import { ContextMenuCondition, ContextMenuTarget, FabricEvent } from "../modules/context-menu"
import * as RenderMenuDefault from "../modules/context-menu/render"

/**
 * @file controllers/ModeController.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles its mode state
 */
export class ContextMenuController {
	//#region    ===========================		   Construction & init  	 		==============================

	declare editor: EditorClass
	constructor(editor: EditorClass) {
		this.editor = editor
	}

	initialize(options: {
		renderMenu: ContextMenuController["render"]
		renderSubMenu?: ContextMenuController["renderSubMenu"]
		clearMenus?: ContextMenuController["clearContextMenus"]
	}) {
		this.render = options.renderMenu
		if (options.renderSubMenu) this.renderSubMenu = options.renderSubMenu
		if (options.clearMenus) this.clearContextMenus = options.clearMenus
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Basic Interface  	 		==============================

	public target: ContextMenuTarget
	public event: MouseEvent
	public fabricEvent?: FabricEvent

	activate(target: ContextMenuTarget, event: MouseEvent, fabricEvent?: FabricEvent) {
		if (!this.render) return
		event.preventDefault()
		this.target = target
		this.event = event
		this.fabricEvent = fabricEvent

		this.render()
	}

	activateSubmenu<T>(submenuOptions, callingMenuEvent: MouseEvent) {
		if (!this.render) return
		callingMenuEvent.preventDefault()
		this.renderSubMenu(submenuOptions, callingMenuEvent)
	}

	clear() {
		this.clearContextMenus && this.clearContextMenus()
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   Helpers for Rendering   	 		==============================

	/**
	 * Get the ordered list of available context menu options, as an array of arrays,
	 * where each inner array contains the available option data for an available section
	 */
	getActiveMenuLayout(menuLayout?: MenuSections): MenuOptionState[][] {
		if (!menuLayout) menuLayout = this.sections
		const sections = []

		for (const section of Object.values(menuLayout)) {
			if (section.showSectionCondition && !section.showSectionCondition()) continue
			const options: MenuOptionState[] = []

			for (const optionId of section.options) {
				const optionData = this.getOptionState(optionId)
				if (optionData.visible === false) continue

				options.push(optionData)
			}

			if (options.length > 0) sections.push(options)
		}

		return sections
	}
	//#endregion =====================================================================================================

	//#region    ===========================		   	   Under the Hood   	 		==============================
	/**
	 * Handle the generation of the context menu
	 * @argument options Leave blank unless this is a submenu - The config to use to load the menu options
	 */
	protected render: (options?: MenuSections) => HTMLElement | void

	/**
	 * Handle the generation of context submenu
	 * @argument event The event that originated the context menu call - may be a fabric.IEvent object if the event originates from the canvas
	 * @argument menuEvent The mouseevent originating from clicking on the parent menu option
	 * @argument target The right click target of the original event
	 */
	protected renderSubMenu: (
		submenuOptions: MenuSections,
		callingMenuEvent: MouseEvent
	) => HTMLElement | void

	protected clearContextMenus: () => void

	registerSection(sectionId: string): MenuSection {
		if (sectionId in this.sections) return this.sections[sectionId]

		console.warn(`Registering context menu section ${sectionId}`)

		this.sections[sectionId] = {
			options: [],
			showSectionCondition: undefined,
		}

		return this.sections[sectionId]
	}

	setSectionCondition(sectionId: string, sectionCondition: ContextMenuCondition) {
		const section = this.sections[sectionId]

		if (section.showSectionCondition && sectionCondition) {
			console.warn(`Replacing showSectionCondition definition for section ${sectionId}!`)
		}
		if (sectionCondition) {
			section.showSectionCondition = sectionCondition
		}

		return this.sections[sectionId]
	}

	registerOption(optionId: string) {
		if (optionId in this.allMenuOptions) return

		this.allMenuOptions[optionId] = undefined
	}

	getOptionState(optionId: string): MenuOptionState {
		const option = this.allMenuOptions[optionId]

		if (!option || !option())
			return {
				action: undefined,
				label: undefined,
				visible: false,
			}

		const state = option()
		if (state.visible !== false) {
			state.visible = true
		}

		return state
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	Menu Building Helpers  	 		==============================

	/** Assign a context menu option a function which determines its text/contents in the menu */
	public setOptionLabel(optionId: string, nameFunction: () => OptionLabel): void
	/** Assign the text/contents of a menu option  */
	public setOptionLabel(optionId: string, newName: OptionLabel): void
	public setOptionLabel(optionId: string, nameOrFunction: OptionLabelDef): void {
		this.registerOption(optionId)

		const stateGetter = this.allMenuOptions[optionId]
		if (!stateGetter)
			throw `Option ${optionId} does not have a base definition to set a custom label to!`

		console.warn(`[ContextMenu] Overwriting the name of ${optionId}`)

		this.allMenuOptions[optionId] = () => {
			const block = stateGetter()
			const label = typeof nameOrFunction === "function" ? nameOrFunction() : nameOrFunction
			block.label = label
			return block
		}
	}

	/**
	 * Set the behavior of a menu option
	 * @param action The click behavior - if it returns "true", the menu will NOT automatically close on click!
	 **/
	setOptionAction(optionId: string, action: () => boolean | undefined): void {
		this.registerOption(optionId)

		const stateGetter = this.allMenuOptions[optionId]
		if (!stateGetter)
			throw `Option ${optionId} does not have a base definition to overwrite action of!`

		console.warn(`[ContextMenu] Overwriting the action of ${optionId}`)

		this.allMenuOptions[optionId] = () => {
			const block = stateGetter()
			block.action = action
			return block
		}
	}

	/** Set a predicate for whether or not a menu option should be rendered */
	setOptionVisibility(optionId: string, visibilityFn?: () => boolean): void {
		this.registerOption(optionId)

		const stateGetter = this.allMenuOptions[optionId]
		if (!stateGetter)
			throw `Option ${optionId} does not have a base definition to overwrite visibility of!`

		console.warn(`[ContextMenu] Overwriting the visibility of ${optionId}`)

		this.allMenuOptions[optionId] = () => {
			const block = stateGetter()
			block.visible = visibilityFn ? visibilityFn() : true
			return block
		}
	}

	/** Set a predicate for whether or not a menu option should be disabled/greyed out */
	setOptionGreyOutCondition(optionId: string, greyOutCondition?: () => boolean): void {
		this.registerOption(optionId)

		const stateGetter = this.allMenuOptions[optionId]
		if (!stateGetter)
			throw `Option ${optionId} does not have a base definition to overwrite greyout condition of!`

		console.warn(`[ContextMenu] Overwriting the greyout condition of ${optionId}`)

		this.allMenuOptions[optionId] = () => {
			const block = stateGetter()
			block.greyedOut = greyOutCondition ? greyOutCondition() : false
			return block
		}
	}

	//#endregion =====================================================================================================

	//#region    ===========================		   	   Default Behavior  	 		==============================

	sections: MenuSections = {}
	allMenuOptions: Record<string, MenuOptionGenerator> = {}

	initializeDefault() {
		this.initialize({
			renderMenu: () => {
				return RenderMenuDefault.renderDefaultContextMenu(
					this.sections,
					this.target,
					this.event,
					this.fabricEvent
				)
			},
			renderSubMenu: (submenuOptions, callingMenuEvent) => {
				const submenu = this.render(submenuOptions)
				if (submenu) {
					submenu.style.left = `${callingMenuEvent.pageX}px`
					submenu.style.top = `${callingMenuEvent.pageY}px`
					submenu.onmouseleave = (e) => {
						submenu.remove()
					}
				}
				return submenu
			},
			clearMenus: () => {
				RenderMenuDefault.clearContextMenus()
			},
		})
	}

	//#endregion =====================================================================================================
}

ContextMenuController.prototype.sections = {}

export type MenuSections = {
	[sectionId: string]: MenuSection
}

/**
 * The definition of a menu section
 */
type MenuSection = {
	/** @property A conditional to check whether or not this menu section should be displayed at the time */
	showSectionCondition: () => boolean
	/** @property An array of registered options (by their key in this.editor.contextMenu.allMenuOptions) to include */
	options: string[]
}

/**
 * The definition of a menu option
 * The properties of this block determine how the name, appearance, and behavior of a menu option are calculated
 **/
export type MenuOption = {
	/** @property What the button for this option will say. Can be a string, an HTML Element, or a function that generates one. */
	optionLabel?: OptionLabelDef
	/** @property Optional function which can set conditions for showing/hiding this option. If no function is set, the option is always visible */
	visibilityFn?: () => boolean
	/** @property Optional function for determining if the option should be disabled. If no function is set, the option is always enabled */
	greyOutFn?: () => boolean
	/** @property the function to run when the option is clicked */
	actionHandler?: () => boolean | undefined | void
}

export type OptionLabel = string | HTMLElement
type OptionLabelDef = OptionLabel | (() => OptionLabel)

/**
 * A data block which defines an option in the menu
 **/
export type MenuOptionState = {
	/** @property A string or HTMLElement containing the text for the menu option */
	label: string | HTMLElement
	/** @property visible Boolean defining if the option is visible right now */
	visible?: boolean
	/** @property action Function which handles on-click behavior */
	action: () => boolean | undefined | void
	/** @property disabled if true, the option should be greyed out and unclickable */
	greyedOut?: boolean
}

export type MenuOptionGenerator = () => MenuOptionState
