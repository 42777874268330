<script lang="ts">
	import { PlusIcon } from "svelte-comps/icons"

	export let title: string
	export let onClick: () => void
</script>

<button id="cancel-button" class="small warn icon" {title} on:click={onClick}><PlusIcon /></button>

<style>
	#cancel-button {
		margin-left: 0.5rem;
	}

	#cancel-button > :global(svg) {
		width: 1rem;
		height: 1rem;
		transform: rotate(45deg);
	}

	#cancel-button > :global(svg .svg-stroke) {
		stroke: var(--color-error);
	}

	#cancel-button:hover > :global(svg .svg-stroke),
	#cancel-button:focus-visible > :global(svg .svg-stroke) {
		stroke: var(--color-text-light);
	}
</style>
