/**
 *
 */

import { getEditor } from "../../.."
import { Polygon } from "../.."
import { ClippingMask } from "./ClippingMask"

export class MaskPolygon extends Polygon {
	maskID: string

	constructor(mask: ClippingMask) {
		super(
			getEditor(),
			{ points: mask.points },
			{
				angle: mask.angle,
				left: mask.left,
				top: mask.top,
				scaleX: mask.scaleX,
				scaleY: mask.scaleY,
				originX: mask.originX,
				originY: mask.originY,
				absolutePositioned: true,
			}
		)

		this.maskID = mask.id
		mask.path.subscribe(this)
	}

	onPathUpdate(e) {
		console.log(e)
	}
}

/**
 * 
		const polygon: Polygon & { maskID: string } = new Polygon(
			getEditor(),
			{
				points: mask.points,
			},
			{
				angle: mask.angle,
				left: mask.left,
				top: mask.top,
				scaleX: mask.scaleX,
				scaleY: mask.scaleY,
				originX: mask.originX,
				originY: mask.originY,
				absolutePositioned: true,
			}
		) as Polygon & { maskID: string }
		polygon.maskID = mask.id

		return polygon
 */
