<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerSnapshot,
		type Calibration,
		type Snapshot,
	} from "luxedo-data"
	import CalibrationSnapshots from "./CalibrationSnapshots.svelte"
	import CalibrationMasks from "./CalibrationMasks.svelte"
	import { DeviceSnapshotManager } from "../../../../../../modules/device-operation-managers/DeviceSnapshotManager"

	export let calibration: Calibration
	let snapshots: Array<Snapshot>

	DeviceSnapshotManager.subscribe(() => {
		setTimeout(() => {
			snapshots = DataHandlerSnapshot.filterByCalibration(calibration.id)
		})
	})

	$: snapshots = DataHandlerSnapshot.filterByCalibration(calibration.id)
</script>

<div class="cal-entry" id="cal-entry-{calibration.id}">
	<h3>
		{calibration.runTime.toFormat("DDD")} at {calibration.runTime.toFormat("hh:mm a")}
	</h3>
	<CalibrationSnapshots
		{snapshots}
		device={DataHandlerDevice.get(calibration.dev_id)}
		{calibration}
	/>
	{#if snapshots.length}
		<CalibrationMasks {calibration} />
	{/if}
</div>

<style>
	h3 {
		font-size: var(--h1);
		color: var(--color-text);
		margin: 0;
		position: sticky;
		top: -1px;
		padding: 1rem 0 1rem 0;
		background-color: var(--color-bg-dark);
		z-index: 1;
		grid-column: 1/3;
	}

	/* .cal-entry {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.cal-entry :global(.snapshots-container) {
		margin-right: 0.5rem;
		height: fit-content;
	}

	.cal-entry :global(.masks-container) {
		margin-left: 0.5rem;
		height: fit-content;
	} */

	.cal-entry :global(.snapshots-container) {
		margin-bottom: 1rem;
	}
</style>
