<script lang="ts">
	import { tooltip } from "svelte-comps/tooltip"

	export let title
	export let url
	export let img
</script>

<a href={url} target="_blank">
	<button class="custom-select-instance" use:tooltip={{ content: title, pointing: "top" }}>
		<img src={img} alt="title" />
	</button>
</a>

<style>
	.custom-select-instance {
		position: relative;
		border-radius: 0.5rem;
		background-color: var(--color-bg-dark);
		min-width: 10rem;
		width: 12vw;
		height: 100%;
	}

	a {
		border: none;
	}

	img {
		object-fit: contain;
		height: 100%;
		width: auto;
	}

	button:hover {
		background-color: var(--color-bg-dark-transparent);
		transform: unset;
	}
</style>
