<script lang="ts">
	export let title: string = undefined
	export let videoURL: string = undefined
	export let description: string = undefined

	let containerWidth

	let iframeWidth = 384
	let iframeHeight = 216

	function updateEmbedSize(contWidth: number) {
		if (!contWidth) return

		let videoWidth = (contWidth / 3) * 2

		iframeHeight = (1080 * videoWidth) / 1920
		iframeWidth = videoWidth
	}

	$: updateEmbedSize(containerWidth)
</script>

<div class="video-tutorial" bind:clientWidth={containerWidth}>
	{#if title}
		<h3>{title}</h3>
	{/if}
	{#if description}
		<p>{@html description}</p>
	{/if}

	{#if videoURL}
		<iframe src={videoURL} {title} width={iframeWidth} height={iframeHeight} />
	{:else}
		<p>Tutorial video coming soon!</p>
	{/if}
</div>

<style>
	.video-tutorial {
		height: fit-content;
	}

	iframe {
		border: none;
	}

	h3 {
		margin: 0;
		font-size: var(--h1);
	}

	p {
		margin-top: 1rem;
		color: var(--color-text);
	}
</style>
