<script lang="ts">
	import type { MediaToolController } from "../MediaToolController"

	interface Controller extends MediaToolController<any> {}
	export let videoController: Controller

	let timestampPercent: number

	videoController.subscribe((ctx) => {
		timestampPercent = ctx.timestampPercent
	})

	function handleMouseDown(event: MouseEvent & { currentTarget: HTMLDivElement }) {
		const target = event.currentTarget

		const moveHandler = (e: MouseEvent) => {
			if (e.target !== target) return
			const percentPos = e.offsetX / target.clientWidth
			videoController.skipToDurationPercent(percentPos)
		}

		moveHandler(event)

		document.addEventListener("mousemove", moveHandler)
		document.addEventListener("mouseup", () => {
			document.removeEventListener("mousemove", moveHandler)
		})
	}
</script>

<div id="media-tool-timeline" on:mousedown={handleMouseDown}>
	<div id="current-timestamp" style="left: {timestampPercent * 100}%;" />
</div>

<style>
	#media-tool-timeline {
		width: 100%;
		margin: 0.5rem 0 0 0;
		position: relative;
		height: 1rem;
		background-color: var(--color-bg-dark);
		border-radius: calc(var(--br) / 2);
		overflow: hidden;
	}

	#current-timestamp {
		top: 0;
		position: absolute;
		width: 1px;
		background-color: var(--color-text-light);
		height: 1rem;
	}
</style>
