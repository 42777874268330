<script lang="ts">
	export let expanded: boolean = false
	export let title: string = undefined
	export let className: string = ""

	let contentHeight: number
	let contentContainer: HTMLDivElement

	export function toggle(expand?: boolean) {
		expanded = expand ?? !expanded
	}

	const resizeObserver = new ResizeObserver((entries) => {
		const entry = entries[0]
		updateContentHeight(entry.target as HTMLDivElement)
	})

	function updateContentHeight(div: HTMLDivElement) {
		contentHeight = div.offsetHeight
	}

	function initializeResizeObserver(div: HTMLDivElement) {
		if (!div) return

		resizeObserver.observe(contentContainer)
	}

	$: initializeResizeObserver(contentContainer)
</script>

<div class="collapsible-section {className} {expanded ? 'section-expanded' : ''}" {title}>
	<div class="section-header" on:click={() => toggle()}>
		<slot name="header" isExpanded={expanded} />
	</div>
	<div class="collapsible-section-body" style="height: {expanded ? contentHeight : 0}px;">
		<div bind:this={contentContainer} class="content">
			<slot name="content" />
		</div>
	</div>
</div>

<style>
	.section-header {
		position: relative;
		width: 100%;
		display: flex;
		align-items: start;
		cursor: pointer;
	}

	.collapsible-section {
		position: relative;
		width: 100%;
	}

	.collapsible-section-body {
		position: relative;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;
		height: 0px;
		transition: height 250ms;
	}
</style>
