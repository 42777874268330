import { fabric } from "fabric"
import { EditorClass, getEditor } from "../../.."
import { AssetOptions, CanvasAsset, Polygon } from "../.."
import { deepCopyPoints, findNearestPointOnLine, fixPointSet } from "../../../fabric-plugins"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../../modules/serialize"
import { QuadraticBezierEquidistantPointCache } from "./Bezier"

// index - point index within this._points; value - control points for quadratic or cubic bezier curve
export type CurvedPointData = {
	[index: number]: [fabric.Point] | [fabric.Point, fabric.Point]
}

export type PathMessageType =
	| "before:newsubscriber"
	| "subscribed"
	| "unsubscribed"
	| "deleted"
	| "point:add"
	| "point:delete"
	| "point:edit"
	| "point:edit:before"

export interface PathSubscriber {
	// Called when any changes are made to the path
	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	): any
}

export interface PathOptions extends AssetOptions {
	// The points to create this path
	points: fabric.Point[]
	// If defined, any point with a defined index within (see type definition) will have the control points applied
	curvedPointData?: CurvedPointData
	// If defined, the path's x/y coordinates will be determined by the relative object instead of using its own
	relativeOrigin?: CanvasAsset
	closed?: boolean
}

export interface Path extends CanvasAsset {}

@CanvasAsset.Mixin
export class Path extends fabric.Path implements Serializable {
	//#region		  ======================================	 Initialization	  ======================================

	declare editor: EditorClass

	declare points: Array<fabric.Point>
	declare curvedPointData: CurvedPointData
	declare curvedPointCache: {
		[index: number]: QuadraticBezierEquidistantPointCache
	}

	constructor(editor: EditorClass, options: PathOptions, style?: fabric.IPolylineOptions) {
		const fabricOptions = {
			...Path.DefaultOptions,
			...style,
			...Path.ForcedOptions,
		} as fabric.IPathOptions

		let pathPoints: string | Array<fabric.Point> = Path.createPointInstructions(options.points, options.curvedPointData)
		super(pathPoints, fabricOptions)

		// make sure to have copy of points
		this.closed = options.closed ?? false
		this.points = options.points

		this.curvedPointData = options.curvedPointData ?? {}
		this.initializeCache()

		if (options.relativeOrigin) this.useRelativeOrigin(options.relativeOrigin)

		this.editor = editor

		this.initAsset(options)
	}

	//#endregion	======================================	 Initialization	  ======================================
	//#region		  ======================================			Interface			======================================

	/**
	 * Gets the points for this path
	 * @returns The points within this path
	 */
	getPoints() {
		return this.points
	}

	/**
	 * Inserts a point at the specified index (defaults to 0)
	 * @param point The point to add
	 * @param index The index where to add
	 */
	insert(point: fabric.Point, index?: number) {
		if (!index || index >= this.points.length) {
			index = this.points.length
		} else if (index < 0) {
			index = 0
		}

		this.points.splice(index, 0, point)
		this.updateCurvedPointData(index, "insert")
		this.notifySubscribers("point:add", index, point)

		this.refresh()
	}

	/**
	 * Deletes a point at the specified index
	 * @param index the index to delete
	 */
	delete(index: number) {
		const oldPoint = this.points[index]
		this.points.splice(index, 1)
		this.updateCurvedPointData(index, "delete")
		this.notifySubscribers("point:delete", index, oldPoint)

		this.refresh()
	}

	/**
	 * Removes the curve data for the specified point index
	 * @param index the point index of the curve data
	 */
	clearCurve(index: number) {
		if (!this.curvedPointData[index]) return

		delete this.curvedPointData[index]
		delete this.curvedPointCache[index]
		this.notifySubscribers("point:edit", index)
		this.refresh()
	}

	/**
	 * Modifies an existing point at the specified index
	 * @param index the index to modify
	 * @param value the new point value
	 */
	editPoint(index: number, value: { x: number; y: number }) {
		const point = this.points[index]
		const oldPoint = new fabric.Point(point.x, point.y)

		this.notifySubscribers("point:edit:before", index, new fabric.Point(value.x, value.y))
		point.setFromPoint(value)
		this.notifySubscribers("point:edit", index, oldPoint)

		this.refresh()
	}

	/**
	 * Adds curve data to the specified point and updates the path data
	 * @param index the index of the point to curve
	 * @param controlPoint1 the first control point (this will be the only one if it is a quadratic curve)
	 * @param controlPoint2 [OPTIONAL] the second control point (if not provided, the curve will be quadratic rather than cubic)
	 */
	curvePoint(index: number, controlPoint1: fabric.Point, controlPoint2?: fabric.Point) {
		controlPoint1 = this.toLocalPoint(controlPoint1, "center", "center")
		this.curvedPointData[index] = controlPoint2 ? [controlPoint1, controlPoint2] : [controlPoint1]
		if (!this.curvedPointCache[index])
			this.curvedPointCache[index] = new QuadraticBezierEquidistantPointCache(
				this.points[index - 1],
				controlPoint1,
				this.points[index]
			)
		else {
			this.curvedPointCache[index].A = this.points[index - 1]
			this.curvedPointCache[index].B = controlPoint1
			this.curvedPointCache[index].C = this.points[index]
		}

		this.notifySubscribers("point:edit", index)
		this.refresh()
	}

	/**
	 * Updates the path data by recreating the point instructions and parsing the path
	 */
	refresh() {
		const path = fabric.util.parsePath(Path.createPointInstructions(this.points, this.curvedPointData))
		this.set({ path } as Partial<this>)
	}

	/**
	 * Register this to the path controller
	 */
	register() {
		this.editor.paths.register(this)
	}

	/**
	 * Returns true if this path has been registered to the path controller
	 */
	get registered(): boolean {
		return this.editor.paths.isRegistered(this)
	}

	get absolutePoints() {
		const points = deepCopyPoints(this.points)

		for (let i = 0; i < points.length; i++) {
			points[i].setFromPoint(this.toAbsolutePoint(this.points[i]))
		}

		return points
	}

	//#endregion	======================================			Interface			======================================
	//#region   	=====================================  Point Interpolation  ====================================

	/**
	 * Finds the point on this path nearest to the point provided, interpolating the curve data to provide points influenced by the curve
	 * @param point the point to find the nearest path point to (absolute, not relative)
	 */
	findNearestPoint(point: fabric.Point): {
		nearestPoint: fabric.Point
		distance: number
		lineIndex: number
	} {
		if (this.points.length < 2) return

		const lines: Array<[fabric.Point, fabric.Point]> = []
		const controlPoints: Array<[fabric.Point, fabric.Point] | [fabric.Point]> = []

		const absolutePoints = this.absolutePoints

		for (let i = 1; i < absolutePoints.length; i++) {
			lines.push([absolutePoints[i - 1], absolutePoints[i]])
			if (this.curvedPointData[i])
				controlPoints.push(this.curvedPointData[i].map((pt) => this.toAbsolutePoint(pt)) as [fabric.Point])
			else controlPoints.push(undefined)
		}

		let distance: number = 999999
		let lineIndex: number = 0
		let nearestPoint: fabric.Point

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i]
			const controlPoint = controlPoints[i]
			let nearPoint: fabric.Point
			if (controlPoint)
				nearPoint = this.toAbsolutePoint(
					this.curvedPointCache[i + 1].getNearestPoint(this.toLocalPoint(point, "left", "top"))
				)
			else nearPoint = findNearestPointOnLine(point, line)
			const dist = point.distanceFrom(nearPoint)

			if (dist < distance) {
				distance = dist
				lineIndex = i
				nearestPoint = nearPoint
			}
		}

		return {
			distance,
			lineIndex,
			nearestPoint,
		}
	}

	/**
	 * Called when a point is added or removed to update the index of all of the curvedPointData.
	 * This ensures adding or removing points does not break which curves are applied to which points.
	 */
	updateCurvedPointData(pointIndex: number, action: "insert" | "delete") {
		if (action === "delete") {
			if (this.curvedPointData[pointIndex]) delete this.curvedPointData[pointIndex]
			if (this.curvedPointCache[pointIndex]) delete this.curvedPointCache[pointIndex]
		}

		// curves associated with these indecies will trigger a regen cache
		let updateIndecies = [pointIndex]

		// Loop throught the curvedPointData, moving each curve instance if relevant
		for (let key of Object.keys(this.curvedPointData)) {
			let index = Number(key) // Index of the point according to this.points
			if (index >= pointIndex) {
				const data = this.curvedPointData[index]
				const cache = this.curvedPointCache[index]

				let newIndex = action === "insert" ? index + 1 : index - 1
				delete this.curvedPointData[index]
				delete this.curvedPointCache[index]
				this.curvedPointData[newIndex] = data
				this.curvedPointCache[newIndex] = cache

				updateIndecies.push(newIndex)
			}
		}

		// If adding a point on a curve, update the curved cache data with the new points and regenerate
		if (action == "insert" && pointIndex + 1 in this.curvedPointCache) {
			this.curvedPointCache[pointIndex + 1].A = this.points[pointIndex]
			this.curvedPointCache[pointIndex + 1].C = this.points[pointIndex + 1]
			this.curvedPointCache[pointIndex + 1].regenerateCache()
		}
	}

	initializeCache() {
		this.curvedPointCache = {}
		for (const i in this.curvedPointData) {
			let index = Number(i)

			this.curvedPointCache[index] = new QuadraticBezierEquidistantPointCache(
				this.points[index - 1],
				this.curvedPointData[index][0],
				this.points[index]
			)
		}
	}

	//#endregion	=====================================  Point Interpolation  ====================================
	//#region   	====================================	Subscriber Management	====================================

	// An array of listeners subscribed to this path
	public subscribers: PathSubscriber[] = []

	/**
	 * Adds a listener for when this path is updated
	 * @param listener the object which needs to be updated when this path is modified
	 * @returns an unbinder funtion
	 **/
	subscribe(listener: PathSubscriber): () => void {
		this.notifySubscribers("before:newsubscriber")

		this.subscribers.push(listener)
		listener.onPathUpdate(this, "subscribed", this.points)

		return () => this.unsubscribe(listener)
	}

	/**
	 * Removes a listener
	 * @param listener the object (mask, position path, ect) to remove
	 */
	private unsubscribe = (listener: PathSubscriber) => {
		const i = this.subscribers.indexOf(listener)
		if (i == -1) return
		this.subscribers.splice(i, 1)
		listener.onPathUpdate(this, "unsubscribed", this.points)
	}

	/**
	 * Triggers `onPathUpdate` for all subscribers
	 * @param messageType the message type being sent
	 * @param modIndex the point index being updated
	 * @param modPoint the point itself
	 */
	notifySubscribers(messageType: PathMessageType, modIndex?: number, modPoint?: fabric.Point) {
		for (const listener of this.subscribers) {
			listener.onPathUpdate(this, messageType, this.points, modIndex, modPoint)
		}
	}

	//#endregion  ====================================	Subscriber Management	====================================
	//#region   	==================================== 		Fabric Overrides		====================================

	// Used to force visibility when this path would otherwise not be visible
	_visibilityOverride: boolean = undefined

	/**
	 * Sets the _visibilityOverride to ensure visibility
	 * @param val the new visibility value
	 */
	forceVisibility(val?: boolean) {
		this._visibilityOverride = val
	}

	/**
	 * True if the path controller paths are visible, or if this path has been overriden
	 */
	get visible() {
		return this.editor.paths.visible || this._visibilityOverride
	}

	/**
	 * Sets the visibility override
	 */
	set visible(v: boolean | undefined) {
		this._visibilityOverride = v
	}

	/**
	 * Always false
	 */
	get evented() {
		return false
	}

	/**
	 * Does nothing
	 */
	set evented(v) {
		return
	}

	/**
	 * Returns the current editor canvas object
	 */
	get canvas() {
		return this.editor.canvas
	}

	/**
	 * Does nothing
	 */
	set canvas(v) {
		return
	}

	//#endregion  ==================================== 		Fabric Overrides		====================================
	//#region		  ======================================		Object Sync			======================================

	/**
	 * Aligns the points and positioning of this path to align with the provided polyline object
	 * @param shape The polyline object to align with
	 */
	like(shape: fabric.Polyline) {
		if (this.points.length !== shape.points.length) {
			this.points = deepCopyPoints(shape.points)
		}

		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left
		this.top = shape.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)

		let i = 0
		for (const pt of this.getPoints()) {
			this.editPoint(i, shape.points[i++])
		}
	}

	/**
	 * Replaces positioning values (top & left) with getters and setters to ensure this path is "anchored" to the provided target object
	 * @param target The object to "anchor" to
	 */
	useRelativeOrigin(target: CanvasAsset) {
		this.left = target.getBaseValue("left")
		this.top = target.getBaseValue("top")

		Object.defineProperty(this, "left", {
			get() {
				return target.getBaseValue("left")
			},
			set(v) {
				target.left = v
			},
		})
		Object.defineProperty(this, "top", {
			get() {
				return target.getBaseValue("top")
			},
			set(v) {
				target.top = v
			},
		})
	}

	//#endregion	======================================		Object Sync			======================================
	//#region   	======================================	Static Generators ======================================

	/**
	 * Create a new path object using the provided fabric object's defined points
	 * @param object A fabric object which extends from Polyline (w
	 * @returns the new path
	 */
	static FromObject(object: fabric.Polyline, style?: fabric.IPolylineOptions) {
		const objectPoints = deepCopyPoints(object.getPoints())
		if (object instanceof Polygon) objectPoints.push(objectPoints[0])
		return new this(getEditor(), { points: objectPoints, closed: object instanceof Polygon }, style)
	}

	/**
	 * Creates draw instructions for this path instance, using the points and curved point data as a guide.
	 * @param points An array of points on the path
	 * @param curvedPointData An object containing each curved point's control points (e.g., {1: [20, 20]} would apply a quadratic curve to the point at index 1)
	 * @returns String containing the curved point instructions
	 */
	static createPointInstructions(points: fabric.Point[], curvedPointData?: CurvedPointData) {
		if (points.length === 0) return ""

		const curvedPointIndecies = curvedPointData ? Object.keys(curvedPointData).map((key) => Number(key)) : []
		let instructions: Array<string> = []
		instructions.push(`M ${points[0].x},${points[0].y}`)

		for (let i = 1; i < points.length; i++) {
			const point = points[i]

			if (curvedPointIndecies.includes(i)) {
				const curveData = curvedPointData[i]
				const curveInstruction = `Q ${curveData[0].x},${curveData[0].y} ${point.x},${point.y}`
				instructions.push(curveInstruction)
			} else {
				const linearInstruction = `L ${point.x},${point.y}`
				instructions.push(linearInstruction)
			}
		}

		return instructions.join(" ")
	}

	//#endregion  ==================================== 		Static Generators		======================================
	//#region   	====================================== 		Serialization		======================================

	/**
	 * Loads a serialized JSON block to create a new instance
	 * @param editor The current editor instance
	 * @param data The JSON block
	 * @param id The id of the instance (only provided if import - not copy/paste)
	 * @returns The new instance
	 */
	static async loadJSON(editor: EditorClass, data: Partial<Path>, id?: string): Promise<Path> {
		if (editor.paths.isRegistered(id)) return editor.paths.getPath(id)

		if (id) data["id"] = id
		data.points = fixPointSet(data.points)
		const newPath = new this(editor, data as PathOptions)
		if ("pathOffset" in data) newPath.pathOffset = data.pathOffset
		return newPath
	}

	/**
	 * Converts this path instance to a JSON block
	 * @param forExport if true, will include id
	 * @returns the JSON block representing this instance
	 */
	serialize(forExport?: boolean) {
		const data = convertInstanceToObject(this, {
			forExport,
			propertiesToExclude: ["controls", "subscribers", "_points", "points", "curvedPointCache"],
		})

		data["points"] = deepCopyPoints(this.points)
		data["id"] = this.id

		return data
	}

	//#endregion  ====================================== 		Serialization		======================================
	//#region   	====================================== 			Defaults			======================================

	/**
	 * Default options will be used unless overwritten by the provided options during construction
	 */
	static DefaultOptions: fabric.IPathOptions = {
		strokeUniform: true,
		transparentCorners: false,
		cornerStyle: "circle" as const,
		stroke: "#ffffff88",
		strokeWidth: 2,
		originX: "center",
		originY: "center",
	}

	/**
	 * Forced options will overwrite provided options during construction
	 */
	static ForcedOptions: fabric.IPathOptions = {
		fill: undefined,
		objectCaching: false,
		hasBorders: false,
	}

	//#endregion  ====================================== 			Defaults			======================================
}

registerSerializableConstructor(Path)
