import {
	Scene,
	Lightshow,
	LightshowBreak,
	type LightshowContentSequence,
	DataHandlerDevice,
	DataHandlerLightshow,
	Device,
	DataHandlerScene,
} from "luxedo-data"
import { closeOverlay } from "svelte-comps/overlay"
import { get as storeGet, writable } from "svelte/store"
import { getCurrentUser } from "../../../stores/UserStore"
import { navigateTo } from "../../../stores/NavigationContext"
import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"
import { openShowOverview } from "../../reusable/overlays"
import { Toast } from "svelte-comps/toaster"

export type ShowLibraryViewOption = "Scenes" | "Lightshows"

type ShowLibraryCTX = {
	view: ShowLibraryViewOption
	isEditingLightshow: boolean
	deviceFilter?: Device
	filterSelection: {
		filter: string
		order: string
	}
}

/**
 * This module manages the state of the show library. At a top level,
 * the only state this module interacts with is purely visual (viewing scenes/ lightshow or currently edititng a lightshow).
 * Within this module are other modules which manage more granular state (the sequence within a lightshow being editted).
 */
export namespace ShowLibraryController {
	/** General library state store - handles top level state (the view of the show library) */
	const libraryStore = writable<ShowLibraryCTX>({
		view: "Scenes",
		isEditingLightshow: false,
		filterSelection: {
			filter: "Last Modified",
			order: "Descending",
		},
	})

	export function subscribe(cb: (ctx: ShowLibraryCTX) => void) {
		return libraryStore.subscribe(cb)
	}

	export function get() {
		return storeGet(libraryStore)
	}

	/** Change the current view of the show library (lightshows or scenes) */
	export function changeView(newView: ShowLibraryViewOption) {
		libraryStore.update((ctx) => {
			return { ...ctx, view: newView, isEditingLightshow: false }
		})
	}

	/** Update the selected filter */
	export function updateFilterOptions(newFilterOptions: { filter: string; order: string }) {
		libraryStore.update((ctx) => {
			return {
				...ctx,
				filterSelection: newFilterOptions,
			}
		})
	}

	/** Sets the library device filter */
	export function setDeviceFilter(dev: Device) {
		libraryStore.update((ctx) => {
			return {
				...ctx,
				deviceFilter: dev,
			}
		})
	}

	export async function editShow(show: Scene | Lightshow) {
		if (show instanceof Lightshow) return LightshowEditor.editLightshow(show)
		if (show.isDirectUpload) return openShowOverview(show)
		if (await show.checkIfOldVersion()) {
			Toast.text("Creating copy and upgrading your scene for the new editor.")
			const id = await show.upgrade()
			DataHandlerScene.get(id).openInEditor()
		} else return show.openInEditor()
	}

	/** This module manages the state of lightshow editting. */
	export namespace LightshowEditor {
		type LightshowEditorCTX = {
			sequence: LightshowContentSequence
			selectedScenes: Array<Scene>
			assignedDevice?: Device
			lightshowDraft?: Lightshow
		}

		const lightshowStore = writable<LightshowEditorCTX>({
			sequence: [],
			selectedScenes: [],
		})

		export function subscribe(cb: (ctx: LightshowEditorCTX) => void) {
			return lightshowStore.subscribe(cb)
		}

		/** Updates the sequence while also updating the selected scenes.
		 * If the sequence is updated outside of this method, the selectedScenes will not be updated. */
		function updateSequence(sequence: LightshowContentSequence) {
			lightshowStore.update((ctx) => {
				return {
					...ctx,
					sequence,
					selectedScenes: getSelectedScenesFromSequence(sequence),
				}
			})
		}

		/** Initializes the lightshow editor, emptying the sequence */
		export function createLightshow(options?: { device?: Device }) {
			navigateTo("show-library")
			setTimeout(() => {
				if (options && options.device) ShowLibraryController.setDeviceFilter(options.device)

				libraryStore.update((ctx) => {
					return { ...ctx, isEditingLightshow: true, view: "Scenes" }
				})

				lightshowStore.set({
					sequence: [],
					selectedScenes: [],
				})
			})
		}

		/** Initializes the lightshow editor populating with the lightshow's sequence */
		export function editLightshow(lightshow: Lightshow) {
			navigateTo("show-library")
			closeOverlay()
			setTimeout(() => {
				libraryStore.update((ctx) => {
					return { ...ctx, isEditingLightshow: true, view: "Scenes" }
				})
				lightshowStore.set({
					sequence: lightshow.getScenesInOrder(),
					selectedScenes: getSelectedScenesFromSequence(lightshow.getScenesInOrder()),
					assignedDevice: DataHandlerDevice.get(lightshow.target_device_id),
					lightshowDraft: lightshow,
				})
			})
		}

		/** Deletes all instances of a scne from the lightshow context */
		export function deleteScene(scene: Scene) {
			const sequence = storeGet(lightshowStore).sequence.filter((block) =>
				block instanceof Scene && block.id === scene.id ? false : true
			)
			updateSequence(sequence)
		}

		/** Adds a new scene or break to the lightshow at the specified index (or at the end if not provided),
		 * Will set the projector filter if applicable. */
		export function addBlock(block: Scene | LightshowBreak, index: number = undefined) {
			const sceneSequence = storeGet(lightshowStore).sequence.filter((block) =>
				block instanceof LightshowBreak ? false : true
			)
			if (sceneSequence.length === 0) {
				const device = DataHandlerDevice.get((block as Scene).target_device_id)
				lightshowStore.update((ctx) => {
					return { ...ctx, assignedDevice: device }
				})
				if (device) ShowLibraryController.setDeviceFilter(device)
			}

			let newSequence: LightshowContentSequence = storeGet(lightshowStore).sequence
			if (index === undefined) {
				newSequence.push(block)
			} else {
				if (index < 0) index = Math.max(0, storeGet(lightshowStore).sequence.length + index) // If negative index, assume backward index (-1 is one before the last block)
				newSequence = newSequence.toSpliced(index, 0, block)
			}

			updateSequence(newSequence)
		}

		/** Removes a lightshow block at the specified index */
		export function deleteBlock(index: number) {
			return deleteBlocks([index])
		}

		/** Removes lightshow blocks at the specified indicies */
		export function deleteBlocks(indicies: number[]) {
			if (indicies.length === 0) return
			const sortedIndicies = [...new Set(indicies.toSorted((a, b) => b - a))] // Sort indicies and ensure no doubles
			const sequence = storeGet(lightshowStore).sequence
			for (let i = 0; i < indicies.length; i++) {
				const index = indicies[i] - i // Get index and subtract iteration amount to keep up with new array count (because the indecies are in order this should just work)
				sequence.splice(index, 1)
			}
			updateSequence(sequence)
			if (sequence.length === 0) {
				SelectedDeviceStore.set(undefined)
			}
		}

		/** Closes the lightshow editor without saving. */
		export function closeEditor() {
			SelectedDeviceStore.set(undefined)
			libraryStore.update((ctx) => {
				return {
					...ctx,
					isEditingLightshow: false,
					view: "Lightshows",
				}
			})
		}

		/** Saves the lightshow in its current state */
		export async function save(title: string) {
			const ctx = storeGet(lightshowStore)

			let lightshow = ctx.lightshowDraft
			const device = ctx.assignedDevice

			if (!device) throw new Error("No assigned device!")

			if (lightshow && title !== lightshow.name) {
				await DataHandlerLightshow.rename(lightshow, title)
			} else if (!lightshow) {
				const lightshowId = await DataHandlerLightshow.createEntry({
					name: title,
					parent_id: getCurrentUser().directories.lightshow,
					res_x: device.resX,
					res_y: device.resY,
					assigned_device_id: device.id,
					target_device_id: device.id,
				})
				lightshow = DataHandlerLightshow.get(lightshowId)
			}

			lightshow.updateBlocksFromList(ctx.sequence)
			await DataHandlerLightshow.save(lightshow)

			libraryStore.update((ctx) => {
				return {
					...ctx,
					isEditingLightshow: false,
					view: "Lightshows",
				}
			})
		}

		/** Filters any breaks out of the sequence and returns an array of scenes, ensuring only one instance of each. */
		function getSelectedScenesFromSequence(sequence: LightshowContentSequence): Array<Scene> {
			const scenes = [
				...new Set(sequence.filter((block) => (block instanceof LightshowBreak ? false : true))),
			] as Array<Scene>
			return scenes
		}
	}
}
