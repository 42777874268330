import { fabric } from "fabric"
import { Path } from "typescript"

export type Corners = [fabric.Point, fabric.Point, fabric.Point, fabric.Point]

export function calcCenter(points: fabric.Point[]) {
	return new fabric.Point(
		(fabric.util.array.max(points, "x") + fabric.util.array.min(points, "x")) / 2,
		(fabric.util.array.max(points, "y") + fabric.util.array.min(points, "y")) / 2
	)
}

export function calcBounds(points: fabric.Point[]) {
	return {
		left: fabric.util.array.min(points, "x"),
		top: fabric.util.array.min(points, "y"),
		right: fabric.util.array.max(points, "x"),
		bottom: fabric.util.array.max(points, "y"),
	}
}

/**
 * If the points aren't actual fabric.Point instances, convert them
 * This is used to fix errors when loading a file
 */
export function fixPointSet(points: fabric.Point[]) {
	if (!(points[0] instanceof fabric.Point)) {
		points = points.map((pt) => new fabric.Point(pt.x, pt.y))
	}

	const lastIndex = points.length - 1
	if (points[0].x == points[lastIndex].x && points[0].y == points[lastIndex].y) {
		points[lastIndex] = points[0]
	}
	return points
}

export function offsetPoints(points: fabric.Point[], offset: fabric.Point): fabric.Point[] {
	for (const pt of points) {
		pt.addEquals(offset)
	}
	return points
}

export function deepCopyPoints(points: fabric.Point[]): fabric.Point[] {
	return points.map((pt) => {
		return new fabric.Point(pt.x, pt.y)
	})
}

export function getQuadrilateralIsConvex(points: fabric.Point[]) {
	function crossProduct(vector1: fabric.Point, vector2: fabric.Point): number {
		return vector1.x * vector2.y - vector1.y * vector2.x
	}

	let pt1 = points[0]
	let pt2 = points[1]
	let pt3 = points[2]
	let pt4 = points[3]

	const vector1 = pt2.subtract(pt1)
	const vector2 = pt3.subtract(pt2)
	const vector3 = pt4.subtract(pt3)
	const vector4 = pt1.subtract(pt4)

	const crossProduct1 = crossProduct(vector1, vector2)
	const crossProduct2 = crossProduct(vector2, vector3)
	const crossProduct3 = crossProduct(vector3, vector4)
	const crossProduct4 = crossProduct(vector4, vector1)

	if (crossProduct1 * crossProduct3 < 0 || crossProduct2 * crossProduct4 < 0) return false
	return true
}

export function findNearestPointOnLine(point: fabric.Point, line: [fabric.Point, fabric.Point]): fabric.Point {
	const pointA = line[0]
	const pointB = line[1]

	const pointToA = point.subtract(pointA)
	const aToB = pointB.subtract(pointA)
	const aToBsqr = aToB.x ** 2 + aToB.y ** 2

	const A2P_dot_A2B = pointToA.x * aToB.x + pointToA.y * aToB.y

	const dist = Math.max(0, Math.min(A2P_dot_A2B / aToBsqr, 1))

	return new fabric.Point(pointA.x + aToB.x * dist, pointA.y + aToB.y * dist)
}

/**
 * Returns the point on a quadratic bezier point at the specified t value
 * @param line the line segment with the curve applied
 * @param controlPoint the control point applied to the line segment
 * @param t the curve position to find the point
 * @returns fabric point
 */
export function interpolateQuadraticBezierPoint(
	line: [fabric.Point, fabric.Point],
	controlPoint: fabric.Point,
	t: number
): fabric.Point {
	const x = (1 - t) * (1 - t) * line[0].x + 2 * (1 - t) * t * controlPoint.x + t * t * line[1].x
	const y = (1 - t) * (1 - t) * line[0].y + 2 * (1 - t) * t * controlPoint.y + t * t * line[1].y
	return new fabric.Point(x, y)
}

export function distanceSquared(p1: fabric.Point, p2: fabric.Point): number {
	return (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2
}

/**
 * Finds the point closest to the provided point (arg1) on a quadratic bezier curve
 * @param point the point to find closest to
 * @param line the line segment with the curve applied
 * @param controlPoint the control point applied to the line segment
 * @returns the closest point
 */
export function findNearestPointOnQuadraticBezier(
	point: fabric.Point,
	line: [fabric.Point, fabric.Point],
	controlPoint: fabric.Point
): fabric.Point {
	let closestPoint = line[0]
	let minDistance = distanceSquared(point, closestPoint)
	let prevDistance = 9999999

	// Iterate over the curve using a fixed number of steps to approximate the closest point
	const steps = 100
	for (let i = 0; i <= steps; i++) {
		const t = i / steps
		const curvePoint = interpolateQuadraticBezierPoint(line, controlPoint, t)
		const dist = distanceSquared(point, curvePoint)
		prevDistance = dist
		if (dist < minDistance) {
			minDistance = dist
			closestPoint = curvePoint
		}
		if (dist > prevDistance) return closestPoint
	}

	return closestPoint
}

const SEGMENT_AMOUNT = 100
export function findQuadraticBezierLength(line: [fabric.Point, fabric.Point], controlPoint: fabric.Point) {
	let length = 0
	let prevPoint = line[0]

	for (let i = 1; i <= SEGMENT_AMOUNT; i++) {
		const t = i / SEGMENT_AMOUNT
		const currentPoint = interpolateQuadraticBezierPoint(line, controlPoint, t)
		length += Math.sqrt(distanceSquared(prevPoint, currentPoint))
		prevPoint = currentPoint
	}

	return length
}
